import React, { useEffect, useState } from 'react';
import { useAuth } from '../../services/auth/use-auth';
import s from './ImageStoryModal.module.scss';
import { Chip, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextGenerationService from '../../services/textgeneration.service';

import { IoCopyOutline } from 'react-icons/io5';
import { GrDownload } from 'react-icons/gr';


const ImageStoryModal = ({ item }) => {
  console.log('ImageStoryModal: item-', item);
  const auth = useAuth();
  const [textGens, setTextGens] = useState(false);
  const [sourceImage, setSourceImage] = useState(false);  

  const getTextGenerations = async (item) => {
    console.log('getTextGenerations: ' + item);
    var textGens = await TextGenerationService.GetUserTextGenerationsByImageId(
      auth.user.uid,
      item.image_id,
      0,
      48,
      auth.user.auth.currentUser.accessToken
    );

    console.log('getTextGenerations: ' + JSON.stringify(textGens));
    setTextGens(textGens);
  }

  useEffect(() => {
    //setSourceImage(item);
    getTextGenerations(item);
  }, []);

  return (
    //console.log('ImageStoryModal: item-', item),
    <div className={s.layout}>
      <Chip
        color="secondary"
        label={<Typography>{' ' + item.category + ' '}</Typography>}
      />
      <p className={s.layout__title}>{item.title}</p>

      <img
        src={item.public_url_protected}
        alt={item.description}
        className={s.layout__image}
      />

      <div>
       
          {textGens && textGens.map((textGen, index) => {
            console.log('textGen: ' + JSON.stringify(textGen));
            return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ backgroundColor: '#f0f0f0' }}
                >
                  { new Date(textGen.generated_timestamp).toLocaleString()}  &nbsp; | &nbsp; 
                  {textGen.textgen_type}  &nbsp; | &nbsp; 
                  {(textGen.tags.length > 0) ? textGen.tags[0] : ""}  &nbsp; | &nbsp; 
                  {(textGen.tags.length > 1) ? textGen.tags[1] : ""}  &nbsp; | &nbsp; 
                  {(textGen.tags.length > 2) ? textGen.tags[2] : ""}
                </AccordionSummary>
                <AccordionDetails
                   sx={{ backgroundColor: '#f0f0f0' }}
                >
                  {textGen.text} 
                </AccordionDetails>
              </Accordion>
            );
          })}

      { /*
      <div className={s.layout__types}>
        <p>TYPE: STORY</p>
        <p>GENRE: DRAMA</p>
        <p>EMOTIVE: LOVE</p>
        <p>ATMOSPHERE: FOGGY</p>
        <IoCopyOutline />
        <GrDownload />
      </div>
       
      <p className={s.layout__footer}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
        malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum
        dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus
        ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet,
        consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
        blandit leo lobortis eget.
      </p>
       */ }
    </div>
  </div>
  );
};

export default ImageStoryModal;
