import React from 'react';
import { Typography } from '@mui/material';
import '../navbar/Navbar.css';
import { FiEdit } from 'react-icons/fi';
import { HiXMark } from 'react-icons/hi2';
import './Modal.scss';

function Modal({ setOpenModal, title, children }) {
  const _clickHandler = (e) => {
    //do thing
    setOpenModal(false);
  };
  const testStyle = {
    display: 'block',
  };
  return (
    <div className="modal fade show" tabindex="-1" style={testStyle}>
      <div className="modal-dialog modal-dialog-centered"
        style={{
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          minWidth: '360px',
          height: '100%',
          margin: 0,
          padding: 0,
        }}
      >
        <div className="modal-content"
        
        >
          <div
            className="modal-header"
            style={{
              backgroundColor: '#183E4B',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
              <span>
                <span
                  className="navbar-logo2"
                  style={{ fontSize: '20px', padding: 0, margin: 0 }}
                >
                  SmartAI&nbsp;
                </span>
                <span
                  className="navbar-company2"
                  style={{ fontSize: '20px', padding: 0, margin: 0 }}
                >
                  Stock Images
                </span>
              </span>

              <Typography
                variant="caption"
                sx={{
                  color: 'white',
                  fontSize: '0.75rem',
                  textTransform: 'uppercase',
                  padding: 0,
                  margin: 0,
                }}
              >
                a smartai.studio production
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <div>
                <p
                  style={{
                    fontSize: '20px',
                    color: '#fff',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  My stories
                </p>
              </div>
              <div>
                <FiEdit
                  style={{
                    color: 'rgba(255, 255, 255, 1)',
                    width: '29px',
                    height: '29px',
                    cursor: 'pointer',
                  }}
                />
              </div>
            </div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={_clickHandler}
              style={{
                outline: 'none',
                background: 'transparent',
                border: 'none',
                width: '30px',
                height: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <HiXMark
                style={{ width: '30px', height: '30px', color: '#fff' }}
              />
            </button>
          </div>
          <div
            style={{
              backgroundColor: '#fff',
              borderBottomLeftRadius: '5px',
              borderBottomRightRadius: '5px',
              overflowY: 'scroll',
            }}
            className="modal-body"
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
