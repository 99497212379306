import { apiURI } from "../shared/api.settings";
import axios from "axios";

class UserService {
  /*
  static GetUserCredits = async (id) => {
      return fetch(`${apiURI}/credits?userId=${id}&extendedInfo=false`)
          .then((response) => response.json());
  }
  */
  static GetUserCredits = async (id, token) => {
    return fetch(
      `${apiURI}/credits/current?userId=${id}&extendedInfo=false`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((response) => response.json());
  };
  
  static GetUserSubscription = async (id, token) => {
    return fetch(`${apiURI}/subscription/current?userId=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(
      (response) => response.json()
    );
  };

  static GetUserProfile = async (userId, accessToken) => {
    return axios.get(`${apiURI}/user/${userId}/profile`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  static UpdateUserProfile = async (userId, accessToken, user) => {
    return axios.put(
      `${apiURI}/user/${userId}/profile`,
      { ...user },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  static CreateNewUserAccount = async ({
    userId,
    email,
    password,
    displayName,
    photoUrl = null,
    phoneNumber = null,
    plan = "Free",
  }) => {
    var body = {
      userId,
      email,
      password,
      displayName,
      photoUrl,
      phoneNumber,
      subscriptionTierName: plan,
    };
    // console.log("body: ", body);
    return fetch(`${apiURI}/user`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  };

  static UpgradeUserAccount = ({ userId, plan }) => {
    var body = {
      userId,
      subscriptionTierName: plan,
    };
    console.log("body: ", body);
    return axios.put(`${apiURI}/user/subscription/upgrade`, body);
  };

  /*
  static SendPasswordResetEmail = (emailToSendTo) => {
    // console.log("emailToSendTo: ", emailToSendTo);
    return axios.post(`${apiURI}/user/password/reset?emailToSendReset=${emailToSendTo}`)
  }
*/

  static SendPasswordResetEmail = async (emailToSendTo) => {
    // console.log("parameter ", emailToSendTo);

    var url = `${apiURI}/user/password/reset`;

    var body = {
      emailToSendReset: emailToSendTo,
    };

    // console.log("calling ", url, " body: ", body);

    // POST request using fetch with set headers
    const requestOptions = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    fetch(url, requestOptions).then((response) => response.json());
  };
}

export default UserService;
