import React, { useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import {
  Box,
  Container,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  useMediaQuery,
  Card,
  CardContent,
  CardHeader,
  Button,
  Paper,
  Input,
  TextField,
  CardMedia,
  Chip,
} from "@mui/material";
import {
  Check,
  CheckCircleOutline,
  DownloadOutlined,
  PaletteOutlined,
  EditOutlined,
  ShareOutlined,
  Download,
  FavoriteOutlined,
} from "@mui/icons-material";

import "./Tiles2.scss";
import loveIcon from "../../assets/img/loveIcon.svg";
import notFavoritedIcon from "../../assets/img/notFavoritedIcon.png";
import SocialShare from "../social-share/SocialShare";
import ImageService from "../../services/image.service";
import { useAuth } from "../../services/auth/use-auth";

export default function Tiles({ data, props, faves }) {
  const onTablet = useMediaQuery("(max-width:900px)");
  const onMobile = useMediaQuery("(max-width:600px)");
  const onXSMMobile = useMediaQuery("(max-width:331px)");
  const navigate = useNavigate();

  const auth = useAuth();

  // loop through favorites and set is_favorited to true
  if (faves && faves.length > 0) {
    if (data && data.length > 0)
      data.map((item, index) => {
        faves.map((fave, index) => {
          if (item.image_id === fave.image_id) {
            item.is_favorited = true;
          }
        });
      });
  }

  const download = async (url) => {
    // console.log("downloadImage: " + JSON.stringify(data));
    var urlDownload = url ? url : data.public_url;
    const blob = await fetch(urlDownload).then((r) => r.blob());
    const urlBlob = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = urlBlob;
    link.target = "_blank";
    link.download =
      "SmartAI-StockImages_" +
      urlDownload.substr(urlDownload.lastIndexOf("/") + 1);
    link.click();
  };

  const renderHoverActions2 = (item) => {
    return (
      <div
        style={{
          display: "flex",
          // flexDirection: 'column',
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ImageListItemBar
          className="show-on-hover-no-click"
          sx={{
            background:
              "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
              "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
            height: "100%",                        
          }}
          position="top"
          actionIcon={
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                aria-label={`info about ${item.title}`}
                onClick={() => navigate("/image?id=" + item.image_id)}
              >
                <DownloadOutlined />
              </IconButton>

               { /*
              <IconButton
                sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                aria-label={`info about ${item.title}`}
                onClick={() => navigate("/studio?id=" + item.image_id)}
              >
                <PaletteOutlined />
              </IconButton>
            */ }
              {/*
                    <SocialShare
                      color="#fff"
                      opacity={0.54}
                      url={window.location.href + 'image?id=' + item.image_id}
                      title={item.title}
                      decription={item.description}
                      hashtags={[item.category]}
                    />
                    */}
            </div>
          }
          label={item.title}          
          title={item.title.length > 30 ? item.title.substring(0, 30) + "..." : item.title}
        />         
      </div>
      
    );
  };

  const renderHoverActions = (item) => {
    return (
      <div className="overlay fs-5">
        <div className="row">
          <div className="col">
            <div className="text-start text-wrap">{item.category + "..."}</div>
          </div>
          <div className="col text-end">
            <i
              onClick={() => download(item.public_url)}
              className="bottom fa-sharp fa-solid fa-download pe-1"
            ></i>
          </div>
        </div>
      </div>
    );
  };

  return (
    // dump data to console, only if is_favorited=  true
    //faves.map((item, index) => (console.log("TILES:FAVE: ", index, " ", item))),
    <Paper
      className="container"
      maxWidth="lg"
      sx={{
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        borderRadius: 2,
        margin: 2,
        padding: 2,
        border: "none",
        boxShadow: "none",
        backgroundColor: "transparent",
        "&:hover": {
          boxShadow: "none",
        },
      }}
    >
      <ImageList
        variant="masonry"
        cols={
          onMobile
            ? onXSMMobile
              ? 1
              : 1
            : onTablet
            ? props?.noPerRow - 1
            : props?.noPerRow
        }
        gap={props ? props.gap : 4}
        sx={{
          borderRadius: 2,
          border: "none",
          boxShadow: "none",
          backgroundColor: "transparent",
        }}
      >
        {data.map((item, index) => (
          <ImageListItem
            key={index}
            sx={{
              borderRadius: 2,
              padding: "0px",
              border: "none",
              boxShadow: "none",
              backgroundColor: "transparent",
              "&:hover": {
                boxShadow: "2px 2px 6px 3px opacity(0.3) #183E4B",
                "& .MuiCardHeader-title": {
                  display: "block",
                  height: "auto",
                },
                "& .MuiCardHeader-action": {
                  display: "block",
                },

                transform: "scale(1.05)",
                transition: "0.2s",
              },
            }}
          >
            <Card
              sx={{
                backgroundColor: "white",
                width: "100%",
                height: "auto",
                padding: 0.5,

                borderRadius: 2,
                border: "none",
                overflow: "hidden",
                boxShadow: "1px 1px 3px 1px rgba(0, 0, 0, 0.1)",
              }}
              onClick={() => {
                navigate(`/image?id=${item.image_id}`);
              }}
            >
              {
                // if props.showQuickActions is true, show the quick actions
                props.showQuickActions && (
                  <CardHeader
                    sx={{
                      backgroundColor: "#183E4B",
                      color: "white",
                      padding: 0,
                      margin: 0,
                      borderRadius: 2,
                      border: "none",
                      "& .MuiCardHeader-title": {
                        display: "none",
                      },
                      "& .MuiCardHeader-action": {
                        display: "none",
                      },
                    }}
                    title={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: 0,
                          margin: 0,
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {renderHoverActions2(item)}
                      </Box>
                    }
                  />
                )
              }
              <CardContent
                sx={{
                  backgroundColor: "transparent",
                  border: "none",
                  padding: 0,
                  "&:last-child": {
                    paddingBottom: 0,
                  },
                  "& .MuiCardContent-root": {
                    padding: 0,
                  },
                  width: "100%",
                }}
              >
                {
                  <img
                    src={item.public_url_thumbnail}
                    alt={item.title}
                    loading="lazy"
                    style={{
                      backgroundColor: "transparent",
                      width: "100%",
                      maxWidth: "100%",
                      height: "auto",
                      borderRadius: 4,
                      border: "none",
                      boxShadow: "none",
                    }}
                  />
                }
              </CardContent>
            </Card>
          </ImageListItem>
        ))}
      </ImageList>
    </Paper>
  );
}
