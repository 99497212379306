import React from "react";

export function getThemes() {
    // theme list
    var themes = [
        "love",
        "hate",
        "war",
        "peace",
        "death",
        "life",
        "fear",
        "courage",
        "hope",
        "despair",
        "joy",
        "sadness",
        "anger",
        "surprise",
        "disgust",
        "trust",
    ];
    return themes.sort();
};

export function getMoods() {
    var moods = [
        "happy",
        "sad",
        "angry",
        "fearful",
        "disgusted",
        "surprised",
        "neutral",
    ];
    return moods.sort();
};

export function getAtmospheric() {
    var atmospheric = [
        "sunny",
        "cloudy",
        "rainy",
        "snowy",
        "windy",
        "stormy",
        "foggy",
        "clear",
        "hot",
        "cold",
        "humid",
        "dry",
        "dark",
        "bright",
        "noisy",
        "quiet",
        "crowded",
        "deserted",
        "calm",
        "exciting",
        "boring",
        "relaxing",
        "stressful",
        "dangerous",
        "safe",
        "clean",
        "dirty",
        "beautiful",
        "ugly",
        "familiar",
        "unfamiliar",
        "comfortable",
        "uncomfortable",
        "friendly",
        "unfriendly",
        "romantic",
        "scary",
        "peaceful",
        "chaotic",
        "sad",
        "happy",
        "angry",
        "fearful",
        "disgusted",
        "surprised",
        "neutral",
    ];
    return atmospheric.sort();
};

export function getGenres() {
    var genres = [
        "action",
        "adventure",
        "comedy",
        "crime",
        "drama",
        "fantasy",
        "historical",
        "historical fiction",
        "horror",
        "magical realism",
        "mystery",
        "paranoid fiction",
        "philosophical",
        "political",
        "romance",
        "saga",
        "satire",
        "science fiction",
        "social",
        "speculative",
        "thriller",
        "urban",
        "western",
        "animation",
        "anime",
        "comic",
        "manga",
        "children's",
        "young adult",
        "adult",
        "fiction",
        "non-fiction",
        "biography",
        "autobiography",
        "memoir",
        "essay",
        "journalism",
        "personal narrative",
        "reference book",
        "self-help book",
        "textbook",
        "poetry",
        "prose",
        "screenplay",
        "short story",
        "novel",
        "novella",
        "play",
        "tragedy",
        "tragic comedy",
        "musical",
        "opera",
    ];
    return genres.sort();
};

export function getPostTypes() {
    var postTypes = [
        "motivation",
        "career",
        "resilience",
        "share picture",
        "happy birthday",
        "congratulations",
        "thank you",
        "inspiration",
        "funny",
        "love",
        "friendship",
        "family",
        "pets",
        "holiday",
        "travel",
        "food",
        "fitness",
        "health",
        "music",
        "movies",
        "books",
        "sports",
        "games",
        "news",
        "politics",
    ];
    return postTypes.sort();
};
