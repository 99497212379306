import { useEffect } from 'react';
import useScript from './useScript';
import { useAuth } from '../services/auth/use-auth';
const scriptFlag = '__googleOneTapScript__';
const googleClientScriptURL = 'https://accounts.google.com/gsi/client';

const useGoogleOneTap = ({
    onComplete,
    onError,
    onDisplay,
    showPrompt,
    disabled,
    disableCancelOnUnmount,
    googleConfig,
}) => {
    const script = useScript(googleClientScriptURL);
    useEffect(() => {
        if (!window[scriptFlag] && window.google && script === 'ready') {
            window.google.accounts.id.initialize({
                ...googleConfig,
              });
            window[scriptFlag] = true;
        }
        if (window[scriptFlag] && script === 'ready' && !disabled) {
            window.google.accounts.id.renderButton(
                document.getElementById("google-signin-button"),
                { theme: "outline", size: "large" }  // customization attributes
            );

            if (showPrompt && !disabled) {
                window.google.accounts.id.prompt((notification) => {
                    if (disabled) {
                        // console.log('disabled in prompt: ', disabled)
                        window.google.accounts.id.cancel();
                        return;
                    }
                    // onClick(true);
                    // console.log(notification);
                    if (notification.isDisplayed()) {
                        // console.log('One Tap sign-in prompt was displayed to user');
                        onDisplay(true)
                    } else if (notification.isNotDisplayed()) {
                        // console.log('One Tap sign-in prompt was not shown to user: ');
                        onDisplay(false)
                    } else if (notification.isSkippedMoment()) {
                        // console.log('One Tap sign-in prompt was skipped by user', notification);
                        onDisplay(false)
                    } else if (notification.isDismissedMoment()) {
                        // console.log('One Tap sign-in prompt was dismissed by user');
                        onDisplay(false)
                    }
                    // if (notification.isNotDisplayed()) {
                    //     // console.log('One Tap sign-in prompt was not shown to user: ', notification.getNotDisplayedReason());
                    // } else if (notification.isSkippedMoment()) {
                    //     // console.log('One Tap sign-in prompt was skipped by user', notification);
                    //     // onClick(false);
                    // } else if (notification.isDismissedMoment()) {
                    //     // console.log('One Tap sign-in prompt was dismissed by user', notification.getDismissedMomentReason());
                    // }
                });
            }

            return () => {
                if (!disableCancelOnUnmount) {
                    window.google.accounts.id.cancel();
                }
            };
        }    
    }, [script, window[scriptFlag], disabled]);
    
    return null;
}

export default useGoogleOneTap;