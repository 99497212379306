// rewrite the colors list into a new array named colorsList2 and removes the key values from the objects
//
// const colorsList2 = [
//     {
//         "name": "Acadia ",
//         "hex": "#1B1404"
//     },

const colorsList = [
        {
            "name": "Acadia ",
            "hex": "#1B1404"
        },
        {
            "name": "Acid Green ",
            "hex": "#8FFE09"
        },
        {
            "name": "Aero Blue ",
            "hex": "#C9FFE5"
        },
        {
            "name": "Alabaster ",
            "hex": "#FAFAFA"
        },
        {
            "name": "Albescent White ",
            "hex": "#F5E9D3"
        },
        {
            "name": "Algae ",
            "hex": "#54AC68"
        },
        {
            "name": "Algae Green ",
            "hex": "#64E986"
        },
        {
            "name": "Alice Blue ",
            "hex": "#F0F8FF"
        },
        {
            "name": "Alien Green ",
            "hex": "#6CC417"
        },
        {
            "name": "Almond ",
            "hex": "#EFDECD"
        },
        {
            "name": "Almond Frost ",
            "hex": "#907B71"
        },
        {
            "name": "Almost Black ",
            "hex": "#070D0D"
        },
        {
            "name": "Alpine ",
            "hex": "#AF8F2C"
        },
        {
            "name": "Aluminium ",
            "hex": "#A9ACB6"
        },
        {
            "name": "Amaranth ",
            "hex": "#E52B50"
        },
        {
            "name": "Amethyst ",
            "hex": "#9966CC"
        },
        {
            "name": "Amethyst Smoke ",
            "hex": "#A397B4"
        },
        {
            "name": "Amour ",
            "hex": "#F9EAF3"
        },
        {
            "name": "Android Green ",
            "hex": "#A4C639"
        },
        {
            "name": "Antique Brass ",
            "hex": "#C88A65"
        },
        {
            "name": "Antique Bronze ",
            "hex": "#704A07"
        },
        {
            "name": "Antique Fuchsia ",
            "hex": "#915C83"
        },
        {
            "name": "Antique White ",
            "hex": "#FAEBD7"
        },
        {
            "name": "Ao ",
            "hex": "#008000"
        },
        {
            "name": "Apache ",
            "hex": "#DFBE6F"
        },
        {
            "name": "Apple ",
            "hex": "#4FA83D"
        },
        {
            "name": "Apple Blossom ",
            "hex": "#AF4D43"
        },
        {
            "name": "Apple Green ",
            "hex": "#76CD26"
        },
        {
            "name": "Apricot ",
            "hex": "#FFB16D"
        },
        {
            "name": "Aqua ",
            "hex": "#13EAC9"
        },
        {
            "name": "Aqua Blue ",
            "hex": "#02D8E9"
        },
        {
            "name": "Aqua Deep ",
            "hex": "#014B43"
        },
        {
            "name": "Aqua Forest ",
            "hex": "#5FA777"
        },
        {
            "name": "Aqua Green ",
            "hex": "#12E193"
        },
        {
            "name": "Aqua Haze ",
            "hex": "#EDF5F5"
        },
        {
            "name": "Aqua Island ",
            "hex": "#A1DAD7"
        },
        {
            "name": "Aqua Marine ",
            "hex": "#2EE8BB"
        },
        {
            "name": "Aqua Spring ",
            "hex": "#EAF9F5"
        },
        {
            "name": "Aqua Squeeze ",
            "hex": "#E8F5F2"
        },
        {
            "name": "Aquamarine ",
            "hex": "#7FFFD4"
        },
        {
            "name": "Aquamarine Blue ",
            "hex": "#71D9E2"
        },
        {
            "name": "Army Brown ",
            "hex": "#827B60"
        },
        {
            "name": "Army Green ",
            "hex": "#4B5320"
        },
        {
            "name": "Arylide Yellow ",
            "hex": "#E9D66B"
        },
        {
            "name": "Ash ",
            "hex": "#C6C3B5"
        },
        {
            "name": "Ash Grey ",
            "hex": "#B2BEB5"
        },
        {
            "name": "Asparagus ",
            "hex": "#7BA05B"
        },
        {
            "name": "Asphalt ",
            "hex": "#130A06"
        },
        {
            "name": "Astra ",
            "hex": "#FAEAB9"
        },
        {
            "name": "Astronaut Blue ",
            "hex": "#013E62"
        },
        {
            "name": "Atlantis ",
            "hex": "#97CD2D"
        },
        {
            "name": "Atomic Tangerine ",
            "hex": "#FF9966"
        },
        {
            "name": "Aubergine ",
            "hex": "#3B0910"
        },
        {
            "name": "Auburn ",
            "hex": "#9A3001"
        },
        {
            "name": "Aureolin ",
            "hex": "#FDEE00"
        },
        {
            "name": "Australian Mint ",
            "hex": "#F5FFBE"
        },
        {
            "name": "Avocado ",
            "hex": "#90B134"
        },
        {
            "name": "Avocado Green ",
            "hex": "#B2C248"
        },
        {
            "name": "Awesome ",
            "hex": "#FF2052"
        },
        {
            "name": "Aztec Purple ",
            "hex": "#893BFF"
        },
        {
            "name": "Azul ",
            "hex": "#1D5DEC"
        },
        {
            "name": "Azure ",
            "hex": "#069AF3"
        },
        {
            "name": "Baby Blue ",
            "hex": "#A2CFFE"
        },
        {
            "name": "Baby Green ",
            "hex": "#8CFF9E"
        },
        {
            "name": "Baby Pink ",
            "hex": "#FFB7CE"
        },
        {
            "name": "Baby Purple ",
            "hex": "#CA9BF7"
        },
        {
            "name": "Baja White ",
            "hex": "#FFF8D1"
        },
        {
            "name": "Ball Blue ",
            "hex": "#21ABCD"
        },
        {
            "name": "Baltic Sea ",
            "hex": "#2A2630"
        },
        {
            "name": "Bamboo ",
            "hex": "#DA6304"
        },
        {
            "name": "Banana Mania ",
            "hex": "#FBE7B2"
        },
        {
            "name": "Banana Yellow ",
            "hex": "#FFE135"
        },
        {
            "name": "Barberry ",
            "hex": "#DED717"
        },
        {
            "name": "Barf Green ",
            "hex": "#94AC02"
        },
        {
            "name": "Barley White ",
            "hex": "#FFF4CE"
        },
        {
            "name": "Barney ",
            "hex": "#AC1DB8"
        },
        {
            "name": "Barney Purple ",
            "hex": "#A00498"
        },
        {
            "name": "Barossa ",
            "hex": "#44012D"
        },
        {
            "name": "Bashful Pink ",
            "hex": "#C25283"
        },
        {
            "name": "Basket Ball Orange ",
            "hex": "#F88158"
        },
        {
            "name": "Battleship Grey ",
            "hex": "#848482"
        },
        {
            "name": "Bay Leaf ",
            "hex": "#7DA98D"
        },
        {
            "name": "Bazaar ",
            "hex": "#98777B"
        },
        {
            "name": "Bean ",
            "hex": "#3D0C02"
        },
        {
            "name": "Bean Red ",
            "hex": "#F75D59"
        },
        {
            "name": "Beauty Bush ",
            "hex": "#EEC1BE"
        },
        {
            "name": "Beaver ",
            "hex": "#9F8170"
        },
        {
            "name": "Bee Yellow ",
            "hex": "#E9AB17"
        },
        {
            "name": "Beer ",
            "hex": "#FBB117"
        },
        {
            "name": "Beetle Green ",
            "hex": "#4C787E"
        },
        {
            "name": "Beige ",
            "hex": "#F5F5DC"
        },
        {
            "name": "Bermuda ",
            "hex": "#7DD8C6"
        },
        {
            "name": "Berry ",
            "hex": "#990F4B"
        },
        {
            "name": "Beryl Green ",
            "hex": "#DEE5C0"
        },
        {
            "name": "Bianca ",
            "hex": "#FCFBF3"
        },
        {
            "name": "Bile ",
            "hex": "#B5C306"
        },
        {
            "name": "Biloba Flower ",
            "hex": "#B2A1EA"
        },
        {
            "name": "Birch ",
            "hex": "#373021"
        },
        {
            "name": "Bird Flower ",
            "hex": "#D4CD16"
        },
        {
            "name": "Bistre ",
            "hex": "#3D2B1F"
        },
        {
            "name": "Bitter Lemon ",
            "hex": "#CAE00D"
        },
        {
            "name": "Bittersweet ",
            "hex": "#FE6F5E"
        },
        {
            "name": "Black ",
            "hex": "#000000"
        },
        {
            "name": "Black Bean ",
            "hex": "#081910"
        },
        {
            "name": "Black Cat ",
            "hex": "#413839"
        },
        {
            "name": "Black Cow ",
            "hex": "#4C4646"
        },
        {
            "name": "Black Eel ",
            "hex": "#463E3F"
        },
        {
            "name": "Black Forest ",
            "hex": "#0B1304"
        },
        {
            "name": "Black Olive ",
            "hex": "#242E16"
        },
        {
            "name": "Black Pearl ",
            "hex": "#041322"
        },
        {
            "name": "Black Rock ",
            "hex": "#0D0332"
        },
        {
            "name": "Black Rose ",
            "hex": "#67032D"
        },
        {
            "name": "Black Russian ",
            "hex": "#0A001C"
        },
        {
            "name": "Black Squeeze ",
            "hex": "#F2FAFA"
        },
        {
            "name": "Blackberry ",
            "hex": "#4D0135"
        },
        {
            "name": "Blaze Orange ",
            "hex": "#FF6600"
        },
        {
            "name": "Bleach White ",
            "hex": "#FEF3D8"
        },
        {
            "name": "Bleached Cedar ",
            "hex": "#2C2133"
        },
        {
            "name": "Bleu De France ",
            "hex": "#318CE7"
        },
        {
            "name": "Blizzard Blue ",
            "hex": "#A3E3ED"
        },
        {
            "name": "Blond ",
            "hex": "#FAF0BE"
        },
        {
            "name": "Blood Red ",
            "hex": "#980002"
        },
        {
            "name": "Blossom ",
            "hex": "#DCB4BC"
        },
        {
            "name": "Blossom Pink ",
            "hex": "#F9B7FF"
        },
        {
            "name": "Blue Angel ",
            "hex": "#B7CEEC"
        },
        {
            "name": "Blue Bayoux ",
            "hex": "#496679"
        },
        {
            "name": "Blue Bell ",
            "hex": "#9999CC"
        },
        {
            "name": "Blue Chalk ",
            "hex": "#F1E9FF"
        },
        {
            "name": "Blue Charcoal ",
            "hex": "#010D1A"
        },
        {
            "name": "Blue Chill ",
            "hex": "#0C8990"
        },
        {
            "name": "Blue Diamond ",
            "hex": "#4EE2EC"
        },
        {
            "name": "Blue Dianne ",
            "hex": "#204852"
        },
        {
            "name": "Blue Dress ",
            "hex": "#157DEC"
        },
        {
            "name": "Blue Eyes ",
            "hex": "#1569C7"
        },
        {
            "name": "Blue Gem ",
            "hex": "#2C0E8C"
        },
        {
            "name": "Blue Green ",
            "hex": "#0D98BA"
        },
        {
            "name": "Blue Grey ",
            "hex": "#607C8E"
        },
        {
            "name": "Blue Haze ",
            "hex": "#BFBED8"
        },
        {
            "name": "Blue Hosta ",
            "hex": "#77BFC7"
        },
        {
            "name": "Blue Ivy ",
            "hex": "#3090C7"
        },
        {
            "name": "Blue Jay ",
            "hex": "#2B547E"
        },
        {
            "name": "Blue Koi ",
            "hex": "#659EC7"
        },
        {
            "name": "Blue Lagoon ",
            "hex": "#8EEBEC"
        },
        {
            "name": "Blue Lotus ",
            "hex": "#6960EC"
        },
        {
            "name": "Blue Marguerite ",
            "hex": "#7666C6"
        },
        {
            "name": "Blue Orchid ",
            "hex": "#1F45FC"
        },
        {
            "name": "Blue Purple ",
            "hex": "#5729CE"
        },
        {
            "name": "Blue Romance ",
            "hex": "#D2F6DE"
        },
        {
            "name": "Blue Smoke ",
            "hex": "#748881"
        },
        {
            "name": "Blue Stone ",
            "hex": "#016162"
        },
        {
            "name": "Blue Violet ",
            "hex": "#8A2BE2"
        },
        {
            "name": "Blue Whale ",
            "hex": "#042E4C"
        },
        {
            "name": "Blue Zircon ",
            "hex": "#57FEFF"
        },
        {
            "name": "Blue Zodiac ",
            "hex": "#13264D"
        },
        {
            "name": "Blueberry ",
            "hex": "#464196"
        },
        {
            "name": "Blueberry Blue ",
            "hex": "#0041C2"
        },
        {
            "name": "Bluish ",
            "hex": "#2976BB"
        },
        {
            "name": "Bluish Green ",
            "hex": "#10A674"
        },
        {
            "name": "Bluish Grey ",
            "hex": "#748B97"
        },
        {
            "name": "Bluish Purple ",
            "hex": "#703BE7"
        },
        {
            "name": "Blush ",
            "hex": "#F29E8E"
        },
        {
            "name": "Blush Pink ",
            "hex": "#E6A9EC"
        },
        {
            "name": "Blush Red ",
            "hex": "#E56E94"
        },
        {
            "name": "Bole ",
            "hex": "#79443B"
        },
        {
            "name": "Bondi Blue ",
            "hex": "#0095B6"
        },
        {
            "name": "Bone ",
            "hex": "#E3DAC9"
        },
        {
            "name": "Bordeaux ",
            "hex": "#5C0120"
        },
        {
            "name": "Boring Green ",
            "hex": "#63B365"
        },
        {
            "name": "Bottle Green ",
            "hex": "#093624"
        },
        {
            "name": "Boulder ",
            "hex": "#7A7A7A"
        },
        {
            "name": "Bouquet ",
            "hex": "#AE809E"
        },
        {
            "name": "Bourbon ",
            "hex": "#BA6F1E"
        },
        {
            "name": "Boysenberry ",
            "hex": "#873260"
        },
        {
            "name": "Brandeis Blue ",
            "hex": "#0070FF"
        },
        {
            "name": "Brandy ",
            "hex": "#DEC196"
        },
        {
            "name": "Brandy Punch ",
            "hex": "#CD8429"
        },
        {
            "name": "Brandy Rose ",
            "hex": "#BB8983"
        },
        {
            "name": "Brass ",
            "hex": "#B5A642"
        },
        {
            "name": "Brick Orange ",
            "hex": "#C14A09"
        },
        {
            "name": "Brick Red ",
            "hex": "#C62D42"
        },
        {
            "name": "Bright Aqua ",
            "hex": "#0BF9EA"
        },
        {
            "name": "Bright Blue ",
            "hex": "#0165FC"
        },
        {
            "name": "Bright Cerulean ",
            "hex": "#1DACD6"
        },
        {
            "name": "Bright Cyan ",
            "hex": "#41FDFE"
        },
        {
            "name": "Bright Gold ",
            "hex": "#FDD017"
        },
        {
            "name": "Bright Grey ",
            "hex": "#3C4151"
        },
        {
            "name": "Bright Lavender ",
            "hex": "#BF94E4"
        },
        {
            "name": "Bright Light Blue ",
            "hex": "#26F7FD"
        },
        {
            "name": "Bright Light Green ",
            "hex": "#2DFE54"
        },
        {
            "name": "Bright Lilac ",
            "hex": "#C95EFB"
        },
        {
            "name": "Bright Lime Green ",
            "hex": "#65FE08"
        },
        {
            "name": "Bright Magenta ",
            "hex": "#FF08E8"
        },
        {
            "name": "Bright Maroon ",
            "hex": "#C32148"
        },
        {
            "name": "Bright Neon Pink ",
            "hex": "#F433FF"
        },
        {
            "name": "Bright Olive ",
            "hex": "#9CBB04"
        },
        {
            "name": "Bright Orange ",
            "hex": "#FF5B00"
        },
        {
            "name": "Bright Red ",
            "hex": "#FF000D"
        },
        {
            "name": "Bright Sea Green ",
            "hex": "#05FFA6"
        },
        {
            "name": "Bright Sky Blue ",
            "hex": "#02CCFE"
        },
        {
            "name": "Bright Sun ",
            "hex": "#FED33C"
        },
        {
            "name": "Bright Teal ",
            "hex": "#01F9C6"
        },
        {
            "name": "Bright Turquoise ",
            "hex": "#08E8DE"
        },
        {
            "name": "Bright Ube ",
            "hex": "#D19FE8"
        },
        {
            "name": "Bright Violet ",
            "hex": "#AD0AFD"
        },
        {
            "name": "Bright Yellow Green ",
            "hex": "#9DFF00"
        },
        {
            "name": "Brilliant Rose ",
            "hex": "#F653A6"
        },
        {
            "name": "Brink Pink ",
            "hex": "#FB607F"
        },
        {
            "name": "British Racing Green ",
            "hex": "#05480D"
        },
        {
            "name": "Bronco ",
            "hex": "#ABA196"
        },
        {
            "name": "Bronze Olive ",
            "hex": "#4E420C"
        },
        {
            "name": "Bronzetone ",
            "hex": "#4D400F"
        },
        {
            "name": "Brown Bear ",
            "hex": "#835C3B"
        },
        {
            "name": "Brown Bramble ",
            "hex": "#592804"
        },
        {
            "name": "Brown Derby ",
            "hex": "#492615"
        },
        {
            "name": "Brown Green ",
            "hex": "#706C11"
        },
        {
            "name": "Brown Grey ",
            "hex": "#8D8468"
        },
        {
            "name": "Brown Red ",
            "hex": "#922B05"
        },
        {
            "name": "Brown Rust ",
            "hex": "#AF593E"
        },
        {
            "name": "Brown Sugar ",
            "hex": "#E2A76F"
        },
        {
            "name": "Brown Tumbleweed ",
            "hex": "#37290E"
        },
        {
            "name": "Brown Yellow ",
            "hex": "#B29705"
        },
        {
            "name": "Brownish ",
            "hex": "#9C6D57"
        },
        {
            "name": "Brownish Green ",
            "hex": "#6A6E09"
        },
        {
            "name": "Brownish Grey ",
            "hex": "#86775F"
        },
        {
            "name": "Brownish Orange ",
            "hex": "#CB7723"
        },
        {
            "name": "Brownish Pink ",
            "hex": "#C27E79"
        },
        {
            "name": "Brownish Purple ",
            "hex": "#76424E"
        },
        {
            "name": "Brownish Red ",
            "hex": "#9E3623"
        },
        {
            "name": "Brownish Yellow ",
            "hex": "#C9B003"
        },
        {
            "name": "Browny Green ",
            "hex": "#6F6C0A"
        },
        {
            "name": "Bubble Gum ",
            "hex": "#FFC1CC"
        },
        {
            "name": "Buddha Gold ",
            "hex": "#C1A004"
        },
        {
            "name": "Buff ",
            "hex": "#FEF69E"
        },
        {
            "name": "Bulgarian Rose ",
            "hex": "#480607"
        },
        {
            "name": "Bull Shot ",
            "hex": "#864D1E"
        },
        {
            "name": "Bullet Shell ",
            "hex": "#AF9B60"
        },
        {
            "name": "Burly Wood ",
            "hex": "#DEB887"
        },
        {
            "name": "Burnham ",
            "hex": "#002E20"
        },
        {
            "name": "Burning Orange ",
            "hex": "#FF7034"
        },
        {
            "name": "Burning Sand ",
            "hex": "#D99376"
        },
        {
            "name": "Burnt Orange ",
            "hex": "#C04E01"
        },
        {
            "name": "Burnt Pink ",
            "hex": "#C12267"
        },
        {
            "name": "Burnt Red ",
            "hex": "#9F2305"
        },
        {
            "name": "Burnt Umber ",
            "hex": "#8A3324"
        },
        {
            "name": "Burnt Yellow ",
            "hex": "#D5AB09"
        },
        {
            "name": "Bush ",
            "hex": "#0D2E1C"
        },
        {
            "name": "Butter ",
            "hex": "#FFFF81"
        },
        {
            "name": "Buttered Rum ",
            "hex": "#A1750D"
        },
        {
            "name": "Butterfly Blue ",
            "hex": "#38ACEC"
        },
        {
            "name": "Butterfly Bush ",
            "hex": "#624E9A"
        },
        {
            "name": "Buttermilk ",
            "hex": "#FFF1B5"
        },
        {
            "name": "Butterscotch ",
            "hex": "#FDB147"
        },
        {
            "name": "Buttery White ",
            "hex": "#FFFCEA"
        },
        {
            "name": "Byzantium ",
            "hex": "#702963"
        },
        {
            "name": "Cab Sav ",
            "hex": "#4D0A18"
        },
        {
            "name": "Cactus ",
            "hex": "#587156"
        },
        {
            "name": "Cadet ",
            "hex": "#536872"
        },
        {
            "name": "Cadet Blue ",
            "hex": "#A9B2C3"
        },
        {
            "name": "Cadet Grey ",
            "hex": "#91A3B0"
        },
        {
            "name": "Cadillac Pink ",
            "hex": "#E38AAE"
        },
        {
            "name": "Cadmium Orange ",
            "hex": "#ED872D"
        },
        {
            "name": "Cadmium Red ",
            "hex": "#E30022"
        },
        {
            "name": "Cadmium Yellow ",
            "hex": "#FFF600"
        },
        {
            "name": "Cafe Noir ",
            "hex": "#4B3621"
        },
        {
            "name": "Camel ",
            "hex": "#C69F59"
        },
        {
            "name": "Camo ",
            "hex": "#7F8F4E"
        },
        {
            "name": "Camo Green ",
            "hex": "#526525"
        },
        {
            "name": "Camouflage Green ",
            "hex": "#78866B"
        },
        {
            "name": "Canary Yellow ",
            "hex": "#FFFE40"
        },
        {
            "name": "Candlelight ",
            "hex": "#FCD917"
        },
        {
            "name": "Candy Pink ",
            "hex": "#FF63E9"
        },
        {
            "name": "Cantaloupe ",
            "hex": "#FFA62F"
        },
        {
            "name": "Caper ",
            "hex": "#DCEDB4"
        },
        {
            "name": "Carbon Grey ",
            "hex": "#625D5D"
        },
        {
            "name": "Cardinal ",
            "hex": "#C41E3A"
        },
        {
            "name": "Caribbean Green ",
            "hex": "#00CC99"
        },
        {
            "name": "Carmine Pink ",
            "hex": "#EB4C42"
        },
        {
            "name": "Carnaby Tan ",
            "hex": "#5C2E01"
        },
        {
            "name": "Carnation Pink ",
            "hex": "#FFA6C9"
        },
        {
            "name": "Carolina Blue ",
            "hex": "#99BADD"
        },
        {
            "name": "Carousel Pink ",
            "hex": "#F9E0ED"
        },
        {
            "name": "Carrot Orange ",
            "hex": "#ED9121"
        },
        {
            "name": "Casal ",
            "hex": "#2F6168"
        },
        {
            "name": "Cascade ",
            "hex": "#8BA9A5"
        },
        {
            "name": "Cashmere ",
            "hex": "#E6BEA5"
        },
        {
            "name": "Casper ",
            "hex": "#ADBED1"
        },
        {
            "name": "Castro ",
            "hex": "#52001F"
        },
        {
            "name": "Catalina Blue ",
            "hex": "#062A78"
        },
        {
            "name": "Catskill White ",
            "hex": "#EEF6F7"
        },
        {
            "name": "Cavern Pink ",
            "hex": "#E3BEBE"
        },
        {
            "name": "Cedar ",
            "hex": "#3E1C14"
        },
        {
            "name": "Cedar Wood ",
            "hex": "#711A00"
        },
        {
            "name": "Celadon ",
            "hex": "#BEFDB7"
        },
        {
            "name": "Celery ",
            "hex": "#C1FD95"
        },
        {
            "name": "Celeste ",
            "hex": "#50EBEC"
        },
        {
            "name": "Celestial Blue ",
            "hex": "#4997D0"
        },
        {
            "name": "Cement ",
            "hex": "#A5A391"
        },
        {
            "name": "Ceramic ",
            "hex": "#FCFFF9"
        },
        {
            "name": "Cerise Pink ",
            "hex": "#EC3B83"
        },
        {
            "name": "Cerulean ",
            "hex": "#02A4D3"
        },
        {
            "name": "Cerulean Blue ",
            "hex": "#2A52BE"
        },
        {
            "name": "Chalky ",
            "hex": "#EED794"
        },
        {
            "name": "Chambray ",
            "hex": "#354E8C"
        },
        {
            "name": "Champagne ",
            "hex": "#FAECCC"
        },
        {
            "name": "Charcoal ",
            "hex": "#343837"
        },
        {
            "name": "Charcoal Grey ",
            "hex": "#3C4142"
        },
        {
            "name": "Chardonnay ",
            "hex": "#FFCD8C"
        },
        {
            "name": "Chartreuse ",
            "hex": "#C1F80A"
        },
        {
            "name": "Chathams Blue ",
            "hex": "#175579"
        },
        {
            "name": "Chelsea Cucumber ",
            "hex": "#83AA5D"
        },
        {
            "name": "Chelsea Gem ",
            "hex": "#9E5302"
        },
        {
            "name": "Cherry ",
            "hex": "#CF0234"
        },
        {
            "name": "Cherry Blossom ",
            "hex": "#FFB7C5"
        },
        {
            "name": "Cherry Pie ",
            "hex": "#2A0359"
        },
        {
            "name": "Cherrywood ",
            "hex": "#651A14"
        },
        {
            "name": "Chestnut ",
            "hex": "#742802"
        },
        {
            "name": "Chestnut Red ",
            "hex": "#C34A2C"
        },
        {
            "name": "Chetwode Blue ",
            "hex": "#8581D9"
        },
        {
            "name": "Chiffon ",
            "hex": "#F1FFC8"
        },
        {
            "name": "Chili Pepper ",
            "hex": "#C11B17"
        },
        {
            "name": "China Ivory ",
            "hex": "#FCFFE7"
        },
        {
            "name": "Chinook ",
            "hex": "#A8E3BD"
        },
        {
            "name": "Chocolate ",
            "hex": "#370202"
        },
        {
            "name": "Chocolate Brown ",
            "hex": "#411900"
        },
        {
            "name": "Chrome White ",
            "hex": "#E8F1D4"
        },
        {
            "name": "Chrome Yellow ",
            "hex": "#FFA700"
        },
        {
            "name": "Cinder ",
            "hex": "#0E0E18"
        },
        {
            "name": "Cinereous ",
            "hex": "#98817B"
        },
        {
            "name": "Citrine ",
            "hex": "#E4D00A"
        },
        {
            "name": "Citrine White ",
            "hex": "#FAF7D6"
        },
        {
            "name": "Citron ",
            "hex": "#9EA91F"
        },
        {
            "name": "Citrus ",
            "hex": "#A1C50A"
        },
        {
            "name": "Clam Shell ",
            "hex": "#D4B6AF"
        },
        {
            "name": "Claret ",
            "hex": "#680018"
        },
        {
            "name": "Classic Rose ",
            "hex": "#FBCCE7"
        },
        {
            "name": "Clay ",
            "hex": "#B66A50"
        },
        {
            "name": "Clay Ash ",
            "hex": "#BDC8B3"
        },
        {
            "name": "Clay Brown ",
            "hex": "#B2713D"
        },
        {
            "name": "Clay Creek ",
            "hex": "#8A8360"
        },
        {
            "name": "Clear Blue ",
            "hex": "#247AFD"
        },
        {
            "name": "Clear Day ",
            "hex": "#E9FFFD"
        },
        {
            "name": "Clinker ",
            "hex": "#371D09"
        },
        {
            "name": "Cloud ",
            "hex": "#C7C4BF"
        },
        {
            "name": "Cloud Burst ",
            "hex": "#202E54"
        },
        {
            "name": "Cloudy Blue ",
            "hex": "#ACC2D9"
        },
        {
            "name": "Cloudy Grey ",
            "hex": "#6D6968"
        },
        {
            "name": "Clover Green ",
            "hex": "#3EA055"
        },
        {
            "name": "Cobalt ",
            "hex": "#1E488F"
        },
        {
            "name": "Cobalt Blue ",
            "hex": "#0020C2"
        },
        {
            "name": "Cocoa ",
            "hex": "#875F42"
        },
        {
            "name": "Cocoa Bean ",
            "hex": "#481C1C"
        },
        {
            "name": "Cocoa Brown ",
            "hex": "#D2691E"
        },
        {
            "name": "Coffee ",
            "hex": "#A6814C"
        },
        {
            "name": "Coffee Bean ",
            "hex": "#2A140E"
        },
        {
            "name": "Cognac ",
            "hex": "#9F381D"
        },
        {
            "name": "Cold Purple ",
            "hex": "#ABA0D9"
        },
        {
            "name": "Cold Turkey ",
            "hex": "#CEBABA"
        },
        {
            "name": "Colonial White ",
            "hex": "#FFEDBC"
        },
        {
            "name": "Columbia Blue ",
            "hex": "#9BDDFF"
        },
        {
            "name": "Comet ",
            "hex": "#5C5D75"
        },
        {
            "name": "Como ",
            "hex": "#517C66"
        },
        {
            "name": "Concord ",
            "hex": "#7C7B7A"
        },
        {
            "name": "Congo Brown ",
            "hex": "#593737"
        },
        {
            "name": "Congress Blue ",
            "hex": "#02478E"
        },
        {
            "name": "Conifer ",
            "hex": "#ACDD4D"
        },
        {
            "name": "Cookie Brown ",
            "hex": "#C7A317"
        },
        {
            "name": "Cool Blue ",
            "hex": "#4984B8"
        },
        {
            "name": "Cool Grey ",
            "hex": "#95A3A6"
        },
        {
            "name": "Copper ",
            "hex": "#B87333"
        },
        {
            "name": "Copper Canyon ",
            "hex": "#7E3A15"
        },
        {
            "name": "Copper Rose ",
            "hex": "#996666"
        },
        {
            "name": "Copper Rust ",
            "hex": "#944747"
        },
        {
            "name": "Coral Blue ",
            "hex": "#AFDCEC"
        },
        {
            "name": "Coral Pink ",
            "hex": "#F88379"
        },
        {
            "name": "Coral Red ",
            "hex": "#FF4040"
        },
        {
            "name": "Coral Reef ",
            "hex": "#C7BCA2"
        },
        {
            "name": "Coral Tree ",
            "hex": "#A86B6B"
        },
        {
            "name": "Cordovan ",
            "hex": "#893F45"
        },
        {
            "name": "Coriander ",
            "hex": "#C4D0B0"
        },
        {
            "name": "Cork ",
            "hex": "#40291D"
        },
        {
            "name": "Corn ",
            "hex": "#E7BF05"
        },
        {
            "name": "Corn Harvest ",
            "hex": "#8B6B0B"
        },
        {
            "name": "Corn Yellow ",
            "hex": "#FFF380"
        },
        {
            "name": "Cornell Red ",
            "hex": "#B31B1B"
        },
        {
            "name": "Cornflower ",
            "hex": "#6A79F7"
        },
        {
            "name": "Cornflower Blue ",
            "hex": "#6495ED"
        },
        {
            "name": "Cornflower Lilac ",
            "hex": "#FFB0AC"
        },
        {
            "name": "Cosmos ",
            "hex": "#FFD8D9"
        },
        {
            "name": "Cotton Candy ",
            "hex": "#FFB7D5"
        },
        {
            "name": "Cotton Seed ",
            "hex": "#C2BDB6"
        },
        {
            "name": "County Green ",
            "hex": "#01371A"
        },
        {
            "name": "Cranberry ",
            "hex": "#DB5079"
        },
        {
            "name": "Crater Brown ",
            "hex": "#462425"
        },
        {
            "name": "Cream Brulee ",
            "hex": "#FFE5A0"
        },
        {
            "name": "Creole ",
            "hex": "#1E0F04"
        },
        {
            "name": "Crimson Red ",
            "hex": "#990000"
        },
        {
            "name": "Crocodile ",
            "hex": "#736D58"
        },
        {
            "name": "Crocus Purple ",
            "hex": "#9172EC"
        },
        {
            "name": "Crusoe ",
            "hex": "#004816"
        },
        {
            "name": "Crystal Blue ",
            "hex": "#5CB3FF"
        },
        {
            "name": "Cumin ",
            "hex": "#924321"
        },
        {
            "name": "Curious Blue ",
            "hex": "#2596D1"
        },
        {
            "name": "Custard ",
            "hex": "#FFFD78"
        },
        {
            "name": "Cyan Opaque ",
            "hex": "#92C7C7"
        },
        {
            "name": "Daffodil ",
            "hex": "#FFFF31"
        },
        {
            "name": "Dairy Cream ",
            "hex": "#F9E4BC"
        },
        {
            "name": "Daisy Bush ",
            "hex": "#4F2398"
        },
        {
            "name": "Dandelion ",
            "hex": "#F0E130"
        },
        {
            "name": "Dark ",
            "hex": "#1B2431"
        },
        {
            "name": "Dark Aqua ",
            "hex": "#05696B"
        },
        {
            "name": "Dark Aquamarine ",
            "hex": "#017371"
        },
        {
            "name": "Dark Beige ",
            "hex": "#AC9362"
        },
        {
            "name": "Dark Blue ",
            "hex": "#00035B"
        },
        {
            "name": "Dark Blue Green ",
            "hex": "#005249"
        },
        {
            "name": "Dark Blue Grey ",
            "hex": "#1F3B4D"
        },
        {
            "name": "Dark Brown ",
            "hex": "#341C02"
        },
        {
            "name": "Dark Burgundy ",
            "hex": "#770F05"
        },
        {
            "name": "Dark Byzantium ",
            "hex": "#5D3954"
        },
        {
            "name": "Dark Carnation Pink ",
            "hex": "#C12283"
        },
        {
            "name": "Dark Cerulean ",
            "hex": "#08457E"
        },
        {
            "name": "Dark Chestnut ",
            "hex": "#986960"
        },
        {
            "name": "Dark Coral ",
            "hex": "#CF524E"
        },
        {
            "name": "Dark Cyan ",
            "hex": "#008B8B"
        },
        {
            "name": "Dark Ebony ",
            "hex": "#3C2005"
        },
        {
            "name": "Dark Fern ",
            "hex": "#0A480D"
        },
        {
            "name": "Dark Forest Green ",
            "hex": "#002D04"
        },
        {
            "name": "Dark Fuchsia ",
            "hex": "#9D0759"
        },
        {
            "name": "Dark Gold ",
            "hex": "#B59410"
        },
        {
            "name": "Dark Goldenrod ",
            "hex": "#B8860B"
        },
        {
            "name": "Dark Grass Green ",
            "hex": "#388004"
        },
        {
            "name": "Dark Green ",
            "hex": "#013220"
        },
        {
            "name": "Dark Green Blue ",
            "hex": "#1F6357"
        },
        {
            "name": "Dark Grey ",
            "hex": "#363737"
        },
        {
            "name": "Dark Grey Blue ",
            "hex": "#29465B"
        },
        {
            "name": "Dark Hot Pink ",
            "hex": "#D90166"
        },
        {
            "name": "Dark Indigo ",
            "hex": "#1F0954"
        },
        {
            "name": "Dark Jungle Green ",
            "hex": "#1A2421"
        },
        {
            "name": "Dark Lavender ",
            "hex": "#734F96"
        },
        {
            "name": "Dark Lilac ",
            "hex": "#9C6DA5"
        },
        {
            "name": "Dark Lime ",
            "hex": "#84B701"
        },
        {
            "name": "Dark Lime Green ",
            "hex": "#7EBD01"
        },
        {
            "name": "Dark Magenta ",
            "hex": "#8B008B"
        },
        {
            "name": "Dark Maroon ",
            "hex": "#3C0008"
        },
        {
            "name": "Dark Mauve ",
            "hex": "#874C62"
        },
        {
            "name": "Dark Midnight Blue ",
            "hex": "#003366"
        },
        {
            "name": "Dark Mint ",
            "hex": "#48C072"
        },
        {
            "name": "Dark Mint Green ",
            "hex": "#20C073"
        },
        {
            "name": "Dark Mustard ",
            "hex": "#A88905"
        },
        {
            "name": "Dark Navy ",
            "hex": "#000435"
        },
        {
            "name": "Dark Olive ",
            "hex": "#373E02"
        },
        {
            "name": "Dark Olive Green ",
            "hex": "#3C4D03"
        },
        {
            "name": "Dark Orange ",
            "hex": "#FF8C00"
        },
        {
            "name": "Dark Orchid ",
            "hex": "#9932CC"
        },
        {
            "name": "Dark Pastel Blue ",
            "hex": "#779ECB"
        },
        {
            "name": "Dark Pastel Green ",
            "hex": "#56AE57"
        },
        {
            "name": "Dark Pastel Purple ",
            "hex": "#966FD6"
        },
        {
            "name": "Dark Pastel Red ",
            "hex": "#C23B22"
        },
        {
            "name": "Dark Peach ",
            "hex": "#DE7E5D"
        },
        {
            "name": "Dark Periwinkle ",
            "hex": "#665FD1"
        },
        {
            "name": "Dark Pink ",
            "hex": "#E75480"
        },
        {
            "name": "Dark Purple ",
            "hex": "#35063E"
        },
        {
            "name": "Dark Raspberry ",
            "hex": "#872657"
        },
        {
            "name": "Dark Red ",
            "hex": "#840000"
        },
        {
            "name": "Dark Rose ",
            "hex": "#B5485D"
        },
        {
            "name": "Dark Royal Blue ",
            "hex": "#02066F"
        },
        {
            "name": "Dark Sage ",
            "hex": "#598556"
        },
        {
            "name": "Dark Salmon ",
            "hex": "#E9967A"
        },
        {
            "name": "Dark Sand ",
            "hex": "#A88F59"
        },
        {
            "name": "Dark Scarlet ",
            "hex": "#560319"
        },
        {
            "name": "Dark Sea Green ",
            "hex": "#8BB381"
        },
        {
            "name": "Dark Seafoam ",
            "hex": "#1FB57A"
        },
        {
            "name": "Dark Sienna ",
            "hex": "#3C1414"
        },
        {
            "name": "Dark Sky Blue ",
            "hex": "#448EE4"
        },
        {
            "name": "Dark Slate Blue ",
            "hex": "#483D8B"
        },
        {
            "name": "Dark Slate Grey ",
            "hex": "#25383C"
        },
        {
            "name": "Dark Spring Green ",
            "hex": "#177245"
        },
        {
            "name": "Dark Tan ",
            "hex": "#661010"
        },
        {
            "name": "Dark Taupe ",
            "hex": "#7F684E"
        },
        {
            "name": "Dark Teal ",
            "hex": "#014D4E"
        },
        {
            "name": "Dark Terra Cotta ",
            "hex": "#CC4E5C"
        },
        {
            "name": "Dark Turquoise ",
            "hex": "#00CED1"
        },
        {
            "name": "Dark Violet ",
            "hex": "#9400D3"
        },
        {
            "name": "Dark Yellow ",
            "hex": "#D5B60A"
        },
        {
            "name": "Dartmouth Green ",
            "hex": "#00693E"
        },
        {
            "name": "Davy Grey ",
            "hex": "#555555"
        },
        {
            "name": "Dawn ",
            "hex": "#A6A29A"
        },
        {
            "name": "Dawn Pink ",
            "hex": "#F3E9E5"
        },
        {
            "name": "Day Sky Blue ",
            "hex": "#82CAFF"
        },
        {
            "name": "Debian Red ",
            "hex": "#D70A53"
        },
        {
            "name": "Deep Aqua ",
            "hex": "#08787F"
        },
        {
            "name": "Deep Blush ",
            "hex": "#E47698"
        },
        {
            "name": "Deep Bronze ",
            "hex": "#4A3004"
        },
        {
            "name": "Deep Brown ",
            "hex": "#410200"
        },
        {
            "name": "Deep Carmine ",
            "hex": "#A9203E"
        },
        {
            "name": "Deep Carmine Pink ",
            "hex": "#EF3038"
        },
        {
            "name": "Deep Cerise ",
            "hex": "#DA3287"
        },
        {
            "name": "Deep Chestnut ",
            "hex": "#B94E48"
        },
        {
            "name": "Deep Coffee ",
            "hex": "#704241"
        },
        {
            "name": "Deep Cove ",
            "hex": "#051040"
        },
        {
            "name": "Deep Forest Green ",
            "hex": "#182D09"
        },
        {
            "name": "Deep Fuchsia ",
            "hex": "#C154C1"
        },
        {
            "name": "Deep Green ",
            "hex": "#02590F"
        },
        {
            "name": "Deep Lavender ",
            "hex": "#8D5EB7"
        },
        {
            "name": "Deep Lilac ",
            "hex": "#9955BB"
        },
        {
            "name": "Deep Oak ",
            "hex": "#412010"
        },
        {
            "name": "Deep Orange ",
            "hex": "#DC4D01"
        },
        {
            "name": "Deep Peach ",
            "hex": "#FFCBA4"
        },
        {
            "name": "Deep Pink ",
            "hex": "#FF1493"
        },
        {
            "name": "Deep Purple ",
            "hex": "#36013F"
        },
        {
            "name": "Deep Rose ",
            "hex": "#C74767"
        },
        {
            "name": "Deep Saffron ",
            "hex": "#FF9933"
        },
        {
            "name": "Deep Sapphire ",
            "hex": "#082567"
        },
        {
            "name": "Deep Sea ",
            "hex": "#01826B"
        },
        {
            "name": "Deep Sea Blue ",
            "hex": "#015482"
        },
        {
            "name": "Deep Sea Green ",
            "hex": "#095859"
        },
        {
            "name": "Deep Teal ",
            "hex": "#00555A"
        },
        {
            "name": "Deep Violet ",
            "hex": "#490648"
        },
        {
            "name": "Deluge ",
            "hex": "#7563A8"
        },
        {
            "name": "Denim ",
            "hex": "#3B638C"
        },
        {
            "name": "Denim Blue ",
            "hex": "#79BAEC"
        },
        {
            "name": "Denim Dark Blue ",
            "hex": "#151B8D"
        },
        {
            "name": "Desert ",
            "hex": "#CCAD60"
        },
        {
            "name": "Desert Sand ",
            "hex": "#EDC9AF"
        },
        {
            "name": "Desert Storm ",
            "hex": "#F8F8F7"
        },
        {
            "name": "Diesel ",
            "hex": "#130000"
        },
        {
            "name": "Dimorphotheca Magenta ",
            "hex": "#E3319D"
        },
        {
            "name": "Dingley ",
            "hex": "#5D7747"
        },
        {
            "name": "Dirt Brown ",
            "hex": "#836539"
        },
        {
            "name": "Dirty Blue ",
            "hex": "#3F829D"
        },
        {
            "name": "Dirty Green ",
            "hex": "#667E2C"
        },
        {
            "name": "Dirty Orange ",
            "hex": "#C87606"
        },
        {
            "name": "Dirty Pink ",
            "hex": "#CA7B80"
        },
        {
            "name": "Dirty Purple ",
            "hex": "#734A65"
        },
        {
            "name": "Dirty Yellow ",
            "hex": "#CDC50A"
        },
        {
            "name": "Dodger Blue ",
            "hex": "#3E82FC"
        },
        {
            "name": "Dogwood Rose ",
            "hex": "#D71868"
        },
        {
            "name": "Dollar Bill Green ",
            "hex": "#85BB65"
        },
        {
            "name": "Dolphin ",
            "hex": "#646077"
        },
        {
            "name": "Donkey Brown ",
            "hex": "#A69279"
        },
        {
            "name": "Dorado ",
            "hex": "#6B5755"
        },
        {
            "name": "Double Colonial White ",
            "hex": "#EEE3AD"
        },
        {
            "name": "Double Spanish White ",
            "hex": "#E6D7B9"
        },
        {
            "name": "Dove Grey ",
            "hex": "#6D6C6C"
        },
        {
            "name": "Downriver ",
            "hex": "#092256"
        },
        {
            "name": "Downy ",
            "hex": "#6FD0C5"
        },
        {
            "name": "Drab ",
            "hex": "#828344"
        },
        {
            "name": "Drab Green ",
            "hex": "#749551"
        },
        {
            "name": "Dragon Green ",
            "hex": "#6AFB92"
        },
        {
            "name": "Dried Blood ",
            "hex": "#4B0101"
        },
        {
            "name": "Driftwood ",
            "hex": "#AF8751"
        },
        {
            "name": "Duck Egg Blue ",
            "hex": "#C3FBF4"
        },
        {
            "name": "Dull Blue ",
            "hex": "#49759C"
        },
        {
            "name": "Dull Brown ",
            "hex": "#876E4B"
        },
        {
            "name": "Dull Green ",
            "hex": "#74A662"
        },
        {
            "name": "Dull Lavender ",
            "hex": "#A899E6"
        },
        {
            "name": "Dull Orange ",
            "hex": "#D8863B"
        },
        {
            "name": "Dull Pink ",
            "hex": "#D5869D"
        },
        {
            "name": "Dull Purple ",
            "hex": "#7F525D"
        },
        {
            "name": "Dull Red ",
            "hex": "#BB3F3F"
        },
        {
            "name": "Dull Yellow ",
            "hex": "#EEDC5B"
        },
        {
            "name": "Dune ",
            "hex": "#383533"
        },
        {
            "name": "Dusk ",
            "hex": "#4E5481"
        },
        {
            "name": "Dusk Blue ",
            "hex": "#26538D"
        },
        {
            "name": "Dusky Blue ",
            "hex": "#475F94"
        },
        {
            "name": "Dusky Purple ",
            "hex": "#895B7B"
        },
        {
            "name": "Dusky Rose ",
            "hex": "#BA6873"
        },
        {
            "name": "Dust ",
            "hex": "#B2996E"
        },
        {
            "name": "Dust Storm ",
            "hex": "#E5CCC9"
        },
        {
            "name": "Dusty Blue ",
            "hex": "#5A86AD"
        },
        {
            "name": "Dusty Green ",
            "hex": "#76A973"
        },
        {
            "name": "Dusty Grey ",
            "hex": "#A8989B"
        },
        {
            "name": "Dusty Lavender ",
            "hex": "#AC86A8"
        },
        {
            "name": "Dusty Orange ",
            "hex": "#F0833A"
        },
        {
            "name": "Dusty Pink ",
            "hex": "#D58A94"
        },
        {
            "name": "Dusty Purple ",
            "hex": "#825F87"
        },
        {
            "name": "Dusty Red ",
            "hex": "#B9484E"
        },
        {
            "name": "Dusty Rose ",
            "hex": "#C0737A"
        },
        {
            "name": "Dusty Teal ",
            "hex": "#4C9085"
        },
        {
            "name": "Eagle ",
            "hex": "#B6BAA4"
        },
        {
            "name": "Early Dawn ",
            "hex": "#FFF9E6"
        },
        {
            "name": "Earth ",
            "hex": "#A2653E"
        },
        {
            "name": "Earth Blue ",
            "hex": "#0000A0"
        },
        {
            "name": "Easter Green ",
            "hex": "#8CFD7E"
        },
        {
            "name": "Easter Purple ",
            "hex": "#C071FE"
        },
        {
            "name": "Eastern Blue ",
            "hex": "#1E9AB0"
        },
        {
            "name": "Ebony ",
            "hex": "#0C0B1D"
        },
        {
            "name": "Ebony Clay ",
            "hex": "#26283B"
        },
        {
            "name": "Eclipse ",
            "hex": "#311C17"
        },
        {
            "name": "Ecru ",
            "hex": "#FEFFCA"
        },
        {
            "name": "Ecru White ",
            "hex": "#F5F3E5"
        },
        {
            "name": "Edgewater ",
            "hex": "#C8E3D7"
        },
        {
            "name": "Egg Sour ",
            "hex": "#FFF4DD"
        },
        {
            "name": "Egg White ",
            "hex": "#FFEFC1"
        },
        {
            "name": "Eggplant ",
            "hex": "#380835"
        },
        {
            "name": "Eggshell ",
            "hex": "#F0EAD6"
        },
        {
            "name": "Eggshell Blue ",
            "hex": "#C4FFF7"
        },
        {
            "name": "Egyptian Blue ",
            "hex": "#1034A6"
        },
        {
            "name": "Electric Blue ",
            "hex": "#7DF9FF"
        },
        {
            "name": "Electric Indigo ",
            "hex": "#6F00FF"
        },
        {
            "name": "Electric Lavender ",
            "hex": "#F4BBFF"
        },
        {
            "name": "Electric Purple ",
            "hex": "#AA23FF"
        },
        {
            "name": "Elephant ",
            "hex": "#123447"
        },
        {
            "name": "Elf Green ",
            "hex": "#088370"
        },
        {
            "name": "Emerald Green ",
            "hex": "#5FFB17"
        },
        {
            "name": "Eminence ",
            "hex": "#6C3082"
        },
        {
            "name": "English Walnut ",
            "hex": "#3E2B23"
        },
        {
            "name": "Espresso ",
            "hex": "#612718"
        },
        {
            "name": "Eucalyptus ",
            "hex": "#278A5B"
        },
        {
            "name": "Everglade ",
            "hex": "#1C402E"
        },
        {
            "name": "Evergreen ",
            "hex": "#05472A"
        },
        {
            "name": "Faded Blue ",
            "hex": "#658CBB"
        },
        {
            "name": "Faded Green ",
            "hex": "#7BB274"
        },
        {
            "name": "Faded Jade ",
            "hex": "#427977"
        },
        {
            "name": "Faded Orange ",
            "hex": "#F0944D"
        },
        {
            "name": "Faded Pink ",
            "hex": "#DE9DAC"
        },
        {
            "name": "Faded Purple ",
            "hex": "#916E99"
        },
        {
            "name": "Faded Red ",
            "hex": "#D3494E"
        },
        {
            "name": "Falcon ",
            "hex": "#7F626D"
        },
        {
            "name": "Fall Leaf Brown ",
            "hex": "#C8B560"
        },
        {
            "name": "Falu Red ",
            "hex": "#801818"
        },
        {
            "name": "Fandango ",
            "hex": "#B53389"
        },
        {
            "name": "Fantasy ",
            "hex": "#FAF3F0"
        },
        {
            "name": "Fawn ",
            "hex": "#CFAF7B"
        },
        {
            "name": "Fern ",
            "hex": "#63B76C"
        },
        {
            "name": "Fern Frond ",
            "hex": "#657220"
        },
        {
            "name": "Fern Green ",
            "hex": "#548D44"
        },
        {
            "name": "Ferra ",
            "hex": "#704F50"
        },
        {
            "name": "Ferrari Red ",
            "hex": "#F70D1A"
        },
        {
            "name": "Feta ",
            "hex": "#F0FCEA"
        },
        {
            "name": "Fiery Orange ",
            "hex": "#B35213"
        },
        {
            "name": "Finch ",
            "hex": "#626649"
        },
        {
            "name": "Fire ",
            "hex": "#AA4203"
        },
        {
            "name": "Fire Bush ",
            "hex": "#E89928"
        },
        {
            "name": "Fire Engine Red ",
            "hex": "#CE2029"
        },
        {
            "name": "Firebrick ",
            "hex": "#800517"
        },
        {
            "name": "Firefly ",
            "hex": "#0E2A30"
        },
        {
            "name": "Flame ",
            "hex": "#E25822"
        },
        {
            "name": "Flame Pea ",
            "hex": "#DA5B38"
        },
        {
            "name": "Flamingo Pink ",
            "hex": "#F9A7B0"
        },
        {
            "name": "Flat Blue ",
            "hex": "#3C73A8"
        },
        {
            "name": "Flat Green ",
            "hex": "#699D4C"
        },
        {
            "name": "Flax Smoke ",
            "hex": "#7B8265"
        },
        {
            "name": "Flint ",
            "hex": "#6F6A61"
        },
        {
            "name": "Flirt ",
            "hex": "#A2006D"
        },
        {
            "name": "Floral White ",
            "hex": "#FFFAF0"
        },
        {
            "name": "Flush Mahogany ",
            "hex": "#CA3435"
        },
        {
            "name": "Foam ",
            "hex": "#D8FCFA"
        },
        {
            "name": "Foam Green ",
            "hex": "#90FDA9"
        },
        {
            "name": "Fog ",
            "hex": "#D7D0FF"
        },
        {
            "name": "Foggy Grey ",
            "hex": "#CBCAB6"
        },
        {
            "name": "Folly ",
            "hex": "#FF004F"
        },
        {
            "name": "Forest Green ",
            "hex": "#4E9258"
        },
        {
            "name": "Forget Me Not ",
            "hex": "#FFF1EE"
        },
        {
            "name": "Fountain Blue ",
            "hex": "#56B4BE"
        },
        {
            "name": "French Beige ",
            "hex": "#A67B5B"
        },
        {
            "name": "French Blue ",
            "hex": "#0072BB"
        },
        {
            "name": "French Grey ",
            "hex": "#BDBDC6"
        },
        {
            "name": "French Pass ",
            "hex": "#BDEDFD"
        },
        {
            "name": "French Rose ",
            "hex": "#F64A8A"
        },
        {
            "name": "Fresh Eggplant ",
            "hex": "#990066"
        },
        {
            "name": "Fresh Green ",
            "hex": "#69D84F"
        },
        {
            "name": "Friar Grey ",
            "hex": "#807E79"
        },
        {
            "name": "Fringy Flower ",
            "hex": "#B1E2C1"
        },
        {
            "name": "Frog Green ",
            "hex": "#99C68E"
        },
        {
            "name": "Frost ",
            "hex": "#EDF5DD"
        },
        {
            "name": "Frosted Mint ",
            "hex": "#DBFFF8"
        },
        {
            "name": "Fuchsia ",
            "hex": "#ED0DD9"
        },
        {
            "name": "Fuchsia Blue ",
            "hex": "#7A58C1"
        },
        {
            "name": "Fuchsia Pink ",
            "hex": "#FF77FF"
        },
        {
            "name": "Fuel Yellow ",
            "hex": "#ECA927"
        },
        {
            "name": "Fun Blue ",
            "hex": "#1959A8"
        },
        {
            "name": "Fuscous Grey ",
            "hex": "#54534D"
        },
        {
            "name": "Fuzzy Wuzzy Brown ",
            "hex": "#C45655"
        },
        {
            "name": "Gable Green ",
            "hex": "#163531"
        },
        {
            "name": "Gainsboro ",
            "hex": "#DCDCDC"
        },
        {
            "name": "Gamboge ",
            "hex": "#E49B0F"
        },
        {
            "name": "Geraldine ",
            "hex": "#FB8989"
        },
        {
            "name": "Geyser ",
            "hex": "#D4DFE2"
        },
        {
            "name": "Ghost ",
            "hex": "#C7C9D5"
        },
        {
            "name": "Ghost White ",
            "hex": "#F8F8FF"
        },
        {
            "name": "Ginger ",
            "hex": "#B06500"
        },
        {
            "name": "Ginger Brown ",
            "hex": "#C9BE62"
        },
        {
            "name": "Glacial Blue Ice ",
            "hex": "#368BC1"
        },
        {
            "name": "Glacier ",
            "hex": "#80B3C4"
        },
        {
            "name": "Glade Green ",
            "hex": "#61845F"
        },
        {
            "name": "Glaucous ",
            "hex": "#6082B6"
        },
        {
            "name": "Gold Drop ",
            "hex": "#F18200"
        },
        {
            "name": "Gold Tips ",
            "hex": "#DEBA13"
        },
        {
            "name": "Golden ",
            "hex": "#F5BF03"
        },
        {
            "name": "Golden Bell ",
            "hex": "#E28913"
        },
        {
            "name": "Golden Brown ",
            "hex": "#EAC117"
        },
        {
            "name": "Golden Dream ",
            "hex": "#F0D52D"
        },
        {
            "name": "Golden Fizz ",
            "hex": "#F5FB3D"
        },
        {
            "name": "Golden Glow ",
            "hex": "#FDE295"
        },
        {
            "name": "Golden Sand ",
            "hex": "#F0DB7D"
        },
        {
            "name": "Golden Yellow ",
            "hex": "#FFDF00"
        },
        {
            "name": "Goldenrod ",
            "hex": "#EDDA74"
        },
        {
            "name": "Gondola ",
            "hex": "#261414"
        },
        {
            "name": "Gordons Green ",
            "hex": "#0B1107"
        },
        {
            "name": "Grandis ",
            "hex": "#FFD38C"
        },
        {
            "name": "Granite ",
            "hex": "#837E7C"
        },
        {
            "name": "Granite Green ",
            "hex": "#8D8974"
        },
        {
            "name": "Grape ",
            "hex": "#6C3461"
        },
        {
            "name": "Grape Purple ",
            "hex": "#5D1451"
        },
        {
            "name": "Grapefruit ",
            "hex": "#DC381F"
        },
        {
            "name": "Graphite ",
            "hex": "#251607"
        },
        {
            "name": "Grass Green ",
            "hex": "#3F9B0B"
        },
        {
            "name": "Gravel ",
            "hex": "#4A444B"
        },
        {
            "name": "Green Apple ",
            "hex": "#5EDC1F"
        },
        {
            "name": "Green Blue ",
            "hex": "#06B48B"
        },
        {
            "name": "Green Brown ",
            "hex": "#544E03"
        },
        {
            "name": "Green Grey ",
            "hex": "#77926F"
        },
        {
            "name": "Green Haze ",
            "hex": "#01A368"
        },
        {
            "name": "Green Leaf ",
            "hex": "#436A0D"
        },
        {
            "name": "Green Mist ",
            "hex": "#CBD3B0"
        },
        {
            "name": "Green Onion ",
            "hex": "#6AA121"
        },
        {
            "name": "Green Pea ",
            "hex": "#1D6142"
        },
        {
            "name": "Green Peas ",
            "hex": "#89C35C"
        },
        {
            "name": "Green Smoke ",
            "hex": "#A4AF6E"
        },
        {
            "name": "Green Snake ",
            "hex": "#6CBB3C"
        },
        {
            "name": "Green Spring Rain ",
            "hex": "#ACCBB1"
        },
        {
            "name": "Green Teal ",
            "hex": "#0CB577"
        },
        {
            "name": "Green Thumb ",
            "hex": "#B5EAAA"
        },
        {
            "name": "Green Vogue ",
            "hex": "#032B52"
        },
        {
            "name": "Green White ",
            "hex": "#E8EBE0"
        },
        {
            "name": "Green Yellow ",
            "hex": "#C9FF27"
        },
        {
            "name": "Greenish Beige ",
            "hex": "#C9D179"
        },
        {
            "name": "Greenish Blue ",
            "hex": "#307D7E"
        },
        {
            "name": "Greenish Brown ",
            "hex": "#696112"
        },
        {
            "name": "Greenish Cyan ",
            "hex": "#2AFEB7"
        },
        {
            "name": "Greenish Grey ",
            "hex": "#96AE8D"
        },
        {
            "name": "Greenish Tan ",
            "hex": "#BCCB7A"
        },
        {
            "name": "Greenish Teal ",
            "hex": "#32BF84"
        },
        {
            "name": "Greenish Turquoise ",
            "hex": "#00FBB0"
        },
        {
            "name": "Greenish Yellow ",
            "hex": "#CDFD02"
        },
        {
            "name": "Greeny Blue ",
            "hex": "#42B395"
        },
        {
            "name": "Greeny Brown ",
            "hex": "#696006"
        },
        {
            "name": "Greeny Grey ",
            "hex": "#7EA07A"
        },
        {
            "name": "Grenadier ",
            "hex": "#D54600"
        },
        {
            "name": "Grey ",
            "hex": "#929591"
        },
        {
            "name": "Grey Asparagus ",
            "hex": "#465945"
        },
        {
            "name": "Grey Blue ",
            "hex": "#6B8BA4"
        },
        {
            "name": "Grey Brown ",
            "hex": "#7F7053"
        },
        {
            "name": "Grey Chateau ",
            "hex": "#A2AAB3"
        },
        {
            "name": "Grey Cloud ",
            "hex": "#B6B6B4"
        },
        {
            "name": "Grey Goose ",
            "hex": "#D1D0CE"
        },
        {
            "name": "Grey Green ",
            "hex": "#789B73"
        },
        {
            "name": "Grey Nickel ",
            "hex": "#C3C3BD"
        },
        {
            "name": "Grey Olive ",
            "hex": "#A9A491"
        },
        {
            "name": "Grey Pink ",
            "hex": "#C3909B"
        },
        {
            "name": "Grey Suit ",
            "hex": "#C1BECD"
        },
        {
            "name": "Grey Teal ",
            "hex": "#5E9B8A"
        },
        {
            "name": "Greyish Blue ",
            "hex": "#5E819D"
        },
        {
            "name": "Greyish Brown ",
            "hex": "#7A6A4F"
        },
        {
            "name": "Greyish Green ",
            "hex": "#82A67D"
        },
        {
            "name": "Greyish Pink ",
            "hex": "#C88D94"
        },
        {
            "name": "Greyish Purple ",
            "hex": "#887191"
        },
        {
            "name": "Greyish Teal ",
            "hex": "#719F91"
        },
        {
            "name": "Greyish Turquoise ",
            "hex": "#5E7D7E"
        },
        {
            "name": "Guardsman Red ",
            "hex": "#BA0101"
        },
        {
            "name": "Gulf Blue ",
            "hex": "#051657"
        },
        {
            "name": "Gulf Stream ",
            "hex": "#80B3AE"
        },
        {
            "name": "Gull Grey ",
            "hex": "#9DACB7"
        },
        {
            "name": "Gum Leaf ",
            "hex": "#B6D3BF"
        },
        {
            "name": "Gun Powder ",
            "hex": "#414257"
        },
        {
            "name": "Gunmetal ",
            "hex": "#2C3539"
        },
        {
            "name": "Gunsmoke ",
            "hex": "#828685"
        },
        {
            "name": "Haiti ",
            "hex": "#1B1035"
        },
        {
            "name": "Half Baked ",
            "hex": "#85C4CC"
        },
        {
            "name": "Half Dutch White ",
            "hex": "#FEF7DE"
        },
        {
            "name": "Halloween Orange ",
            "hex": "#E66C2C"
        },
        {
            "name": "Hampton ",
            "hex": "#E5D8AF"
        },
        {
            "name": "Harp ",
            "hex": "#E6F2EA"
        },
        {
            "name": "Harvest Gold ",
            "hex": "#E0B974"
        },
        {
            "name": "Havelock Blue ",
            "hex": "#5590D9"
        },
        {
            "name": "Hawaiian Tan ",
            "hex": "#9D5616"
        },
        {
            "name": "Hawkes Blue ",
            "hex": "#D4E2FC"
        },
        {
            "name": "Hazel ",
            "hex": "#8E7618"
        },
        {
            "name": "Hazel Green ",
            "hex": "#617C58"
        },
        {
            "name": "Heath ",
            "hex": "#541012"
        },
        {
            "name": "Heather ",
            "hex": "#B7C3D0"
        },
        {
            "name": "Heathered Grey ",
            "hex": "#B6B095"
        },
        {
            "name": "Heavy Metal ",
            "hex": "#2B3228"
        },
        {
            "name": "Heliotrope Purple ",
            "hex": "#D462FF"
        },
        {
            "name": "Hemlock ",
            "hex": "#5E5D3B"
        },
        {
            "name": "Hemp ",
            "hex": "#907874"
        },
        {
            "name": "Hibiscus ",
            "hex": "#B6316C"
        },
        {
            "name": "Highland ",
            "hex": "#6F8E63"
        },
        {
            "name": "Highlighter Green ",
            "hex": "#1BFC06"
        },
        {
            "name": "Himalaya ",
            "hex": "#6A5D1B"
        },
        {
            "name": "Hint Of Green ",
            "hex": "#E6FFE9"
        },
        {
            "name": "Hint Of Yellow ",
            "hex": "#FAFDE4"
        },
        {
            "name": "Hippie Blue ",
            "hex": "#589AAF"
        },
        {
            "name": "Hippie Green ",
            "hex": "#53824B"
        },
        {
            "name": "Hit Grey ",
            "hex": "#A1ADB5"
        },
        {
            "name": "Hit Pink ",
            "hex": "#FFAB81"
        },
        {
            "name": "Hoki ",
            "hex": "#65869F"
        },
        {
            "name": "Hollywood Cerise ",
            "hex": "#F400A1"
        },
        {
            "name": "Honey Flower ",
            "hex": "#4F1C70"
        },
        {
            "name": "Honeydew ",
            "hex": "#F0FFF0"
        },
        {
            "name": "Honeysuckle ",
            "hex": "#EDFC84"
        },
        {
            "name": "Hopbush ",
            "hex": "#D06DA1"
        },
        {
            "name": "Horizon ",
            "hex": "#5A87A0"
        },
        {
            "name": "Hot Green ",
            "hex": "#25FF29"
        },
        {
            "name": "Hot Magenta ",
            "hex": "#F504C9"
        },
        {
            "name": "Hot Pink ",
            "hex": "#F660AB"
        },
        {
            "name": "Hot Purple ",
            "hex": "#CB00F5"
        },
        {
            "name": "Hummingbird Green ",
            "hex": "#7FE817"
        },
        {
            "name": "Hunter Green ",
            "hex": "#0B4008"
        },
        {
            "name": "Hurricane ",
            "hex": "#877C7B"
        },
        {
            "name": "Husk ",
            "hex": "#B7A458"
        },
        {
            "name": "Ice ",
            "hex": "#D6FFFA"
        },
        {
            "name": "Ice Cold ",
            "hex": "#B1F4E7"
        },
        {
            "name": "Iceberg ",
            "hex": "#DAF4F0"
        },
        {
            "name": "Icky Green ",
            "hex": "#8FAE22"
        },
        {
            "name": "Iguana Green ",
            "hex": "#9CB071"
        },
        {
            "name": "Illusion ",
            "hex": "#F6A4C9"
        },
        {
            "name": "Indian Red ",
            "hex": "#CD5C5C"
        },
        {
            "name": "Indian Tan ",
            "hex": "#4D1E01"
        },
        {
            "name": "Indian Yellow ",
            "hex": "#E3A857"
        },
        {
            "name": "Indigo ",
            "hex": "#4B0082"
        },
        {
            "name": "Indochine ",
            "hex": "#C26B03"
        },
        {
            "name": "International Orange ",
            "hex": "#FF4F00"
        },
        {
            "name": "Iridium ",
            "hex": "#3D3C3A"
        },
        {
            "name": "Iris ",
            "hex": "#6258C4"
        },
        {
            "name": "Irish Coffee ",
            "hex": "#5F3D26"
        },
        {
            "name": "Irish Green ",
            "hex": "#019529"
        },
        {
            "name": "Iroko ",
            "hex": "#433120"
        },
        {
            "name": "Iron ",
            "hex": "#D4D7D9"
        },
        {
            "name": "Ironside Grey ",
            "hex": "#676662"
        },
        {
            "name": "Ironstone ",
            "hex": "#86483C"
        },
        {
            "name": "Ivory ",
            "hex": "#FFFFF0"
        },
        {
            "name": "Jade ",
            "hex": "#1FA774"
        },
        {
            "name": "Jade Green ",
            "hex": "#2BAF6A"
        },
        {
            "name": "Jagged Ice ",
            "hex": "#C2E8E5"
        },
        {
            "name": "Jaguar ",
            "hex": "#080110"
        },
        {
            "name": "Japanese Laurel ",
            "hex": "#0A6906"
        },
        {
            "name": "Japanese Maple ",
            "hex": "#780109"
        },
        {
            "name": "Jasmine ",
            "hex": "#F8DE7E"
        },
        {
            "name": "Jasmine Purple ",
            "hex": "#A23BEC"
        },
        {
            "name": "Jasper ",
            "hex": "#D73B3E"
        },
        {
            "name": "Jazzberry Jam ",
            "hex": "#A50B5E"
        },
        {
            "name": "Jeans Blue ",
            "hex": "#A0CFEC"
        },
        {
            "name": "Jellyfish ",
            "hex": "#46C7C7"
        },
        {
            "name": "Jet Grey ",
            "hex": "#616D7E"
        },
        {
            "name": "Jet Stream ",
            "hex": "#B5D2CE"
        },
        {
            "name": "Jewel ",
            "hex": "#126B40"
        },
        {
            "name": "Jordy Blue ",
            "hex": "#8AB9F1"
        },
        {
            "name": "Judge Grey ",
            "hex": "#544333"
        },
        {
            "name": "June Bud ",
            "hex": "#BDDA57"
        },
        {
            "name": "Jungle Green ",
            "hex": "#29AB87"
        },
        {
            "name": "Jungle Mist ",
            "hex": "#B4CFD3"
        },
        {
            "name": "Kaitoke Green ",
            "hex": "#004620"
        },
        {
            "name": "Kangaroo ",
            "hex": "#C6C8BD"
        },
        {
            "name": "Kashmir Blue ",
            "hex": "#507096"
        },
        {
            "name": "Kelly Green ",
            "hex": "#4CBB17"
        },
        {
            "name": "Kenyan Copper ",
            "hex": "#7C1C05"
        },
        {
            "name": "Key Lime ",
            "hex": "#AEFF6E"
        },
        {
            "name": "Khaki ",
            "hex": "#ADA96E"
        },
        {
            "name": "Khaki Green ",
            "hex": "#728639"
        },
        {
            "name": "Kingfisher Daisy ",
            "hex": "#3E0480"
        },
        {
            "name": "Kiwi ",
            "hex": "#9CEF43"
        },
        {
            "name": "Kiwi Green ",
            "hex": "#8EE53F"
        },
        {
            "name": "La Salle Green ",
            "hex": "#087830"
        },
        {
            "name": "Languid Lavender ",
            "hex": "#D6CADD"
        },
        {
            "name": "Lapis Blue ",
            "hex": "#15317E"
        },
        {
            "name": "Lava ",
            "hex": "#CF1020"
        },
        {
            "name": "Lava Red ",
            "hex": "#E42217"
        },
        {
            "name": "Lavender ",
            "hex": "#B57EDC"
        },
        {
            "name": "Lavender Blue ",
            "hex": "#8B88F8"
        },
        {
            "name": "Lavender Blush ",
            "hex": "#FFF0F5"
        },
        {
            "name": "Lavender Grey ",
            "hex": "#C4C3D0"
        },
        {
            "name": "Lavender Indigo ",
            "hex": "#9457EB"
        },
        {
            "name": "Lavender Mist ",
            "hex": "#E6E6FA"
        },
        {
            "name": "Lavender Pink ",
            "hex": "#DD85D7"
        },
        {
            "name": "Lavender Pinocchio ",
            "hex": "#EBDDE2"
        },
        {
            "name": "Lavender Purple ",
            "hex": "#967BB6"
        },
        {
            "name": "Lavender Rose ",
            "hex": "#FBA0E3"
        },
        {
            "name": "Lawn Green ",
            "hex": "#4DA409"
        },
        {
            "name": "Leaf ",
            "hex": "#71AA34"
        },
        {
            "name": "Leaf Green ",
            "hex": "#5CA904"
        },
        {
            "name": "Leafy Green ",
            "hex": "#51B73B"
        },
        {
            "name": "Leather ",
            "hex": "#AC7434"
        },
        {
            "name": "Lemon Chiffon ",
            "hex": "#FFF8C6"
        },
        {
            "name": "Lemon Ginger ",
            "hex": "#AC9E22"
        },
        {
            "name": "Lemon Grass ",
            "hex": "#9B9E8F"
        },
        {
            "name": "Lemon Green ",
            "hex": "#ADF802"
        },
        {
            "name": "Lemon Lime ",
            "hex": "#BFFE28"
        },
        {
            "name": "Lichen ",
            "hex": "#8FB67B"
        },
        {
            "name": "Light Apricot ",
            "hex": "#FDD5B1"
        },
        {
            "name": "Light Aqua ",
            "hex": "#8CFFDB"
        },
        {
            "name": "Light Aquamarine ",
            "hex": "#93FFE8"
        },
        {
            "name": "Light Beige ",
            "hex": "#FFFEB6"
        },
        {
            "name": "Light Blue ",
            "hex": "#ADDFFF"
        },
        {
            "name": "Light Blue Green ",
            "hex": "#7EFBB3"
        },
        {
            "name": "Light Blue Grey ",
            "hex": "#B7C9E2"
        },
        {
            "name": "Light Bluish Green ",
            "hex": "#76FDA8"
        },
        {
            "name": "Light Bright Green ",
            "hex": "#53FE5C"
        },
        {
            "name": "Light Brown ",
            "hex": "#B5651D"
        },
        {
            "name": "Light Burgundy ",
            "hex": "#A8415B"
        },
        {
            "name": "Light Carmine Pink ",
            "hex": "#E66771"
        },
        {
            "name": "Light Coral ",
            "hex": "#F08080"
        },
        {
            "name": "Light Cornflower Blue ",
            "hex": "#93CCEA"
        },
        {
            "name": "Light Cyan ",
            "hex": "#ACFFFC"
        },
        {
            "name": "Light Eggplant ",
            "hex": "#894585"
        },
        {
            "name": "Light Forest Green ",
            "hex": "#4F9153"
        },
        {
            "name": "Light Fuchsia Pink ",
            "hex": "#F984EF"
        },
        {
            "name": "Light Gold ",
            "hex": "#FDDC5C"
        },
        {
            "name": "Light Goldenrod Yellow ",
            "hex": "#FAFAD2"
        },
        {
            "name": "Light Grass Green ",
            "hex": "#9AF764"
        },
        {
            "name": "Light Green ",
            "hex": "#96F97B"
        },
        {
            "name": "Light Green Blue ",
            "hex": "#56FCA2"
        },
        {
            "name": "Light Greenish Blue ",
            "hex": "#63F7B4"
        },
        {
            "name": "Light Grey ",
            "hex": "#D8DCD6"
        },
        {
            "name": "Light Grey Blue ",
            "hex": "#9DBCD4"
        },
        {
            "name": "Light Grey Green ",
            "hex": "#B7E1A1"
        },
        {
            "name": "Light Indigo ",
            "hex": "#6D5ACF"
        },
        {
            "name": "Light Jade ",
            "hex": "#C3FDB8"
        },
        {
            "name": "Light Khaki ",
            "hex": "#E6F2A2"
        },
        {
            "name": "Light Lavender ",
            "hex": "#DFC5FE"
        },
        {
            "name": "Light Lilac ",
            "hex": "#EDC8FF"
        },
        {
            "name": "Light Lime ",
            "hex": "#AEFD6C"
        },
        {
            "name": "Light Lime Green ",
            "hex": "#B9FF66"
        },
        {
            "name": "Light Maroon ",
            "hex": "#A24857"
        },
        {
            "name": "Light Mauve ",
            "hex": "#C292A1"
        },
        {
            "name": "Light Mint ",
            "hex": "#B6FFBB"
        },
        {
            "name": "Light Mint Green ",
            "hex": "#A6FBB2"
        },
        {
            "name": "Light Moss Green ",
            "hex": "#A6C875"
        },
        {
            "name": "Light Mustard ",
            "hex": "#F7D560"
        },
        {
            "name": "Light Navy ",
            "hex": "#155084"
        },
        {
            "name": "Light Navy Blue ",
            "hex": "#2E5A88"
        },
        {
            "name": "Light Olive ",
            "hex": "#ACBF69"
        },
        {
            "name": "Light Olive Green ",
            "hex": "#A4BE5C"
        },
        {
            "name": "Light Orange ",
            "hex": "#FDAA48"
        },
        {
            "name": "Light Orchid ",
            "hex": "#E29CD2"
        },
        {
            "name": "Light Pastel Green ",
            "hex": "#B2FBA5"
        },
        {
            "name": "Light Pastel Purple ",
            "hex": "#B19CD9"
        },
        {
            "name": "Light Pea Green ",
            "hex": "#C4FE82"
        },
        {
            "name": "Light Peach ",
            "hex": "#FFD8B1"
        },
        {
            "name": "Light Periwinkle ",
            "hex": "#C1C6FC"
        },
        {
            "name": "Light Pink ",
            "hex": "#FFD1DF"
        },
        {
            "name": "Light Plum ",
            "hex": "#9D5783"
        },
        {
            "name": "Light Purple ",
            "hex": "#BF77F6"
        },
        {
            "name": "Light Red ",
            "hex": "#FF474C"
        },
        {
            "name": "Light Rose ",
            "hex": "#FFC5CB"
        },
        {
            "name": "Light Royal Blue ",
            "hex": "#3A2EFE"
        },
        {
            "name": "Light Sage ",
            "hex": "#BCECAC"
        },
        {
            "name": "Light Salmon ",
            "hex": "#FEA993"
        },
        {
            "name": "Light Salmon Pink ",
            "hex": "#FF9999"
        },
        {
            "name": "Light Sea Green ",
            "hex": "#3EA99F"
        },
        {
            "name": "Light Seafoam ",
            "hex": "#A0FEBF"
        },
        {
            "name": "Light Seafoam Green ",
            "hex": "#A7FFB5"
        },
        {
            "name": "Light Sky Blue ",
            "hex": "#C6FCFF"
        },
        {
            "name": "Light Slate ",
            "hex": "#CCFFFF"
        },
        {
            "name": "Light Slate Blue ",
            "hex": "#736AFF"
        },
        {
            "name": "Light Slate Grey ",
            "hex": "#6D7B8D"
        },
        {
            "name": "Light Steel Blue ",
            "hex": "#728FCE"
        },
        {
            "name": "Light Tan ",
            "hex": "#FBEEAC"
        },
        {
            "name": "Light Taupe ",
            "hex": "#B38B6D"
        },
        {
            "name": "Light Teal ",
            "hex": "#90E4C1"
        },
        {
            "name": "Light Thulian Pink ",
            "hex": "#E68FAC"
        },
        {
            "name": "Light Turquoise ",
            "hex": "#7EF4CC"
        },
        {
            "name": "Light Violet ",
            "hex": "#D6B4FC"
        },
        {
            "name": "Light Wisteria ",
            "hex": "#C9A0DC"
        },
        {
            "name": "Light Yellow Green ",
            "hex": "#CCFD7F"
        },
        {
            "name": "Light Yellowish Green ",
            "hex": "#C2FF89"
        },
        {
            "name": "Lightning Yellow ",
            "hex": "#FCC01E"
        },
        {
            "name": "Lilac ",
            "hex": "#CEA2FD"
        },
        {
            "name": "Lily ",
            "hex": "#C8AABF"
        },
        {
            "name": "Lily White ",
            "hex": "#E7F8FF"
        },
        {
            "name": "Lime Green ",
            "hex": "#41A317"
        },
        {
            "name": "Lime Yellow ",
            "hex": "#D0FE1D"
        },
        {
            "name": "Limed Ash ",
            "hex": "#747D63"
        },
        {
            "name": "Limed Oak ",
            "hex": "#AC8A56"
        },
        {
            "name": "Limed Spruce ",
            "hex": "#394851"
        },
        {
            "name": "Lincoln Green ",
            "hex": "#195905"
        },
        {
            "name": "Linen ",
            "hex": "#FAF0E6"
        },
        {
            "name": "Link Water ",
            "hex": "#D9E4F5"
        },
        {
            "name": "Lion ",
            "hex": "#C19A6B"
        },
        {
            "name": "Lipstick Pink ",
            "hex": "#C48793"
        },
        {
            "name": "Lipstick Red ",
            "hex": "#C0022F"
        },
        {
            "name": "Lisbon Brown ",
            "hex": "#423921"
        },
        {
            "name": "Liver ",
            "hex": "#534B4F"
        },
        {
            "name": "Loafer ",
            "hex": "#EEF4DE"
        },
        {
            "name": "London Hue ",
            "hex": "#BEA6C3"
        },
        {
            "name": "Lotus ",
            "hex": "#863C3C"
        },
        {
            "name": "Lovely Purple ",
            "hex": "#7F38EC"
        },
        {
            "name": "Lunar Green ",
            "hex": "#3C493A"
        },
        {
            "name": "Luxor Gold ",
            "hex": "#A7882C"
        },
        {
            "name": "Mabel ",
            "hex": "#D9F7FF"
        },
        {
            "name": "Macaroni And Cheese ",
            "hex": "#EFB435"
        },
        {
            "name": "Macaw Blue Green ",
            "hex": "#43BFC7"
        },
        {
            "name": "Magic Mint ",
            "hex": "#AAF0D1"
        },
        {
            "name": "Magnolia ",
            "hex": "#F8F4FF"
        },
        {
            "name": "Mahogany ",
            "hex": "#C04000"
        },
        {
            "name": "Maize ",
            "hex": "#F5D5A0"
        },
        {
            "name": "Malachite ",
            "hex": "#0BDA51"
        },
        {
            "name": "Malibu ",
            "hex": "#7DC8F7"
        },
        {
            "name": "Mandy ",
            "hex": "#E25465"
        },
        {
            "name": "Mandys Pink ",
            "hex": "#F2C3B2"
        },
        {
            "name": "Mango Orange ",
            "hex": "#FF8040"
        },
        {
            "name": "Mango Tango ",
            "hex": "#E77200"
        },
        {
            "name": "Manilla ",
            "hex": "#FFFA86"
        },
        {
            "name": "Mantis ",
            "hex": "#74C365"
        },
        {
            "name": "Marble Blue ",
            "hex": "#566D7E"
        },
        {
            "name": "Mardi Gras ",
            "hex": "#350036"
        },
        {
            "name": "Marigold Yellow ",
            "hex": "#FBE870"
        },
        {
            "name": "Marine ",
            "hex": "#042E60"
        },
        {
            "name": "Mariner ",
            "hex": "#286ACD"
        },
        {
            "name": "Maroon ",
            "hex": "#650021"
        },
        {
            "name": "Maroon Oak ",
            "hex": "#520C17"
        },
        {
            "name": "Martini ",
            "hex": "#AFA09E"
        },
        {
            "name": "Marzipan ",
            "hex": "#F8DB9D"
        },
        {
            "name": "Matterhorn ",
            "hex": "#4E3B41"
        },
        {
            "name": "Mauve ",
            "hex": "#E0B0FF"
        },
        {
            "name": "Medium Aquamarine ",
            "hex": "#66DDAA"
        },
        {
            "name": "Medium Blue ",
            "hex": "#0000CD"
        },
        {
            "name": "Medium Brown ",
            "hex": "#7F5112"
        },
        {
            "name": "Medium Electric Blue ",
            "hex": "#035096"
        },
        {
            "name": "Medium Forest Green ",
            "hex": "#347235"
        },
        {
            "name": "Medium Green ",
            "hex": "#39AD48"
        },
        {
            "name": "Medium Grey ",
            "hex": "#7D7F7C"
        },
        {
            "name": "Medium Jungle Green ",
            "hex": "#1C352D"
        },
        {
            "name": "Medium Orchid ",
            "hex": "#B048B5"
        },
        {
            "name": "Medium Persian Blue ",
            "hex": "#0067A5"
        },
        {
            "name": "Medium Pink ",
            "hex": "#F36196"
        },
        {
            "name": "Medium Purple ",
            "hex": "#8467D7"
        },
        {
            "name": "Medium Red Violet ",
            "hex": "#BB3385"
        },
        {
            "name": "Medium Sea Green ",
            "hex": "#3CB371"
        },
        {
            "name": "Medium Slate Blue ",
            "hex": "#7B68EE"
        },
        {
            "name": "Medium Spring Bud ",
            "hex": "#C9DC87"
        },
        {
            "name": "Medium Spring Green ",
            "hex": "#348017"
        },
        {
            "name": "Medium Taupe ",
            "hex": "#674C47"
        },
        {
            "name": "Medium Teal Blue ",
            "hex": "#0054B4"
        },
        {
            "name": "Medium Turquoise ",
            "hex": "#48CCCD"
        },
        {
            "name": "Medium Violet Red ",
            "hex": "#CA226B"
        },
        {
            "name": "Melon ",
            "hex": "#FEBAAD"
        },
        {
            "name": "Melrose ",
            "hex": "#C7C1FF"
        },
        {
            "name": "Mercury ",
            "hex": "#E5E5E5"
        },
        {
            "name": "Merino ",
            "hex": "#F6F0E6"
        },
        {
            "name": "Merlot ",
            "hex": "#831923"
        },
        {
            "name": "Metallic Blue ",
            "hex": "#4F738E"
        },
        {
            "name": "Metallic Bronze ",
            "hex": "#49371B"
        },
        {
            "name": "Metallic Copper ",
            "hex": "#71291D"
        },
        {
            "name": "Metallic Silver ",
            "hex": "#BCC6CC"
        },
        {
            "name": "Meteor ",
            "hex": "#D07D12"
        },
        {
            "name": "Meteorite ",
            "hex": "#3C1F76"
        },
        {
            "name": "Mid Blue ",
            "hex": "#276AB3"
        },
        {
            "name": "Mid Green ",
            "hex": "#50A747"
        },
        {
            "name": "Mid Grey ",
            "hex": "#5F5F6E"
        },
        {
            "name": "Midnight ",
            "hex": "#011635"
        },
        {
            "name": "Midnight Blue ",
            "hex": "#020035"
        },
        {
            "name": "Midnight Moss ",
            "hex": "#041004"
        },
        {
            "name": "Midnight Purple ",
            "hex": "#280137"
        },
        {
            "name": "Mikado Yellow ",
            "hex": "#FFC40C"
        },
        {
            "name": "Milano Red ",
            "hex": "#B81104"
        },
        {
            "name": "Military Green ",
            "hex": "#667C3E"
        },
        {
            "name": "Milk Chocolate ",
            "hex": "#7F4E1E"
        },
        {
            "name": "Milk Punch ",
            "hex": "#FFF6D4"
        },
        {
            "name": "Milk White ",
            "hex": "#FEFCFF"
        },
        {
            "name": "Mimosa ",
            "hex": "#F8FDD3"
        },
        {
            "name": "Mineral Green ",
            "hex": "#3F5D53"
        },
        {
            "name": "Mint ",
            "hex": "#3EB489"
        },
        {
            "name": "Mint Cream ",
            "hex": "#F5FFFA"
        },
        {
            "name": "Mint Julep ",
            "hex": "#F1EEC1"
        },
        {
            "name": "Mint Tulip ",
            "hex": "#C4F4EB"
        },
        {
            "name": "Minty Green ",
            "hex": "#0BF77D"
        },
        {
            "name": "Mirage ",
            "hex": "#161928"
        },
        {
            "name": "Mist Blue ",
            "hex": "#646D7E"
        },
        {
            "name": "Misty Rose ",
            "hex": "#FFE4E1"
        },
        {
            "name": "Mobster ",
            "hex": "#7F7589"
        },
        {
            "name": "Moccaccino ",
            "hex": "#6E1D14"
        },
        {
            "name": "Moccasin ",
            "hex": "#827839"
        },
        {
            "name": "Mocha ",
            "hex": "#9D7651"
        },
        {
            "name": "Mojo ",
            "hex": "#C04737"
        },
        {
            "name": "Monsoon ",
            "hex": "#8A8389"
        },
        {
            "name": "Moody Blue ",
            "hex": "#7F76D3"
        },
        {
            "name": "Moon Glow ",
            "hex": "#FCFEDA"
        },
        {
            "name": "Moon Mist ",
            "hex": "#DCDDCC"
        },
        {
            "name": "Moonraker ",
            "hex": "#D6CEF6"
        },
        {
            "name": "Moonstone Blue ",
            "hex": "#73A9C2"
        },
        {
            "name": "Morning Glory ",
            "hex": "#9EDEE0"
        },
        {
            "name": "Moss ",
            "hex": "#769958"
        },
        {
            "name": "Moss Green ",
            "hex": "#658B38"
        },
        {
            "name": "Mountain Meadow ",
            "hex": "#1AB385"
        },
        {
            "name": "Mountain Mist ",
            "hex": "#959396"
        },
        {
            "name": "Mountbatten Pink ",
            "hex": "#997A8D"
        },
        {
            "name": "Muddy Brown ",
            "hex": "#886806"
        },
        {
            "name": "Muddy Green ",
            "hex": "#657432"
        },
        {
            "name": "Muddy Waters ",
            "hex": "#B78E5C"
        },
        {
            "name": "Muddy Yellow ",
            "hex": "#BFAC05"
        },
        {
            "name": "Muesli ",
            "hex": "#AA8B5B"
        },
        {
            "name": "Mulberry ",
            "hex": "#920A4E"
        },
        {
            "name": "Mulberry Wood ",
            "hex": "#5C0536"
        },
        {
            "name": "Mule Fawn ",
            "hex": "#8C472F"
        },
        {
            "name": "Mulled Wine ",
            "hex": "#4E4562"
        },
        {
            "name": "Murky Green ",
            "hex": "#6C7A0E"
        },
        {
            "name": "Mushroom ",
            "hex": "#BA9E88"
        },
        {
            "name": "Mustard ",
            "hex": "#CEB301"
        },
        {
            "name": "Mustard Brown ",
            "hex": "#AC7E04"
        },
        {
            "name": "Mustard Green ",
            "hex": "#A8B504"
        },
        {
            "name": "Mustard Yellow ",
            "hex": "#D2BD0A"
        },
        {
            "name": "Muted Blue ",
            "hex": "#3B719F"
        },
        {
            "name": "Muted Green ",
            "hex": "#5FA052"
        },
        {
            "name": "Muted Pink ",
            "hex": "#D1768F"
        },
        {
            "name": "Muted Purple ",
            "hex": "#805B87"
        },
        {
            "name": "Myrtle ",
            "hex": "#21421E"
        },
        {
            "name": "Napier Green ",
            "hex": "#2A8000"
        },
        {
            "name": "Naples Yellow ",
            "hex": "#FADA5E"
        },
        {
            "name": "Nasty Green ",
            "hex": "#70B23F"
        },
        {
            "name": "Natural Grey ",
            "hex": "#8B8680"
        },
        {
            "name": "Navajo White ",
            "hex": "#FFDEAD"
        },
        {
            "name": "Navy ",
            "hex": "#01153E"
        },
        {
            "name": "Navy Blue ",
            "hex": "#000080"
        },
        {
            "name": "Navy Green ",
            "hex": "#35530A"
        },
        {
            "name": "Nebula ",
            "hex": "#CBDBD6"
        },
        {
            "name": "Nebula Green ",
            "hex": "#59E817"
        },
        {
            "name": "Neon Blue ",
            "hex": "#04D9FF"
        },
        {
            "name": "Neon Carrot ",
            "hex": "#FFA343"
        },
        {
            "name": "Neon Fuchsia ",
            "hex": "#FE59C2"
        },
        {
            "name": "Neon Pink ",
            "hex": "#F535AA"
        },
        {
            "name": "Neon Purple ",
            "hex": "#BC13FE"
        },
        {
            "name": "Neon Yellow ",
            "hex": "#CFFF04"
        },
        {
            "name": "Neptune ",
            "hex": "#7CB7BB"
        },
        {
            "name": "Nice Blue ",
            "hex": "#107AB0"
        },
        {
            "name": "Night ",
            "hex": "#0C090A"
        },
        {
            "name": "Night Blue ",
            "hex": "#040348"
        },
        {
            "name": "Nile Blue ",
            "hex": "#193751"
        },
        {
            "name": "Nobel ",
            "hex": "#B7B1B1"
        },
        {
            "name": "Northern Lights Blue ",
            "hex": "#78C7C7"
        },
        {
            "name": "Nutmeg ",
            "hex": "#81422C"
        },
        {
            "name": "Nutmeg Wood ",
            "hex": "#683600"
        },
        {
            "name": "Oak Brown ",
            "hex": "#806517"
        },
        {
            "name": "Oasis ",
            "hex": "#FEEFCE"
        },
        {
            "name": "Ocean ",
            "hex": "#017B92"
        },
        {
            "name": "Ochre ",
            "hex": "#BF9005"
        },
        {
            "name": "Off Green ",
            "hex": "#E6F8F3"
        },
        {
            "name": "Off White ",
            "hex": "#FFFFE4"
        },
        {
            "name": "Oil ",
            "hex": "#281E15"
        },
        {
            "name": "Old Brick ",
            "hex": "#901E1E"
        },
        {
            "name": "Old Copper ",
            "hex": "#724A2F"
        },
        {
            "name": "Old Gold ",
            "hex": "#CFB53B"
        },
        {
            "name": "Old Lace ",
            "hex": "#FDF5E6"
        },
        {
            "name": "Old Lavender ",
            "hex": "#796878"
        },
        {
            "name": "Old Mauve ",
            "hex": "#673147"
        },
        {
            "name": "Old Pink ",
            "hex": "#C77986"
        },
        {
            "name": "Old Rose ",
            "hex": "#C08081"
        },
        {
            "name": "Olive ",
            "hex": "#6E750E"
        },
        {
            "name": "Olive Brown ",
            "hex": "#645403"
        },
        {
            "name": "Olive Drab ",
            "hex": "#6B8E23"
        },
        {
            "name": "Olive Green ",
            "hex": "#BAB86C"
        },
        {
            "name": "Olive Haze ",
            "hex": "#8B8470"
        },
        {
            "name": "Olive Yellow ",
            "hex": "#C2B709"
        },
        {
            "name": "Olivine ",
            "hex": "#9AB973"
        },
        {
            "name": "Onion ",
            "hex": "#2F270E"
        },
        {
            "name": "Onyx ",
            "hex": "#0F0F0F"
        },
        {
            "name": "Opal ",
            "hex": "#A9C6C2"
        },
        {
            "name": "Opera Mauve ",
            "hex": "#B784A7"
        },
        {
            "name": "Opium ",
            "hex": "#8E6F70"
        },
        {
            "name": "Orange Brown ",
            "hex": "#BE6400"
        },
        {
            "name": "Orange Gold ",
            "hex": "#D4A017"
        },
        {
            "name": "Orange Peel ",
            "hex": "#FF9F00"
        },
        {
            "name": "Orange Salmon ",
            "hex": "#C47451"
        },
        {
            "name": "Orangey Red ",
            "hex": "#FA4224"
        },
        {
            "name": "Orangey Yellow ",
            "hex": "#FDB915"
        },
        {
            "name": "Orchid ",
            "hex": "#DA70D6"
        },
        {
            "name": "Oriental Pink ",
            "hex": "#C69191"
        },
        {
            "name": "Oslo Grey ",
            "hex": "#878D91"
        },
        {
            "name": "Otter Brown ",
            "hex": "#654321"
        },
        {
            "name": "Outer Space ",
            "hex": "#2D383A"
        },
        {
            "name": "Oxley ",
            "hex": "#779E86"
        },
        {
            "name": "Oyster Bay ",
            "hex": "#DAFAFF"
        },
        {
            "name": "Oyster Pink ",
            "hex": "#E9CECD"
        },
        {
            "name": "Pacific Blue ",
            "hex": "#009DC4"
        },
        {
            "name": "Palatinate Blue ",
            "hex": "#273BE2"
        },
        {
            "name": "Palatinate Purple ",
            "hex": "#682860"
        },
        {
            "name": "Pale Aqua ",
            "hex": "#BCD4E6"
        },
        {
            "name": "Pale Blue Lily ",
            "hex": "#CFECEC"
        },
        {
            "name": "Pale Brown ",
            "hex": "#987654"
        },
        {
            "name": "Pale Canary ",
            "hex": "#FFFF99"
        },
        {
            "name": "Pale Carmine ",
            "hex": "#AF4035"
        },
        {
            "name": "Pale Cerulean ",
            "hex": "#9BC4E2"
        },
        {
            "name": "Pale Chestnut ",
            "hex": "#DDADAF"
        },
        {
            "name": "Pale Copper ",
            "hex": "#DA8A67"
        },
        {
            "name": "Pale Cornflower Blue ",
            "hex": "#ABCDEF"
        },
        {
            "name": "Pale Cyan ",
            "hex": "#B7FFFA"
        },
        {
            "name": "Pale Gold ",
            "hex": "#FDDE6C"
        },
        {
            "name": "Pale Goldenrod ",
            "hex": "#EEE8AA"
        },
        {
            "name": "Pale Green ",
            "hex": "#98FB98"
        },
        {
            "name": "Pale Grey ",
            "hex": "#FDFDFE"
        },
        {
            "name": "Pale Lavender ",
            "hex": "#EECFFE"
        },
        {
            "name": "Pale Leaf ",
            "hex": "#C0D3B9"
        },
        {
            "name": "Pale Light Green ",
            "hex": "#B1FC99"
        },
        {
            "name": "Pale Lilac ",
            "hex": "#E4CBFF"
        },
        {
            "name": "Pale Lime ",
            "hex": "#BEFD73"
        },
        {
            "name": "Pale Magenta ",
            "hex": "#D767AD"
        },
        {
            "name": "Pale Mauve ",
            "hex": "#FED0FC"
        },
        {
            "name": "Pale Olive ",
            "hex": "#B9CC81"
        },
        {
            "name": "Pale Olive Green ",
            "hex": "#B1D27B"
        },
        {
            "name": "Pale Orange ",
            "hex": "#FFA756"
        },
        {
            "name": "Pale Oyster ",
            "hex": "#988D77"
        },
        {
            "name": "Pale Peach ",
            "hex": "#FFE5AD"
        },
        {
            "name": "Pale Pink ",
            "hex": "#FADADD"
        },
        {
            "name": "Pale Purple ",
            "hex": "#B790D4"
        },
        {
            "name": "Pale Red ",
            "hex": "#D9544D"
        },
        {
            "name": "Pale Robin Egg Blue ",
            "hex": "#96DED1"
        },
        {
            "name": "Pale Rose ",
            "hex": "#FDC1C5"
        },
        {
            "name": "Pale Salmon ",
            "hex": "#FFB19A"
        },
        {
            "name": "Pale Silver ",
            "hex": "#C9C0BB"
        },
        {
            "name": "Pale Sky ",
            "hex": "#6E7783"
        },
        {
            "name": "Pale Sky Blue ",
            "hex": "#BDF6FE"
        },
        {
            "name": "Pale Slate ",
            "hex": "#C3BFC1"
        },
        {
            "name": "Pale Spring Bud ",
            "hex": "#ECEBBD"
        },
        {
            "name": "Pale Taupe ",
            "hex": "#BC987E"
        },
        {
            "name": "Pale Teal ",
            "hex": "#82CBB2"
        },
        {
            "name": "Pale Turquoise ",
            "hex": "#A5FBD5"
        },
        {
            "name": "Pale Violet ",
            "hex": "#CEAEFA"
        },
        {
            "name": "Pale Violet Red ",
            "hex": "#DB7093"
        },
        {
            "name": "Palm Green ",
            "hex": "#09230F"
        },
        {
            "name": "Palm Leaf ",
            "hex": "#19330E"
        },
        {
            "name": "Pansy Purple ",
            "hex": "#78184A"
        },
        {
            "name": "Papaya Orange ",
            "hex": "#E56717"
        },
        {
            "name": "Papaya Whip ",
            "hex": "#FFEFD5"
        },
        {
            "name": "Paprika ",
            "hex": "#8D0226"
        },
        {
            "name": "Parchment ",
            "hex": "#FEFCAF"
        },
        {
            "name": "Paris Green ",
            "hex": "#50C878"
        },
        {
            "name": "Parsley ",
            "hex": "#134F19"
        },
        {
            "name": "Pastel Blue ",
            "hex": "#A2BFFE"
        },
        {
            "name": "Pastel Brown ",
            "hex": "#836953"
        },
        {
            "name": "Pastel Green ",
            "hex": "#B0FF9D"
        },
        {
            "name": "Pastel Grey ",
            "hex": "#CFCFC4"
        },
        {
            "name": "Pastel Magenta ",
            "hex": "#F49AC2"
        },
        {
            "name": "Pastel Orange ",
            "hex": "#FF964F"
        },
        {
            "name": "Pastel Pink ",
            "hex": "#FFD1DC"
        },
        {
            "name": "Pastel Purple ",
            "hex": "#B39EB5"
        },
        {
            "name": "Pastel Red ",
            "hex": "#DB5856"
        },
        {
            "name": "Pastel Violet ",
            "hex": "#CB99C9"
        },
        {
            "name": "Pattens Blue ",
            "hex": "#DEF5FF"
        },
        {
            "name": "Pavlova ",
            "hex": "#D7C498"
        },
        {
            "name": "Pea ",
            "hex": "#A4BF20"
        },
        {
            "name": "Pea Green ",
            "hex": "#8EAB12"
        },
        {
            "name": "Peach ",
            "hex": "#FFB07C"
        },
        {
            "name": "Peach Orange ",
            "hex": "#FFCC99"
        },
        {
            "name": "Peach Puff ",
            "hex": "#FFDAB9"
        },
        {
            "name": "Peach Schnapps ",
            "hex": "#FFDCD6"
        },
        {
            "name": "Peach Yellow ",
            "hex": "#FADFAD"
        },
        {
            "name": "Peachy Pink ",
            "hex": "#FF9A8A"
        },
        {
            "name": "Peacock Blue ",
            "hex": "#016795"
        },
        {
            "name": "Peanut ",
            "hex": "#782F16"
        },
        {
            "name": "Pear ",
            "hex": "#D1E231"
        },
        {
            "name": "Pearl ",
            "hex": "#FDEEF4"
        },
        {
            "name": "Pearl Aqua ",
            "hex": "#88D8C0"
        },
        {
            "name": "Pearl Bush ",
            "hex": "#E8E0D5"
        },
        {
            "name": "Peppermint ",
            "hex": "#E3F5E1"
        },
        {
            "name": "Peridot ",
            "hex": "#E6E200"
        },
        {
            "name": "Periglacial Blue ",
            "hex": "#E1E6D6"
        },
        {
            "name": "Periwinkle ",
            "hex": "#CCCCFF"
        },
        {
            "name": "Periwinkle Blue ",
            "hex": "#8F99FB"
        },
        {
            "name": "Periwinkle Grey ",
            "hex": "#C3CDE6"
        },
        {
            "name": "Persian Blue ",
            "hex": "#1C39BB"
        },
        {
            "name": "Persian Green ",
            "hex": "#00A693"
        },
        {
            "name": "Persian Indigo ",
            "hex": "#32127A"
        },
        {
            "name": "Persian Orange ",
            "hex": "#D99058"
        },
        {
            "name": "Persian Pink ",
            "hex": "#F77FBE"
        },
        {
            "name": "Persian Plum ",
            "hex": "#701C1C"
        },
        {
            "name": "Persian Red ",
            "hex": "#CC3333"
        },
        {
            "name": "Persian Rose ",
            "hex": "#FE28A2"
        },
        {
            "name": "Pesto ",
            "hex": "#7C7631"
        },
        {
            "name": "Petite Orchid ",
            "hex": "#DB9690"
        },
        {
            "name": "Petrol ",
            "hex": "#005F6A"
        },
        {
            "name": "Phlox ",
            "hex": "#DF00FF"
        },
        {
            "name": "Phthalo Blue ",
            "hex": "#000F89"
        },
        {
            "name": "Phthalo Green ",
            "hex": "#123524"
        },
        {
            "name": "Pickled Bean ",
            "hex": "#6E4826"
        },
        {
            "name": "Pickled Bluewood ",
            "hex": "#314459"
        },
        {
            "name": "Picton Blue ",
            "hex": "#45B1E8"
        },
        {
            "name": "Pig Pink ",
            "hex": "#FDD7E4"
        },
        {
            "name": "Pine ",
            "hex": "#2B5D34"
        },
        {
            "name": "Pine Cone ",
            "hex": "#6D5E54"
        },
        {
            "name": "Pine Green ",
            "hex": "#01796F"
        },
        {
            "name": "Pine Tree ",
            "hex": "#171F04"
        },
        {
            "name": "Pink Daisy ",
            "hex": "#E799A3"
        },
        {
            "name": "Pink Flare ",
            "hex": "#E1C0C8"
        },
        {
            "name": "Pink Lace ",
            "hex": "#FFDDF4"
        },
        {
            "name": "Pink Lemonade ",
            "hex": "#E4287C"
        },
        {
            "name": "Pink Pearl ",
            "hex": "#E7ACCF"
        },
        {
            "name": "Pink Red ",
            "hex": "#F5054F"
        },
        {
            "name": "Pink Rose ",
            "hex": "#E7A1B0"
        },
        {
            "name": "Pink Sherbet ",
            "hex": "#F78FA7"
        },
        {
            "name": "Pink Swan ",
            "hex": "#BEB5B7"
        },
        {
            "name": "Pinkish Brown ",
            "hex": "#B17261"
        },
        {
            "name": "Pinkish Grey ",
            "hex": "#C8ACA9"
        },
        {
            "name": "Pinkish Orange ",
            "hex": "#FF724C"
        },
        {
            "name": "Pinkish Purple ",
            "hex": "#D648D7"
        },
        {
            "name": "Pinkish Tan ",
            "hex": "#D99B82"
        },
        {
            "name": "Pirate Gold ",
            "hex": "#BA7F03"
        },
        {
            "name": "Pistachio ",
            "hex": "#93C572"
        },
        {
            "name": "Pistachio Green ",
            "hex": "#9DC209"
        },
        {
            "name": "Pixie Green ",
            "hex": "#C0D8B6"
        },
        {
            "name": "Plantation ",
            "hex": "#27504B"
        },
        {
            "name": "Platinum ",
            "hex": "#E5E4E2"
        },
        {
            "name": "Plum ",
            "hex": "#DDA0DD"
        },
        {
            "name": "Plum Purple ",
            "hex": "#583759"
        },
        {
            "name": "Plum Velvet ",
            "hex": "#7D0552"
        },
        {
            "name": "Poison Green ",
            "hex": "#40FD14"
        },
        {
            "name": "Porcelain ",
            "hex": "#EFF2F3"
        },
        {
            "name": "Portland Orange ",
            "hex": "#FF5A36"
        },
        {
            "name": "Potters Clay ",
            "hex": "#8C5738"
        },
        {
            "name": "Powder Ash ",
            "hex": "#BCC9C2"
        },
        {
            "name": "Powder Blue ",
            "hex": "#B0E0E6"
        },
        {
            "name": "Powder Pink ",
            "hex": "#FFB2D0"
        },
        {
            "name": "Primrose ",
            "hex": "#EDEA99"
        },
        {
            "name": "Prussian Blue ",
            "hex": "#003153"
        },
        {
            "name": "Puce ",
            "hex": "#7F5A58"
        },
        {
            "name": "Pumpkin Orange ",
            "hex": "#F87217"
        },
        {
            "name": "Pumpkin Skin ",
            "hex": "#B1610B"
        },
        {
            "name": "Purple Blue ",
            "hex": "#632DE9"
        },
        {
            "name": "Purple Brown ",
            "hex": "#673A3F"
        },
        {
            "name": "Purple Daffodil ",
            "hex": "#B041FF"
        },
        {
            "name": "Purple Dragon ",
            "hex": "#C38EC7"
        },
        {
            "name": "Purple Flower ",
            "hex": "#A74AC7"
        },
        {
            "name": "Purple Grey ",
            "hex": "#866F85"
        },
        {
            "name": "Purple Haze ",
            "hex": "#4E387E"
        },
        {
            "name": "Purple Heart ",
            "hex": "#652DC1"
        },
        {
            "name": "Purple Iris ",
            "hex": "#571B7E"
        },
        {
            "name": "Purple Jam ",
            "hex": "#6A287E"
        },
        {
            "name": "Purple Mimosa ",
            "hex": "#9E7BFF"
        },
        {
            "name": "Purple Monster ",
            "hex": "#461B7E"
        },
        {
            "name": "Purple Pink ",
            "hex": "#E03FD8"
        },
        {
            "name": "Purple Red ",
            "hex": "#990147"
        },
        {
            "name": "Purple Sage Bush ",
            "hex": "#7A5DC7"
        },
        {
            "name": "Purple Taupe ",
            "hex": "#50404D"
        },
        {
            "name": "Purplish Blue ",
            "hex": "#601EF9"
        },
        {
            "name": "Purplish Brown ",
            "hex": "#6B4247"
        },
        {
            "name": "Purplish Grey ",
            "hex": "#7A687F"
        },
        {
            "name": "Purplish Pink ",
            "hex": "#CE5DAE"
        },
        {
            "name": "Purplish Red ",
            "hex": "#B0054B"
        },
        {
            "name": "Quicksand ",
            "hex": "#BD978E"
        },
        {
            "name": "Quill Grey ",
            "hex": "#D6D6D1"
        },
        {
            "name": "Racing Green ",
            "hex": "#0C1911"
        },
        {
            "name": "Radical Red ",
            "hex": "#FF355E"
        },
        {
            "name": "Radioactive Green ",
            "hex": "#2CFA1F"
        },
        {
            "name": "Rangoon Green ",
            "hex": "#1C1E13"
        },
        {
            "name": "Raspberry ",
            "hex": "#E30B5D"
        },
        {
            "name": "Raspberry Glace ",
            "hex": "#915F6D"
        },
        {
            "name": "Raspberry Pink ",
            "hex": "#E25098"
        },
        {
            "name": "Raspberry Rose ",
            "hex": "#B3446C"
        },
        {
            "name": "Raven ",
            "hex": "#727B89"
        },
        {
            "name": "Raw Sienna ",
            "hex": "#D27D46"
        },
        {
            "name": "Raw Umber ",
            "hex": "#734A12"
        },
        {
            "name": "Razzle Dazzle Rose ",
            "hex": "#FF33CC"
        },
        {
            "name": "Red Beech ",
            "hex": "#7B3801"
        },
        {
            "name": "Red Berry ",
            "hex": "#8E0000"
        },
        {
            "name": "Red Brown ",
            "hex": "#A52A2A"
        },
        {
            "name": "Red Devil ",
            "hex": "#860111"
        },
        {
            "name": "Red Fox ",
            "hex": "#C35817"
        },
        {
            "name": "Red Orange ",
            "hex": "#FF3F34"
        },
        {
            "name": "Red Oxide ",
            "hex": "#6E0902"
        },
        {
            "name": "Red Pink ",
            "hex": "#FA2A55"
        },
        {
            "name": "Red Purple ",
            "hex": "#820747"
        },
        {
            "name": "Red Ribbon ",
            "hex": "#ED0A3F"
        },
        {
            "name": "Red Violet ",
            "hex": "#C71585"
        },
        {
            "name": "Red Wine ",
            "hex": "#990012"
        },
        {
            "name": "Reddish ",
            "hex": "#C44240"
        },
        {
            "name": "Reddish Brown ",
            "hex": "#7F2B0A"
        },
        {
            "name": "Reddish Grey ",
            "hex": "#997570"
        },
        {
            "name": "Reddish Orange ",
            "hex": "#F8481C"
        },
        {
            "name": "Redwood ",
            "hex": "#5D1E0F"
        },
        {
            "name": "Reef ",
            "hex": "#C9FFA2"
        },
        {
            "name": "Reef Gold ",
            "hex": "#9F821C"
        },
        {
            "name": "Regal Blue ",
            "hex": "#013F6A"
        },
        {
            "name": "Regent Grey ",
            "hex": "#86949F"
        },
        {
            "name": "Reno Sand ",
            "hex": "#A86515"
        },
        {
            "name": "Resolution Blue ",
            "hex": "#002387"
        },
        {
            "name": "Rhino ",
            "hex": "#2E3F62"
        },
        {
            "name": "Rice Flower ",
            "hex": "#EEFFE2"
        },
        {
            "name": "Rich Black ",
            "hex": "#004040"
        },
        {
            "name": "Rich Blue ",
            "hex": "#021BF9"
        },
        {
            "name": "Rich Carmine ",
            "hex": "#D70040"
        },
        {
            "name": "Rich Electric Blue ",
            "hex": "#0892D0"
        },
        {
            "name": "Rich Gold ",
            "hex": "#A85307"
        },
        {
            "name": "Rich Lilac ",
            "hex": "#B666D2"
        },
        {
            "name": "Rich Maroon ",
            "hex": "#B03060"
        },
        {
            "name": "Rich Purple ",
            "hex": "#720058"
        },
        {
            "name": "Rifle Green ",
            "hex": "#414833"
        },
        {
            "name": "Ripe Lemon ",
            "hex": "#F4D81C"
        },
        {
            "name": "Ripe Plum ",
            "hex": "#410056"
        },
        {
            "name": "River Bed ",
            "hex": "#434C59"
        },
        {
            "name": "Robin Egg Blue ",
            "hex": "#8AF1FE"
        },
        {
            "name": "Rock ",
            "hex": "#4D3833"
        },
        {
            "name": "Rock Blue ",
            "hex": "#9EB1CD"
        },
        {
            "name": "Rodeo Dust ",
            "hex": "#C9B29B"
        },
        {
            "name": "Roman Coffee ",
            "hex": "#795D4C"
        },
        {
            "name": "Romance ",
            "hex": "#FFFEFD"
        },
        {
            "name": "Roof Terracotta ",
            "hex": "#A62F20"
        },
        {
            "name": "Rope ",
            "hex": "#8E4D1E"
        },
        {
            "name": "Rosa ",
            "hex": "#FE86A4"
        },
        {
            "name": "Rose ",
            "hex": "#E8ADAA"
        },
        {
            "name": "Rose Bud ",
            "hex": "#FBB2A3"
        },
        {
            "name": "Rose Bud Cherry ",
            "hex": "#800B47"
        },
        {
            "name": "Rose Ebony ",
            "hex": "#674846"
        },
        {
            "name": "Rose Fog ",
            "hex": "#E7BCB4"
        },
        {
            "name": "Rose Gold ",
            "hex": "#ECC5C0"
        },
        {
            "name": "Rose Madder ",
            "hex": "#E32636"
        },
        {
            "name": "Rose Pink ",
            "hex": "#F7879A"
        },
        {
            "name": "Rose Quartz ",
            "hex": "#AA98A9"
        },
        {
            "name": "Rose Red ",
            "hex": "#BE013C"
        },
        {
            "name": "Rose Taupe ",
            "hex": "#905D5D"
        },
        {
            "name": "Rose Vale ",
            "hex": "#AB4E52"
        },
        {
            "name": "Rose White ",
            "hex": "#FFF6F5"
        },
        {
            "name": "Rosewood ",
            "hex": "#65000B"
        },
        {
            "name": "Rosso Corsa ",
            "hex": "#D40000"
        },
        {
            "name": "Rosy Brown ",
            "hex": "#B38481"
        },
        {
            "name": "Rosy Finch ",
            "hex": "#7F4E52"
        },
        {
            "name": "Rosy Pink ",
            "hex": "#F6688E"
        },
        {
            "name": "Rouge ",
            "hex": "#A23B6C"
        },
        {
            "name": "Royal ",
            "hex": "#0C1793"
        },
        {
            "name": "Royal Azure ",
            "hex": "#0038A8"
        },
        {
            "name": "Royal Blue ",
            "hex": "#2B60DE"
        },
        {
            "name": "Royal Fuchsia ",
            "hex": "#CA2C92"
        },
        {
            "name": "Royal Heath ",
            "hex": "#AB3472"
        },
        {
            "name": "Royal Purple ",
            "hex": "#4B006E"
        },
        {
            "name": "Rubber Ducky Yellow ",
            "hex": "#FFD801"
        },
        {
            "name": "Ruby Red ",
            "hex": "#F62217"
        },
        {
            "name": "Ruddy ",
            "hex": "#FF0028"
        },
        {
            "name": "Ruddy Brown ",
            "hex": "#BB6528"
        },
        {
            "name": "Ruddy Pink ",
            "hex": "#E18E96"
        },
        {
            "name": "Rum ",
            "hex": "#796989"
        },
        {
            "name": "Rum Swizzle ",
            "hex": "#F9F8E4"
        },
        {
            "name": "Russet ",
            "hex": "#A13905"
        },
        {
            "name": "Rust Brown ",
            "hex": "#8B3103"
        },
        {
            "name": "Rust Orange ",
            "hex": "#C45508"
        },
        {
            "name": "Rust Red ",
            "hex": "#AA2704"
        },
        {
            "name": "Rustic Red ",
            "hex": "#480404"
        },
        {
            "name": "Rusty Orange ",
            "hex": "#CD5909"
        },
        {
            "name": "Rusty Red ",
            "hex": "#AF2F0D"
        },
        {
            "name": "Saddle Brown ",
            "hex": "#583401"
        },
        {
            "name": "Saffron Mango ",
            "hex": "#F9BF58"
        },
        {
            "name": "Sage ",
            "hex": "#9EA587"
        },
        {
            "name": "Sage Green ",
            "hex": "#848b79"
        },
        {
            "name": "Sahara ",
            "hex": "#B7A214"
        },
        {
            "name": "Sahara Sand ",
            "hex": "#F1E788"
        },
        {
            "name": "Salad Green ",
            "hex": "#A1C935"
        },
        {
            "name": "Salmon ",
            "hex": "#FF796C"
        },
        {
            "name": "Salmon Pink ",
            "hex": "#FE7B7C"
        },
        {
            "name": "Sand ",
            "hex": "#E2CA76"
        },
        {
            "name": "Sand Brown ",
            "hex": "#CBA560"
        },
        {
            "name": "Sand Dune ",
            "hex": "#826F65"
        },
        {
            "name": "Sandrift ",
            "hex": "#AB917A"
        },
        {
            "name": "Sandstone ",
            "hex": "#796D62"
        },
        {
            "name": "Sandstorm ",
            "hex": "#ECD540"
        },
        {
            "name": "Sandwisp ",
            "hex": "#F5E7A2"
        },
        {
            "name": "Sandy Beach ",
            "hex": "#FFEAC8"
        },
        {
            "name": "Sandy Brown ",
            "hex": "#C4A661"
        },
        {
            "name": "Sandy Taupe ",
            "hex": "#967117"
        },
        {
            "name": "Sandy Yellow ",
            "hex": "#FDEE73"
        },
        {
            "name": "Sanguine Brown ",
            "hex": "#8D3D38"
        },
        {
            "name": "Santa Grey ",
            "hex": "#9FA0B1"
        },
        {
            "name": "Sap Green ",
            "hex": "#5C8B15"
        },
        {
            "name": "Sapphire ",
            "hex": "#2138AB"
        },
        {
            "name": "Sapphire Blue ",
            "hex": "#2554C7"
        },
        {
            "name": "Satin Linen ",
            "hex": "#E6E4D4"
        },
        {
            "name": "Satin Sheen Gold ",
            "hex": "#CBA135"
        },
        {
            "name": "Scampi ",
            "hex": "#675FA6"
        },
        {
            "name": "Scarlet ",
            "hex": "#BE0119"
        },
        {
            "name": "School Bus Yellow ",
            "hex": "#E8A317"
        },
        {
            "name": "Science Blue ",
            "hex": "#0066CC"
        },
        {
            "name": "Scotch Mist ",
            "hex": "#FFFBDC"
        },
        {
            "name": "Screaming Green ",
            "hex": "#76FF7A"
        },
        {
            "name": "Sea ",
            "hex": "#3C9992"
        },
        {
            "name": "Sea Green ",
            "hex": "#2E8B57"
        },
        {
            "name": "Sea Mist ",
            "hex": "#C5DBCA"
        },
        {
            "name": "Sea Pink ",
            "hex": "#ED989E"
        },
        {
            "name": "Sea Turtle Green ",
            "hex": "#438D80"
        },
        {
            "name": "Seafoam ",
            "hex": "#80F9AD"
        },
        {
            "name": "Seafoam Blue ",
            "hex": "#78D1B6"
        },
        {
            "name": "Seal Brown ",
            "hex": "#321414"
        },
        {
            "name": "Seashell ",
            "hex": "#F1F1F1"
        },
        {
            "name": "Seaweed ",
            "hex": "#1B2F11"
        },
        {
            "name": "Seaweed Green ",
            "hex": "#35AD6B"
        },
        {
            "name": "Sedona ",
            "hex": "#CC6600"
        },
        {
            "name": "Selective Yellow ",
            "hex": "#FFBA00"
        },
        {
            "name": "Sepia ",
            "hex": "#704214"
        },
        {
            "name": "Sepia Black ",
            "hex": "#2B0202"
        },
        {
            "name": "Sepia Skin ",
            "hex": "#9E5B40"
        },
        {
            "name": "Shadow ",
            "hex": "#8A795D"
        },
        {
            "name": "Shadow Green ",
            "hex": "#9AC2B8"
        },
        {
            "name": "Shamrock Green ",
            "hex": "#009E60"
        },
        {
            "name": "Sherpa Blue ",
            "hex": "#004950"
        },
        {
            "name": "Sherwood Green ",
            "hex": "#02402C"
        },
        {
            "name": "Ship Grey ",
            "hex": "#3E3A44"
        },
        {
            "name": "Shiraz ",
            "hex": "#B20931"
        },
        {
            "name": "Shocking Orange ",
            "hex": "#E55B3C"
        },
        {
            "name": "Shocking Pink ",
            "hex": "#FC0FC0"
        },
        {
            "name": "Shuttle Grey ",
            "hex": "#5F6672"
        },
        {
            "name": "Sienna ",
            "hex": "#A9561E"
        },
        {
            "name": "Silk ",
            "hex": "#BDB1A8"
        },
        {
            "name": "Silk Blue ",
            "hex": "#488AC7"
        },
        {
            "name": "Silver ",
            "hex": "#C5C9C7"
        },
        {
            "name": "Silver Chalice ",
            "hex": "#ACACAC"
        },
        {
            "name": "Silver Sand ",
            "hex": "#BFC1C2"
        },
        {
            "name": "Silver Tree ",
            "hex": "#66B58F"
        },
        {
            "name": "Sinopia ",
            "hex": "#CB410B"
        },
        {
            "name": "Sky Magenta ",
            "hex": "#CF71AF"
        },
        {
            "name": "Slate ",
            "hex": "#516572"
        },
        {
            "name": "Slate Blue ",
            "hex": "#5B7C99"
        },
        {
            "name": "Slate Green ",
            "hex": "#658D6D"
        },
        {
            "name": "Slate Grey ",
            "hex": "#708090"
        },
        {
            "name": "Slime Green ",
            "hex": "#BCE954"
        },
        {
            "name": "Smalt ",
            "hex": "#003399"
        },
        {
            "name": "Smalt Blue ",
            "hex": "#51808F"
        },
        {
            "name": "Smokey Grey ",
            "hex": "#726E6D"
        },
        {
            "name": "Smoky Black ",
            "hex": "#100C08"
        },
        {
            "name": "Smoky Topaz ",
            "hex": "#933D41"
        },
        {
            "name": "Snow Drift ",
            "hex": "#F7FAF7"
        },
        {
            "name": "Snow Flurry ",
            "hex": "#E4FFD1"
        },
        {
            "name": "Snowy Mint ",
            "hex": "#D6FFDB"
        },
        {
            "name": "Soapstone ",
            "hex": "#FFFBF9"
        },
        {
            "name": "Soft Amber ",
            "hex": "#D1C6B4"
        },
        {
            "name": "Soft Blue ",
            "hex": "#6488EA"
        },
        {
            "name": "Soft Green ",
            "hex": "#6FC276"
        },
        {
            "name": "Soft Peach ",
            "hex": "#F5EDEF"
        },
        {
            "name": "Soft Pink ",
            "hex": "#FDB0C0"
        },
        {
            "name": "Soft Purple ",
            "hex": "#A66FB5"
        },
        {
            "name": "Solid Pink ",
            "hex": "#893843"
        },
        {
            "name": "Sorrell Brown ",
            "hex": "#CEB98F"
        },
        {
            "name": "Soya Bean ",
            "hex": "#6A6051"
        },
        {
            "name": "Spanish Green ",
            "hex": "#819885"
        },
        {
            "name": "Spearmint ",
            "hex": "#1EF876"
        },
        {
            "name": "Spicy Mix ",
            "hex": "#885342"
        },
        {
            "name": "Spicy Mustard ",
            "hex": "#74640D"
        },
        {
            "name": "Spicy Pink ",
            "hex": "#816E71"
        },
        {
            "name": "Spring Leaves ",
            "hex": "#578363"
        },
        {
            "name": "Spring Rain ",
            "hex": "#B8C1B1"
        },
        {
            "name": "Spring Sun ",
            "hex": "#F6FFDC"
        },
        {
            "name": "Spring Wood ",
            "hex": "#F8F6F1"
        },
        {
            "name": "Sprout ",
            "hex": "#C1D7B0"
        },
        {
            "name": "Spruce ",
            "hex": "#0A5F38"
        },
        {
            "name": "Spun Pearl ",
            "hex": "#AAABB7"
        },
        {
            "name": "Squash ",
            "hex": "#F2AB15"
        },
        {
            "name": "Star Dust ",
            "hex": "#9F9F9C"
        },
        {
            "name": "Steel ",
            "hex": "#738595"
        },
        {
            "name": "Steel Grey ",
            "hex": "#6F828A"
        },
        {
            "name": "Stone ",
            "hex": "#ADA587"
        },
        {
            "name": "Stonewall ",
            "hex": "#928573"
        },
        {
            "name": "Stoplight Go Green ",
            "hex": "#57E964"
        },
        {
            "name": "Storm Dust ",
            "hex": "#646463"
        },
        {
            "name": "Storm Grey ",
            "hex": "#717486"
        },
        {
            "name": "Stratos ",
            "hex": "#000741"
        },
        {
            "name": "Straw ",
            "hex": "#E4D96F"
        },
        {
            "name": "Strawberry ",
            "hex": "#FB2943"
        },
        {
            "name": "Strong Blue ",
            "hex": "#0C06F7"
        },
        {
            "name": "Sugar Cane ",
            "hex": "#F9FFF6"
        },
        {
            "name": "Summer Green ",
            "hex": "#96BBAB"
        },
        {
            "name": "Sun Yellow ",
            "hex": "#FFDF22"
        },
        {
            "name": "Sundown ",
            "hex": "#FFB1B3"
        },
        {
            "name": "Sunglow ",
            "hex": "#FFCC33"
        },
        {
            "name": "Sunny Yellow ",
            "hex": "#FFF917"
        },
        {
            "name": "Sunrise Orange ",
            "hex": "#E67451"
        },
        {
            "name": "Sunset ",
            "hex": "#FAD6A5"
        },
        {
            "name": "Sunset Orange ",
            "hex": "#FD5E53"
        },
        {
            "name": "Sunshade ",
            "hex": "#FF9E2C"
        },
        {
            "name": "Supernova ",
            "hex": "#FFC901"
        },
        {
            "name": "Surf ",
            "hex": "#BBD7C1"
        },
        {
            "name": "Surf Crest ",
            "hex": "#CFE5D2"
        },
        {
            "name": "Surfie Green ",
            "hex": "#0C7A79"
        },
        {
            "name": "Swamp ",
            "hex": "#698339"
        },
        {
            "name": "Swamp Green ",
            "hex": "#748500"
        },
        {
            "name": "Sweet Corn ",
            "hex": "#FBEA8C"
        },
        {
            "name": "Sweet Pink ",
            "hex": "#FD9FA2"
        },
        {
            "name": "Tabasco ",
            "hex": "#A02712"
        },
        {
            "name": "Tacao ",
            "hex": "#EDB381"
        },
        {
            "name": "Tahiti Gold ",
            "hex": "#E97C07"
        },
        {
            "name": "Tahuna Sands ",
            "hex": "#EEF0C8"
        },
        {
            "name": "Tan ",
            "hex": "#D1B26F"
        },
        {
            "name": "Tan Brown ",
            "hex": "#ECE5B6"
        },
        {
            "name": "Tan Green ",
            "hex": "#A9BE70"
        },
        {
            "name": "Taupe ",
            "hex": "#483C32"
        },
        {
            "name": "Tawny ",
            "hex": "#CD5700"
        },
        {
            "name": "Tea ",
            "hex": "#65AB7C"
        },
        {
            "name": "Tea Green ",
            "hex": "#D0F0C0"
        },
        {
            "name": "Tea Rose ",
            "hex": "#F4C2C2"
        },
        {
            "name": "Teal ",
            "hex": "#029386"
        },
        {
            "name": "Teal Blue ",
            "hex": "#01889F"
        },
        {
            "name": "Teal Green ",
            "hex": "#006D5B"
        },
        {
            "name": "Tealish Green ",
            "hex": "#0CDC73"
        },
        {
            "name": "Tequila ",
            "hex": "#FFE6C7"
        },
        {
            "name": "Terra Cotta ",
            "hex": "#E2725B"
        },
        {
            "name": "Terracotta ",
            "hex": "#CA6641"
        },
        {
            "name": "Texas Rose ",
            "hex": "#FFB555"
        },
        {
            "name": "Thatch Green ",
            "hex": "#403D19"
        },
        {
            "name": "Thistle ",
            "hex": "#D2B9D3"
        },
        {
            "name": "Thistle Green ",
            "hex": "#CCCAA8"
        },
        {
            "name": "Thulian Pink ",
            "hex": "#DE6FA1"
        },
        {
            "name": "Thunder ",
            "hex": "#33292F"
        },
        {
            "name": "Thunderbird ",
            "hex": "#C02B18"
        },
        {
            "name": "Tiffany Blue ",
            "hex": "#81D8D0"
        },
        {
            "name": "Tiger Eye ",
            "hex": "#E08D3C"
        },
        {
            "name": "Tiger Orange ",
            "hex": "#C88141"
        },
        {
            "name": "Timber Green ",
            "hex": "#16322C"
        },
        {
            "name": "Timberwolf ",
            "hex": "#DBD7D2"
        },
        {
            "name": "Titan White ",
            "hex": "#F0EEFF"
        },
        {
            "name": "Titanium Yellow ",
            "hex": "#EEE600"
        },
        {
            "name": "Tobacco Brown ",
            "hex": "#715D47"
        },
        {
            "name": "Tomato ",
            "hex": "#FF6347"
        },
        {
            "name": "Tomato Red ",
            "hex": "#EC2D01"
        },
        {
            "name": "Toolbox ",
            "hex": "#746CC0"
        },
        {
            "name": "Topaz ",
            "hex": "#13BBAF"
        },
        {
            "name": "Torch Red ",
            "hex": "#FD0E35"
        },
        {
            "name": "Tory Blue ",
            "hex": "#1450AA"
        },
        {
            "name": "Toxic Green ",
            "hex": "#61DE2A"
        },
        {
            "name": "Tree Green ",
            "hex": "#2A7E19"
        },
        {
            "name": "Trendy Green ",
            "hex": "#7C881A"
        },
        {
            "name": "Trendy Pink ",
            "hex": "#8C6495"
        },
        {
            "name": "Tron Blue ",
            "hex": "#7DFDFE"
        },
        {
            "name": "Tropical Blue ",
            "hex": "#C3DDF9"
        },
        {
            "name": "Tropical Rain Forest ",
            "hex": "#00755E"
        },
        {
            "name": "Tufts Blue ",
            "hex": "#417DC1"
        },
        {
            "name": "Tulip Pink ",
            "hex": "#C25A7C"
        },
        {
            "name": "Tulip Tree ",
            "hex": "#EAB33B"
        },
        {
            "name": "Tumbleweed ",
            "hex": "#DEA681"
        },
        {
            "name": "Tuna ",
            "hex": "#353542"
        },
        {
            "name": "Turkish Rose ",
            "hex": "#B57281"
        },
        {
            "name": "Turmeric ",
            "hex": "#CABB48"
        },
        {
            "name": "Turquoise Blue ",
            "hex": "#6CDAE7"
        },
        {
            "name": "Turquoise Green ",
            "hex": "#A0D6B4"
        },
        {
            "name": "Turtle Green ",
            "hex": "#75B84F"
        },
        {
            "name": "Tuscan Red ",
            "hex": "#66424D"
        },
        {
            "name": "Twilight ",
            "hex": "#4E518B"
        },
        {
            "name": "Twilight Blue ",
            "hex": "#EEFDFF"
        },
        {
            "name": "Twilight Lavender ",
            "hex": "#8A496B"
        },
        {
            "name": "Tyrian Purple ",
            "hex": "#C45AEC"
        },
        {
            "name": "Ultra Pink ",
            "hex": "#FF6FFF"
        },
        {
            "name": "Ultramarine Blue ",
            "hex": "#4166F5"
        },
        {
            "name": "Valentine Red ",
            "hex": "#E55451"
        },
        {
            "name": "Vampire Grey ",
            "hex": "#565051"
        },
        {
            "name": "Vanilla ",
            "hex": "#D1BEA8"
        },
        {
            "name": "Vanilla Ice ",
            "hex": "#F3D9DF"
        },
        {
            "name": "Velvet ",
            "hex": "#750851"
        },
        {
            "name": "Velvet Maroon ",
            "hex": "#7E354D"
        },
        {
            "name": "Venetian Red ",
            "hex": "#C80815"
        },
        {
            "name": "Venice Blue ",
            "hex": "#055989"
        },
        {
            "name": "Venom Green ",
            "hex": "#728C00"
        },
        {
            "name": "Venus ",
            "hex": "#928590"
        },
        {
            "name": "Verdigris ",
            "hex": "#43B3AE"
        },
        {
            "name": "Verdun Green ",
            "hex": "#495400"
        },
        {
            "name": "Very Dark Brown ",
            "hex": "#1D0200"
        },
        {
            "name": "Very Dark Green ",
            "hex": "#062E03"
        },
        {
            "name": "Very Dark Purple ",
            "hex": "#2A0134"
        },
        {
            "name": "Very Light Blue ",
            "hex": "#D5FFFF"
        },
        {
            "name": "Very Light Brown ",
            "hex": "#D3B683"
        },
        {
            "name": "Very Light Green ",
            "hex": "#D1FFBD"
        },
        {
            "name": "Very Light Pink ",
            "hex": "#FFF4F2"
        },
        {
            "name": "Very Light Purple ",
            "hex": "#F6CEFC"
        },
        {
            "name": "Very Pale Green ",
            "hex": "#CFFDBC"
        },
        {
            "name": "Vibrant Green ",
            "hex": "#0ADD08"
        },
        {
            "name": "Vibrant Purple ",
            "hex": "#AD03DE"
        },
        {
            "name": "Viola Purple ",
            "hex": "#7E587E"
        },
        {
            "name": "Violent Violet ",
            "hex": "#290C5E"
        },
        {
            "name": "Violet ",
            "hex": "#EE82EE"
        },
        {
            "name": "Violet Blue ",
            "hex": "#510AC9"
        },
        {
            "name": "Violet Eggplant ",
            "hex": "#991199"
        },
        {
            "name": "Violet Pink ",
            "hex": "#FB5FFC"
        },
        {
            "name": "Violet Red ",
            "hex": "#A50055"
        },
        {
            "name": "Viridian ",
            "hex": "#40826D"
        },
        {
            "name": "Viridian Green ",
            "hex": "#678975"
        },
        {
            "name": "Vista Blue ",
            "hex": "#8FD6B4"
        },
        {
            "name": "Vista White ",
            "hex": "#FCF8F7"
        },
        {
            "name": "Vivid Auburn ",
            "hex": "#922724"
        },
        {
            "name": "Vivid Blue ",
            "hex": "#152EFF"
        },
        {
            "name": "Vivid Burgundy ",
            "hex": "#9F1D35"
        },
        {
            "name": "Vivid Cerise ",
            "hex": "#DA1D81"
        },
        {
            "name": "Vivid Green ",
            "hex": "#2FEF10"
        },
        {
            "name": "Vivid Tangerine ",
            "hex": "#FFA089"
        },
        {
            "name": "Vivid Violet ",
            "hex": "#9F00FF"
        },
        {
            "name": "Waikawa Grey ",
            "hex": "#5A6E9C"
        },
        {
            "name": "Walnut ",
            "hex": "#773F1A"
        },
        {
            "name": "Warm Blue ",
            "hex": "#4B57DB"
        },
        {
            "name": "Warm Grey ",
            "hex": "#978A84"
        },
        {
            "name": "Warm Pink ",
            "hex": "#FB5581"
        },
        {
            "name": "Warm Purple ",
            "hex": "#952E8F"
        },
        {
            "name": "Wasabi ",
            "hex": "#788A25"
        },
        {
            "name": "Washed Out Green ",
            "hex": "#BCF5A6"
        },
        {
            "name": "Water ",
            "hex": "#EBF4FA"
        },
        {
            "name": "Water Blue ",
            "hex": "#0E87CC"
        },
        {
            "name": "Water Leaf ",
            "hex": "#A1E9DE"
        },
        {
            "name": "Watermelon ",
            "hex": "#FD4659"
        },
        {
            "name": "Watermelon Pink ",
            "hex": "#FC6C85"
        },
        {
            "name": "Wattle ",
            "hex": "#DCD747"
        },
        {
            "name": "Wax Flower ",
            "hex": "#FFC0A8"
        },
        {
            "name": "Wedgewood ",
            "hex": "#4E7F9E"
        },
        {
            "name": "Wenge ",
            "hex": "#645452"
        },
        {
            "name": "Wheatfield ",
            "hex": "#F3EDCF"
        },
        {
            "name": "Whiskey ",
            "hex": "#D59A6F"
        },
        {
            "name": "White ",
            "hex": "#FFFFFF"
        },
        {
            "name": "White Ice ",
            "hex": "#DDF9F1"
        },
        {
            "name": "White Lilac ",
            "hex": "#F8F7FC"
        },
        {
            "name": "White Rock ",
            "hex": "#EAE8D4"
        },
        {
            "name": "White Smoke ",
            "hex": "#F5F5F5"
        },
        {
            "name": "Wild Blue Yonder ",
            "hex": "#7A89B8"
        },
        {
            "name": "Wild Rice ",
            "hex": "#ECE090"
        },
        {
            "name": "Wild Strawberry ",
            "hex": "#FF43A4"
        },
        {
            "name": "Wild Willow ",
            "hex": "#B9C46A"
        },
        {
            "name": "Windows Blue ",
            "hex": "#357EC7"
        },
        {
            "name": "Wine ",
            "hex": "#722F37"
        },
        {
            "name": "Wine Berry ",
            "hex": "#591D35"
        },
        {
            "name": "Wine Red ",
            "hex": "#7B0323"
        },
        {
            "name": "Winter Hazel ",
            "hex": "#D5D195"
        },
        {
            "name": "Wintergreen ",
            "hex": "#20F986"
        },
        {
            "name": "Wisp Pink ",
            "hex": "#FEF4F8"
        },
        {
            "name": "Wisteria ",
            "hex": "#A87DC2"
        },
        {
            "name": "Wisteria Purple ",
            "hex": "#C6AEC7"
        },
        {
            "name": "Wood ",
            "hex": "#966F33"
        },
        {
            "name": "Wood Bark ",
            "hex": "#261105"
        },
        {
            "name": "Woodland ",
            "hex": "#4D5328"
        },
        {
            "name": "Woodrush ",
            "hex": "#302A0F"
        },
        {
            "name": "Woodsmoke ",
            "hex": "#0C0D0F"
        },
        {
            "name": "Woody Brown ",
            "hex": "#483131"
        },
        {
            "name": "Yale Blue ",
            "hex": "#0F4D92"
        },
        {
            "name": "Yellow Brown ",
            "hex": "#B79400"
        },
        {
            "name": "Yellow Green ",
            "hex": "#52D017"
        },
        {
            "name": "Yellow Metal ",
            "hex": "#716338"
        },
        {
            "name": "Yellow Ochre ",
            "hex": "#CB9D06"
        },
        {
            "name": "Yellow Orange ",
            "hex": "#FCB001"
        },
        {
            "name": "Yellow Tan ",
            "hex": "#FFE36E"
        },
        {
            "name": "Yellowish Brown ",
            "hex": "#9B7A01"
        },
        {
            "name": "Yellowish Green ",
            "hex": "#B0DD16"
        },
        {
            "name": "Yellowish Orange ",
            "hex": "#FFAB0F"
        },
        {
            "name": "Yellowish Tan ",
            "hex": "#FCFC81"
        },
        {
            "name": "Yellowy Brown ",
            "hex": "#AE8B0C"
        },
        {
            "name": "Yellowy Green ",
            "hex": "#BFF128"
        },
        {
            "name": "Zaffre ",
            "hex": "#0014A8"
        },
        {
            "name": "Zeus ",
            "hex": "#292319"
        },
        {
            "name": "Zinnwaldite Brown ",
            "hex": "#2C1608"
        },
        {
            "name": "Zircon ",
            "hex": "#F4F8FF"
        },
        {
            "name": "Zombie Green ",
            "hex": "#54C571"
        },
        {
            "name": "Zucchini ",
            "hex": "#044022"
        }
];

export default colorsList;
