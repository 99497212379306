import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './Custom.scss';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material';
//import 'bootstrap/dist/css/bootstrap.min.css';

const theme = createTheme({
  
  palette: {
    mode: 'light',
    primary: {
      main: '#d74a4a',
    },
    secondary: {
      main: '#183e4b',      
    },
    tertiary: {
      main: '#8BA0A4',
      contrastText: '#000',
    },
    quaternary: {
      main: '#EAEAEA',
    },
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
