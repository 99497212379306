import React, { useState } from "react";
import PropTypes from "prop-types";

import "./MultiDropdown.css";

class MultiDropdown extends React.Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
  };

  static defaultProps = {
    list: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      labelItem: null,
      typeMultiDropdown: null,
    };
  }

  componentWillMount() {
    const { label } = this.props.list[0];
    let firstItem = null;
    if (typeof label != "undefined") {
      this.checkType(false);
      firstItem = label;
    } else {
      this.checkType(true);
      firstItem = this.props.list[0];
    }
    this.setState({
      labelItem: "",
    });
  }

  checkType = (value) => {
    this.setState({
      typeMultiDropdown: value,
    });
  };

  showMultiDropdown = (e) => {
    this.setState({ isOpen: true });
    e.stopPropagation();
    document.addEventListener("click", this.hideMultiDropdown);
  };

  hideMultiDropdown = () => {
    this.setState({ isOpen: false });
    document.removeEventListener("click", this.hideMultiDropdown);
  };

  chooseItem = (value) => {
    if (this.state.labelItem !== value) {
      this.setState({
        labelItem: value,
      });
    }
  };

  renderDataMultiDropdown = (item, index) => {
    const { value, label } = this.state.typeMultiDropdown
      ? { value: index, label: item }
      : item;
    return (
      <li key={index} value={value} onClick={() => this.chooseItem(label)}>
        <a>
          <i className="fa-regular fa-square"></i>
          <i className="fa-solid fa-square"></i>
          &nbsp;{label}
        </a>
      </li>
    );
  };

  render() {
    const { list } = this.props;

    return (
      <div
        className={`${this.props.className} multi-dropdown${
          this.state.isOpen ? " open" : ""
        }`}
      >
        <button
          className="h-100 multi-dropdown-arrow"
          type="button"
          onClick={this.showMultiDropdown}
        >
          {this.props.children}{" "}
          {this.state.labelItem.length > 7
            ? this.state.labelItem.substring(0, 7) + "..."
            : this.state.labelItem.substring(0, 7)}
          &nbsp;
          <i className="fa-solid fa-chevron-down"></i>
        </button>
        <ul className="multi-dropdown-menu">
          <div className="multi-dropdown-content">
            {list.map(this.renderDataMultiDropdown)}
          </div>
        </ul>
      </div>
    );
  }
}

export default MultiDropdown;
