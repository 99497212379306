import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import LogoSmartaiStudio from "../../assets/img/smartai-studio-logo-small.png";
import LogoSmartAIPlatform from "../../assets/img/logo2_tiny.png";
import ListService from "../../services/list.service";

import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Button,
  Input,
  Typography,
} from "@mui/material";
import { EmailOutlined, NewspaperOutlined } from "@mui/icons-material";


function Footer() {
  const [emailError, setEmailError] = useState(false);
  const [alreadySubscribed, setAlreadySubscribed] = useState(false);
  const [newsletterEmail, setNewsletterEmail] = useState("");

  const StockImageLogo = (
    <Link to="/" className="text-decoration-none">
      <img
        src={LogoSmartaiStudio}
        className="img-fluid"
        alt="Stock Images Logo"
      />
    </Link>
  );

  const SmartAIPlatformLogo = (
    <div className="col-4 text-platform">
      <img
        src={LogoSmartAIPlatform}
        className="logo-platform img-fluid"
        alt="SmartAI Platform Logo"
      />
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        className="text-white-thin"
        sx={{
          fontSize: 12,
          fontStyle: "italic",
        }}
      >
        powered by SSGP
      </Typography>
    </div>
  );

  
  const handleEmailValidation = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (emailRegex.test(email)) {
      setEmailError(false);
      setNewsletterEmail(email);
    } else {
      setEmailError(true);
    }
  };

  const handleEmailSubmit = async (email) => {
    if (!emailError) {
      const response = await ListService.SubscribeNewsletter(email, true)
        .then((response) => {
          //console.log(response.data);
          setAlreadySubscribed(true);
          return;
          //alert("Thank you for subscribing to our newsletter!");
        })
        .catch((error) => {
          console.log(error);
          //alert("There was an error subscribing to our newsletter.");
        });
        setAlreadySubscribed(true);
        return;
    } else {
      alert("Please enter a valid email address.");
    }

    setNewsletterEmail("");
    setAlreadySubscribed(false);
  };
  
  return (
    <footer className="pt-4 bg-navbar">
      <div className="container">
        {
          // email capture input field to allow user to sign up for newsletter
          // gradient background color for the email capture input field
          // text field for user to enter email address
          // button to submit email address
        }

        <Card
          className="row"
          style={{
            backgroundColor: "secondary",
            maxWidth: "100%",
            borderRadius: "0",
            border: "none",
            boxShadow: "none",
            borderRadius: "5px",
            padding: "10px",
            marginBottom: "10px",
          }}
        >
          <CardHeader
            sx={{
              color: "white",
              backgroundColor: "#183E4B",
            }}
            avatar={<NewspaperOutlined sx={{ fontSize: 40, color: "white" }} />}
            title={
              <div>
                <Typography variant="h6" component="div">
                  Sign up for our newsletter
                </Typography>
                <Typography variant="body2" component="div">
                  Get the latest updates on new features in smartai.studio
                </Typography>
              </div>
            }
          />
          <CardContent sx={{}}>
          { alreadySubscribed && 
            <div
              className="row"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                textAlign: "center",
                border: "0px solid black",
              }}
            >
              <Typography variant="h6" component="div">
                Thank you for subscribing to our newsletter!
              </Typography>
            </div>
          }
          {!alreadySubscribed && 
            <div
              className="row"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                border: "0px solid black",
              }}            
            >
              <Button
                startIcon={<EmailOutlined />}
                className="col-2"
                variant="contained"
                color="primary"
                width="40%"
                style={{
                   margin: "10px" ,
                   minWidth: "120px",
                  }}
                onClick={() => {
                  handleEmailSubmit(newsletterEmail);
                }}
              >
                Subscribe
              </Button>
              <Input
                id="subscribe-email"
                className="col-2"
                placeholder="Enter your email address"
                type="email"
                error={!emailError} 
                style={{ 
                  borderRadius: "0",
                  border: "none",
                  boxShadow: "none",
                  width: "40%",
                  minWidth: "220px",
                  backgroundColor: "white",
                }}
                onChange={(e) => {
                  handleEmailValidation(e.target.value);
                }}
              />
            </div>
      }   
          </CardContent>
        </Card>

        <div 
          className="row text-center text-white"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            border: "0px solid black",
            padding: "10px",
          }}
        >
          <div className="col-4">{StockImageLogo}</div>

          <div className="col-4">
            <div className="row text-center text-md-start row-cols-1 row-cols-md-2 gy-2">
              {
                // <div className="col"><Link className="footer-link" to="/search?q=random">Explore</Link></div>
              }
              <div className="col">
                <Link className="footer-link" to="/credits">
                  Credits
                </Link>
              </div>
              <div className="col">
                <Link className="footer-link" to="/about">
                  About
                </Link>
              </div>
              <div className="col">
                <Link className="footer-link" to="/profile">
                  Profile
                </Link>
              </div>
              <div className="col">
                <Link className="footer-link" to="/categories">
                  Categories
                </Link>
              </div>
              { /*
              <div className="col">
                <a
                  className="footer-link"
                  href="https://www.bonfire.com/store/smartai-studio/"
                >
                  Merchandise
                </a>
              </div>
              */}
              <div className="col">
                <a
                  className="footer-link"
                  href="https://app.termly.io/document/terms-of-use-for-website/376fff7a-0bd7-4e6b-a466-732926a0cbce"
                >
                  Terms of Use
                </a>
              </div>
              <div className="col">
                <a
                  className="footer-link"
                  href="https://app.termly.io/document/privacy-policy/d7cbc9ff-588c-4a7d-962f-f8c5e4913d5e"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>

          {SmartAIPlatformLogo}
        </div>

        <div className="mt-2 text-white-thin text-center">
          Copyright &copy; SmartAI Technologies 2024
        </div>
      </div>
    </footer>
  );
}

export default Footer;
