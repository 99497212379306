

export default function GetFriendlyUsername(name) {
    //console.log("GetFriendlyUsername: " + name);
    if (name === null) return "";
    if (name.length === 0) return "";
    if (name.length === 1) return name;
    let buffer = name.replaceAll("_", " ");
    let buffer2 = buffer.replaceAll("_", " ");

    // create array of tokens from buffer
    let tokens = buffer2.split(" ");

    // remove any empty tokens
    
    tokens = tokens.filter((token) => {
      return token.length > 0;
    });

    // capitalize first letter of each token
    /*
    tokens = tokens.map((token) => {
      return token.charAt(0).toUpperCase() + token.slice(1);
    });
    */
    // return joined tokens
    let result = tokens.join(" ");
    //console.log("GetFriendlyUsername: " + result);
    return result;
  }