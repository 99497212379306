import React, { useState, useRef, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import ImageService from "../../services/image.service";
import ProductService from "../../services/product.service";
import TextGenerationService from "../../services/textgeneration.service";
import { useAuth } from "../../services/auth/use-auth";
import CropComponent from "../../components/StudioComponent/crop/CropComponent";
import TimeAgo from "react-timeago";
import Typography from "@mui/material/Typography";
// import "./ImageDetailBeta.css";
import "./ImageDetail.css";
import externalLinkIconBlack from "../../assets/img/externalLinkIconBlack.svg";
import { useTheme } from "@mui/material/styles";
import TimerComponent from "../../components/timer/Timer";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Paper,
  Grid,
  Divider,
  Avatar,
  IconButton,
  Box,
  CircularProgress,
  LinearProgress,
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  ToggleButtonGroup,
  ToggleButton,
  Checkbox,
  Link,
  Chip,
  Slider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  Stack,
  InputBase,
  useMediaQuery,
  createTheme,
  ThemeProvider,
  Backdrop,
} from "@mui/material";
import {
  Favorite,
  FavoriteBorder,
  PhotoCamera,
  PhotoOutlined,
  PhotoRounded,
  PhotoTwoTone,
  PhotoCameraBackOutlined,
  DrawOutlined,
  DrawFilled,
} from "@mui/icons-material";

import UserService from "../../services/user.service";
import { LoadingButton } from "@mui/lab";
import StoryIcon2 from "../../icons/story";
import SocialShare from "../../components/social-share/SocialShare";
import ImageFilter from "react-image-filter";
import { SingleColorPicker } from "../../utils/utilsColorPickers";
import { EffectPercentPicker } from "../../utils/utilsColorPickers";
//import downloadSvgImage from "../../utils/utilsImageFuncs";
import CanvasFilters from "../../components/Graphics/CanvasFilters";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { getMoods } from "../../utils/utilsTextFuncs";
import { getGenres } from "../../utils/utilsTextFuncs";
import { getAtmospheric } from "../../utils/utilsTextFuncs";
import { getPostTypes } from "../../utils/utilsTextFuncs";
import { getThemes } from "../../utils/utilsTextFuncs";

import "react-image-crop/dist/ReactCrop.css";
import varyIcon from "../../assets/img/discover.png";
import filtersIcon from "../../assets/img/smartai-studio-filters-logo.png";
import cropsIcon from "../../assets/img/smartai-studio-crops-logo.png";
import storyIcon from "../../assets/img/smartai-studio-story-logo.png";
import framesIcon from "../../assets/img/smartai-studio-frames-logo.png";
import textIcon from "../../assets/img/smartai-studio-text-logo.png";
import duotonesIcon from "../../assets/img/smartai-studio-duotones-logo-burgundy-cyan.png";
import StackedEffectCanvas from "./StackedEffects";
import html2canvas from "html2canvas";
import GetFriendlyUsername from "../../utils/utilsStringFuncs";
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
  deviceType,
  mobileVendor,
  mobileModel,
  getUA,
  isDesktop,
  isMobile,
  isTablet,
} from "react-device-detect";

async function urlToBlob(url, setUrl) {
  try {
    // Fetch the data from the URL
    const blober = await fetch(url, { mode: "cors" }).then((r) => r.blob());

    // fetch url in a CORS compliant way and convert to blob
    //const response = await fetch(url, { mode: "cors" });

    const urlBlob = URL.createObjectURL(blober);
    // console.log(urlBlob);
    return setUrl(urlBlob);
  } catch (error) {
    console.error(error);
    // Handle the error appropriately
    return null;
  }
}

export default function ImageStudio() {
  const auth = useAuth();
  const [data, setData] = useState(null);
  const [localImageUrl, setLocalImageUrl] = useState(null);
  const [currentStage, setCurrentStage] = useState(null);
  const [story, setStory] = useState("");
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(null);
  const [credits, setCredits] = useState(null);
  const [hashtags, setHashtags] = useState([]); // ["#smartai", "#stockimages"
  const [shareUrl, setShareUrl] = useState("");
  const [resolutionWidth, setResolutionWidth] = useState(0);
  const [resolutionHeight, setResolutionHeight] = useState(0);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [includeTagsGen, setIncludeTagsGen] = useState(false);
  const [productsTextGen, setProductsTextGen] = useState([]);
  const [productsImageGen, setProductsImageGen] = useState([]);
  const [productsImageGenPrice, setProductsImageGenPrice] = useState(0);
  const [productIdImageGen, setProductIdImageGen] = useState(0);
  const [productIdTextGen, setProductIdTextGen] = useState(0);

  const [imageGenProduct, setImageGenProduct] = useState([]);
  const [seed, setSeed] = useState(0);

  //const [product, setSelectedProduct] = useState(null);
  const [capabilities, setCapabilities] = useState(null);
  const [genImages, setgenImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [storyLoading, setStoryLoading] = useState(false);
  const [imageGenLoading, setImageGenLoading] = useState(false);
  const [generatedType, setGeneratedType] = useState("");
  const [imageGenStyle, setImageGenStyle] = useState("Realistic");
  const [imageGenStyle2, setImageGenStyle2] = useState("");
  const [seedOption, setSeedOption] = useState("Original");
  const [storyExpanded, setStoryExpanded] = useState(true);
  const [customExpanded, setCustomExpanded] = useState(false);
  const [poemExpanded, setPoemExpanded] = useState(false);

  const [selectedTheme1, setSelectedTheme1] = useState("");
  const [selectedTheme2, setSelectedTheme2] = useState("");
  const [selectedTheme3, setSelectedTheme3] = useState("");
  const [textGens, setTextGens] = useState([]);

  const [currentTab, setCurrentTab] = useState(0);
  //const [selectedColors, setSelectedColors] = useState([]);
  //const [selectedFilterColorsDuoTone, setFilterColorsDuoTone] = useState([[0, 0, 0],[0, 0, 0]]);
  const [color1R, setColor1R] = React.useState(255);
  const [color1G, setColor1G] = React.useState(191);
  const [color1B, setColor1B] = React.useState(0);
  const [color2R, setColor2R] = React.useState(75);
  const [color2G, setColor2G] = React.useState(0);
  const [color2B, setColor2B] = React.useState(130);
  //  const [color1, setColor1] = React.useState([color1R, color1G, color1B]);
  //  const [color2, setColor2] = React.useState([color2R, color2G, color2B]);

  const [genEffect, setGenEffect] = React.useState(0);
  const [inFocus, setInfocus] = React.useState(null);
  const [effectBlur, setEffectBlur] = React.useState({
    bool: false,
    value: 0,
    inFocus: "blur",
  });
  const [effectSepia, setEffectSepia] = React.useState({
    bool: false,
    value: 0,
    inFocus: "sepia",
  });
  const [effectGrayscale, setEffectGrayscale] = React.useState({
    bool: false,
    value: 0,
    inFocus: "grayscale",
  });
  /*
  const [effectEdgeDetect, setEffectEdgeDetect] = React.useState({
    bool: false,
    value: 0,
    inFocus: "edge-detect",
  });
  */
  const [effectSharpen, setEffectSharpen] = React.useState({
    bool: false,
    value: 0,
    inFocus: "sharpen",
  });
  const [effectOpacity, setEffectOpacity] = React.useState({
    bool: false,
    value: 100,
    inFocus: "opacity",
  });
  const [effectHueRotate, setEffectHueRotate] = React.useState({
    bool: false,
    value: 0,
    inFocus: "hue-rotate",
  });
  const [effectInvert, setEffectInvert] = React.useState({
    bool: false,
    value: 0,
    inFocus: "invert",
  });
  const [effectSaturate, setEffectSaturate] = React.useState({
    bool: true,
    value: 55,
    inFocus: "saturate",
  });

  const [effectContrast, setEffectContrast] = React.useState({
    bool: true,
    value: 55,
    inFocus: "contrast",
  });

  const [effectBrightness, setEffectBrightness] = React.useState({
    bool: true,
    value: 45,
    inFocus: "brightness",
  });
  const [effectDropShadow, setEffectDropShadow] = React.useState({
    bool: false,
    value: 0,
    inFocus: "drop-shadow",
  });

  //const [selectedTags, setSelectedTags] = useState([]);
  const [selectedFrame, setSelectedFrame] = useState(0);
  //const [quad, setQuad] = useState([]);
  const [userTags, setUserTags] = useState([]);

  const [searchParam] = useSearchParams();
  const identifier = searchParam.get("id");
  const SMARTAI_CATEGORY_TEXTGEN_ID = 3;
  const SMARTAI_CATEGORY_GENIMAGE_ID = 4;
  const SMARTAI_DEFAULT_PRODUCT_ID_IMAGEGEN = 37;
  const SMARTAI_PRODUCT_CAPABILITY_TEXTGEN_ID = 15;

  useEffect(() => {
    // console.log("ImageStudio ID: ", JSON.stringify(identifier));
    // console.log("ImageStudio user: ", JSON.stringify(auth.user));
    getSubscription();
    fetchData();
    getTextGenerations(identifier);
    getProducts();
    getImageGenPrice();
    getTextGenPrice();
    resetFilters();
  }, [identifier]);

  const studioTheme = createTheme({
    components: {
      // set the default color for all MuiToggleButtons
      MuiToggleButton: {
        styleOverrides: {
          root: {
            color: "black",
            "&:hover": {
              backgroundColor: "#d74a4a",
            },
            "&.Mui-selected": {
              color: "primary",
              backgroundColor: "#8BA0A4",
            },
          },
        },
      },
    },
  });

  // make a general url to render images everywhere
  useEffect(() => {
    if (data !== null) {
      urlToBlob(data.public_url, setLocalImageUrl);
    }
  }, [data]);

  useEffect(() => {
    // console.log(localImageUrl);
  }, [localImageUrl]);

  const handleGeneralSliderChange = (event, newValue) => {
    setGenEffect(newValue);
  };

  useEffect(() => {
    if (inFocus === "blur") {
      setEffectBlur({
        bool: effectBlur.bool,
        value: genEffect,
        inFocus: effectBlur.inFocus,
      });
    } else if (inFocus === "sepia") {
      setEffectSepia({
        bool: effectSepia.bool,
        value: genEffect,
        inFocus: effectSepia.inFocus,
      });
    } else if (inFocus === "grayscale") {
      setEffectGrayscale({
        bool: effectGrayscale.bool,
        value: genEffect,
        inFocus: effectGrayscale.inFocus,
      });
    } else if (inFocus === "brightness") {
      setEffectBrightness({
        bool: effectBrightness.bool,
        value: genEffect,
        inFocus: effectBrightness.inFocus,
      });
    } else if (inFocus === "sharpen") {
      setEffectSharpen({
        bool: effectSharpen.bool,
        value: genEffect,
        inFocus: effectSharpen.inFocus,
      });
    } else if (inFocus === "opacity") {
      setEffectOpacity({
        bool: effectOpacity.bool,
        value: genEffect,
        inFocus: effectOpacity.inFocus,
      });
    } else if (inFocus === "hue-rotate") {
      setEffectHueRotate({
        bool: effectHueRotate.bool,
        value: genEffect,
        inFocus: effectHueRotate.inFocus,
      });
    } else if (inFocus === "invert") {
      setEffectInvert({
        bool: effectInvert.bool,
        value: genEffect,
        inFocus: effectInvert.inFocus,
      });
    } else if (inFocus === "saturate") {
      setEffectSaturate({
        bool: effectSaturate.bool,
        value: genEffect,
        inFocus: effectSaturate.inFocus,
      });
    } else if (inFocus === "contrast") {
      setEffectContrast({
        bool: effectContrast.bool,
        value: genEffect,
        inFocus: effectContrast.inFocus,
      });
    }
  }, [genEffect]);

  function randomizeSeed() {
    var _seed = Math.floor(Math.random() * 100000);
    //console.log("randomizeSeed: " + _seed);
    setSeed(_seed);
    return _seed;
  }

  const getTextGenerations = async (id) => {
    //console.log('getTextGenerations: ' + item);
    if (auth.user === null) {
      return;
    }

    var textGens = await TextGenerationService.GetUserTextGenerationsByImageId(
      auth.user.uid,
      id,
      0,
      48,
      auth.user.auth.currentUser.accessToken
    );

    //console.log("getTextGenerations: " + JSON.stringify(textGens));
    setTextGens(textGens);
  };

  const getSubscription = async () => {
    if (auth.user) {
      const response = await UserService.GetUserCredits(
        auth.user.uid,
        auth.user.auth.currentUser.accessToken
      );

      /*
      console.log(
        "Logged in, subscription info for auth.user.id:",
        auth.user.uid,
        "\n",
        JSON.stringify(response)
      );
    */

      setCredits(response.creditProfile);
      //// console.log("CreditProfile: ", JSON.stringify(response.creditProfile));
    } else {
      // console.log("CreditProfile: No user logged in");
      /*
      var credit = {
        creditProfile: {
          numSubscriptionCreditsRemaining: -1,
          numWalletCreditsRemaining: -1,
          numPendingCredits: -1,
        },
      };

      setSubscription(credit);
      */
    }
  };

  const fetchData = async () => {
    var imageData = await getImageDetails();

    // set seed
    //seed = imageData.seed;
    setSeed(imageData.seed);

    // set hashtags
    var hashtags = imageData.tags
      .filter((tag) => {
        return (
          tag.split(":")[0] === "topic" || tag.split(":")[0] === "category"
        );
      })
      .map((tag) => {
        return tag.split(":")[1];
      });

    setHashtags(hashtags);

    // set share url
    var shareUrl =
      "https://" + window.location.host + "/image?id=" + identifier;
    setShareUrl(shareUrl);

    return imageData;
    //resetFilters();
  };

  const getTextGenPrice = async () => {
    return 5;
    let products = productsTextGen;
    if (products === null) {
      products = await ProductService.GetProductListByCategoryId(
        SMARTAI_CATEGORY_TEXTGEN_ID
      );
      setProductsTextGen(products);
    }

    if (products) {
      let _textGenProduct = products.find(
        (p) => p.name.includes("-textgen-") && p.name.includes("500")
      );

      if (_textGenProduct === null || _textGenProduct === undefined) {
        return 0;
      } else {
        setProductIdTextGen(_textGenProduct.productId);
        return _textGenProduct.creditsPrice;
      }
    }

    return 0;
  };

  const getImageGenPrice = async () => {
    var height = resolutionHeight;
    var width = resolutionWidth;
    if (data === null) {
      var imageData = await getImageDetails();
      height = imageData.height;
      width = imageData.width;
    }

    //console.log("getImageGenPrice(height): " + height);
    //console.log("getImageGenPrice(width): " + width);

    if (height === 0 || width === 0) {
      return 0;
    }

    let products = productsImageGen;
    if (products === null) {
      products = await ProductService.GetProductListByCategoryId(
        SMARTAI_CATEGORY_GENIMAGE_ID
      );
      setProductsImageGen(products);
    }

    //console.log("getImageGenPrice(productsImageGen): " + JSON.stringify(products));

    if (products) {
      let res1 = `${height}x${width}`;
      let res2 = `${width}x${height}`;

      //console.log("getImageGenPrice(res1): " + res1);
      //console.log("getImageGenPrice(res2): " + res2);
      let _imageGenProduct = products.find(
        (p) =>
          p.name.includes("-imagegen-") &&
          (p.name.includes(res1) || p.name.includes(res2))
      );

      if (_imageGenProduct === null || _imageGenProduct === undefined) {
        // console.log("getImageGenPrice(products): product not found");
        return 0;
      } else {
        //console.log("getImageGenPrice(_imageGenProduct): product found: " + JSON.stringify(_imageGenProduct));

        if (
          _imageGenProduct.productId !== null ||
          _imageGenProduct.productId !== undefined
        ) {
          setProductIdImageGen(_imageGenProduct.productId);
          setImageGenProduct(_imageGenProduct);

          if (_imageGenProduct.creditsPrice > 0) {
            setProductsImageGenPrice(_imageGenProduct.creditsPrice);
            /*
            console.log(
              "getImageGenPrice(_imageGenProduct): " +
                JSON.stringify(_imageGenProduct)
            );
            */
          }
        } else {
          // console.log("getImageGenPrice(products): product not found");
          return 0;
        }

        return _imageGenProduct.creditsPrice;
      }
    } else {
      // console.log("getImageGenPrice(products): product not found");
    }

    return 0;
  };

  const genImage = async (data, tags, seedNew, includeTags, style, style2) => {
    // console.log("genImage: ", data, " tags: ", tags, " seedNew: ", seedNew, " includeTags: ", includeTags, " style: ", style);

    if (data === null) {
      return;
    }

    if (auth.user === null) {
      return;
    }

    if (imageGenLoading) {
      return;
    }

    setImageGenLoading(true);

    // add style to tags if already exists, replace
    if (tags.includes("style:")) {
      tags = tags.filter((tag) => !tag.startsWith("style:"));
    }
    tags.push("style:" + style);

    if (tags.includes("style2:")) {
      tags = tags.filter((tag) => !tag.startsWith("style2:"));
    }
    tags.push("style2:" + style2);

    let productId = productIdImageGen;

    if (productId === 0) {
      productId = SMARTAI_DEFAULT_PRODUCT_ID_IMAGEGEN;
    }

    if (auth.user && acceptTerms && data.image_id !== "" && productId > 0) {
      var userId = auth.user.uid;
      var token = auth.user.auth.currentUser.accessToken;

      try {
        const response = await ProductService.VaryImageUsingCredits(
          userId,
          token,
          productId,
          data.image_id,
          seedNew,
          data.public_url,
          tags,
          includeTags
        );

        if (response.success === false) {
          alert("Vary failed: " + response.message);
          // console.log("genImage: " + response);
        } else {
          //console.log("Vary Response: " + response);
          //console.log("Vary Response: " + JSON.stringify(response));

          setgenImages(response.imageUrls);
          //console.log("Vary Response: " + response.imageUrls);
          // console.log("Vary Response: " + JSON.stringify(response.imageUrls));
        }
      } catch (error) {
        alert("Failed to generate image: ", error);
      }
    }
    setImageGenLoading(false);
    // update credits remaining
    getSubscription();
  };

  /*
    const formulatePromptFromImage = (imageData, postType, themes) => {
        console.log("formulatePromptFromImage: ", imageData, " themes: ", themes);
        var cat = themes[0].toLowerCase();
        var mood = themes[1];
        var atmosphere = themes[2];

        if (imageData) {
            if (cat === undefined || cat === null || cat === "") {
                cat = imageData.category;
            }

            if (mood === undefined || mood === null || mood === "") {
                // get random topic if undefined
                var topics = imageData.tags.filter((tag) => tag.startsWith("tag:"));
                if (topics.length > 0) {
                    mood = topics[Math.floor(Math.random() * topics.length)];
                } else {
                    mood = "happy";
                }
            }

            if (
                atmosphere === undefined ||
                atmosphere === null ||
                atmosphere === ""
            ) {
                // get random topic if undefined
                var topics = imageData.tags.filter((tag) => tag.startsWith("topic:"));
                if (topics.length > 0) {
                    atmosphere = topics[Math.floor(Math.random() * topics.length)];
                } else {
                    atmosphere = "happy";
                }
            }

            var colorTags = imageData.tags.filter((tag) => tag.startsWith("color:"));
            var materialsTags = imageData.tags.filter((tag) =>
                tag.startsWith("materials:")
            );
            var topicTags = imageData.tags.filter((tag) => tag.startsWith("topic:"));
            var tags = imageData.tags.filter((tag) => tag.startsWith("tag:"));
            var locations = imageData.tags.filter((tag) =>
                tag.startsWith("location:")
            );

            // TODO - Add tagsExtra to the prompt
            var promptPrefix =
                "Generate a " +
                cat +
                " styled " + postType + " with an " +
                atmosphere +
                " based atmosphere and an overaching mood of " +
                mood +
                " incorporate the following additional context: ";

            var tagsBuffer =
                colorTags.length > 0
                    ? " colors:" + colorTags.join(", ").replaceAll("color:", "")
                    : "" + (materialsTags.length > 0)
                        ? " and materials: " +
                        materialsTags.join(", ").replaceAll("materials:", "")
                        : "" + (topicTags.length > 0)
                            ? " and topics: " + topicTags.join(", ").replaceAll("topic:", "")
                            : "" + (tags.length > 0)
                                ? " and tags: " + tags.join(", ").replaceAll("tag:", "")
                                : "";

            //var suffix = (themes.length > 0) ? " and incorporate the following thematic elements: " + themes.join(", ") : "";

            var promptComplete =
                promptPrefix + imageData.description + tagsBuffer + " [/INST]";
            console.log("formulatePromptFromImage: prompt - ", promptComplete);
        }

        return promptComplete;
    };
*/
  const GenerateTextFromImage = async (
    productId,
    data,
    postType,
    numTokens,
    includeTags
  ) => {
    /*
    console.log(
      "GenerateStoryFromImage: productId(",
      productId + ") data:" + data,
      " postType: " + postType + " numTokens: ",
      numTokens
    );
  */
    setGeneratedType(postType);

    var userId = auth.user.uid;
    var imageId = data.image_id;
    //var productId = 0; // product.productId;

    /*
    console.log(
      "GenerateStoryFromImage: ",
      data,
      " userId: " + userId + " imageId: ",
      imageId
    );
    */

    try {
      setStoryLoading(true);

      var themes = [];
      themes.push(selectedTheme1);
      themes.push(selectedTheme2);
      themes.push(selectedTheme3);

      var response = await ProductService.GenerateTextForCredits(
        auth.user.uid,
        auth.user.auth.currentUser.accessToken,
        imageId,
        productId,
        numTokens,
        postType,
        themes,
        includeTags
      );
      //console.log("GenerateStoryFromImage: ", response);

      if (prompt === undefined || prompt === null || prompt === "") {
        setStoryLoading(false);
        return;
      }

      // set story vars
      setStory(response);
      setStoryLoading(false);
    } catch (error) {
      setStoryLoading(false);
      console.log("Error generating story: ", error);
      alert("Error generating story: ", error);
    }
    // scroll to story
    var element = document.getElementById("storyGen");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const handleSelectedFrame = (id) => {
    setSelectedFrame(id);
  };

  // download image from bucket
  const download = async () => {
    // console.log("downloadImage: " + JSON.stringify(data));
    // const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
    currentStage.toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      var urlDownload = url ? url : data.public_url_reduced;
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.download =
        "SmartAI-StockImages_" +
        urlDownload.substr(urlDownload.lastIndexOf("/") + 1);
      link.click();
    });
    // const blob = await fetch(urlDownload).then((r) => r.blob());
    // const urlBlob = URL.createObjectURL(blob);
  };

  const resetFilters = () => {
    if (!genEffect) {
      return;
    }
    setEffectBlur({ bool: true, value: 0, inFocus: "blur" });
    handleFilterRender("blur", setEffectBlur);
    setEffectSepia({ bool: true, value: 0, inFocus: "sepia" });
    handleFilterRender("sepia", setEffectSepia);
    setEffectGrayscale({ bool: true, value: 0, inFocus: "grayscale" });
    handleFilterRender("grayscale", setEffectGrayscale);
    setEffectSharpen({ bool: true, value: 0, inFocus: "sharpen" });
    handleFilterRender("sharpen", setEffectSharpen);
    setEffectOpacity({ bool: true, value: 100, inFocus: "opacity" });
    handleFilterRender("opacity", setEffectOpacity);
    setEffectHueRotate({ bool: true, value: 0, inFocus: "hue-rotate" });
    handleFilterRender("hue-rotate", setEffectHueRotate);
    setEffectInvert({ bool: true, value: 0, inFocus: "invert" });
    handleFilterRender("invert", setEffectInvert);
    setEffectSaturate({ bool: true, value: 55, inFocus: "saturate" });
    handleFilterRender("saturate", setEffectSaturate);
    setEffectContrast({ bool: true, value: 55, inFocus: "contrast" });
    handleFilterRender("contrast", setEffectContrast);
    setEffectBrightness({ bool: true, value: 45, inFocus: "brightness" });
    handleFilterRender("brightness", setEffectBrightness);
    setEffectDropShadow({ bool: true, value: 0, inFocus: "drop-shadow" });
    handleFilterRender("drop-shadow", setEffectDropShadow);
    //setGenEffect(0);
  };

  const downloadImageFromUrl = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const urlBlob = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = urlBlob;
    link.target = "_blank";
    link.download = "SmartAI-StockImages_vary_" + identifier + ".png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadDuotoneImage = async (id) => {
    const imageElement = document.getElementById(id);
    imageElement.style.width = resolutionWidth > 0 ? resolutionWidth : "1024px";
    imageElement.style.height =
      resolutionHeight > 0 ? resolutionHeight : "1024px";

    const svgImageElement = imageElement.querySelector("svg image");
    const urlDownload = svgImageElement.getAttribute("xlink:href").toString();
    const downloadName = `SmartAI-StockImages_${urlDownload.substring(
      urlDownload.lastIndexOf("/") + 1
    )}_duotone`;

    const response = await fetch(svgImageElement.getAttribute("xlink:href"));
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      svgImageElement.setAttribute("xlink:href", base64data);
      html2canvas(imageElement).then((canvas) => {
        const a = document.createElement("a");
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob);
          a.href = url;
          a.download = `${downloadName}.png`;
          a.click();
        });
      });
    };
    
  };

  const downloadSvgFilteredImage = async (id, width, height) => {
    // Get the filtered image element
    const imageElement = document.getElementById(id);   
    imageElement.style.width =  width > 0 ? width : "1024px";
    imageElement.style.height = height > 0 ? height : "1024px";
    //console.log(imageElement);

    // Get the SVG image element
    const svgImageElement = imageElement.querySelector("svg image");
    //svgImageElement.style.width = "100%" //(width); // + (.05 * width);
    //svgImageElement.style.height = "100%" //(height); //  + (.05 * height);
    //svgImageElement.style.width = resolutionWidth > 0 ? resolutionWidth : "1024px";
    //svgImageElement.style.height = resolutionHeight > 0 ? resolutionHeight : "1024px";
    const urlDownload = svgImageElement.getAttribute("xlink:href").toString();
    //console.log(urlDownload.substring(urlDownload.lastIndexOf("/") + 1));
    const downloadName = `SmartAI-StockImages_${urlDownload.substring(
      urlDownload.lastIndexOf("/") + 1
    )}_duotone`;

    // Convert blob URL to data URL
    const response = await fetch(svgImageElement.getAttribute("xlink:href"));
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;

      // Replace the blob URL with the data URL
      svgImageElement.setAttribute("xlink:href", base64data);

      // const cloneImageElement = imageElement.cloneNode(true);
      // cloneImageElement.style.width = '1024px';
      // cloneImageElement.style.height = '1024px';
      // console.log(cloneImageElement);

      // Convert the filtered image to a canvas
      html2canvas(imageElement).then((canvas) => {
        const a = document.createElement("a");

        // Convert the canvas to a Blob object
        canvas.toBlob((blob) => {
          // Create a URL for the Blob object
          const url = URL.createObjectURL(blob);

          // Set the href of the anchor to the Blob URL
          a.href = url;

          // Set the download attribute of the anchor to the desired file name
          a.download = `${downloadName}.png`;

          // Trigger a click event on the anchor
          a.click();
        });
      });
    };
  };

  /*
    const purchaseTextGeneration = async (id) => {
        console.log('purchaseTextGeneration(): ' + identifier);
        console.log('purchaseTextGeneration(): auth.user ' + auth.user);
        console.log('purchaseTextGeneration(): auth.user.id ' + auth.user.uid);
        console.log('purchaseTextGeneration(): acceptTerms ' + acceptTerms);
        console.log('purchaseTextGeneration(): product ' + product);
        console.log('purchaseTextGeneration(): productId ' + product.productId);
        console.log('purchaseTextGeneration(): # credits ' + product.creditsPrice);
    
        setLoading(true);

        if (
          auth.user &&
          acceptTerms &&
          identifier !== '' &&
          product &&
          product.productId > 0
        ) {
         
          var userId = auth.user.uid;
    
          const response = await ProductService.PurchaseTextGenerationUsingCredits(
            userId,
            product.productId,
            identifier,
          );
    
          if (response.success === false) {
            alert('Purchase failed: ' + response.message);
            // console.log("purchaseImage: " + response);
          } else {
            // console.log("downloadImage: " + response.product.downloadUrl);
            // console.log("downloadImage: " + response.product.assetUrl);
            //downloadImage(response.product.downloadUrl);
            download(response.product.downloadUrl);
            navigate('/studio?id=' + identifier);
          }
        }
        setLoading(false);
      };
*/

  const handleThemeChange1 = (event) => {
    setSelectedTheme1(event.target.value);
  };
  const handleThemeChange2 = (event) => {
    setSelectedTheme2(event.target.value);
  };
  const handleThemeChange3 = (event) => {
    setSelectedTheme3(event.target.value);
  };

  const getSubscriptionCreditsRemaining = () => {
    if (credits) {
      return credits.numSubscriptionCreditsRemaining;
    }
    return 0;
  };

  const getWalletCreditsRemaining = () => {
    if (credits) {
      return credits.numWalletCreditsRemaining;
    }
    return 0;
  };

  const getProducts = async () => {
    const productResponseText = await ProductService.GetProductListByCategoryId(
      SMARTAI_CATEGORY_TEXTGEN_ID
    );
    // console.log("getProducts(Text): ", JSON.stringify(productResponseText));
    setProductsTextGen(productResponseText);

    const productResponseImage =
      await ProductService.GetProductListByCategoryId(
        SMARTAI_CATEGORY_GENIMAGE_ID
      );
    // console.log("getProducts(Image): ", JSON.stringify(productResponseImage));
    setProductsImageGen(productResponseImage);
  };

  const getImageDetails = async () => {
    if (!identifier) {
      return;
    }

    // console.log("getImageDetails - auth.user: ", auth.user);

    // get image from public
    const response = await ImageService.GetImageByIdentifier(identifier);
    setResolutionHeight(response.height);
    setResolutionWidth(response.width);
    var url = response.public_url_protected;
    // console.log("Normal URL: ", response.public_url_protected);

    // check if user has image
    if (auth.user) {
      // console.log("getImageDetails - auth.user: ", auth.user);
      const userImageResponse = await ImageService.GetUserImage(
        auth.user.uid,
        identifier,
        auth.user.auth.currentUser.accessToken
      );
      if (userImageResponse) {
        // console.log("getImageDetails - userImageResponse: ", userImageResponse);
        url = userImageResponse.public_url;
        // console.log("getImageDetails - USER URL: ", userImageResponse.public_url);
      }
    }

    response.public_url = url;
    // console.log("getImageDetails: ", response);

    setData(response);
    setUserTags(
      response.tags.filter((tag) => {
        return tag.split(":")[0] === "color" && tag.includes(":");
      })
    );

    return response;
  };

  const renderFilteredImageDuoTone = (imageUrl, filterName, color1, color2, width, height) => {
    // 'duotone'
    // console.log(`renderImage(${filterName}): [` + color1 + `] [` + color2 + `]`);
    var id = filterName + "_" + color1 + "_" + color2;
    // console.log("renderImage(" + id + ")");

    return (
      <>
        <ImageFilter
          id={id}
          image={imageUrl}
          filter={filterName}
          colorOne={color1}
          colorTwo={color2}
          width={width}
          height={height}
        />
        
        {/* this allows for resizing the duotone image for download without messing up the preview on the UI */}
        { 
        <Box sx={{ position: "fixed", zIndex: "-1", top: "-10000px" }}>
          <ImageFilter
            id={"download_duotone_image"}
            image={imageUrl}
            filter={filterName}
            colorOne={color1}
            colorTwo={color2}
            width={width}
            height={height}
          />
        </Box>
     }
        <Stack direction="row" py="20px" gap="10px" justifyContent="center">
          <Button
            className="rounded-pill"
            variant="contained"
            sx={{
              textTransform: "none",
              fontSize: "1rem",
            }}
            disabled={loading}
            onClick={() => downloadSvgFilteredImage("download_duotone_image", width, height)}
            //onClick={() => downloadDuotoneImage("download_duotone_image")}
          >
            Download Duotone Image
          </Button>
        </Stack>
        <Stack direction="row" py="20px" gap="10px" justifyContent="center">
          <Typography
            sx={{
              fontSize: "1rem",
              fontFamily: "Raleway",
              fontWeight: "bold",
              color: "black",
            }}
          >
            Featured Duotones
          </Typography>
          {renderFeaturedDuotones()}
        </Stack>
      </>
    );
  };

  /*
          const generateFilteredImages = async (identifier) => {
              console.log("generateFilteredImages(): " + identifier);
              console.log("generateFilteredImages(): auth.user " + auth.user);

              var filtered = utilsImageFuncs.applyFilter(data.public_url, "grayscale");
              setFilteredImages(filtered);
              console.log("generateFilteredImages(): " + filtered);
          }
      */
  const inputIncludeTags = acceptTerms && (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        padding: "10px",
      }}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeTagsGen}
              onChange={(e, c) => setIncludeTagsGen(c)}
            />
          }
          label={
            <Typography sx={{ fontSize: "13px" }}>include tags</Typography>
          }
        />
      </FormGroup>
    </Box>
  );
  const inputTermsPrivacy = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        padding: "20px",
      }}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptTerms}
              onChange={(e, c) => setAcceptTerms(c)}
            />
          }
          label={
            <Typography sx={{ fontSize: "13px" }}>
              I agree to the{" "}
              <Link
                target="_blank"
                href="https://app.termly.io/document/terms-of-use-for-website/376fff7a-0bd7-4e6b-a466-732926a0cbce"
              >
                terms &amp; conditions
              </Link>
            </Typography>
          }
        />
      </FormGroup>
    </Box>
  );
  const copyImageToClipboard = () => {
    return (
      <IconButton
        className="fas fa-image"
        title="copy image as png"
        aria-label="copy image as png"
        onClick={async () => {
          const img = document.querySelector("#studioImage");
          const canvas = document.createElement("canvas");
          canvas.width = img.naturalWidth; // or 'width' if you want a special size
          canvas.height = img.naturalHeight; // or 'height' if you want a special size
          canvas.getContext("2d").drawImage(img, 0, 0);
          const blob = await new Promise((resolve) =>
            canvas.toBlob(resolve, "image/png")
          );
          const clipboardItem = new ClipboardItem({ "image/png": blob });
          navigator.clipboard.write([clipboardItem]);
        }}
      />
    );
  };

  const RenderTopics = (data) => {    
    return (
      <Box>
        <div className="fw-bold mb-2">Topics:</div>
        {data.tags
          .filter((tag) => {
            return tag.split(":")[0] === "topic";
          })
          .map((tag, index) => {
            return (
              <Chip
                key={`tags__${index}`}
                color="tertiary"
                sx={{ margin: 0.2 }}
                label={
                  <Typography
                    sx={{
                      margin: 1,
                      fontFamily: "Raleway",
                      fontSize: 12,
                    }}
                  >
                    {tag.split(":")[1]}
                  </Typography>
                }
              />
            );
          })}
      </Box>
    );
  };

  const RenderTags = (data) => {
    return (
      <Box>
        <div className="mt-2 fw-bold mb-2">Tags:</div>
        <div className="d-flex flex-row flex-wrap">
          {data.tags
            .filter((tag) => {
              return (
                tag.split(":")[0] !== "topic" &&
                tag.split(":")[0] !== "category" &&
                tag.includes(":")
              );
            })
            .map((tag, index) => {
              return (
                <Chip
                  key={`selected__key__${index}`}
                  sx={{ margin: 0.2 }}
                  label={
                    <Typography
                      sx={{
                        margin: 1,
                        fontFamily: "Raleway",
                        fontSize: 12,
                      }}
                    >
                      {tag.split(":")[1]}
                    </Typography>
                  }
                />
                // <Card
                //   variant="outlined"
                //   sx={{ width: 180, height: 50 }}
                // >
                //   <CardContent className="text-center">
                //     <Typography
                //       sx={{ fontFamily: "Raleway", fontSize: 12 }}
                //     >
                //       {tag.split(":")[1]}
                //     </Typography>
                //   </CardContent>
                // </Card>
              );
            })}
        </div>
      </Box>
    );
  };

  const SocialMedia = (isStory) => {
    return (
      <SocialShare
        url={shareUrl}
        title={data.title}
        externalLinkIcon={externalLinkIconBlack}
        decription={isStory ? story : data.description}
        hashtags={hashtags}
      />
    );
  };

  const ImageCard = data ? (
    <Card
      className=""
      sx={{
        boxShadow: "none",
        borderRadius: "10px",
        border: "1px solid #e0e0e0",

        backgroundColor: "white",
        alignContent: "center",
        justifyContent: "center",
        padding: "10px",

        width: "100%",
        maxWidth: `${data.width}px !important`,
        minWidth: "400px !important",
        // pin to top
        position: "sticky",
        top: "0",
        zIndex: 10,
      }}
    >
      <CardHeader
        sx={{
          borderRadius: "10px 10px 0 0",
        }}
        title={
          <div className="">
            <div className="card__header__top">
              <Chip
                sx={{
                  margin: 0.2,
                  padding: "10px",
                }}
                color="secondary"
                label={<Typography>{" " + data.category + " "}</Typography>}
              />

              {/*
              <div className="icons">
                <div className="icon">
                  <SocialShare
                    url={shareUrl}
                    title={data.title}
                    externalLinkIcon={externalLinkIconBlack}
                    decription={data.description}
                    hashtags={hashtags}
                  />
                </div>
              </div>
            */}
            </div>
            <Typography variant="h6" color="text.primary">
              {data.title}
            </Typography>
          </div>
        }
        subheader={
          <Typography variant="body2" color="text.secondary">
            Uploaded by {GetFriendlyUsername(data.user_id)} &nbsp;
            <TimeAgo date={data.generated_timestamp} />
          </Typography>
        }
        action={<IconButton aria-label="settings"></IconButton>}
      />

      {localImageUrl !== null ? (
        <Stack
          width="100%"
          justifyContent="center"
          alignItems="center"
          sx={{
            maxWidth: `${data.width}px !important`,
            width: "100%",
          }}
        >
          <StackedEffectCanvas
            imageUrl={localImageUrl}
            blur={effectBlur.bool ? effectBlur.value : 0}
            sepia={effectSepia.bool ? effectSepia.value : 0}
            grayscale={effectGrayscale.bool ? effectGrayscale.value : 0}
            opacity={effectOpacity.bool ? effectOpacity.value : 100}
            hueRotate={effectHueRotate.bool ? effectHueRotate.value * 3.6 : 0}
            invert={effectInvert.bool ? effectInvert.value : 0}
            saturate={effectSaturate.bool ? effectSaturate.value : 0}
            contrast={effectContrast.bool ? effectContrast.value : 0}
            brightness={effectBrightness.bool ? effectBrightness.value : 0}
            handleCurrentStage={(stage) => setCurrentStage(stage)}
            data={data}
          />
        </Stack>
      ) : (
        <Stack
          width="100%"
          height="600px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="inherit" />
        </Stack>
      )}

      <CardContent
        sx={
          {
            // borderRadius: "0 0 10px 10px",
          }
        }
      >
        <Typography
          variant="h6"
          color="text.secondary"
          className="fw-bold mb-1"
        >
          Resolution
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{
            fontFamily: "Raleway",
            fontSize: "18px",
            paddingBottom: "10px",
          }}
        >
          {data.width}w x {data.height}h
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          className="fw-bold mb-1"
          sx={{
            fontFamily: "Raleway",
            fontSize: "18px",
          }}
        >
          Description
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          className="my-2"
          sx={{
            fontFamily: "Raleway",
            fontSize: "16px",
            paddingBottom: "10px",
          }}
        >
          {data.description}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <span className="fw-bold mb-1">generated by:</span>
          <span className="mb-1">
            {" "}
            {GetFriendlyUsername(data.generated_by)}
          </span>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <span className="fw-bold mb-1">prompted by:</span>
          <span className="mb-1"> {GetFriendlyUsername(data.prompted_by)}</span>
        </Typography>

        <div className="col-12">
          <div className="mt-3 mb-5">
            {RenderTopics(data)}
            {RenderTags(data)}
          </div>
        </div>

        <div className="flex__button">
          {
            //inputTermsPrivacy
          }
        </div>
      </CardContent>
    </Card>
  ) : (
    <div></div>
  );

  const createColorSlider = (handler) => {
    return (
      <Slider
        defaultValue={0}
        aria-label="Small"
        valueLabelDisplay="auto"
        step={1}
        marks
        min={0}
        max={255}
        onChange={handler}
      />
    );
  };

  const createColorSliderRGB = (name, handler) => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          color: "white",
          fontWeight: "bold",
          fontSize: "20px",
        }}
      >
        {createColorSlider(handler)}
        {createColorSlider(handler)}
        {createColorSlider(handler)}
      </Box>
    );
  };

  const createDuotonePreset = (name, color1, color2) => {
    return (
      <Button
        variant="contained"
        onClick={() => {
          setColor1R(color1[0]);
          setColor1G(color1[1]);
          setColor1B(color1[2]);
          setColor2R(color2[0]);
          setColor2G(color2[1]);
          setColor2B(color2[2]);
        }}
        style={{
          backgroundColor: `rgb(${color1[0]}, ${color1[1]}, ${color1[2]})`,
          color: `rgb(${color2[0]}, ${color2[1]}, ${color2[2]})`,
          width: "140px",
          objectFit: "cover",
          height: "24px",
          margin: "2px",
          padding: "4px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          fontSize: "12px",
          fontSmooth: "auto",
          fontOpticalSizing: "auto",
          fontStretch: "normal",

        }}
      >
        {name}
      </Button>
    );
  };

  const getDuoToneFilters = (data) => {
    let filters = [
      ["Burgundy Cyan", [128, 0, 32], [0, 255, 255]],
      ["Violet Gold", [138, 43, 226], [255, 215, 0]],
      ["ForestGreen Beige", [34, 139, 34], [245, 245, 220]],
      ["Navy Peach", [0, 0, 128], [255, 218, 185]],
      ["Raspberry Lemon", [227, 11, 93], [255, 247, 0]],
      ["Emerald Magenta", [0, 128, 0], [255, 0, 255]],
      ["Teal Orange", [0, 128, 128], [255, 165, 0]],
      ["Blue Tangerine", [65, 105, 225], [255, 140, 0]],
      ["HotPink Lime", [255, 105, 180], [0, 255, 0]],
      ["DeepPurple Mint", [75, 0, 130], [189, 252, 201]],
      ["Crimson Aqua", [220, 20, 60], [127, 255, 212]],
      ["SlateBlue Coral", [106, 90, 205], [255, 127, 80]],
      ["Indigo Chartreuse", [75, 0, 130], [127, 255, 0]],
      ["Electric Blue Rose", [0, 191, 255], [255, 0, 128]],
      ["Fuchsia Emerald", [255, 0, 255], [0, 128, 0]],
      ["Sunset Purple", [255, 99, 71], [148, 0, 211]],
      ["Midnight Gold", [25, 25, 112], [255, 215, 0]],
      ["Neon Green Coral", [57, 255, 20], [255, 127, 80]],
      ["Aqua Crimson", [0, 255, 255], [220, 20, 60]],
      ["Cobalt Yellow", [0, 71, 171], [255, 255, 0]],
      ["Magenta Cyan", [255, 0, 255], [0, 255, 255]],
      ["Lavender Gold", [230, 230, 250], [255, 215, 0]],
      ["Cherry Aqua", [222, 49, 99], [0, 255, 255]],
      ["Turquoise Lavender", [64, 224, 208], [230, 230, 250]],
      ["Amber Teal", [255, 191, 0], [0, 128, 128]],
      ["Slate Gray Apricot", [112, 128, 144], [251, 206, 177]],
      ["Crimson Lime", [220, 20, 60], [0, 255, 0]],
      ["Coral Aqua", [255, 127, 80], [0, 255, 255]],
      ["Magenta Lime", [255, 0, 255], [0, 255, 0]],
      ["Emerald Peach", [34, 139, 34], [255, 218, 185]],
      ["MidnightBlue Coral", [25, 25, 112], [255, 127, 80]],
      ["Plum Silver", [142, 69, 133], [192, 192, 192]],
      ["Teal RoseGold", [0, 128, 128], [183, 110, 121]],
      ["Amber Indigo", [255, 191, 0], [75, 0, 130]],
      ["Sunset Lavender", [255, 140, 0], [230, 230, 250]],
      ["Sapphire Cream", [15, 82, 186], [255, 253, 208]],
      ["Olive Blush", [128, 128, 0], [255, 182, 193]],
      ["Charcoal Mint", [54, 69, 79], [152, 255, 152]],
      ["Cobalt Coral", [0, 71, 171], [255, 127, 80]],
      ["Crimson Gold", [220, 20, 60], [255, 215, 0]],
      ["Lime Coral", [0, 255, 0], [255, 127, 80]],
      ["Magenta Gold", [255, 0, 255], [255, 215, 0]],
      ["Mint Coral", [152, 255, 152], [255, 127, 80]],
      ["Navy Gold", [0, 0, 128], [255, 215, 0]],
      ["Olive Coral", [128, 128, 0], [255, 127, 80]],
      ["Sapphire Coral", [15, 82, 186], [255, 127, 80]],
      ["Sapphire Gold", [15, 82, 186], [255, 215, 0]],
      ["Sapphire Peach", [15, 82, 186], [255, 218, 185]],
      ["Sapphire Silver", [15, 82, 186], [192, 192, 192]],
      ["Sapphire Teal", [15, 82, 186], [0, 128, 128]],
      ["Sapphire Violet", [15, 82, 186], [138, 43, 226]],
      ["Silver Coral", [192, 192, 192], [255, 127, 80]],
      ["Silver Gold", [192, 192, 192], [255, 215, 0]],
      ["Silver Peach", [192, 192, 192], [255, 218, 185]],
      ["Silver Teal", [192, 192, 192], [0, 128, 128]],
      ["Silver Violet", [192, 192, 192], [138, 43, 226]],
      ["Teal Coral", [0, 128, 128], [255, 127, 80]],
      ["Indigo Gold", [75, 0, 130], [255, 215, 0]],
      ["Aqua Magenta", [0, 255, 255], [255, 0, 255]],
      ["Ruby Cyan", [224, 17, 95], [0, 255, 255]],
      ["Moss Lime", [173, 223, 173], [50, 205, 50]],
      ["Electric Lime", [173, 255, 47], [50, 205, 50]],
      ["Fuchsia Blue", [255, 20, 147], [0, 0, 255]],
      ["Sunset Blue", [255, 69, 0], [0, 0, 255]],
      ["Cobalt Orange", [0, 71, 171], [255, 165, 0]],
      ["Neon Pink", [255, 105, 180], [255, 20, 147]],
      ["Turquoise Pink", [64, 224, 208], [255, 20, 147]],
      ["Mint Rose", [189, 252, 201], [255, 105, 180]],
      ["Copper Teal", [184, 115, 51], [0, 128, 128]],
      ["Rose Gold", [255, 192, 203], [255, 215, 0]],
      ["Cherry Lime", [222, 49, 99], [50, 205, 50]],
      ["Jade Lemon", [0, 168, 107], [255, 247, 0]],
      ["Amethyst Lime", [153, 102, 204], [50, 205, 50]],
      ["Graphite Orange", [54, 69, 79], [255, 165, 0]],
      ["Lime Violet", [50, 205, 50], [138, 43, 226]],
      ["Peacock Orange", [0, 204, 204], [255, 140, 0]],
      ["Aqua Violet", [0, 255, 255], [138, 43, 226]],
      ["Coral Violet", [255, 127, 80], [138, 43, 226]],
      ["Crimson Violet", [220, 20, 60], [138, 43, 226]],
      ["Emerald Violet", [0, 128, 0], [138, 43, 226]],
      ["Gold Violet", [255, 215, 0], [138, 43, 226]],
      ["Lime Violet", [50, 205, 50], [138, 43, 226]],
      ["Magenta Violet", [255, 0, 255], [138, 43, 226]],
      ["Mint Violet", [152, 255, 152], [138, 43, 226]],
      ["Orange Violet", [255, 165, 0], [138, 43, 226]],
      ["Rose Violet", [255, 105, 180], [138, 43, 226]],
      ["Teal Violet", [0, 128, 128], [138, 43, 226]],
      ["Violet Violet", [138, 43, 226], [138, 43, 226]],
      ["Aqua Gold", [0, 255, 255], [255, 215, 0]],
      ["Coral Gold", [255, 127, 80], [255, 215, 0]],
      ["Crimson Gold", [220, 20, 60], [255, 215, 0]],
      ["Emerald Gold", [0, 128, 0], [255, 215, 0]],
      ["Gold Gold", [255, 215, 0], [255, 215, 0]],
      ["Lime Gold", [50, 205, 50], [255, 215, 0]],
      ["Magenta Gold", [255, 0, 255], [255, 215, 0]],
      ["Mint Gold", [152, 255, 152], [255, 215, 0]],
      ["Orange Gold", [255, 165, 0], [255, 215, 0]],
      ["Rose Gold", [255, 105, 180], [255, 215, 0]],
      ["Teal Gold", [0, 128, 128], [255, 215, 0]],
      ["Violet Gold", [138, 43, 226], [255, 215, 0]],
      ["Aqua Peach", [0, 255, 255], [255, 218, 185]],
      ["Coral Peach", [255, 127, 80], [255, 218, 185]],
      ["Crimson Peach", [220, 20, 60], [255, 218, 185]],
      ["Emerald Peach", [0, 128, 0], [255, 218, 185]],
      ["Lime Peach", [50, 205, 50], [255, 218, 185]],
      ["Magenta Peach", [255, 0, 255], [255, 218, 185]],
      ["Mint Peach", [152, 255, 152], [255, 218, 185]],
      ["Orange Peach", [255, 165, 0], [255, 218, 185]],
      ["Rose Peach", [255, 105, 180], [255, 218, 185]],
      ["Teal Peach", [0, 128, 128], [255, 218, 185]],
      ["Violet Peach", [138, 43, 226], [255, 218, 185]],
      ["Cherry Midnight", [222, 49, 99], [25, 25, 112]],
      ["Sunset Aqua", [255, 69, 0], [0, 255, 255]],
      ["Emerald Violet", [0, 128, 0], [138, 43, 226]],
      ["Electric Purple", [0, 191, 255], [75, 0, 130]],
      ["Peach Emerald", [255, 218, 185], [0, 128, 0]],
      ["Coral Mint", [255, 127, 80], [152, 255, 152]],
      ["Ruby Mint", [224, 17, 95], [152, 255, 152]],
      ["Amethyst Gold", [153, 102, 204], [255, 215, 0]],
      ["Aqua Rose", [0, 255, 255], [255, 105, 180]],
      ["Midnight Teal", [25, 25, 112], [0, 128, 128]],
      ["Lime Magenta", [50, 205, 50], [255, 0, 255]],
      ["Sapphire Lime", [15, 82, 186], [50, 205, 50]],
      ["Crimson Turquoise", [220, 20, 60], [64, 224, 208]],
      ["Peach Plum", [255, 218, 185], [142, 69, 133]],
      ["Gold Mint", [255, 215, 0], [152, 255, 152]],
      ["Teal Lavender", [0, 128, 128], [230, 230, 250]],
      ["Ruby Indigo", [224, 17, 95], [75, 0, 130]],
      ["Fuchsia Navy", [255, 0, 255], [0, 0, 128]],
      ["Gold Sapphire", [255, 215, 0], [15, 82, 186]],
      ["Cyan Plum", [0, 255, 255], [142, 69, 133]],
      // now super creative and high contrast duotones
    ];

    // sort by contrast
    filters.sort((a, b) => {
      return a[1][0] - b[1][0];
    });

    return filters.map((filter, index) => {
      return createDuotonePreset(filter[0], filter[1], filter[2]);
    });
  };

  const renderSingleToneFilters = () => {
    return (
      <div className="row">
        <Box text="center">
          {createDuotonePreset("Black & White", [255, 255, 255], [0, 0, 0])}
          {createDuotonePreset("Red", [255, 255, 255], [255, 0, 0])}
          {createDuotonePreset("Green", [255, 255, 255], [0, 255, 0])}
          {createDuotonePreset("Blue", [255, 255, 255], [0, 0, 255])}
          {createDuotonePreset("Yellow", [255, 255, 255], [255, 255, 0])}
          {createDuotonePreset("Pink", [255, 255, 255], [255, 0, 255])}
          {createDuotonePreset("Cyan", [255, 255, 255], [0, 255, 255])}
          {createDuotonePreset("Orange", [255, 255, 255], [255, 128, 0])}
          {createDuotonePreset("Magenta", [255, 255, 255], [255, 0, 128])}
        </Box>
      </div>
    );
  };

  const renderFeaturedDuotones = () => {
    return (
      <div>
        <Box textAlign="center">
          {createDuotonePreset("Burgundy Cyan", [128, 0, 32], [0, 255, 255])}
          {createDuotonePreset("Violet Gold", [138, 43, 226], [255, 215, 0])}
          {createDuotonePreset(
            "ForestGreen Beige",
            [34, 139, 34],
            [245, 245, 220]
          )}
          {createDuotonePreset("Navy Peach", [0, 0, 128], [255, 218, 185])}
          {createDuotonePreset("Raspberry Lemon", [227, 11, 93], [255, 247, 0])}
          {createDuotonePreset("Emerald Magenta", [0, 128, 0], [255, 0, 255])}
          {createDuotonePreset("Teal Orange", [0, 128, 128], [255, 165, 0])}
          {createDuotonePreset("Blue Tangerine", [65, 105, 225], [255, 140, 0])}
          {createDuotonePreset("HotPink Lime", [255, 105, 180], [0, 255, 0])}
          {createDuotonePreset(
            "DeepPurple Mint",
            [75, 0, 130],
            [189, 252, 201]
          )}
          {createDuotonePreset("Crimson Aqua", [220, 20, 60], [127, 255, 212])}
          {createDuotonePreset(
            "SlateBlue Coral",
            [106, 90, 205],
            [255, 127, 80]
          )}
          {createDuotonePreset(
            "Indigo Chartreuse",
            [75, 0, 130],
            [127, 255, 0]
          )}
          {createDuotonePreset(
            "Electric Blue Rose",
            [0, 191, 255],
            [255, 0, 128]
          )}
          {createDuotonePreset("Fuchsia Emerald", [255, 0, 255], [0, 128, 0])}
          {createDuotonePreset("Sunset Purple", [255, 99, 71], [148, 0, 211])}
          {createDuotonePreset("Midnight Gold", [25, 25, 112], [255, 215, 0])}
          {createDuotonePreset(
            "Neon Green Coral",
            [57, 255, 20],
            [255, 127, 80]
          )}
          {createDuotonePreset("Aqua Crimson", [0, 255, 255], [220, 20, 60])}
          {createDuotonePreset("Cobalt Yellow", [0, 71, 171], [255, 255, 0])}
        </Box>
      </div>
    );
  };

  const DuoToneFilters = (data) => {
    return (
      <div>
        <Box textAlign="center">
          <Box
            onClick={() => handleSelectedFrame(1)}
            className={`frame ${selectedFrame === 1 ? "selected" : ""}`}
          >
            {renderFilteredImageDuoTone(
              localImageUrl ?? data.public_url,
              "duotone",
              [color1R, color1G, color1B],
              [color2R, color2G, color2B],
              data.width,
              data.height
            )}

            <Accordion
              expanded={selectedFrame === 1}
              sx={{
                "&.MuiAccordion-root": {
                  boxShadow: "none!important",
                  paddingTop: "10px",
                  marginBottom: "20px",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  fontWeight="500"
                  fontSize="18px"
                  color="text.secondary"
                >
                  Custom DuoTone Filter
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  "&.MuiAccordionDetails-root": {
                    padding: "0 0 8px 0",
                  },
                }}
              >
                <div className="row">
                  <Box text="center">
                    <Stack
                      direction="row"
                      justifyContent="center"
                      gap="24px"
                      flexWrap="nowrap"
                    >
                      <Box
                        sx={{
                          width: "50%",
                          height: "fit-content",
                          padding: "20px",
                          boxSizing: "border-box",
                          border: "3px dashed #ccc",
                        }}
                      >
                        <Typography textAlign="center" pb="10px">
                          Color 1
                        </Typography>
                        <SingleColorPicker
                          value={color1R}
                          onChange={setColor1R}
                        />
                        <SingleColorPicker
                          value={color1G}
                          onChange={setColor1G}
                        />
                        <SingleColorPicker
                          value={color1B}
                          onChange={setColor1B}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "50%",
                          height: "fit-content",
                          padding: "20px",
                          boxSizing: "border-box",
                          border: "3px dashed #ccc",
                        }}
                      >
                        <Typography textAlign="center" pb="10px">
                          Color 2
                        </Typography>
                        <SingleColorPicker
                          value={color2R}
                          onChange={setColor2R}
                        />
                        <SingleColorPicker
                          value={color2G}
                          onChange={setColor2G}
                        />
                        <SingleColorPicker
                          value={color2B}
                          onChange={setColor2B}
                        />
                      </Box>
                    </Stack>
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                "&.MuiAccordion-root": {
                  boxShadow: "none!important",
                  paddingTop: "10px",
                  marginBottom: "20px",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  fontWeight="500"
                  fontSize="18px"
                  color="text.secondary"
                >
                  Additional DuoTone Filters
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  "&.MuiAccordionDetails-root": {
                    padding: "0 0 8px 0",
                  },
                }}
              >
                <div className="row">
                  <Box
                    textAlign="center"
                    style={{
                      width: "100%",
                    }}
                  >
                    {getDuoToneFilters(data)}
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                "&.MuiAccordion-root": {
                  boxShadow: "none!important",
                  paddingTop: "10px",
                  marginBottom: "20px",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  fontWeight="500"
                  fontSize="18px"
                  color="text.secondary"
                >
                  Single Tone Filters
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  "&.MuiAccordionDetails-root": {
                    padding: "0 0 8px 0",
                  },
                }}
              >
                {renderSingleToneFilters()}
              </AccordionDetails>
            </Accordion>

            {/* <div className="row">
                            <Box
                                textAlign="center"
                                style={{
                                    width: "130px",
                                    height: "150px",
                                    padding: "10px",
                                    marginLeft: "25px",
                                    border: "3px dashed #ccc",
                                }}
                            >
                                <SingleColorPicker value={color1R} onChange={setColor1R}/>
                                <SingleColorPicker value={color1G} onChange={setColor1G}/>
                                <SingleColorPicker value={color1B} onChange={setColor1B}/>
                                Color 1
                            </Box>

                            <Box
                                textAlign="center"
                                style={{
                                    width: "130px",
                                    height: "150px",
                                    padding: "10px",

                                    border: "3px dashed #ccc",
                                }}
                            >
                                <SingleColorPicker value={color2R} onChange={setColor2R}/>
                                <SingleColorPicker value={color2G} onChange={setColor2G}/>
                                <SingleColorPicker value={color2B} onChange={setColor2B}/>
                                Color 2
                            </Box>

                           
                        </div>
                        {
                            // setup an accordian for remaining dutones
                        }

                        <Accordion expanded={storyExpanded}>
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon
                                        onChange={() => {
                                            setStoryExpanded(!storyExpanded);
                                        }}
                                    />
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography
                                    variant="body"
                                    color="text.secondary"
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    More Duotone Filters
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="row">
                                    <Box
                                        textAlign="center"
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                     
                                    </Box>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography
                                    variant="body"
                                    color="text.secondary"
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    Single Tone Filters
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="row">
                                    <Box text="center">
                                        {createDuotonePreset(
                                            "Black & White",
                                            [255, 255, 255],
                                            [0, 0, 0]
                                        )}
                                        {createDuotonePreset("Red", [255, 255, 255], [255, 0, 0])}
                                        {createDuotonePreset("Green", [255, 255, 255], [0, 255, 0])}
                                        {createDuotonePreset("Blue", [255, 255, 255], [0, 0, 255])}
                                        {createDuotonePreset(
                                            "Yellow",
                                            [255, 255, 255],
                                            [255, 255, 0]
                                        )}
                                        {createDuotonePreset(
                                            "Pink",
                                            [255, 255, 255],
                                            [255, 0, 255]
                                        )}
                                        {createDuotonePreset(
                                            "Cyan",
                                            [255, 255, 255],
                                            [0, 255, 255]
                                        )}
                                        {createDuotonePreset(
                                            "Orange",
                                            [255, 255, 255],
                                            [255, 128, 0]
                                        )}
                                        {createDuotonePreset(
                                            "Magenta",
                                            [255, 255, 255],
                                            [255, 0, 128]
                                        )}
                                    </Box>
                                </div>
                            </AccordionDetails>
                        </Accordion> */}
          </Box>
        </Box>
      </div>
    );
  };

  const generateIcon = (icon, altText, title) => {
    return (
      <div className="icon">
        <img
          alt={altText}
          src={icon}
          title={title}
          style={{
            width: "50px",
            height: "50px",
            marginTop: "10px",
            marginBottom: "10px",
            padding: "2px",
            fontSize: "10px",
          }}
        />

        <Typography
          variant="body"
          color="text.secondary"
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: "Raleway",
          }}
        >
          {title}
        </Typography>
      </div>
    );
  };

  const RenderTabsSafari = () => {
    return (
      <div className="body">
        <div className="tabs">
          {/*
          <div
            className={`tab ${currentTab === 0 ? "active" : ""}`}
            onClick={() => setCurrentTab(0)}
          >
            {generateIcon(filtersIcon, "filters", "Filters")}
          </div>
          */}
          <div
            className={`tab ${currentTab === 1 ? "active" : ""}`}
            onClick={() => setCurrentTab(1)}
          >
            {generateIcon(duotonesIcon, "duotone", "Duotones")}
          </div>

          {/*
          <div
            className={`tab ${currentTab === 2 ? "active" : ""}`}
            onClick={() => setCurrentTab(2)}
          >
            {generateIcon(cropsIcon, "Crops & Cuts", "Crop")}
          </div>
          */}

          <div
            className={`tab ${currentTab === 3 ? "active" : ""}`}
            onClick={() => setCurrentTab(3)}
          >
            {generateIcon(framesIcon, "Generate Image", "✨Image")}
          </div>

          <div
            className={`tab ${currentTab === 4 ? "active" : ""}`}
            onClick={() => setCurrentTab(4)}
          >
            {generateIcon(storyIcon, "✨Create a Story", "✨Story")}
          </div>

          {/*
          <div
            className={`tab ${currentTab === 5 ? "active" : ""}`}
            onClick={() => setCurrentTab(5)}
          >
            {generateIcon(framesIcon, "✨Variations", "✨✨Variations")}
          </div>
        */}
        </div>
      </div>
    );
  };
  const RenderTabs = () => {
    return (
      <div className="body">
        <div className="tabs">
          <div
            className={`tab ${currentTab === 0 ? "active" : ""}`}
            onClick={() => setCurrentTab(0)}
          >
            {generateIcon(filtersIcon, "filters", "Filters")}
          </div>
          <div
            className={`tab ${currentTab === 1 ? "active" : ""}`}
            onClick={() => setCurrentTab(1)}
          >
            {generateIcon(duotonesIcon, "duotone", "Duotones")}
          </div>

          <div
            className={`tab ${currentTab === 2 ? "active" : ""}`}
            onClick={() => setCurrentTab(2)}
          >
            {generateIcon(cropsIcon, "Crops & Cuts", "Crop")}
          </div>

          <div
            className={`tab ${currentTab === 3 ? "active" : ""}`}
            onClick={() => setCurrentTab(3)}
          >
            {generateIcon(framesIcon, "Generate Image", "✨Image")}
          </div>

          <div
            className={`tab ${currentTab === 4 ? "active" : ""}`}
            onClick={() => setCurrentTab(4)}
          >
            {generateIcon(storyIcon, "✨Create a Story", "✨Story")}
          </div>

          {/*
          <div
            className={`tab ${currentTab === 5 ? "active" : ""}`}
            onClick={() => setCurrentTab(5)}
          >
            {generateIcon(framesIcon, "✨Variations", "✨✨Variations")}
          </div>
        */}
        </div>
      </div>
    );
  };

  const filterClickHandler = (e) => {
    // console.log("filterClickHandler: ", e);
    alert("filterClickHandler: " + e);
  };

  const handleFilterRender = (filter, filterHandler) => {
    if (filter.bool === false) {
      filterHandler({
        ...filter,
        bool: true,
      });
      setGenEffect(filter.value);
      setInfocus(filter.inFocus);
    } else {
      if (filter.inFocus !== inFocus) {
        setGenEffect(filter.value);
        setInfocus(filter.inFocus);
      } else {
        filterHandler({
          ...filter,
          bool: false,
        });
        setGenEffect(0);
        setInfocus(null);
      }
    }
  };

  const SetupEffectBox = (data, renderValue, renderedHandler) => {
    return data && renderValue && renderValue.inFocus ? (
      <Box
        sx={{
          // allow box to be responsive -- grow/shrink padding as needed
          width: "100%",
          maxWidth: "320px",
          backgroundColor: "white",
          border: `${renderValue.inFocus === inFocus ? "4px" : "2px"} solid ${
            renderValue.bool ? "#ac3b3a" : "#ccc"
          }`,
          borderRadius: "5px",
        }}
        onClick={() => handleFilterRender(renderValue, renderedHandler)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CanvasFilters
            baseImageUrl={data.public_url_thumbnail}
            filterType={renderValue.inFocus}
            filterAmount={renderValue.value}
            width={data.width}
            height={data.height}
          />
          <Typography
            style={{
              fontWeight: "500",
              fontSize: "14px",
              textAlign: "center",
              fontFamily: "Raleway",
              marginTop: "10px",
              color: renderValue.bool ? "#ac3b3a" : "#ccc",
            }}
          >
            {renderValue.inFocus.replaceAll("-", " ")}&nbsp;({renderValue.value}
            )
          </Typography>
        </div>
      </Box>
    ) : (
      <div></div>
    );
  };

  const SetupQuadBox = (title, url, index) => {
    // console.log("SetupQuadBox: ", title, url);
    return url ? (
      <CardMedia
        sx={{
          opacity: url ? "1" : "0.5",
        }}
        id={`quadImage-` + index}
        component="img"
        image={url ? url : data.public_url_thumbnail}
        title={
          url === data.public_url_thumbnail
            ? "Agree to terms to generate image"
            : title
        }
        alt={
          url === data.public_url_thumbnail
            ? "Agree to terms to generate image"
            : title
        }
        onClick={
          url !== data.public_url_thumbnail
            ? () => {
                window.open(url);
              }
            : null
        }
      />
    ) : (
      <Box
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          border: "1px solid #ccc",
          borderRadius: "5px",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          style={{
            textAlign: "center",
            fontFamily: "Raleway",
            fontWeight: "300",
            padding: "8px",
          }}
        >
          {title}
        </Typography>
      </Box>
    );
  };
  const SetupStoryBox = (title, url) => {
    // console.log("SetupStoryBox: ", title, url);
    return url ? (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridGap: 0.2,
          padding: 1,
          opacity: url ? "1" : "0.5",
          backgroundColor: "white",
          border: "1px solid #ccc",
          borderRadius: "5px",
          marginBottom: "10px",
        }}
      >
        <img
          style={{
            width: "100%",
            padding: "2px",
            border: "1px solid primary",
            borderRadius: "5px",
            opacity: url ? "1" : "0.5",
          }}
          
          border="1px solid primary"
          borderRadius="5px"
          id="storyImage"          
          src={url ? url : data.public_url_thumbnail}
          title={url ? title : "No Image"}
          alt={url ? title : "Agree to terms to generate image"}
          onClick={() => {
            window.open(url);
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            padding: 1,
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Title:
          </Typography>
          <Typography variant="body2">{data.title}</Typography>
          <br />
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Description:
          </Typography>
          <Typography variant="body2">{data.description}</Typography>
          { /*
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Category:
          </Typography>
          <Chip
            key={`story_category_chip`}
            color="secondary"
            sx={{ margin: 0.2 }}
            label={
              <Typography
                sx={{
                  margin: 1,
                  fontFamily: "Raleway",
                  fontSize: 12,
                }}
              >
                {data.category}
              </Typography>
            }
          />

          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Topics:
          </Typography>
          */}
          <br />
          <div className="row">
          { RenderTopics(data) }
            </div>
        </Box>
      </Box>
    ) : (
      <Box
        style={{
          backgroundColor: "white",
          border: "1px solid #ccc",
          borderRadius: "5px",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          style={{
            textAlign: "center",
            fontFamily: "Raleway",
            fontWeight: "300",
            padding: "8px",
          }}
        >
          {title}
        </Typography>
      </Box>
    );
  };
  const RenderImageQuad = (quad, originalUrl) => {
    //console.log("RenderImageQuad: ", originalUrl, quad);
    /*
    if (quad) {
      for (var i = 0; i < 4; i++) 
        {
          quad.push(originalUrl);
        }
    }
    */
    //console.log("RenderImageQuad: ", quad);
    return quad ? (
      // create a canvas for each frame
      // frames are square, circular or other shapes
      // frames can be transparent or have a color
      // console.log("ImageCanvasFrames: ", data),
      // setup a two column grid
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridGap: 1,
          padding: 1,
          opacity: quad.length === 0 ? "0.5" : "1",
          backgroundColor: "white",
          border: "1px solid #ccc",
          borderRadius: "5px",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {SetupQuadBox(data.title, quad.length > 0 ? quad[0] : originalUrl)}
        {SetupQuadBox(data.title, quad.length > 1 ? quad[1] : originalUrl)}
        {SetupQuadBox(data.title, quad.length > 2 ? quad[2] : originalUrl)}
        {SetupQuadBox(data.title, quad.length > 3 ? quad[3] : originalUrl)}
      </Box>
    ) : (
      <div></div>
    );
  };

  const ImageCanvasFrames = (data) => {
    return data ? (
      // create a canvas for each frame
      // frames are square, circular or other shapes
      // frames can be transparent or have a color
      // console.log("ImageCanvasFrames: ", data),
      // setup a two column grid
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridGap: 10,
          padding: 3,
          paddingTop: 0,
          backgroundColor: "white",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {SetupEffectBox(data, effectBrightness, setEffectBrightness)}
        {SetupEffectBox(data, effectContrast, setEffectContrast)}
        {SetupEffectBox(data, effectSaturate, setEffectSaturate)}
        {SetupEffectBox(data, effectBlur, setEffectBlur)}
        {SetupEffectBox(data, effectGrayscale, setEffectGrayscale)}
        {SetupEffectBox(data, effectHueRotate, setEffectHueRotate)}
        {SetupEffectBox(data, effectSepia, setEffectSepia)}
        {SetupEffectBox(data, effectInvert, setEffectInvert)}
      </Box>
    ) : (
      <div></div>
    );
  };

  const EffectFilters = (data) => {
    return !browserName.includes("Safari") ? (
      <div>
        {/*
        <Typography
          variant="h6"
          color="text.secondary"
          style={{
            textAlign: "center",
            fontFamily: "Raleway",
            fontWeight: "bold",
            padding: "10px",
          }}
        >
          Image Effects
        </Typography>
        */}

        <Stack
          direction="row"
          justifyContent="center"
          gap="10px"
          sx={{
            padding: "10px",
          }}
        >
          <Button
            className="rounded-pill"
            variant="contained"
            sx={{
              textTransform: "none",
              fontSize: "1rem",
            }}
            disabled={currentStage === null}
            onClick={download}
          >
            Download Filtered Image
          </Button>
          <Button
            className="rounded-pill"
            variant="contained"
            sx={{
              textTransform: "none",
              fontSize: "1rem",
            }}
            disabled={currentStage === null}
            onClick={resetFilters}
          >
            Reset Filters
          </Button>
        </Stack>
        <Box
          sx={{
            padding: "24px 48px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" color="text.secondary">
              Filter Intensity ({inFocus === null ? "none" : inFocus}){" "}
            </Typography>
            <InputBase
              type="number"
              sx={{
                border: "1px solid black",
                outline: "none",
                input: {
                  color: "black",
                  padding: "20px",
                  boxSizing: "border-box",
                  fontSize: "16px",
                  marginRight: "-20px",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none!important",
                      margin: 0,
                    },
                  "&[type=number]": {
                    MozAppearance: "textfield!important",
                  },
                },
              }}
              value={genEffect}
              onChange={(event) => {
                const value = parseInt(event.target.value, 10);
                if (value > 100) {
                  setGenEffect(100);
                } else if (value < 0) {
                  setGenEffect(0);
                } else {
                  setGenEffect(value);
                }
              }}
              inputProps={{ min: 0, max: 100 }}
            />
          </Stack>
          <Slider
            value={genEffect}
            onChange={handleGeneralSliderChange}
            min={0}
            max={100}
            defaultValue={1}
            step={1}
            aria-labelledby="effect-slider"
            valueLabelDisplay="auto"
          />
          <Typography
            variant="body2"
            color="text.secondary"
            style={{
              textAlign: "center",
              fontFamily: "Raleway",
              fontWeight: "300",
              fontStyle: "italic",
              padding: "10px",
            }}
          >
            NOTE: the filters are applied together as a composite filter. <br />
            Adjust the intensity of a single filter by selecting it and moving
            the slider.
          </Typography>
        </Box>
        {ImageCanvasFrames(data)}
      </div>
    ) : (
      <div>
        {renderWarningMessage(
          "Image filters not currently supported on Safari browsers - try our Duotone Filters!",
          true
        )}
      </div>
    );
  };

  const RenderMaterials = (data) => {
    return (
      <Box>
        <div className="d-flex flex-row flex-wrap">
          {data.tags
            .filter((tag) => {
              return tag.split(":")[0] === "materials" && tag.includes(":");
            })
            .map((tag, index) => {
              return (
                <Chip
                  key={`selected__key__${index}`}
                  sx={{ margin: 0.2 }}
                  label={
                    <Typography
                      sx={{
                        margin: 1,
                        fontFamily: "Raleway",
                        fontSize: 12,
                      }}
                    >
                      {tag.split(":")[1]}
                    </Typography>
                  }
                />
              );
            })}
        </div>
      </Box>
    );
  };
  const RenderOtherTags = (data) => {
    return (
      <Box>
        <div className="d-flex flex-row flex-wrap">
          {data.tags
            .filter((tag) => {
              return (
                tag.split(":")[0] !== "color" &&
                tag.split(":")[0] !== "topic" &&
                tag.split(":")[0] !== "category" &&
                tag.split(":")[0] !== "materials" &&
                tag.split(":")[0] !== "resolution" &&
                tag.split(":")[0] !== "width" &&
                tag.split(":")[0] !== "height" &&
                tag.includes(":")
              );
            })
            .map((tag, index) => {
              return (
                <Chip
                  key={`selected__key__${index}`}
                  sx={{ margin: 0.2 }}
                  label={
                    <Typography
                      sx={{
                        margin: 1,
                        fontFamily: "Raleway",
                        fontSize: 12,
                      }}
                    >
                      {tag.split(":")[1]}
                    </Typography>
                  }
                />
              );
            })}
        </div>
      </Box>
    );
  };

  const RenderColors = (data) => {
    return (
      <Box>
        <div className="d-flex flex-row flex-wrap">
          {userTags
            .filter((tag) => {
              return tag.split(":")[0] === "color" && tag.includes(":");
            })
            .map((tag, index) => {
              return (
                // show color chip with color name and color
                // also add remove icon on chip to remove color
                // also show color picker

                <Chip
                  key={`selected__key__${index}`}
                  sx={{ margin: 0.2 }}
                  icon={
                    <IconButton
                      onClick={() => {
                        // console.log("Remove Color: ", tag);
                        var removeTags = userTags.filter((t) => {
                          return t !== tag;
                        });
                        setUserTags(removeTags);
                      }}
                      className="fas fa-times-circle"
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        margin: 1,
                        fontFamily: "Raleway",
                        fontSize: 12,
                      }}
                    >
                      {tag.split(":")[1]}
                    </Typography>
                  }
                />
              );
            })}
        </div>
      </Box>
    );
  };

  const ShowSubscriptionInfo = () => {
    return (
      <Box
        className="image__checkout"
        sx={{
          bgColor: "#f5f5f5",
          width: "100%",
        }}
      >
        <div className="mt-2 fw-bold mb-2">
          <i className="fa-solid fa-coins"></i>
          <span className="ms-2">
            Monthly Credits Balance: {getSubscriptionCreditsRemaining()}
          </span>
          <br />
          <span className="ms-2">
            Wallet Credits: {getWalletCreditsRemaining()}
          </span>
        </div>
      </Box>
    );
  };

  /*
  const ThreeOptionRadioStyle = (label, option1, option2, option3) => {
    const theme = useTheme();

    return (
      // if mobile, show radio buttons in a column
      // if desktop, show radio buttons in a row

      <FormControl
        component="fieldset"
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)", // on extra small (xs) screens, display 1 column
            sm: "repeat(2, 1fr)", // on small (sm) screens, display 2 columns
            md: "repeat(4, 1fr)", // on medium (md) screens and up, display 4 columns
          },
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <FormLabel
          component="legend"
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: "Raleway",
            textAlign: "center",
            paddingRight: { xs: "0px", sm: "20px", md: "40px" },
          }}
        >
          {label}
        </FormLabel>
        <RadioGroup
          className="row"
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            "& .MuiFormControlLabel-root": {
              color: "#333",
            },
            "& .MuiRadio-root": {
              color: "#333",
              "&.Mui-checked": {
                color: "#4A90E2",
              },
            },
          }}
          aria-label="style"
          name="style"
          value={imageGenStyle}
          onChange={(event) => {
            setImageGenStyle(event.target.value);
          }}
        >
          <FormControlLabel
            sx={{
              alignItems: "center",
              padding: "1px",
              textAlign: "center",
              fontFamily: "Raleway",
              fontSize: "10px",
            }}
            className="col"
            value={option1}
            control={
              <ThemeProvider theme={studioTheme}>
                <Radio
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite />}
                  style={{ color: "pink" }}
                />
              </ThemeProvider>
            }
            label={option1}
          />
          <FormControlLabel
            sx={{
              alignItems: "center",
              padding: "1px",
              textAlign: "center",
              fontFamily: "Raleway",
              fontSize: "10px",
            }}
            className="col"
            value={option2}
            control={
              <ThemeProvider theme={studioTheme}>
                <Radio />
              </ThemeProvider>
            }
            label={option2}
          />
          <FormControlLabel
            sx={{
              alignItems: "center",
              padding: "1px",
              textAlign: "center",
              fontFamily: "Raleway",
              fontSize: "10px",
            }}
            className="col"
            value={option3}
            control={
              <ThemeProvider theme={studioTheme}>
                <Radio />
              </ThemeProvider>
            }
            label={option3}
          />
        </RadioGroup>
      </FormControl>
    );
  };
  */

  const ThreeOptionToggleStyle = (label, option1, option2, option3, value, handlerChangeValue) => {
    //console.log("ThreeOptionToggleStyle: ", label, option1, option2, option3);
    //console.log("ThreeOptionToggleStyle: current:", imageGenStyle);
    return (
      // if mobile, show radio buttons in a column
      // if desktop, show radio buttons in a row

      <Paper
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)", // on extra small (xs) screens, display 1 column
            sm: "repeat(2, 1fr)", // on small (sm) screens, display 2 columns
            md: "repeat(3, 1fr)", // on medium (md) screens and up, display 4 columns
          },
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: "Raleway",
            textAlign: "center",
            paddingRight: { xs: "0px", sm: "20px", md: "40px" },
          }}
        >
          {label}
        </Typography>
        <ToggleButtonGroup
          sx={{}}
          aria-label="style"
          name="style"
          value={value}
          exclusive
          onChange={(event, newValue) => {
            handlerChangeValue(newValue);
          }}
        >
          <ThemeProvider theme={studioTheme}>
            <ToggleButton
              value={option1}
              backgroundcolor={
                value !== undefined
                  ? option1 === value
                    ? "primary"
                    : "default"
                  : "default"
              }
            >
              <Typography>{option1}</Typography>
            </ToggleButton>
          </ThemeProvider>
          <ThemeProvider theme={studioTheme}>
            <ToggleButton
              value={option2}
              backgroundcolor={
                imageGenStyle !== undefined
                  ? option2 === value
                    ? "primary"
                    : "default"
                  : "default"
              }
            >
              <Typography>{option2}</Typography>
            </ToggleButton>
          </ThemeProvider>
          <ThemeProvider theme={studioTheme}>
            <ToggleButton
              value={option3}
              backgroundcolor={
                value !== undefined
                  ? option3 === value
                    ? "primary"
                    : "default"
                  : "default"
              }
            >
              <Typography>{option3}</Typography>
            </ToggleButton>
          </ThemeProvider>
        </ToggleButtonGroup>
      </Paper>
    );
  };
  const NOptionToggleStyle = (label, options, value, handlerChangeValue) => {
    //console.log("ThreeOptionToggleStyle: ", label, option1, option2, option3);
    //console.log("ThreeOptionToggleStyle: current:", imageGenStyle);
    return (
      // if mobile, show radio buttons in a column
      // if desktop, show radio buttons in a row

      <Paper
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)", // on extra small (xs) screens, display 1 column
            sm: "repeat(2, 1fr)", // on small (sm) screens, display 2 columns
            md: "repeat(3, 1fr)", // on medium (md) screens and up, display 4 columns
          },
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: "Raleway",
            textAlign: "center",
            paddingRight: { xs: "0px", sm: "20px", md: "40px" },
          }}
        >
          {label}
        </Typography>
        <ToggleButtonGroup
          sx={{}}
          aria-label="style"
          name="style"
          value={value}
          exclusive
          onChange={(event, newValue) => {
            handlerChangeValue(newValue);
          }}
        >
          {options.map((option, index) => {
            return (
              <ThemeProvider theme={studioTheme}>
                <ToggleButton
                  value={option}
                  backgroundcolor={
                    value !== undefined
                      ? option === value
                        ? "primary"
                        : "default"
                      : "default"
                  }
                >
                  <Typography>{option}</Typography>
                </ToggleButton>
              </ThemeProvider>
            );
          })}
        </ToggleButtonGroup>
      </Paper>
    );
  };
  const NOptionsRadioStyle = (label, options,value, handlerChangeValue) => {
    const theme = useTheme();

    return (
      // if mobile, show radio buttons in a column
      // if desktop, show radio buttons in a row

      <Box>
        <Typography
          variant="h6"
          color="text.secondary"
          style={{
            textAlign: "center",
            fontFamily: "Raleway",
            fontWeight: "bold",
            padding: "10px",
          }}
        >
          {label}
        </Typography>

        <FormControl
          component="fieldset"
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)", // on extra small (xs) screens, display 1 column
              sm: "repeat(2, 1fr)", // on small (sm) screens, display 2 columns
              md: "repeat(3, 1fr)", // on medium (md) screens and up, display 4 columns
            },
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            textAlign: "center",
          }}
        >
          <RadioGroup
            className="row"
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "10px",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              "& .MuiFormControlLabel-root": {
                color: "#333",
              },
              "& .MuiRadio-root": {
                color: "#333",
                "&.Mui-checked": {
                  color: "#4A90E2",
                },
              },
            }}
            aria-label="style"
            name="style"
            value={value}
            onChange={(event) => {
              handlerChangeValue(event.target.value);
            }}
          >
            {options.map((option, index) => {
              return (
                <FormControlLabel
                  sx={{
                    alignItems: "center",
                    padding: "1px",
                    textAlign: "center",
                    fontFamily: "Raleway",
                    fontSize: "10px",
                  }}
                  className="col"
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </Box>
    );
  };

  const ImageGenOptions = () => {
    return (
      acceptTerms && (
        <Box 
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
            <Box
             className="row"
             sx={{
               justifyContent: "center",
               alignItems: "center",
               width: "100%",
             }}
           >    
           
            {  ThreeOptionToggleStyle(
                "Style Options",
                "Realistic",
                "Illustration",
                "Cartoon",
                imageGenStyle,
                setImageGenStyle
              ) }
            </Box>
            <Box
             className="row"
             sx={{
               justifyContent: "center",
               alignItems: "center",
               width: "100%",
             }}
           >    
           
            {  ThreeOptionToggleStyle(
                "Modifiers",
                "Cyberpunk",
                "Surrealism",
                "Abstract",
                imageGenStyle2,
                setImageGenStyle2
              ) }
            </Box>
            <Box
             className="row"
             sx={{
               justifyContent: "center",
               alignItems: "center",
               width: "100%",
             }}
           >       
             { ThreeOptionToggleStyle(
                "Seed Options",
                "Original",
                "Random",
                "Custom",
                seedOption,
                setSeedOption
              )
            }
          </Box>
          <Paper
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <Chip
              width="80px"
              label={<Typography>{productsImageGenPrice} credits </Typography>}
            />

            {inputIncludeTags}

            <Typography variant="body2" color="text.secondary">
              Image Seed
            </Typography>

            <InputBase
              type="number"
              sx={{
                border: "1px solid black",
                outline: "none",
                width: "60px",
                input: {
                  color: "black",
                  padding: "10px",
                  boxSizing: "border-box",
                  fontSize: "10px",
                  marginRight: "-20px",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none!important",
                      margin: 0,
                    },
                  "&[type=number]": {
                    MozAppearance: "textfield!important",
                  },
                },
              }}
              value={seed}
              onChange={(event) => {
                const value = parseInt(event.target.value, 10);
                if (value > 1000000) {
                  setSeed(1000000);
                } else if (value < 0) {
                  setSeed(0);
                } else {
                  setSeed(value);
                }
              }}
              inputProps={{ min: 0, max: 1000000 }}
            />
          </Paper>
        </Box>
      )
    );
  };

  const RenderTagOptions = () => {
    return (
      <Box
        sx={{
          border: "1px solid #ccc",
        }}
      >
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="body"
              color="text.secondary"
              style={{
                textAlign: "center",
              }}
            >
              Colors
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              <Box
                textAlign="center"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                {RenderColors(data)}
              </Box>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="body"
              color="text.secondary"
              style={{
                textAlign: "center",
              }}
            >
              Materials
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              <Box
                textAlign="center"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                {RenderMaterials(data)}
              </Box>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="body"
              color="text.secondary"
              style={{
                textAlign: "center",
              }}
            >
              Other Tags
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              <Box
                textAlign="center"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                {RenderOtherTags(data)}
              </Box>
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  const RenderCreditsButton = () => {
    return (
      <div className="bg-banner text-center ">
        <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
          <Button
            className="rounded-pill"
            variant="contained"
            onClick={() => {
              navigate("/credits");
            }}
          >
            View Credit Packs
          </Button>
        </div>
      </div>
    );
  };

  const handleImageGen = async () => {
    //console.log("handleImageGen: ", data);
    var seedNew = seed;
    if (seedOption.toLowerCase() === "random") {
      seedNew = randomizeSeed();
    } else if (seedOption.toLowerCase() === "original") {
        seedNew = data.seed;
    } else if (seedOption.toLowerCase() === "custom") {
        seedNew = seed;
    }

    genImage(
      data,
      userTags,
      seedNew,
      includeTagsGen,
      imageGenStyle,
      imageGenStyle2
    );
  };

  const VaryStyles = (data) => {
    var price = productsImageGenPrice;
    if (price === 0) {
      price = getImageGenPrice();
    }
    var enoughCredits =
      getSubscriptionCreditsRemaining() + getWalletCreditsRemaining() >=
      productsImageGenPrice;

    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Card
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            border: "1px solid #ccc",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {imageGenLoading ? (
            <div
              style={{
                width: "100%",
                height: "250px",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <Backdrop open={imageGenLoading}>
                {
                  //<CircularProgress color="inherit" />  <LinearProgress variant="determinate" value={progress} />
                }

                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          ) : (
            RenderImageQuad(genImages, data.public_url)

            // </Card>
            //</Box>  <CardMedia
            //    className="img-responsive img-thumbnail"
            //    component="img"
            //    image={data.public_url}
            //    alt={data.description}
            //  />
          )}
        </Card>

        <Box
          className="text-center"
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            border: "1px solid #ccc",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <LoadingButton
            sx={{
              width: "100%",
              height: "100%",
              padding: "10px",
              marginBottom: "10px",
            }}
            variant="contained"
            disabled={!acceptTerms || imageGenLoading || !enoughCredits}
            onClick={async () => { handleImageGen() }}
          >
            {enoughCredits
              ? `✨ AI Generate Variations`
              : acceptTerms
              ? `✨ AI Generate Variations - buy more credits`
              : `✨ AI Generate Variations - not enough credits to proceed`}
          </LoadingButton>

           { /* enoughCredits && (
            <div>
              <LoadingButton
                sx={{
                  width: "100%",
                  height: "100%",
                  padding: "10px",
                  marginBottom: "10px",
                }}
                variant="contained"
                disabled={!acceptTerms || imageGenLoading || !enoughCredits}
                onClick={async () =>
                  genImage(
                    data,
                    userTags,
                    randomizeSeed(),
                    includeTagsGen,
                    imageGenStyle
                  )
                }
              >
                `✨ AI Generate Variations - random seed`
              </LoadingButton>
            </div>
          ) */ }

          {/* enoughCredits && (
            <div>
              <LoadingButton
                sx={{
                  width: "100%",
                  height: "100%",
                  padding: "10px",
                  marginBottom: "10px",
                }}
                variant="contained"
                disabled={!acceptTerms || imageGenLoading || !enoughCredits}
                onClick={async () =>
                  genImage(data, userTags, seed, includeTagsGen, imageGenStyle)
                }
              >
                `✨ AI Generate Variations - custom seed`
              </LoadingButton>
            </div>
          ) */}

          {enoughCredits && (
            <>
              <Typography
                variant="body"
                color="text.secondary"
                style={{
                  textAlign: "center",
                  fontFamily: "Raleway",
                  fontWeight: "300",
                  padding: "10px",
                }}
              >
                NOTE: The AI will generate variations of the image based on the
                seed value, style selected and whether tags are included. <br />Results may widely vary.
              </Typography>
            </>
          )}
          {ImageGenOptions()}
          {enoughCredits && inputTermsPrivacy}
          {ShowSubscriptionInfo()}
          {!enoughCredits && RenderCreditsButton()}
        </Box>
        {
          //RenderTagOptions()
        }
      </Box>
    );
  };

  const CropCuts = (data) => {
    var cropCoordinates = { x: 100, y: 100, width: 200, height: 200 };
    //console.log("CropCuts: ", data);
    return !browserName.includes("Safari") ? (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          border: "1px solid #ccc",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CropComponent imgSrc={localImageUrl ?? data.public_url} />
      </Box>
    ) : (
      <div>
        {renderWarningMessage(
          "Image cropping not currently supported on Safari browsers",
          true
        )}
      </div>
    );
  };

  const FormatText = (text) => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          border: "1px solid #ccc",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <Typography
          variant="body"
          color="text.secondary"
          style={{
            textAlign: "center",
          }}
        >
          {text}
        </Typography>
      </Box>
    );
  };

  const getNumCreditsPerStory = (productId, numTokens) => {
    if (productsTextGen) {
      // get product
      let productLocal = productsTextGen.find((p) => p.productId === productId);
      //console.log("productLocal: ", productLocal);
      if (productLocal && productLocal.creditsPrice > 0) {
        numCredits = productLocal.creditsPrice;
      }
    }

    if (numCredits <= 0) {
      var numCredits = 1;
      if (numTokens <= 1) numCredits = 0;
      else if (numTokens <= 128) numCredits = 1;
      else if (numTokens <= 256) numCredits = 2;
      else if (numTokens <= 512) numCredits = 3;
      else if (numTokens <= 768) numCredits = 4;
      else if (numTokens <= 1024) numCredits = 5;
    }
    return numCredits;
  };

  const GenerateStoryButtonOptions = (
    productId,
    numTokens,
    buttonText,
    storyType
  ) => {
    var _textGenPrice = getNumCreditsPerStory(productId, numTokens);
    var enoughCredits =
      getSubscriptionCreditsRemaining() + getWalletCreditsRemaining() >=
      _textGenPrice;

    return (
      <LoadingButton
        sx={{
          width: "100%",
          height: "100%",
          padding: "10px",
          marginTop: "10px",
          marginBottom: "10px",
        }}
        variant="contained"
        disabled={!acceptTerms || storyLoading || !enoughCredits} // || (subscription.subscriptionTierId === "1")}
        onClick={async () =>
          GenerateTextFromImage(
            productId,
            data,
            storyType,
            numTokens,
            includeTagsGen
          )
        }
      >
        ✨{enoughCredits ? buttonText : buttonText + " - need more credits"}
      </LoadingButton>
    );
  };

  const GeneratePostButtonOptions = (
    productId,
    postType,
    numTokens,
    buttonText,
    genType
  ) => {
    var _textGenPrice = getNumCreditsPerStory(productId, numTokens);
    var enoughCredits =
      getSubscriptionCreditsRemaining() + getWalletCreditsRemaining() >=
      _textGenPrice;

    return (
      <LoadingButton
        sx={{
          width: "100%",
          height: "100%",
          padding: "10px",
          marginBottom: "10px",
        }}
        variant="contained"
        disabled={!acceptTerms || storyLoading || !enoughCredits} // || (subscription.subscriptionTierId === "1")}
        onClick={async () =>
          GenerateTextFromImage(
            productId,
            data,
            postType,
            numTokens,
            includeTagsGen
          )
        }
      >
        ✨{enoughCredits ? buttonText : buttonText + " - need more credits"}
      </LoadingButton>
    );
  };

  const textGenerationFiltersPostType = () => {
    var genres = getGenres();
    var themes = getThemes();
    var atmospheric = getAtmospheric();
    var postTypes = getPostTypes();

    return data && data.category ? (
      <div
        className="row"
        style={{
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          marginBottom: "10px",
        }}
      >
        <div className="col-md-4">
          <Stack>
            <Typography
              variant="body"
              color="text.secondary"
              style={{
                textAlign: "center",
                padding: "10px",
              }}
            >
              Type:
            </Typography>
            <Select
              value={selectedTheme1}
              onChange={handleThemeChange1}
              inputProps={{ "aria-label": "Without label" }}
            >
              {postTypes.map((theme, index) => (
                <MenuItem key={index} value={theme}>
                  {theme}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </div>

        <div
          className="col-md-4"
          style={{
            textAlign: "center",
          }}
        >
          <Stack>
            <Typography
              variant="body"
              color="text.secondary"
              style={{
                textAlign: "center",
                padding: "10px",
              }}
            >
              Moods
            </Typography>
            <Select
              value={selectedTheme2}
              onChange={handleThemeChange2}
              renderValue={(value) =>
                value === undefined ? "mood" : `${value}`
              }
              inputProps={{ "aria-label": "Without label" }}
            >
              {themes.map((theme, index) => (
                <MenuItem key={index} value={theme}>
                  {theme}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </div>

        <div
          className="col-md-4"
          style={{
            textAlign: "center",
          }}
        >
          <Stack>
            <Typography
              variant="body"
              color="text.secondary"
              style={{
                textAlign: "center",
                padding: "10px",
              }}
            >
              Atmopshere
            </Typography>
            <Select
              value={selectedTheme3}
              onChange={handleThemeChange3}
              renderValue={(value) =>
                value === undefined ? "atmosphere" : `${value}`
              }
              inputProps={{ "aria-label": "Without label" }}
            >
              {atmospheric.map((theme, index) => (
                <MenuItem key={index} value={theme}>
                  {theme}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </div>
      </div>
    ) : (
      <div></div>
    );
  };

  const getDesignTypes = () => {
    var designTypes = ["Concept","Mood Board","Design Review", "Design Tips", "Design Trends", "Design Inspiration", "Design Guide"];
    return designTypes;
  };

  const getDesignThemes = () => {
    var designThemes = ["Modern", "Retro", "Vintage", "Minimalist", "Futuristic", "Classic", "Elegant", "Luxury", "Industrial", "Scandinavian", 
    "Bohemian", "Eclectic", "Coastal", "Farmhouse", "Rustic", "Traditional", "Transitional", "Contemporary", "Mid-Century Modern", "Shabby Chic", 
    "French Country", "Tropical", "Asian", "Moroccan", "Mediterranean", "Tuscan", "Cottage", "Beach", "Southwestern", "Victorian", "Art Deco", 
    "Gothic", "Baroque", "Rococo", "Renaissance", "Colonial", "Craftsman", "Mission", "Prairie", "Art Nouveau", "Bauhaus", "De Stijl", 
    "International Style", "Postmodern", "Brutalist", "High-Tech", "Organic", "Biophilic", "Sustainable", "Green", "Eco-Friendly", "Smart", 
    "Healthy", "Wellness", "Holistic", "Zen", "Feng Shui", "Vastu"];
    return designThemes;
  }; 
     
  const getCharacterTypes = () => {
    var characterTypes = ["Protagonist", "Antagonist", "Sidekick", "Mentor", "Love Interest", "Family", "Friend", "Enemy", "Hero", "Villain"];
    return characterTypes;
  };

  const getCharacterAtmoshperes = () => { 
    var characterAtmospheres = ["Tragic", "Happy", "Sad", "Angry", "Exciting", "Peaceful", "Mysterious", "Dangerous", "Romantic", "Epic", "Funny", "Heartwarming", "Heartbreaking", "Inspiring", "Motivating", "Challenging", "Frightening", "Thrilling", "Suspenseful", "Intense", "Relaxing", "Soothing", "Uplifting", "Hopeful", "Empowering"];
    return characterAtmospheres.sort();
  };

  const textGenerationFiltersByCategory = () => {
    //var themes = getThemes();
    //var atmospheric = getAtmospheric();
    //var moods = getMoods();

    
    if (data && data.category) {
      var genres = getGenres();
      var themes = getThemes();
      var atmospheric = getAtmospheric();
      var postTypes = getPostTypes();
    
      var theme1Label = "Genres";
      var theme2Label = "Moods";
      var theme3Label = "Atmosphere";

      if (data.category === "Culinary") {
        postTypes = ["Recipe"];
        themes = ["Breakfast", "Lunch", "Dinner", "Dessert"];
        atmospheric = ["Sweet", "Savory", "Spicy", "Sour", "Extra spicy"];
        theme1Label = "Recipe Type";
        theme2Label = "Meal Type";
        theme3Label = "Flavor Profile";
      } else if (data.category === "Characters") {
        postTypes = [
          "Hero's Journey",
          "Backstory",
          "Life Altering Events",
          "Character Traits",
          "Character Arc",
        ];
        themes = getCharacterTypes();
        atmospheric = getCharacterAtmoshperes();
        theme1Label = "Story Type";
        theme2Label = "Story Arc";
        theme3Label = "Atmosphere";
      } else if (data.category === "Fantasy") {
        postTypes = [
          "Fantasy RPG Encounter",
          "Fantasy RPG Quest",
          "Backstory",
          "Battle Scene",
          "Character Arc",
          "Character Design",
          "Story Arc",
          "Village Encounter",
          "Forest Encounter",
          "Desert Encounter",
          "Mountain Encounter",
          "Swamp Encounter",
          "Dungeon Encounter",
          "Castle Encounter",
        ];
        themes = [
          "Forest",
          "Desert",
          "Mountains",
          "Plains",
          "Swamp",
          "Underwater",
          "City",
          "Castle",
          "Dungeon",
          "Cave",
          "Ruins",
          "Temple",
          "Tavern",
          "Inn",
          "Village",
          "Town",
          "Capital",
          "Kingdom",
          "Empire",
          "Continent",
          "World",
          "Plane",
          "Dimension",
        ];
        theme1Label = "Story Type";
        theme2Label = "Story Arc";
        theme3Label = "Atmosphere";
      } else if (data.category === "Cinema") {
        postTypes = [
          "Movie Script Pitch",
          "Movie Scene",
          "Movie Title",
          "Movie Poster",
          "Movie Trailer",
          "Movie Review",
        ];
        themes = [
          "Action",
          "Adventure",
          "Comedy",
          "Drama",
          "Fantasy",
          "Horror",
          "Mystery",
          "Romance",
          "Sci-Fi",
          "Thriller",
        ];
        theme1Label = "Cinematic Type";
        theme2Label = "Genre";
        theme3Label = "Atmosphere";
      } else if (data.category === "Anime") {
        postTypes = [
          "Anime battle scene",
          "Anime character design",
          "Anime character arc",
          "Anime story arc",
          "Anime backstory",
          "Anime trailer",
          "Anime review",
        ];
        themes = [
          "Action",
          "Adventure",
          "Comedy",
          "Drama",
          "Fantasy",
          "Horror",
          "Mystery",
          "Romance",
          "Sci-Fi",
          "Thriller",
        ];
        theme1Label = "Anime Type";
        theme2Label = "Genre";
        theme3Label = "Atmosphere";
       } else if (data.category === "Portraits") {
        postTypes = [
          "Fictional Backstory",
          "Character Arc",
          "Character Design",
          "Character Traits",
          "Most Wanted Profile",
          "Love Letter",   
          "Haiku",
          "Poem",
        ];
        themes = [
          "Action",
          "Adventure",
          "Comedy",
          "Drama",
          "Fantasy",
          "Horror",
          "Mystery",
          "Romance",
          "Sci-Fi",
          "Thriller",          
        ];
        theme1Label = "Post Type";
        theme2Label = "Genre";
        theme3Label = "Atmosphere";
       } else if (data.category === "Science Fiction") {
        postTypes = [
          "SciFi battle story",
          "SciFi character design",
          "SciFi character arc",
          "SciFi story arc",
          "SciFi backstory",
          "SciFi trailer",
          "SciFi review",
        ];
        themes = [
          "Action",
          "Adventure",
          "Comedy",
          "Drama",
          "Fantasy",
          "Horror",
          "Mystery",
          "Romance",
          "Thriller",
        ];
        theme1Label = "SciFi Story";
        theme2Label = "Genre";
        theme3Label = "Atmosphere";
       } else if (data.category === "Interior Design") {
        postTypes = getDesignTypes();
        themes = getDesignThemes();       
      
        theme1Label = "Design Type";
        theme2Label = "Design Theme";
        theme3Label = "Atmosphere";
       } else if (data.category === "Floral Arrangements") {
        postTypes = getDesignTypes();
        themes = getDesignThemes();       
      
        theme1Label = "Design Type";
        theme2Label = "Design Theme";
        theme3Label = "Atmosphere";
       } else if (data.category === "Fashion") {
        postTypes = ["Fashion Design", "Fashion Show", "Fashion Review", "Fashion Trends", "Fashion Inspiration", "Fashion Guide"];
        themes = ["Casual", "Formal", "Business", "Athletic", "Bohemian", "Urban", "Gothic", "Punk", "Retro", "Vintage", "Modern", "Classic", "Elegant", "Luxury", "Streetwear", "Hipster", "Preppy", "Artsy", "Minimalist", "Edgy", "Chic", "Trendy", "Eclectic", "Avant-Garde", "Romantic", "Sexy", "Sophisticated", "Whimsical", "Feminine", "Masculine", "Androgynous", "Unisex",
        "Youthful", "Mature", "Timeless", "Seasonal"];
      
        theme1Label = "Fashion Type";
        theme2Label = "Fashion Theme";
        theme3Label = "Atmosphere";
       } else if (data.category === "Environments") {
        // provide options for different types of environments and landscapes including exoplanet environments and alien landscapes
        postTypes = [
          "Haiku",
          "Poem",
          "Environment Design",
          "Environment Concept",
          "Event Setting",
          "World Building",
          "Planning",
          "Location Review",
          "Location Guide",
          "Location Tips",
          "Historical Event",
          "Future Event",
          "Story of Discovery",
          "Story of Exploration",
          "Story of Adventure",
          "Story of Survival",
          "Story of Mystery",       
        ];

        themes = getGenres();
        theme1Label = "Story Type";
        theme2Label = "Genre";
        theme3Label = "Atmosphere";
      } else {
        postTypes = ["Story", "Poem", "Haiku", "Letter", "Movie Script Pitch"];
        themes = getThemes();
        theme1Label = "Genre";
        theme2Label = "Mood";
        theme3Label = "Atmosphere";
      }

      return (
        <div
          className="row"
          style={{
            color: "black",
            border: "1px solid #183e4b ",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            marginBottom: "10px",
            backgroundColor: !customExpanded ? "#f5f5f5" : "mintcream",
          }}
        >
          <div
            className="col-md-4"
            style={{
              textAlign: "center",
            }}
          >
            <Stack>
              <Typography
                variant="body"
                color="text.primary"
                style={{
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {theme1Label}
              </Typography>
              <Select
                value={selectedTheme1}
                defaultValue={data.category}
                onChange={handleThemeChange1}
                inputProps={{ "aria-label": "Without label" }}
              >
                {postTypes.sort().map((theme, index) => (
                  <MenuItem key={index} value={theme}>
                    {theme}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </div>

          <div
            className="col-md-4"
            style={{
              textAlign: "center",
            }}
          >
            <Stack>
              <Typography
                variant="body"
                color="text.primary"
                style={{
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {theme2Label}
              </Typography>

              <Select
                value={selectedTheme2}
                onChange={handleThemeChange2}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {themes.sort().map((theme, index) => (
                  <MenuItem key={index} value={theme}>
                    {theme}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </div>

          <div
            className="col-md-4"
            style={{
              textAlign: "center",
            }}
          >
            <Stack>
              <Typography
                variant="body"
                color="text.primary"
                style={{
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {theme3Label}
              </Typography>
              <Select
                value={selectedTheme3}
                onChange={handleThemeChange3}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {atmospheric.sort().map((theme, index) => (
                  <MenuItem key={index} value={theme}>
                    {theme}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const textGenerationFiltersStory = () => {
    var themes = getThemes();
    var atmospheric = getAtmospheric();
    var genres = getGenres();
    var moods = getMoods();

    return data && data.category && acceptTerms ? (
      <div className="row">
        <div
          className="col-md-4"
          style={{
            textAlign: "center",
          }}
        >
          <Stack>
            <Typography
              variant="body"
              color="text.primary"
              style={{
                textAlign: "center",
                padding: "10px",
              }}
            >
              Genres
            </Typography>
            <Select
              sx={{
                width: "100%",
                height: "80%",
                backgroundColor: "primary",
                borderRadius: "4px",
                "& .MuiSelect-select": {
                  paddingLeft: "10px",
                },
                "&:hover": {
                  backgroundColor: "primary",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(0, 0, 0, 0.23)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4A90E2",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4A90E2",
                },
              }}
              value={selectedTheme1}
              defaultValue={data.category}
              onChange={handleThemeChange1}
              inputProps={{ "aria-label": "Without label" }}
            >
              {genres.sort().map((theme, index) => (
                <MenuItem key={index} value={theme}>
                  {theme}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </div>

        <div
          className="col-md-4"
          style={{
            textAlign: "center",
          }}
        >
          <Stack>
            <Typography
              variant="body"
              color="text.primary"
              style={{
                textAlign: "center",
                padding: "10px",
              }}
            >
              Moods
            </Typography>

            <Select
              sx={{
                width: "100%",
                height: "80%",
                backgroundColor: "primary",
                borderRadius: "4px",
                "& .MuiSelect-select": {
                  paddingLeft: "10px",
                },
                "&:hover": {
                  backgroundColor: "primary",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(0, 0, 0, 0.23)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4A90E2",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4A90E2",
                },
              }}
              value={selectedTheme2}
              onChange={handleThemeChange2}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {themes.sort().map((theme, index) => (
                <MenuItem key={index} value={theme}>
                  {theme}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </div>

        <div
          className="col-md-4"
          style={{
            textAlign: "center",
          }}
        >
          <Stack>
            <Typography
              variant="body"
              color="text.primary"
              style={{
                textAlign: "center",
                padding: "10px",
              }}
            >
              Atmopshere
            </Typography>
            <Select
              sx={{
                width: "100%",
                height: "80%",
                backgroundColor: "primary",
                borderRadius: "4px",
                "& .MuiSelect-select": {
                  paddingLeft: "10px",
                },
                "&:hover": {
                  backgroundColor: "primary",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(0, 0, 0, 0.23)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4A90E2",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4A90E2",
                },
              }}
              value={selectedTheme3}
              onChange={handleThemeChange3}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {atmospheric.sort().map((theme, index) => (
                <MenuItem key={index} value={theme}>
                  {theme}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </div>
      </div>
    ) : (
      <div></div>
    );
  };

  const showFantasyOptions = () => {
    return (
      <Box
        sx={{
          paddingBottom: "10px",
        }}
      >
        {GeneratePostButtonOptions(
          27,
          "a fantasy story",
          1024,
          "Generate a Fantasy Story",
          "Long Fantasy Story"
        )}
      </Box>
    );
  };

  const showTextGenOptions = () => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        {inputTermsPrivacy}
      </Box>
    );
  };

  function hasCustomTextGenOptions() {
    return (
      data.category === "Culinary" ||
      data.category === "Characters" ||
      data.category === "Fantasy" ||
      data.category === "Cinema" ||
      data.category === "Anime" ||
      data.category === "Portraits" ||
      data.category === "Science Fiction" ||
      data.category === "Interior Design" ||
      data.category === "Fashion" ||
      data.category === "Floral Arrangements" ||
      data.category === "Comics" ||
      data.category === "Environments" 
    );
  }

  const showCategoryOptions = () => {
    if (data === null) return <></>;

    var showRecipes = (data.category === "Culinary" ? true : false) || false;
    var showBackstory =
      (data.category === "Characters" ? true : false) || false;
    var showMovieScript = (data.category === "Cinema" ? true : false) || false;
    var showAnime = (data.category === "Anime" ? true : false) || false;
    var showFantasy = (data.category === "Fantasy" ? true : false) || false;
    var showPortraits = (data.category === "Portraits" ? true : false) || false;
    var showSciFi = (data.category === "Science Fiction" ? true : false) || false;
    var showInteriorDesign = (data.category === "Interior Design" ? true : false) || false;
    var showFashion = (data.category === "Fashion" ? true : false) || false;
    var showFloral = (data.category === "Floral Arrangements" ? true : false) || false;
    var showComics = (data.category === "Comics" ? true : false) || false;
    var showEnvironments = (data.category === "Environments" ? true : false) || false;

    return (
      <Box>
        {showAnime ||
        showBackstory ||
        showFantasy ||
        showMovieScript ||
        showPortraits ||
        showSciFi ||
        showInteriorDesign ||
        showFashion ||
        showFloral ||
        showComics ||
        showEnvironments ||
        showRecipes ? (
          textGenerationFiltersByCategory()
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const showCategoryButtons = () => {
    if (data === null) return <></>;

    var showRecipes = (data.category === "Culinary" ? true : false) || false;
    var showBackstory =
      (data.category === "Characters" ? true : false) || false;
    var showMovieScript = (data.category === "Cinema" ? true : false) || false;
    var showAnime = (data.category === "Anime" ? true : false) || false;
    var showFantasy = (data.category === "Fantasy" ? true : false) || false;
    var showPortraits = (data.category === "Portraits" ? true : false) || false;
    var showSciFi = (data.category === "Science Fiction" ? true : false) || false;
    var showInteriorDesign = (data.category === "Interior Design" ? true : false) || false;
    var showFashion = (data.category === "Fashion" ? true : false) || false;
    var showFloral = (data.category === "Floral Arrangements" ? true : false) || false;
    var showComics = (data.category === "Comics" ? true : false) || false;
    var showEnvironments = (data.category === "Environments" ? true : false) || false;

    return (
      <Box
        sx={{
          padding: "10px",
        }}
      >
        {showAnime ? (
          GeneratePostButtonOptions(
            23,
            "an anime themed",
            784,
            "Generate an Anime themed story",
            "Anime Story"
          )
        ) : (
          <></>
        )}
        {showRecipes ? (
          GeneratePostButtonOptions(
            27,
            "a recipe",
            1024,
            "Generate a recipe",
            "Recipe"
          )
        ) : (
          <></>
        )}
        {showBackstory ? (
          GeneratePostButtonOptions(
            27,
            "a character backstory",
            1024,
            "Generate a character backstory",
            "Character Backstory"
          )
        ) : (
          <></>
        )}
        {showPortraits ? (
          GeneratePostButtonOptions(
            23,
            "a character portrait",
            784,
            "Generate a character story or poem",
            "Character Portrait"
          )
        ) : (
          <></>
        )}
        {showSciFi ? (
          GeneratePostButtonOptions(
            27,
            "a scifi story",
            1024,
            "Generate a SciFi Story",
            "SciFi Story"
          )
        ) : (
          <></>
        )}
        {showInteriorDesign ? (
          GeneratePostButtonOptions(
            23,
            "an interior design",
            784,
            "Generate an Interior Design Narrative ",
            "Interior Design Story"
          )
        ) : (
          <></>
        )}
        {showFashion ? (
          GeneratePostButtonOptions(
            23,
            "a fashion design",
            784,
            "Generate a Fashion Design Narrative",
            "Fashion Design Story"
          )
        ) : (
          <></>
        )}
        {showFloral ? (
          GeneratePostButtonOptions(
            23,
            "a floral arrangement",
            784,
            "Generate a Floral Arrangement Narrative",
            "Floral Arrangement Story"
          )
        ) : (
          <></>
        )}
        {showComics ? (
          GeneratePostButtonOptions(
            23,
            "a comics",
            784,
            "Generate a Comics themed Story",
            "Comics Story"
          )
        ) : (
          <></>
        )}
        {showEnvironments ? (
          GeneratePostButtonOptions(
            23,
            "an environment",
            784,
            "Generate an Environment Narrative",
            "Environment Story"
          )
        ) : (
          <></>
        )}

        {showFantasy ? showFantasyOptions() : <></>}

        {showMovieScript ? (
          GeneratePostButtonOptions(
            21,
            "cinematic movie",
            1024,
            "Generate a Cinematic Treatment"
          )
        ) : (
          <></>
        )}
        { /*showMovieScript ? (
          GeneratePostButtonOptions(
            23,
            "cinematic movie",
            1024,
            "Medium Length",
            "Movie Script"
          )
        ) : (
          <></>
        )}
        {showMovieScript ? (
          GeneratePostButtonOptions(
            27,
            "cinematic movie",
            1024,
            "Long Length",
            "Long Movie Script"
          )
        ) : (
          <></>
        )*/}
      </Box>
    );
  };

  const showStoryOptions = (pid, tok) => {
    return data && acceptTerms ? (
      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: !storyExpanded ? "#f5f5f5" : "mintcream",
          borderRadius: "10px",
          marginTop: "20px",
          marginBottom: "20px",
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          border: "1px solid #ddd",
          width: "100%",
          marginLeft: "0px",
        }}
      >
        {textGenerationFiltersStory()}

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            width: "100%",
            height: "100%",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            textAlign: "center",
          }}
        >
          {!acceptTerms ? null : (
            <Chip
              label={
                <Typography>
                  {getNumCreditsPerStory(pid, tok)} credits
                </Typography>
              }
            />
          )}

          {inputIncludeTags}
        </Box>

        <Typography
          variant="body2"
          color="text.secondary"
          style={{
            textAlign: "center",
            fontFamily: "Raleway",
            fontWeight: "300",
            fontStyle: "italic",
            padding: "10px",
          }}
        >
          NOTE: Genres, moods and atmosphere are optional and can be left blank.{" "}
          <br />
          If selected they will help guide the AI in generating the story.
          </Typography>
          <br /><br />
          <Typography
          variant="body2"
          color="text.secondary"
          style={{
            textAlign: "center",
            fontFamily: "Raleway",
            fontWeight: "500",
            fontStyle: "italic",
            padding: "10px",
          }}
        >
          Expand Story <IconButton
                title="Copy Story to Clipboard"
                className="fas fa-copy"
                onClick={() => {
                  navigator.clipboard.writeText(story.generatedText);
                }}
              /> section (below) to view your generated stories.
          </Typography>
      </div>
    ) : (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {storyLoading ? (
          <Typography
            variant="h4"
            color="text.secondary"
            style={{
              textAlign: "center",
              width: "100%",
              padding: "10px",
            }}
          >
            Loading...
          </Typography>
        ) : null}
      </Box>
    );
  };

  const PreviousTextGenerations = () => {
    return textGens ? (
      textGens.map((textGen, index) => {
        // console.log("textGen: " + JSON.stringify(textGen));
        return (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "#eee",
                border: "1px solid #ccc",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                fontFamily: "Raleway",
              }}
            >
              {new Date(textGen.generated_timestamp).toLocaleString()}{" "}
              &nbsp;|&nbsp;
              {textGen.textgen_type} &nbsp; | &nbsp;
              {textGen.tags.length > 0 ? textGen.tags[0] : ""} &nbsp; | &nbsp;
              {textGen.tags.length > 1 ? textGen.tags[1] : ""} &nbsp; | &nbsp;
              {textGen.tags.length > 2 ? textGen.tags[2] : ""}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "#eee",
                border: "1px solid #ccc",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                fontFamily: "Raleway",
              }}
            >
              {textGen.text}
            </AccordionDetails>
          </Accordion>
        );
      })
    ) : (
      <Box></Box>
    );
  };

  const ShowGeneratedTextSection = () => {
    return data ? (
      <div className="storyGen">
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            border: "1px solid #ccc",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Backdrop open={storyLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                // if story loading then style differently
                width: "100%",
                height: "100%",
                //backgroundColor: "#eee",
                border: "1px solid #ccc",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                fontFamily: "Raleway",               
                backgroundColor: !storyLoading ? "#f5f5f5" : "mintcream",

              }}
            >
              <IconButton
                title="Copy Story to Clipboard"
                className="fas fa-copy"
                onClick={() => {
                  navigator.clipboard.writeText(story.generatedText);
                }}
              />

              <Typography
                variant="h5"
                color="text.secondary"
                style={{
                  textAlign: "center",
                  width: "100%",
                  padding: "10px",
                  fontFamily: "Raleway",
                  fontWeight: "400",
                }}
              >
                {story
                  ? storyLoading
                    ? "Generating New..."
                    : "Latest Story (click to expand)"
                  : textGens.length === 0
                  ? storyLoading
                    ? "Generating New..."
                    : "No Stories"
                  : "Prior Stories (click to expand)"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "#eee",
                border: "1px solid #ccc",
                alignContent: "left",
                alignItems: "left",
                justifyContent: "left",
                padding: "10px",
                fontFamily: "Raleway",
              }}
            >
              <Typography
                variant="body"
                color="text.secondary"
                style={{
                  textAlign: "left",
                }}
              >
                {
                  // parse out the story into paragraphs and display
                  story.generatedText
                    ? story.generatedText.split("\n").map((i, key) => {
                        return (
                          <Typography
                            variant="body"
                            color="text.secondary"
                            style={{
                              textAlign: "left",
                              padding: "10px",
                            }}
                          >
                            {i}
                            <br />
                          </Typography>
                        );
                      })
                    : story.message
                }
              </Typography>
              {PreviousTextGenerations()}
            </AccordionDetails>
          </Accordion>
        </Box>
      </div>
    ) : (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography
          variant="h4"
          color="text.secondary"
          style={{
            textAlign: "center",
            width: "100%",
            padding: "10px",
          }}
        >
          Loading...
        </Typography>
      </Box>
    );
  };

  const TextGenStory = () => {
    let _productId = 27;
    let _productsTextGenPrice = getTextGenPrice();
    let _numTokens = 1024;

    var enoughCredits =
      getSubscriptionCreditsRemaining() + getWalletCreditsRemaining() >=
      _productsTextGenPrice;

    return data ? (
      <div>
        <Accordion expanded={storyExpanded}>
          <AccordionSummary
            onClick={() => setStoryExpanded(!storyExpanded)}
            expandIcon={
              <ExpandMoreIcon
                onClick={() => setStoryExpanded(!storyExpanded)}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              width: "100%",
              height: "100%",
             
              padding: "10px",
              fontSize: "1.2rem",
              fontFamily: "Raleway",
              backgroundColor: !storyExpanded ? "#f5f5f5" : "#8BA0A4",
            }}
          >
            <Typography
              variant="h5"
              color="text.secondary"
              style={{

                width: "100%",
                padding: "10px",
                fontFamily: "Raleway",
                fontWeight: "600",
              }}
            >
            ✨ AI Generate a Story{" "}
            </Typography>
            {
              //(storyExpanded ? " - Click to Collapse" : " - Click to Expand")
            }
          </AccordionSummary>

          <AccordionDetails
            sx={{
              backgroundColor: !storyExpanded ? "#f5f5f5" : "#8BA0A4",
            }}
          >
            <Box text="center">
              {SetupStoryBox(data.title, data.public_url_thumbnail)}
              {GenerateStoryButtonOptions(
                _productId,
                _numTokens,
                acceptTerms
                  ? "Generate a story"
                  : "Generate - agree to terms to proceed",
                "Long Story"
              )}

              {showStoryOptions(_productId, _numTokens)}
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>
    ) : (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography
          variant="h4"
          color="text.secondary"
          style={{
            textAlign: "center",
            width: "100%",
            padding: "10px",
          }}
        >
          Loading...
        </Typography>
      </Box>
    );
  };

  const TextGenPoems = () => {
    return data ? (
      <Accordion expanded={poemExpanded}>
        <AccordionSummary
          onClick={() => setPoemExpanded(!poemExpanded)}
          expandIcon={
            <ExpandMoreIcon onClick={() => setPoemExpanded(!poemExpanded)} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Prompts, Poems & Letters
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
            <Box text="center">
              <Stack
                direction="row"
                justifyContent="center"
                gap="24px"
                flexWrap="nowrap"
              >
                <Box>{PromptsPoems()}</Box>
              </Stack>
            </Box>
          </div>
        </AccordionDetails>
      </Accordion>
    ) : (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography
          variant="h4"
          color="text.secondary"
          style={{
            textAlign: "center",
            width: "100%",
            padding: "10px",
          }}
        >
          Loading...
        </Typography>
      </Box>
    );
  };

  const TextGenCustom = () => {
    return data ? (
      <Accordion
        expanded={customExpanded}
        disabled={!hasCustomTextGenOptions()}
      >
        <AccordionSummary
          onClick={() => setCustomExpanded(!customExpanded)}
          expandIcon={
            <ExpandMoreIcon
              onClick={() => setCustomExpanded(!customExpanded)}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            width: "100%",
            height: "100%",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            fontSize: "1.2rem",
            fontFamily: "Raleway",
            backgroundColor: !customExpanded ? "#f5f5f5" : "#8BA0A4",
          }}
        >
          ✨ AI Generate - '{data.category}' Category Options{" "}
          {!hasCustomTextGenOptions() ? " (None)" : ""}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: !customExpanded ? "#f5f5f5" : "#8BA0A4",
          }}
        >
          <div className="row">
            <Box text="center">
              {showCategoryButtons()}
              {showCategoryOptions()}
            </Box>
          </div>
        </AccordionDetails>
      </Accordion>
    ) : (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography
          variant="h4"
          color="text.secondary"
          style={{
            textAlign: "center",
            width: "100%",
            padding: "10px",
          }}
        >
          Loading...
        </Typography>
      </Box>
    );
  };

  const TabTextGen = () => {
    var enoughCredits =
      getSubscriptionCreditsRemaining() + getWalletCreditsRemaining() >=
      productsImageGenPrice;
    // setup a grid for the story options and themes
    return data ? (
      <div className="story">
        {TextGenStory()}
        {
          //TextGenPoems()
        }
        {TextGenCustom()}
        {enoughCredits && inputTermsPrivacy}
        {ShowGeneratedTextSection()}
        {ShowSubscriptionInfo()}
        {!enoughCredits && RenderCreditsButton()}
      </div>
    ) : (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );
  };

  const PromptsPoems = () => {
    // setup a grid for the story options and themes
    return data ? (
      <div className="story">
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            border: "1px solid #ccc",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          {
            // setup a grid for the story options and themes, should look cool and slick with shadowed borders using theme colors
          }

          {textGenerationFiltersPostType()}

          {GeneratePostButtonOptions(
            19,
            "an AI image generation prompt",
            128,
            "Generate an AI Image Generation Prompt",
            "AI Image Generation Prompt"
          )}

          {GeneratePostButtonOptions(
            21,
            "poem",
            256,
            "Generate a Poem",
            "Poem"
          )}

          {GeneratePostButtonOptions(
            21,
            "Haiku poem",
            256,
            "Generate a Haiku",
            "Haiku Poem"
          )}
          {GeneratePostButtonOptions(
            23,
            "a letter",
            512,
            "Generate a Letter",
            "Letter"
          )}
        </Box>
      </div>
    ) : (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );
  };

  const browserDetailsLog = () => {
    // console.log("Browser: " + browserName);
    // console.log("Browser Version: " + browserVersion);
    // console.log("Operating System: " + osName);
    // console.log("OS Version: " + osVersion);
    // console.log("Device Type: " + deviceType);
    // console.log("Mobile Vendor: " + mobileVendor);
    // console.log("Mobile Model: " + mobileModel);
    // console.log("User Agent: " + getUA);
    // console.log("Is Desktop: " + isDesktop);
    // console.log("Is Mobile: " + isMobile);
    // console.log("Is Tablet: " + isTablet);
  };

  const browserDetails = () => {
    return (
      <Paper
        elevation={3}
        style={{
          padding: "10px",
          marginTop: "10px",
          marginBottom: "10px",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Typography>Browser: {browserName}</Typography>
        <Typography>Browser Version: {browserVersion}</Typography>
        <Typography>Operating System: {osName}</Typography>
        <Typography>OS Version: {osVersion}</Typography>
        <Typography>Device Type: {deviceType}</Typography>
        <Typography>Mobile Vendor: {mobileVendor}</Typography>
        <Typography>Mobile Model: {mobileModel}</Typography>
        <Typography>User Agent: {getUA}</Typography>
        <Typography>Is Desktop: {isDesktop ? "Yes" : "No"}</Typography>
        <Typography>Is Mobile: {isMobile ? "Yes" : "No"}</Typography>
        <Typography>Is Tablet: {isTablet ? "Yes" : "No"}</Typography>
      </Paper>
    );
  };

  function renderWarningMessage(message, includeBrowserDetails) {
    return (
      <div className="row">
        <div className="col-md-12">
          <div
            className="alert alert-warning"
            role="alert"
            style={{
              padding: "10px",
              marginTop: "10px",
              marginBottom: "10px",
              backgroundColor: "#f5f5f5",
            }}
          >
            {message}
            {
              //(includeBrowserDetails === true ? browserDetails() : <></>)
            }
          </div>
        </div>
      </div>
    );
  }

  const StudioCard = (
    <div
      className="custom__card"
      style={{
        backgroundColor: "white",
        border: "1px solid #ccc",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        width: "100%",
        maxWidth: data?.width ? data.width : "50%",
        minWidth: "400px !important",
        position: "relative",
        top: "10",
      }}
    >
      <div className="leftbadge">✨ STUDIO ✨</div>
      <div className="badge">✨BETA✨</div>

      {browserName.includes("Safari") ? RenderTabsSafari() : RenderTabs()}

      <div className="main__body">
        {// console.log("browserDetails: " + browserDetailsLog())
        }
        {currentTab === 0 ? EffectFilters(data) : <></>}
        {currentTab === 1 ? DuoToneFilters(data) : <></>}
        {currentTab === 2 ? CropCuts(data) : <></>}
        {currentTab === 3 ? VaryStyles(data) : <></>}
        {currentTab === 4 ? TabTextGen() : <></>}
        {
          //currentTab === 5 ? VaryStyles(data) : <></>
        }
      </div>
    </div>
  );

  return (
    <>
      {data ? (
        <div
          style={{
            backgroundColor: "#f5f5f5",
            padding: "10px",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",

              backgroundColor: "#f5f5f5",
              padding: "10px",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            <div
              className="col-md-6 "
              style={{
                textAlign: "center",
                marginBottom: "20px",
                maxWidth: "600px",
              }}
            >
              {ImageCard}
            </div>
            <div
              className="col-md-6"
              style={{
                textAlign: "center",
                maxWidth: "600px",
              }}
            >
              {StudioCard}
            </div>
          </div>

          <div className="bg-banner my-3 px-4 text-center ">
            <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
              <p className="mb-0 me-3 size-options-text">Want more credits?</p>
              <Button
                className="rounded-pill"
                variant="contained"
                onClick={() => {
                  navigate("/credits");
                }}
              >
                View Credit Packs
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
}
