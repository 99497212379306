import React, { useState, useRef } from "react";

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    convertToPixelCrop,
} from "react-image-crop";
import canvasPreview from "./canvasPreview";
import useDebounceEffect from "./useDebounceEffect";

import "react-image-crop/dist/ReactCrop.css";
import "./CropComponent.css"
import GalleryTile from "../../galleryPackTiles/GalleryTile";
import {LoadingButton} from "@mui/lab";
import {Box, Button, Stack} from "@mui/material";

// This is to demonstrate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: "%",
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    );
}

const CropComponent = ({imgSrc}) => {

    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);
    const hiddenAnchorRef = useRef(null);
    const blobUrlRef = useRef("");
    const [crop, setCrop] = useState({unit: '%', width: 30, aspect: 16 / 9});
    const [completedCrop, setCompletedCrop] = useState();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [aspect, setAspect] = useState(16 / 9);

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }

    React.useEffect(() => {
        setAspect(16 / 9);
        setTimeout(() => {
            setAspect(undefined);
            if (imgRef.current) {
                const { width, height } = imgRef.current;
                const newCrop = centerAspectCrop(width, height, 16 / 9);
                setCrop(newCrop);
                // Updates the preview
                setCompletedCrop(convertToPixelCrop(newCrop, width, height));
            }
        }, 300);
    }, []);

    async function onDownloadCropClick() {
        const image = imgRef.current;
        const previewCanvas = previewCanvasRef.current;

        if (!image || !previewCanvas || !completedCrop) {
            throw new Error("Crop canvas does not exist");
        }

        // This will size relative to the uploaded image
        // size. If you want to size according to what they
        // are looking at on screen, remove scaleX + scaleY
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const offscreen = new OffscreenCanvas(
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
        );
        const ctx = offscreen.getContext("2d");
        if (!ctx) {
            throw new Error("No 2d context");
        }

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height,
        );

        // You might want { type: "image/jpeg", quality: <0 to 1> } to
        // reduce image size
        const blob = await offscreen.convertToBlob({
            type: "image/png",
        });

        if (blobUrlRef.current) {
            URL.revokeObjectURL(blobUrlRef.current);
        }
        blobUrlRef.current = URL.createObjectURL(blob);
        hiddenAnchorRef.current.href = blobUrlRef.current;
        hiddenAnchorRef.current.click();
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                await canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                );
            }
        },
        100,
        [completedCrop],
    );

    function handleToggleAspectClick() {
        if (aspect) {
            setAspect(undefined);
        } else {
            setAspect(16 / 9);

            if (imgRef.current) {
                const { width, height } = imgRef.current;
                const newCrop = centerAspectCrop(width, height, 16 / 9);
                setCrop(newCrop);
                // Updates the preview
                setCompletedCrop(convertToPixelCrop(newCrop, width, height));
            }
        }
    }

    return (
        <div>
            {/* <Box className="Crop-Controls" sx={{
                padding: "20px"
            }}>
                <div>
                    <label htmlFor="scale-input">Scale: </label>
                    <input
                        id="scale-input"
                        min={0.1}
                        type="range"
                        step="0.1"
                        value={scale}
                        disabled={!imgSrc}
                        onChange={(e) => setScale(Number(e.target.value))}
                    />
                </div>
                <Box sx={{
                    marginTop: "20px"
                }}>
                    <label htmlFor="rotate-input">Rotate: </label>
                    <input
                        id="rotate-input"
                        min={0.1}
                        max={180}
                        type="range"
                        value={rotate}
                        disabled={!imgSrc}
                        onChange={(e) =>
                            setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
                        }
                    />
                </Box>
                <Box sx={{
                    marginTop: "20px"
                }}>
                    <Button variant="contained" onClick={handleToggleAspectClick}>
                        Toggle aspect {aspect ? "off" : "on"}
                    </Button>
                </Box>
            </Box> */}
            {!!imgSrc && (
                <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                    minWidth={100}
                    minHeight={100}
                    // circularCrop
                >
                    <img
                        ref={imgRef}
                        alt="Crop me"
                        src={imgSrc}
                        // crossOrigin="anonymous"
                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                        onLoad={onImageLoad}
                    />
                </ReactCrop>
            )}
            {!!completedCrop && (
                <Stack gap="32px" pt="25px" pb="25px" justifyContent="center" alignItems="center">
                    <Box sx={{ height: '1px', width: '80%', background: "#B8B8B8" }} />
                    <Stack justifyContent="center" direction="row">
                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                // border: "1px solid black",
                                objectFit: "contain",
                                width: completedCrop.width,
                                height: completedCrop.height,
                            }}
                        />
                    </Stack>
                    <Stack direction="row" justifyContent="center" width="100%" px="33px">
                        {/*
                        <LoadingButton
                            className="rounded-pill"
                            variant="contained"
                            sx={{
                                textTransform: "none",
                                fontSize: "1rem",
                            }}
                        >
                            Copy Cropped Image
                        </LoadingButton>
                        */ }
                        <LoadingButton
                            className="rounded-pill"
                            variant="contained"
                            onClick={async () => {
                                await onDownloadCropClick();
                            }}
                            sx={{
                                textTransform: "none",
                                fontSize: "1rem",
                            }}
                        >
                            Download Cropped Image
                        </LoadingButton>
                        <a
                            href="#hidden"
                            ref={hiddenAnchorRef}
                            download
                            style={{
                                position: "absolute",
                                top: "-200vh",
                                visibility: "hidden",
                            }}
                        >
                            Hidden download
                        </a>
                    </Stack>
                </Stack>
            )}
        </div>
    );
}

export default CropComponent;