import React from 'react';


import {
    Button,

    Slider
} from "@mui/material";


export function SingleColorPicker({value, onChange}) {
    const handleSliderChange = (event, newValue) => {
        onChange(newValue);
    };

    return (
        <div>
            <Slider
                value={value}
                onChange={handleSliderChange}
                min={0}
                max={255}
                step={1}
                aria-labelledby="rgb-slider"
            />
        </div>
    );
}

export function EffectPercentPicker({value, onChange}) {
    const handleSliderChange = (event, newValue) => {
        onChange(newValue);
    };

    return (
        <div>
            <Slider
                value={value}
                onChange={handleSliderChange}
                min={0}
                max={100}
                marks
                defaultValue={1}
                step={1}
                aria-labelledby="effect-slider"
                valueLabelDisplay="auto"
            />
        </div>
    );
}

// RGB Color Picker
export function RGBColorPicker({value, onChange}) {
    const handleSliderChange = (event, newValue) => {
        console.log("SLIDER: e(" + JSON.stringify(event) + ") nv(" + newValue + ")");
        onChange(newValue);
    };

    // break value down into red, green, blue components
    const red = value[0];
    const green = value[1];
    const blue = value[2];
    console.log("RGB: " + red + ", " + green + ", " + blue);

    return (
        <div>
            <Slider
                value={red}
                onChange={handleSliderChange}
                min={0}
                max={255}
                step={1}
                aria-labelledby="rgb-slider"
            />
            <Slider
                value={green}
                onChange={handleSliderChange}
                min={0}
                max={255}
                step={1}
                aria-labelledby="rgb-slider"
            />
            <Slider
                value={blue}
                onChange={handleSliderChange}
                min={0}
                max={255}
                step={1}
                aria-labelledby="rgb-slider"
            />
        </div>
    );
}

// Other Color Picker
export function OtherColorPicker({value, onChange}) {
    // Add your custom logic for the other color picker here
    // You can use the Slider component or any other UI component of your choice

    return (
        <div>
            {/* Add your UI components here */}
        </div>
    );
}

// show a sample usage of the color picker
export function ColorPickerSample() {
    const [color, setColor] = React.useState([0, 0, 0]);

    return (
        <div>
            <RGBColorPicker value={color} onChange={setColor}/>
            <OtherColorPicker value={color} onChange={setColor}/>
        </div>
    );
}

export function setAllColors() {
    return [
        {
            id: 2,
            backgroundColor: "#96B97B",
            textColor: "#2E2E2E",
            text: "Sage Green"
        },
        {
            id: 3,
            backgroundColor: "#DDC9FF",
            textColor: "#2E2E2E",
            text: "Lavender"
        },
        {
            id: 4,
            backgroundColor: "#FFDA57",
            textColor: "#2E2E2E",
            text: "Yellow"
        },
        {
            id: 5,
            backgroundColor: "#FF78A9",
            textColor: "#2E2E2E",
            text: "Pink"
        },
        {
            id: 6,
            backgroundColor: "#81D1FF",
            textColor: "#2E2E2E",
            text: "Sky Blue"
        },
        {
            id: 7,
            backgroundColor: "#898989",
            textColor: "#2E2E2E",
            text: "Gray"
        }
    ]
}


