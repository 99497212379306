import React, {useState, useEffect} from "react";

import "./SignupComplete.scss";
import {useEffectOnce} from "../../utils/useEffectOnce";
import {useNavigate} from "react-router-dom";

export default function SignupComplete() {
    const navigate = useNavigate();

    useEffectOnce(() => {
        setTimeout(() => {
            navigate("/profile/edit?tab=settings")
        }, 5000)
    }, [])

    return (
        <div className="complete__page">
            <div className="icon">
                <svg width="200" height="200" viewBox="0 0 284 284" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M276.006 129.751V142.08C275.989 170.976 266.632 199.093 249.33 222.238C232.028 245.382 207.708 262.313 179.997 270.506C152.287 278.7 122.67 277.716 95.5637 267.702C68.4578 257.687 45.3152 239.179 29.5873 214.938C13.8595 190.696 6.38917 162.02 8.29048 133.186C10.1918 104.352 21.3629 76.9049 40.1376 54.9385C58.9124 32.972 84.2849 17.663 112.471 11.2947C140.657 4.92642 170.147 7.84001 196.542 19.6009"
                        stroke="#55828B" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M276.005 34.8779L142.003 169.015L101.802 128.814" stroke="#55828B" strokeWidth="15"
                          strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <div className="text__content">
                <h3 className="main__title">✨Thank you for your purchase!</h3>
                <h3 className="main__title__2">Welcome to <strong>SmartAI</strong> Stock Images✨</h3>
                <p className="info__text">You will now be redirected to your profile</p>
            </div>

            <div className="buttons">
                <a href="/">Get Started</a>
            </div>
        </div>
    );
}