import React, { useState, useEffect } from "react";
import MembershipTab from "./membership";
import SettingsTab from "./settings";
import { useSearchParams } from "react-router-dom";

function ProfileEdit() {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.has("tab")? searchParams.get("tab"): "settings"
  // console.log(initialTab);
  const [activeTab, setActiveTab] = useState(searchParams.get("initialTab"));

  // Functions to handle Tab Switching
  const handleTab1 = () => {
    // update the state to tab1
    setActiveTab("settings");
    setSearchParams({tab: "settings"});
  };
  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab("membership");
    setSearchParams({tab: "membership"});
  };

  return (
    <div className="container my-3">
      <div className="row my-4">
        <div className="col d-flex justify-content-center">
          <button
            type="button"
            className={`tab-button me-3 ${
              searchParams.get("tab") == "settings" ? "active" : ""
            }`}
            onClick={handleTab1}
          >
            Profile
          </button>
          <button
            type="button"
            className={`tab-button me-3 ${
              searchParams.get("tab") == "membership" ? "active" : ""
            }`}
            onClick={handleTab2}
          >
            Membership
          </button>
        </div>
      </div>
      <div className="container">
        {searchParams.get("tab") === "membership" ? <MembershipTab /> : <SettingsTab />}
      </div>
    </div>
  );
}

export default ProfileEdit;
