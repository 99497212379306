/* eslint-disable react/function-component-definition */

import * as React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import UserService from "../../services/user.service";
import { Box, FormHelperText } from "@mui/material";
import { FormLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../services/auth/use-auth";

const ResetPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [emailValid, setEmailValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState(false);

  const onSetEmail = (email) => {
    setEmail(email);
    var emailValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/.test(email);
    setEmailValid(emailValid);
    if (emailValid) {
      setError(false);
      setMessage("");
    } else if (email.length > 4) {
      setMessage("Please enter a valid email address.");
    }
  };

  const handleReset = async () => {
    setLoading(true);
    if (email) {
      try {
        const response = await auth.sendPasswordResetEmail(email);
        if (response) {
          alert("Reset password request sent to: " + email);
          setLoading(false);
          navigate("/login");
          // console.log("reset password request sent. navigating to home");
        } else {
          alert("Reset password request failed. Please try again.");
          setLoading(false);
        }
      } catch (error) {
        if (error.code) {
          if (error.code === "auth/user-not-found") {
            setMessage(
              "We can't find a user with that email. Please try again."
            );
            setError(true);
          } else {
            setMessage("Something went wrong. Please try again.");
            setError(true);
          }
          setLoading(false);
        }
      }
    } else {
      alert("Please enter email address.");
      setLoading(false);
    }
  };

  React.useEffect(() => {
    //if (auth.user) {
    // navigate('/profile');
    //}
  });
  return (
    <main className="fill d-flex align-items-center">
      <Paper
        sx={{
          width: 400,
          mx: "auto", // margin left & right
          my: 4, // margin top & botom
          py: 3, // padding top & bottom
          px: 2, // padding left & right
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRadius: "sm",
          boxShadow: "md",
        }}
      >
        <div>
          <Typography level="h4" component="h1">
            <b>SmartAI Account</b>
          </Typography>
        </div>
        <FormLabel
          sx={(theme) => ({
            "--FormLabel-color": "#1d74a4a",
          })}
        >
          Send Password Reset Email
        </FormLabel>
        <TextField
          sx={{ "--Input-decorator-childHeight": "45px" }}
          name="email"
          type="email"
          placeholder="Email"
          onChange={(e) => onSetEmail(e.target.value)}
          // pass down to FormLabel as children
          label="Email"
        />
        <Button
          variant="contained"
          loading={loading}
          disabled={!emailValid}
          onClick={handleReset}
          type="submit"
        >
          Send Email
        </Button>
        {!error || message.length > 0 || !emailValid ? (
          <FormHelperText
            sx={(theme) => ({
              mx: "auto",
            })}
          >
            {message}
          </FormHelperText>
        ) : (
          <></>
        )}
        <Typography
          fontSize="sm"
          sx={{ alignSelf: "center", fontStyle: "italic" }}
        >
          Check your SPAM folder if you don't see the email in your inbox.
        </Typography>
        <Box sx={{ alignSelf: "center" }}>
          <Link to="/login">Back to Login</Link>
        </Box>
      </Paper>
    </main>
  );
};

export default ResetPage;
