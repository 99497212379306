import React, { useState, useEffect } from "react";
//import MultiDropdown from "../../components/multi-dropdown/MultiDropdown";
import Tiles from "../../components/Tiles/Tiles2";
import ImageService from "../../services/image.service";
import ListService from "../../services/list.service";
import {
  Link,
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import Searchbar from "../../components/searchbar/searchbar";
import { useAuth } from "../../services/auth/use-auth";
import UserService from "../../services/user.service";
import filterProfanity from "../../services/text.service";  
import useGoogleOneTap from "../../hooks/useGoogleOneTap";
import { GoogleAuthProvider } from "firebase/auth";
import "./Home.css";
import {
  Button,
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  ImageListItemBar,
  IconButton,
  ImageList,
  ImageListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  Select,
  useMediaQuery,
} from "@mui/material";
import { 
  Check, 
} from "@mui/icons-material";

export default function Home() {
  const auth = useAuth();
  //const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [page, setPage] = useState(0);
  //const [filter, setFilter] = useState("recent");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get("q") || "");
  const [authLoading, setAuthLoading] = useState(false);
  const [trendingSearches, setTrendingSearches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [favorites, setFavorites] = useState([]); // [image_id, ...
  const [showMore, setShowMore] = useState(true);
  const [showQuickActions, setShowQuickActions] = useState(true);
  
  const [props, setProps] = useState({
    noPerRow: 4,
    gap: 14,
    showQuickActions: showQuickActions,
  });
  const [props2, setProps2] = useState({
    noPerRow: 4,
    gap: 0,
  });

  useEffect(() => {
    setProps({ ...props, showQuickActions: showQuickActions });
  }, [props, showQuickActions]);
  

  // determine different screen sizes using a library

  const onMobile = useMediaQuery("(max-width:600px)");
  const onXSMMobile = useMediaQuery("(max-width:331px)");

  const handleGoogleOneTapResponse = async (response) => {
    setAuthLoading(true);
    try {
      // // console.log(
      //   "SUCCESS: Signin with Google One Tap Success: ",
      //   response.credential
      // );
      // console.log("loading: ", loading);
      const credential = response.credential;
      // console.log("google one tap response: ", response);
      const googleAuthCredential = GoogleAuthProvider.credential(credential);
      // console.log("googleAuthCredential: ", googleAuthCredential);
      var result = await auth.signInWithCredentialFlow(googleAuthCredential);
      // console.log("result: ", result);
      var userDetails = auth.getAdditionalUserInfoFlow(result);
      // console.log("userDetails: ", userDetails);
      var currentUser = result.user;
      // console.log("Successfully created account using Google");
      const isNewUser = userDetails.isNewUser;
      if (isNewUser) {
        // console.log("Creating New user");
        const newUser = {
          userId: currentUser.uid,
          email: currentUser.email,
          password: null,
          displayName: currentUser.displayName,
          photoURL: currentUser.photoURL,
        };
        var createNewUserRes = await UserService.CreateNewUserAccount(newUser);
        // console.log(createNewUserRes);
        if (createNewUserRes?.userId) {
          navigate("/");
        } else {
          alert("Failed to create new user - please contact support team or try another method.");
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      // console.log("ERROR: Signin with Google One Tap Error: ", error);
    }
    setAuthLoading(false);
    // console.log("loading: ", loading);
  };

  const handleGoogleButtonClick = (isShowing) => {
    if (isShowing) {
      setAuthLoading(true);
      // console.log("Google OneTap Button Clicked");
      // console.log("event: ", isShowing);
    } else {
      setAuthLoading(false);
    }
  };

  useGoogleOneTap({
    // onError: (error) => // console.log("Google OneTap Error: ", error),
    // onComplete: (response) => // console.log("Google OneTap Success: ", response),
    onDisplay: (isShowing) => handleGoogleButtonClick(isShowing),
    showPrompt: true,
    disabled: auth.user ? true : false,
    disableCancelOnUnmount: false,
    googleConfig: {
      auto_select: auth.user ? true : false,
      cancel_on_tap_outside: false,
      callback: handleGoogleOneTapResponse,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    },
  });

  const loadMore = async (startIndex, stopIndex, currentItems) => {
    setLoading(true);
    setLoadingMore(true);
    // console.log("loading next page: ", page + 1);
    if (selectedCategory === "All") {
      try {
        const response = await ImageService.GetCleanImages(page + 1);
        setPage((prevPage) => prevPage + 1);
        setData((prevData) => [...prevData, ...response]);
      } catch (error) {
        // console.log("error loading more images: ", error);
      }
    } else {
      // filter by category
      await filterData(selectedCategory, page + 1);
      setPage((prevPage) => prevPage + 1);
    }
    setLoading(false);
    setLoadingMore(false);
    // console.log("fetching more Data");
    // console.log("data", data);
  };

  const handleCategoryChange = (e) => {
    if (e.target.value !== selectedCategory) {
      setPage(0);
      setData([]);
    }
    setShowMore(true);
    setSelectedCategory(e.target.value);
    // console.log("handleCategoryChange() - entered");
    // console.log("handleCategoryChange() - e.target.value:", e.target.value);
    filterData(e.target.value);
  };

  const filterData = async (value, pageParam = 0) => {
    // console.log("filterData() - entered: ", value);
    setLoading(true);
    // console.log("filterData() - entered");
    //setPage(0);
    // get value from filter select
    //var value = document.getElementsByName("filter1").value;
    // console.log("filterData() - FILTER(", JSON.stringify(value), ")");

    if (value === "All") {
      // console.log("filterData() - ALL");
      ImageService.GetCleanImages(pageParam)
        .then((response) => {
          // console.log("filterData() - ALL - DATA:", response);
          if (response.length === 0) {
            setShowMore(false);
          } else {
            setData([...response]);
            setShowMore(true);
          }
          if (searchParams.has("q")) {
            searchParams.delete("q");
            setSearchParams(searchParams);
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log("error: ", error);
          setLoading(false);
        });
    } else {
      setSearchParams(createSearchParams({ q: value }));
      // console.log("filterData() - CATEGORY:", value);
      ImageService.GetImagesByCategory(value, pageParam).then((response) => {
        // console.log("filterData() - CATEGORY:", value, "DATA:", response);
        if (pageParam !== 0) {
          // in a load more scenario
          setData((currentData) => [...currentData, ...response]);
        } else {
          // in a new filter scenario
          setData([...response]);
        }
        if (response.length === 0) {
          setShowMore(false);
        }
        setLoading(false);
      });
    }
  };

  const getFavoriteForUser = async () => {
    let favorites = [];
    if (auth.user) {
      //console.log("useEffect: auth.uid: ", JSON.stringify(auth.user.uid));
      ImageService.getFavoriteImagesForUser(auth.user.uid, auth.user.auth.currentUser.accessToken).then((response) => {
        //console.log("useEffect: GetFavorites call: ", response);
        setFavorites(response);
        favorites = response;
        // console.log("Tiles Favorites: ", favorites);
      });

      // set favorite flag for each image
      //let currentData = data;
      for (let i = 0; i < data.length; i++) {
        data[i].is_favorited = favorites.some((f) => {
          return f.image_id === data[i].image_id;
        });

        //if (data[i].is_favorited)
          // console.log("Tiles: IS favorited: ", data[i].is_favorited);
        //else
        //console.log("Tiles: NOT favorited: ", data[i].is_favorited);
      }
    }
    return favorites;
  };
  const getFeatured = async () => {
    //if (auth.user) {
    //console.log("useEffect: auth.uid: ", JSON.stringify(auth.user.uid));
    if (featured.length > 0) return;

    ImageService.GetFeaturedImages(0, 25).then((response) => {
      //console.log("useEffect: GetFavorites call: ", response);
      // get 5 random images from the response
      let randomized = [];
      for (let i = 0; i < 5; i++) {
        let index = Math.floor(Math.random() * response.length);
        randomized.push(response[index]);
      }

      setFeatured(randomized);
    });
    //}
  };

  useEffect(() => {
    let mounted = true;

    const trendingSearchesList = async () => {
      const response = await ListService.GetTrendingSearches();

      // console.log("trendingSearchesList() - response: ", response);

      setTrendingSearches(response.slice(0, 5));
    };

    // get favorites for user

    if (mounted) {
      setLoading(true);
      if (searchParams.has("q")) {
        setSelectedCategory(searchParams.get("q"));
      } else {
        setSelectedCategory("All");
      }
      ListService.GetCategories().then((response) => {
        // console.log("fetched categories: ", response);
        setCategories(["All", ...response.sort()]);
      });

      const query = searchParams.get("q");
      // console.log("query", query);

      if (query) {
        ImageService.GetImagesByCategory(query, page).then((response) => {
          setData([...response]);
          setLoading(false);
        });
        // document.getElementById("filter1").value = query;
      } else {
        ImageService.GetCleanImages(page).then((response) => {
          setData([...response]);
          setLoading(false);
        });
      }

      // get favorites for user
      setFavorites(getFavoriteForUser());

      //trendingSearchesList();
      //getFeatured();
    }
    return () => {
      mounted = false;
    };
  }, [searchParams, auth.user]);

  const handleFilterChange = (e) => {
    // console.log("handleFilterChange() - entered");
    // console.log("handleFilterChange() - e.target.value:", e.target.value);
    //setFilter(e.target.value);
    filterData(e.target.value);
  };
  function getFriendlyName(name) {
    //console.log("getFriendlyName() - name: ", name);
    let clean = "";

    if (name === "SearchByCategory") {
      clean = "Category";
    } else if (name === "SearchBySearchTerm") {
      clean = "Search";
    } else if (name === "SearchByGenericTag") {
      clean = "Tag";
    } else if (name === "SearchByTopic") {
      clean = "Topic";
    } else if (name === "SearchByColor") {
      clean = "Color";
    }

    //console.log("getFriendlyName() - -name: ", name, "clean: ", clean);
    return clean;
  }

  const getTrendingSearches = () => {
    // now render results
    return (
      <Box className="search__result" sx={{ marginTop: "50px" }}>
        <Typography
          sx={{
            margin: 1,
            fontFamily: "Raleway",
            fontSize: 22,
            fontWeight: "bold",
            marginTop: "10px",
          }}
        >
          Trending Searches
        </Typography>
        <div className="searchbar search__result__container">
          {trendingSearches.map((item, index) => (
            <Button
              key={index}
              label={getFriendlyName(item.interaction)}
              title={getFriendlyName(item.interaction) + " - " + filterProfanity(item.meta1)}
              style={{
                // make button look like cool chip using primary color
                backgroundColor: "#e0e0e0",

                borderRadius: "20px",
                color: "#000000",
                padding: "5px 10px",
                margin: "5px",
                fontSize: "14px",
                fontWeight: "bold",
                textTransform: "none",
              }}
            >
              <Link
                className="text-decoration-none"
                to={`/search?q=${item.meta1}`}
                state={{ category: item.meta1 }}
              >
                <Typography
                  // center content and apply padding
                  sx={{
                    color: "var(--primary-color)",
                    margin: 1,
                    padding: "2px",
                    fontFamily: "Raleway",
                    fontSize: 14,
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  <i
                    style={{
                      marginRight: "15px",
                      fontSize: "16px",
                      color: "var(--secondary-color)",
                    }}
                    className="fa-solid fa-magnifying-glass"
                  ></i>
                  {getFriendlyName(item.interaction) + " - " + filterProfanity(item.meta1)}
                </Typography>
              </Link>
            </Button>
          ))}
        </div>
      </Box>
    );
  };

  const ShowCategoriesCombo = () => {
    return (
      <FormControl variant="outlined" sx={{ minWidth: 280, margin: "auto" }}>
        <InputLabel>Categories</InputLabel>
        <Select
          value={categories.length > 0 ? selectedCategory : ""}
          data={categories}
          label="Categories"
          renderValue={(value) => value}
          onChange={(e) => handleCategoryChange(e)}
        >
          {categories.map((category) => (
            <MenuItem
              key={category}
              value={category}
              selected={category === selectedCategory}
            >
              {selectedCategory === category ? (
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
              ) : null}
              <ListItemText inset={selectedCategory !== category}>
                {category}
              </ListItemText>
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Select a category to browse images</FormHelperText>
      </FormControl>
    );
  };

  
  return (
    
    <div
      className="home"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        minWidth: "400px",
        width: "100%",
        minHeight: "800px",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={authLoading}
      >
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
      <div className="bg-hero px-4 py-5 text-center">
        <Typography 
          variant="h3"
          className="display-5"
          color="secondary"
          sx={{
            fontFamily: "Raleway",
            fontWeight: "500",
          }}
        >
          Original AI-Generated stock images
        </Typography>
        { // do not show in mobile
        !onMobile &&         
        <div className="mx-auto">
          <Typography
            color="secondary"
            className="mb-4"        
            sx={{
              fontFamily: "Raleway",
              fontSize: 20,
              paddingTop: "12px",
              fontWeight: "400",              
            }}
          >
            Beautifully prompted, expertly curated, royalty-free images for all your digital projects
          </Typography>
          <Typography
            color="secondary"
            className="mb-4"
            sx={{
              fontFamily: "Raleway",
              fontStyle: "italic",
              fontSize: 16,
              fontWeight: "500",
            }}
          >
            "Enhance, Create, and Inspire with smartai.studio"
          </Typography>
        </div>
        }
        <Searchbar />
      </div>
      {!auth.user ? (
        <div className="bg-banner px-4 text-center"
          title="Register to access AI Studio features"
          label="Register to access AI Studio features"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "400px",
            width: "100%",
          }}
        >
          <div className="d-flex align-items-center justify-content-center mx-auto py-2">
            <p className="mb-0 me-3 register-text">
              Register to access AI Studio features:
            </p>
            <div className="button" onClick={() => navigate("/free-signup")}>
              Join For Free
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Box flex
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
            minWidth: "400px",
        }}
      >
        {loading && !loadingMore ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "10vh",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <div>
           {/*
            <Box
              sx={{
                minWidth: "90%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              {getTrendingSearches()}
            </Box>
           
            <Box className="px-4 text-center color-primary"
              title="Featured Images"
              label="Featured Images"

              sx={{
                minWidth: "70%",
                maxHeight: "240px",
                justifyContent: "center",   
                textAlign: "center",
                alignContent: "center",             
                borderRadius: "10px",
                boxShadow: "1px 1px 2px 2px #183E4B",
                backgroundColor: "#EAEAEA",
                padding: "10px",
                marginTop: "20px",
                marginLeft: 3,
                marginRight: 3,
              }}
            >

               <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontSize: 22,
                  fontWeight: "bold",
                  paddingTop: "10px",
                }}
              >
                Featured Images
              </Typography>

                
                <Tiles
                  sx={{
                    maxHeight: "100px",
                  }}
                  props={props2}
                  data={featured.slice(0, 5)}
                  faves={favorites}
                  loadMore={loadMore}
                >
                </Tiles>
                
              </Box> 
              */}

            <Box
              sx={{
                margin: "auto",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                paddingTop: "20px",
              }}
            >
              {ShowCategoriesCombo()}
            </Box>
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontSize: 14,
                fontWeight: "italic",
                paddingTop: "4px",
                color: "#000000",
                textAlign: "center",
              }}
            >
              Hover over images to see options (tap on mobile)              
            </Typography>
            { /* add a checkbox above the Tiles to show quick actions on hover
            !onMobile &&
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                minWidth: "400px",
                width: "100%",
              }}
            >
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showQuickActions}
                      onChange={() => { setShowQuickActions(!showQuickActions); } }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Show Quick Actions on Hover"
                />
              </FormControl>
            </Box>
              */ }
            <Tiles
              props={props}
              data={data}
              faves={favorites}
              loadMore={loadMore}
            >
            </Tiles>



            
            {loadingMore ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "10vh",
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            ) : (
              <></>
            )}
          </div>
        )}
        {showMore ? (
          <div className="px-4 my-5 text-center">
            <div className="d-flex align-items-center justify-content-center mx-auto py-2">
              <h5 className="fw-bold color-primary">
                <Button
                  className="rounded-pill"
                  variant="contained"
                  onClick={() => {
                    loadMore();
                  }}
                >
                  Load More Images
                </Button>
              </h5>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="bg-banner-info px-4 text-center"
          title="Browse All Categories"
          label="Browse All Categories"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            minWidth: "400px",
            width: "100%",
          }}
        >
          <div className="d-flex align-items-center justify-content-center mx-auto py-2">
            <p className="mb-0 me-3">Browse All Categories</p>
          </div>
        </div>
        <p />
        <Box
          sx={{
            margin: "auto",
            width: "50%",
            mt: 3,
            mb: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="row text-center row-cols-2 row-cols-md-3 gy-5">
            {categories ? (
              categories.map((category, index) => {
                return (
                  <div className="col" key={index}>
                    <Link
                      to={`/search?q=${category}`}
                      state={{ category }}
                      className="text-decoration-none m-auto"
                    >
                      {category}
                    </Link>
                  </div>
                );
              })
            ) : (
              // <Box
              //   sx={{
              //     display: "flex",
              //     justifyContent: "center",
              //     alignItems: "center",
              //     height: "10vh",
              //   }}
              // >
              //   <CircularProgress color="inherit" />
              // </Box>
              <></>
            )}
          </div>
        </Box>
      </Box>
    </div>
  );
}
