/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import ImageService from "../../services/image.service";
import ProductService from "../../services/product.service";
import { useAuth } from "../../services/auth/use-auth";
import TimeAgo from "react-timeago";
import Typography from "@mui/material/Typography";
import "./ImageDetailBeta.css";
import s from "./imageDetail.module.scss";
import GetFriendlyUsername from "../../utils/utilsStringFuncs";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Box,
  CircularProgress,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Link,
  Chip,
  Backdrop,
  Divider,
} from "@mui/material";

import "./ImageDetail.css";
import UserService from "../../services/user.service";
import { LoadingButton } from "@mui/lab";
import SocialShare from "../../components/social-share/SocialShare";
import { generateSocialMediaMetaTags } from "../../components/social-share/SocialShare";
import externalLinkIconBlack from "../../assets/img/externalLinkIconBlack.svg";
import notFavoritedIcon from "../../assets/img/notFavoritedIcon.png";
import favoritedIcon from "../../assets/img/loveIcon.svg";
import { 
  browserName, 
  browserVersion, 
  osName, 
  osVersion, 
  deviceType, 
  mobileVendor, 
  mobileModel, 
  getUA, 
  isDesktop, 
  isMobile, 
  isTablet 
} from 'react-device-detect';

export default function ImageDetail() {
  const auth = useAuth();
  const [data, setData] = useState(null);

  const navigate = useNavigate();
  const [price, setImagePrice] = useState(0);
  const [subscription, setSubscription] = useState(null);
  const [credits, setCredits] = useState(null);

  const [resolutionWidth, setResolutionWidth] = useState(1024);
  const [resolutionHeight, setResolutionHeight] = useState(1024);

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hashtags, setHashtags] = useState([]); // ["#smartai", "#stockimages"
  const [shareUrl, setShareUrl] = useState("");
  const [relatedImages, setRelatedImages] = useState([]);
  const [isFavorited, setIsFavorited] = useState(false);

  const [searchParam] = useSearchParams();
  const identifier = searchParam.get("id");

  const fetchData = async () => {
    // retrieve image data
    let imageData = await getImageDetails();
    //generateSocialMediaMetaTags(imageData);

    // retrieve applicable products
    getProducts(imageData.width, imageData.height);

    // set hashtags
    let hashtags = imageData.tags
      .filter((tag) => {
        return (
          tag.split(":")[0] === "topic" || tag.split(":")[0] === "category"
        );
      })
      .map((tag) => {
        return tag.split(":")[1];
      });

    setHashtags(hashtags);

    // set share url
    //var shareUrl ='https://' + window.location.host + '/image?id=' + identifier;
    //setShareUrl(shareUrl);

    return imageData;
  };

  useEffect(() => {
    setLoading(true);
    const getSubscription = async () => {
      if (auth.user) {
        const response = await UserService.GetUserCredits(
          auth.user.uid,
          auth.user.auth.currentUser.accessToken
        );
        // console.log(
        //   "Logged in, subscription info for auth.user.id:",
        //   auth.user.uid,
        //   "\n",
        //   response
        // );
        console.log(response);
        setCredits(response.creditProfile);
        // console.log("CreditProfile: ", JSON.stringify(response.creditProfile));
      } else {
        var credit = {
          creditProfile: {
            numSubscriptionCreditsRemaining: -1,
            numWalletCreditsRemaining: -1,
            numPendingCredits: -1,
          },
        };
        setSubscription(credit);
      }
    };

    fetchData();

    // {data.title.substring(0, 100) + "..."}
    getSubscription();

    getRelatedImages();
    //getPrices();
    setLoading(false);
  }, [auth.user, identifier]);

  const download = async (url) => {   
    try {

      var urlDownload = url ? url : data.public_url_reduced;
      console.log("downloadImage: url(" + url + ")");
      console.log("downloadImage: urlDownload(" + urlDownload + ")");
      const blob = await fetch(urlDownload).then((r) => r.blob());
      const urlBlob = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = urlBlob;
      link.target = "_blank";
      link.download =
        "SmartAI-StockImages_" +
        urlDownload.substr(urlDownload.lastIndexOf("/") + 1);
      link.click();
    }
    catch (error) {
      console.log("downloadImage: error(" + error + ")");
    }
  };

  const chooseRelatedImage = (id) => {
    setLoading(true);
    navigate(`/image?id=${id}`);
    //setLoading(false);
  };

  const purchaseRelatedBatchImages = async (relatedImages) => {
    setLoading(true);

    for (var i = 0; i < relatedImages.length; i++) {
      if (
        auth.user &&
        acceptTerms &&
        relatedImages[i].image_id !== "" &&
        selectedProduct &&
        selectedProduct.productId > 0
      ) {
        var userId = auth.user.uid;
        var token = auth.user.auth.currentUser.accessToken;

        const response = await ProductService.PurchaseImageUsingCredits(
          userId,
          token,
          selectedProduct.productId,
          relatedImages[i].image_id
        );

        if (response.success === false) {
          alert("Purchase failed: " + response.message);
        } else {
          download(response.product.downloadUrl);
        }
      }
    }

    navigate("/profile");
    setLoading(false);
  };

  const purchaseImage = async (id) => {
    //console.log("purchaseImage(): " + id);
    //console.log("purchaseImage(): auth.user " + auth.user);
    //console.log("purchaseImage(): auth.user.id " + auth.user.uid);
    //console.log("purchaseImage(): acceptTerms " + acceptTerms);
    //console.log("purchaseImage(): product " + JSON.stringify(selectedProduct));
    //console.log('purchaseImage(): productId ' + selectedProduct.productId);
    //console.log('purchaseImage(): # credits ' + selectedProduct.creditsPrice);

    setLoading(true);

    //if (auth.user && acceptTerms && auth.user.id && identifier !== "") {
    if (
      auth.user &&
      acceptTerms &&
      id !== "" &&
      selectedProduct &&
      selectedProduct.productId > 0
    ) {
      // console.log(
      //   "purchaseImage() - Passed  check: id #",
      //   identifier,
      //   ", product #",
      //   product.productId,
      //   " user: ",
      //   JSON.stringify(auth.user)
      // );
      var userId = auth.user.uid;
      var token = auth.user.auth.currentUser.accessToken;
      const response = await ProductService.PurchaseImageUsingCredits(
        userId,
        token,
        selectedProduct.productId,
        id
      );

      if (response.success === false) {
        alert("Download was not successful: " + response.message);
        // console.log("purchaseImage: " + response);
      } else if (response.success === true) {
        // console.log("downloadImage: " + response.product.downloadUrl);
        // console.log("downloadImage: " + response.product.assetUrl);
        if (!browserName.includes("Safari")) {
          download(response.product.downloadUrl);
        }

        setLoading(false);
        navigate("/studio?id=" + id);
      }
    } else {
      console.log("purchaseImage() - Failed check: id #", identifier, ", product #", selectedProduct);
    }

    setLoading(false);
  };
      
     
  const getResolutionText = (index) => {
    // console.log("getResolutionText: " + index);
    var buffer = `${resolutionWidth}w x ${resolutionHeight}h`;
    switch (index) {
      case 1: {
        buffer = `${resolutionWidth}w x ${resolutionHeight}h`;
        break;
      }
      case 2: {
        buffer = `${resolutionWidth / 2}w x ${resolutionHeight / 2}h`;
        break;
      }
      case 0: {
        buffer = `${resolutionWidth * 4}w x ${resolutionHeight * 4}h`;
        break;
      }
      default: {
        buffer = `${resolutionWidth}w x ${resolutionHeight}h`;
        break;
      }
    }

    if (
      !productExists(
        getResolutionWidth(index),
        getResolutionHeight(index),
        "download"
      )
    ) {
      buffer = "Resolution: " + buffer;
    }
    // console.log("getResolutionText: " + buffer);
    return buffer;
  };

  const getResolutionWidth = (index) => {
    if (index === 1) {
      return resolutionWidth;
    } else if (index === 2) {
      return resolutionWidth / 2;
    } else if (index === 0) {
      return resolutionWidth * 4;
    } else {
      return resolutionWidth;
    }
  };

  const getResolutionHeight = (index) => {
    if (index === 1) {
      return resolutionHeight;
    } else if (index === 2) {
      return resolutionHeight / 2;
    } else if (index === 0) {
      return resolutionHeight * 4;
    } else {
      return resolutionHeight;
    }
  };

  const productExists = (width, height, action) => {
    // console.log("productExists: ", width, height);
    if (products) {
      if (action === "download") {
        //let product = products.find((p) => p.name === `${width}x${height}`);
        let product = products.find(
          (p) =>
            p.name === `${width}x${height}` || p.name === `${height}x${width}`
        );
        if (product) {
          // console.log("productExists: " + product);
          return true;
        }
      } else if (action === "vary") {
        return true;
      }
    }

    return false;
  };

  const getSubscriptionCreditsRemaining = () => {
    if (credits) {
      return credits.numSubscriptionCreditsRemaining;
    }
    return 0;
  };

  const getWalletCreditsRemaining = () => {
    if (credits) {
      return credits.numWalletCreditsRemaining;
    }
    return 0;
  };

  const getResolutionPrice = (width, height) => {
    //console.log(products);

    /*
    var _products = products;
    if (!products) {
      _products = getProducts();
    }
*/
    let product = selectedProduct;
    if (product) {
      return product.creditsPrice;
    }

    if (products) {
      console.log("ResolutionWidth: " + width);
      console.log("ResolutionHeight: " + height);

      product = products.find(
        (p) =>
          p.name === `${width}x${height}` || p.name === `${height}x${width}`
      );
      if (product) {
        //console.log("Product: " + product);
        // console.log(
        //   "Product: " + product.productId,
        //   " is " + product.creditsPrice,
        //   " credits"
        // );
        console.log(product);
        setSelectedProduct(product);
        setImagePrice(product.creditsPrice);
      } else {
        console.log("Product not found");
      }
    }

    //return product.creditsPrice;
  };

  const getProducts = async (width, height) => {
    // get products
    const productResponse = await ProductService.GetProductList();

    //let product = productResponse.find((p) => p.name === `1024x1024`);
    //let product = productResponse.find((p) => p.name === `{height}x{width}`);
    let product = products.find(
      (p) => p.name === `${width}x${height}` || p.name === `${height}x${width}`
    );
    if (product) {
      // console.log("Product: " + product);
      // console.log(
      //   "Product: " + product.productId,
      //   " is " + product.creditsPrice,
      //   " credits"
      // );
      console.log(product);
      setSelectedProduct(product);
      setImagePrice(product.creditsPrice);
    } else {
      // console.log("Product not found");
    }

    setProducts(productResponse);

    return productResponse;
  };

  const getImageDetails = async () => {
    // get image and image details
    const response = await ImageService.GetImageByIdentifier(identifier);
    setResolutionHeight(response.height);
    setResolutionWidth(response.width);
    setData(response);

    // get favorite status
    if (auth.user) {
      const response = await ImageService.GetFavoriteStatus(
        auth.user.uid,
        identifier,
        auth.user.auth.currentUser.accessToken
      );
      setIsFavorited(response);
    }
    return response;
  };

  const getRelatedImages = async () => {
    // if user is logged in, retrieve related images
    if (auth.user) {
    // get related images
    const response = await ImageService.GetRelatedImages(identifier, 4);
    setRelatedImages(response);
    }

    //console.log("Related Images: " + JSON.stringify(response));
  };

  const getRelatedImagesGrid = relatedImages ? (
    <div className="related_image_section">
      <div className="row" style={{ justifyContent: "center" }}>
        {relatedImages.map((item, index) => {
          return (
            <Box
              className="col-6"
              key={`related__image__${index}`}
              onClick={() => navigate(`/image?id=${item.image_id}`)}
              title={item.title}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                padding: "10px",
              }}
            >
              <div className="related__image__card">
                <img
                  src={item.public_url_thumbnail}
                  alt={item.title}
                  className="img-fluid"
                  style={{ width: "100%", borderRadius: "5px 5px 0 0" }}
                />

                <div className="related__image__card__body">
                  <LoadingButton
                    style={{ width: "100%", borderRadius: "0 0 5px 5px" }}
                    variant="contained"
                    disabled={!acceptTerms || loading}
                    onClick={() => {
                      purchaseImage(item.identifier);
                    }}
                  >
                    <Typography variant="body2">
                      Download Single
                      <br />
                      {getResolutionPrice(item.width, item.height)} Credits
                    </Typography>
                  </LoadingButton>
                </div>
              </div>
            </Box>
          );
        })}
      </div>
    </div>
  ) : (
    <div></div>
  );

  const relatedImagePicture = (item, index) => {
    <div className="col-6 col-md-3" key={`related__image__${index}`}>
      <div className="related__image__card">
        <div className="related__image__card__image">
          <img
            src={item.public_url_reduced}
            alt={item.title}
            className="img-fluid"
          />
        </div>
        <div className="related__image__card__body">
          <div className="related__image__card__title">
            <h5>{item.title}</h5>
          </div>
          <div className="related__image__card__footer">
            <div className="related__image__card__footer__left">
              <div className="related__image__card__footer__left__image">
                <img
                  src={item.public_url}
                  alt={item.user_id}
                  className="img-fluid"
                />
              </div>
              <div className="related__image__card__footer__left__text">
                <h6>{item.user_id}</h6>
              </div>
            </div>
            <div className="related__image__card__footer__right">
              <div className="related__image__card__footer__right__text">
                <h6>
                  <TimeAgo date={item.generated_timestamp} />
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  };

  const getTagsSection = () => {
    return (
      
      <Box>
        <div className="mt-2 fw-bold mb-2">Tags:</div>
        <div className="d-flex flex-row flex-wrap">
          {auth.user && data.tags
            .filter((tag) => {
              return (
                tag.split(":")[0] !== "topic" &&
                tag.split(":")[0] !== "category" &&
                tag.includes(":")
              );
            })
            .slice(0, 10)
            .map((tag, index) => {
              return (
                <Chip
                  key={`selected__key__${index}`}
                  sx={{ margin: 0.2 }}
                  label={
                    <Typography
                      sx={{
                        margin: 1,
                        fontFamily: "Raleway",
                        fontSize: 12,
                      }}
                    >
                      {tag.split(":")[1]}
                    </Typography>
                  }
                />
                // <Card
                //   variant="outlined"
                //   sx={{ width: 180, height: 50 }}
                // >
                //   <CardContent className="text-center">
                //     <Typography
                //       sx={{ fontFamily: "Raleway", fontSize: 12 }}
                //     >
                //       {tag.split(":")[1]}
                //     </Typography>
                //   </CardContent>
                // </Card>
              );
            })}
           
        </div>
      </Box>
    );
  };
  const getTopicsSection = () => {
    return (
      auth.user && data.tags && data.tags.length > 0 && (
      <Box>  
        <div className="fw-bold mb-2">Topics:</div>      
        {  data.tags
          .filter((tag) => {
            return tag.split(":")[0] === "topic";
          })
          .map((tag, index) => {
            return (
              <Chip
                key={`tags__${index}`}
                color="tertiary"
                sx={{ margin: 0.2 }}
                label={
                  <Typography
                    sx={{
                      margin: 1,
                      fontFamily: "Raleway",
                      fontSize: 12,
                    }}
                  >
                    {tag.split(":")[1]}
                  </Typography>
                }
              />
              // <Card
              //   variant="outlined"
              //   sx={{ width: 180, height: 50 }}
              // >
              //   <CardContent className="text-center">
              //     <Typography
              //       sx={{ fontFamily: "Raleway", fontSize: 12 }}
              //       variant="body2"
              //     >
              //       {tag.split(":")[1]}
              //     </Typography>
              //   </CardContent>
              // </Card>
            );
          })}
      </Box>
      )  
    );
  };
  const getBatchDownloadSummary = () => {
    return (
      <Box
        className="image__checkout"
        sx={{
          bgColor: "#f5f5f5",
          width: "100%",
        }}
      >
        <Typography variant="h4">{price} Credits</Typography>

        <div className="mt-2 fw-bold mb-2">
          <i className="fa-solid fa-coins"></i>
          <span className="ms-2">
            Monthly Credits Balance: {getSubscriptionCreditsRemaining()}
          </span>
          <br />
          <span className="ms-2">
            Wallet Credits: {getWalletCreditsRemaining()}
          </span>
        </div>
        <LoadingButton
          variant="contained"
          disabled={!acceptTerms || loading}
          sx={{ marginTop: "10px" }}
          onClick={() => {
            purchaseRelatedBatchImages(relatedImages);
          }}
        >
          {`Download Batch ( ${price * 5} Credits )`}
        </LoadingButton>
      </Box>
    );
  };

  const getImageDownloadSummary = () => {
    return (
      <Box
        className="image__checkout"
        sx={{
          bgColor: "#f5f5f5",
          padding: "10px",
          borderRadius: "10px",
          width: 300,
          height: 180,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Raleway",
            fontSize: 18,
            fontWeight: 600,
          }}
        >
          {price} Credit(s)
        </Typography>
        <Typography>
          Monthly Credit Balance: {getSubscriptionCreditsRemaining()}
        </Typography>
        <Typography>Wallet Credit: {getWalletCreditsRemaining()}</Typography>
        <i className="fa-solid fa-coins"></i>
        <span className="ms-2">
          Monthly Credits Balance: {getSubscriptionCreditsRemaining()}{" "}
        </span>
      </Box>
    );
  };

  const RenderLoginMessage = (label) => {
    return (
      <div>
        <Typography
          sx={{
            fontFamily: "Raleway",
            fontSize: 14,
            fontWeight: 400,
            padding: "10px",
            textAlign: "center",
          }}
        >
          {
            // navigate to /signup if not logged in
          }
          <span className="fw-bold mb-1">Please </span>
          <Link to="/signup" style={{ color: "#183E4B" }}>
            sign up
          </Link>
          <span className="fw-bold mb-1"> or </span>
          <Link to="/login" style={{ color: "#183E4B" }}>
            <span className="fw-bold mb-1">login</span>
          </Link>
          <span className="fw-bold mb-1"> {label}</span>
        </Typography>
      </div>
    );
  };


  const inputTermsPrivacy =
    auth && auth.user ? (
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptTerms}
              onChange={(e, c) => setAcceptTerms(c)}
            />
          }
          label={
            <p className={s.inputTerms}>
              I agree to the{" "}
              <Link
                style={{
                  color: "#183E4B",
                  textDecoration: "none",
                  borderBottom: "1px solid #183E4B",
                  margin: "0",
                }}
                target="_blank"
                href="https://app.termly.io/document/terms-of-use-for-website/376fff7a-0bd7-4e6b-a466-732926a0cbce"
              >
                terms &amp; conditions
              </Link>
            </p>
          }
        />
      </FormGroup>
    ) : (
      <>{ RenderLoginMessage("to download images") }</>
    );

  const ImageCard = data ? (
    <Card
      className=""
      sx={{
        boxShadow: "none",
        borderRadius: "10px",
        border: "1px solid #e0e0e0",
      }}
    >
      <CardHeader
        sx={{
          borderRadius: "10px 10px 0 0",
        }}
        title={
          <div className="">
            <div className="card__header__top">
              <Chip
                color="secondary"
                label={<Typography>{" " + data.category + " "}</Typography>}
              />

              {/*
              <div className="icons">
                <div className="icon">
                  <SocialShare
                    url={shareUrl}
                    title={data.title}
                    externalLinkIcon={externalLinkIconBlack}
                    decription={data.description}
                    hashtags={hashtags}
                  />
                </div>
              </div>
            */}
            </div>
            <Typography variant="h6" color="text.primary">
              {data.title}
            </Typography>
          </div>
        }
        action={<IconButton aria-label="settings"></IconButton>}
      ></CardHeader>

      <CardMedia
        className="img-responsive img-thumbnail"
        sx={{
          padding: 0,
        }}
        component="img"
        image={data.public_url_protected}
        alt={data.description}
      />
      <CardContent
        sx={{
          borderRadius: "0 0 10px 10px",
        }}
      >
        <Typography variant="body2" color="text.secondary">
          Uploaded by {GetFriendlyUsername(data.user_id)} &nbsp;
          <TimeAgo date={data.generated_timestamp} />
        </Typography>
        <br />
        <Typography
          variant="body2"
          color="text.secondary"
          className="fw-bold mb-1"
        >
          Description
        </Typography>
        <Typography variant="body2" color="text.secondary" className="my-2">
          {data.description}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <span className="fw-bold mb-1">generated by:</span>
          <span className="mb-1">{GetFriendlyUsername(data.generated_by)}</span>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <span className="fw-bold mb-1">prompted by:</span>
          <span className="mb-1">{GetFriendlyUsername(data.prompted_by)}</span>
        </Typography>

        <div className="flex__button">
          <LoadingButton
            variant="contained"
            disabled={!acceptTerms || loading}
            onClick={() => {
              purchaseImage(data.identifier);
            }}
          >
            {auth && auth.user
              ? acceptTerms
                ? `Download Image ( ${price} Credits )`
                : "Download Image - Agree to terms"
              : "Download Image - Login or Sign Up"}
          </LoadingButton>
          {inputTermsPrivacy}
        </div>

        <Box
          className="mt-3"
          sx={{
            padding: "10px",
          }}
        >
          {getTopicsSection()}
        </Box>
      </CardContent>
    </Card>
  ) : (
    <div></div>
  );

  const renderRelatedImages = relatedImages && auth.user ? (
    <div className={s.imageBox}>
      <p className={s.imageBox__heading}>Related Images</p>

      <div className={s.imageBox__wrapper}>
        {relatedImages.length > 0 ? (
          relatedImages.slice(0, 4).map((item, index) => (
            <div
              className={s.imageBox__wrapper__box}
              key={`related__image__${index}`}
              style={{ 
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                borderRadius: "10px",
                border: "1px solid #8d8d8d",                
              }}
            >
              <img
                style={{
                  width: "90%",
                  borderRadius: "10px",
                  border: "1px solid #8d8d8d",
                  objectFit: "fill",
                  padding: "5px",
                  marginTop: "5px",
                  maxWidth: `${(data.width / 4)}px`,      
                }}
                className={s.imageBox__wrapper__image}
                onClick={() => chooseRelatedImage(item.image_id)}
                src={item.public_url_thumbnail}
                alt={item.title}
              />
              <button
                className={s.imageBox__wrapper__button}
                disabled={!acceptTerms || loading}
                onClick={() => {
                  purchaseImage(item.image_id);
                }}
                sx={{
                  borderRadius: "0 0 5px 5px",
                  padding: "5px",
                }}
              >
                {acceptTerms ? `Download Image` : `Download - Agree to terms`}
              </button>
            </div>
          ))
        ) : ( /*
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
              width: "100%",
            }}
          >
            <CircularProgress color="inherit" />
          </Box> */
          <></>
        )}
      </div>
      { /* relatedImages.length > 0 ? (
        <div className={s.batchBox}>
          <div className={s.batchBox__container}>
            <p className={s.batchBox__container__text}>
              Download Batch! <br /> {price * 5} Credits (5 Images)
            </p>
            <button
              disabled={!acceptTerms || loading}
              onClick={() => {
                purchaseRelatedBatchImages(relatedImages);
              }}
              className={s.batchBox__container__button}
            >
              {acceptTerms
                ? `Download Images (${
                    5 * getResolutionPrice(data.width, data.height)
                  } Credits)`
                : `Download - Agree to terms`}
            </button>
          </div>
        </div>
      ) : null
    */ }
    </div>
  ) : (
    /*
    <div>
      <CircularProgress />
    </div>
    */
    <></>
  );

  function handleFavoriteImage() {
    if (auth.user && data) {
      ImageService.favoriteImage(
        auth.user.uid,
        data.image_id,
        !isFavorited,
        auth.user.auth.currentUser.accessToken
      );

      setIsFavorited(!isFavorited);
    }
  }

  /*
{/* <div className="mb-3 ">
            <div className="container">
              <div className="row px-5 gx-5 gy-2 mt-5">
                <div className="col"></div>
                <div className="col-12 col-md-6"></div>
                <div className="col-12 col-md-7">{ImageCard}</div>
                <div className="col-12 col-md-5">
                  <Box
                    sx={{
                      bgColor: '#f5f5f5',
                      borderRadius: '10px',
                      marginBottom: '10px',
                      background: '#FFF',
                      border: '2px solid #959595',
                      position: 'relative',
                      justifyContent: 'center',

                      gap: '25px',
                    }}
                  >
                    <Chip
                      color="secondary"
                      label={<Typography>✨BETA✨</Typography>}
                      sx={{
                        position: 'absolute',
                        right: '30px',
                        top: '-10px',
                      }}
                    />

                    <Box
                      className="header"
                      sx={{
                        padding: '20px 10px',
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Raleway',
                          fontSize: 32,
                          fontWeight: 600,
                          textAlign: 'center',
                        }}
                      >
                        Related Images
                      </Typography>
                      <div className="resolution">
                        <Typography
                          sx={{
                            fontFamily: 'Raleway',
                            fontSize: 24,
                            fontWeight: 600,
                            textAlign: 'center',
                          }}
                        >
                          {getResolutionText(1)}
                        </Typography>
                      </div>
                    </Box>

                    <Box
                      className="text-center"
                      sx={{
                        bgColor: '#f5f5f5',
                        padding: '20px',
                        borderRadius: '10px',
                        alignContent: 'center',
                      }}
                    >
                      <div>{getRelatedImagesGrid}</div>
                    </Box>

                    <Box
                      // center child items horizontally
                      className="text-center"
                      sx={{
                        bgColor: '#f5f5f5',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {getBatchDownloadSummary()}
                    </Box>
                  </Box>
                </div>

                <div className="col-12">
                  <div className="mt-3 mb-5">{getTagsSection()}</div>
                </div>
              </div>
            </div>
            <div className="bg-banner my-3 px-4 text-center ">
              <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
                <p className="mb-0 me-3 size-options-text">
                  Want more credits?
                </p>
                <Button
                  className="rounded-pill"
                  variant="contained"
                  onClick={() => {
                    navigate('/credits');
                  }}
                >
                  View Plans
                </Button>
              </div>
            </div>
          </div> */

  /*

    var enoughCredits =
      getSubscriptionCreditsRemaining() + getWalletCreditsRemaining() >=
      productsImageGenPrice;
*/

  const renderDownloadOptions = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5px",
          paddingBottom: "10px",
        }}
      >
        <button
          className={s.imageBox__wrapper__button}
          disabled={!auth.user || !acceptTerms || loading || !enoughCredits}
          onClick={() => {
            purchaseImage(identifier);
          }}
        >
          { auth && auth.user 
            ? acceptTerms 
              ? `Download Image` 
              : (enoughCredits ? `Download Image - Agree to terms`: `Download - Not enough credits`)
            : `Download Image - Login or Join`
          }
        </button>
        &nbsp; &nbsp;
        {enoughCredits && inputTermsPrivacy}
      </div>
    );
  };

  const renderImageDetails = () => {
    return (
     <>
        <div className="card__header">
          <div className="card__header__top">
            <Chip
             sx={{
              padding: "10px",
            }}
              color="secondary"
              title={`Category: ${data.category}`}
              label={<Typography>{" " + data.category + " "}</Typography>}
            />

            {auth.user && (
            <Chip
            sx={{
              padding: "10px",
            }}
              title={`Download Cost: ${
                getResolutionPrice(data.width, data.height) + " Credits"
              }`}
              label={
                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  {" " +
                    getResolutionPrice(data.width, data.height) +
                    " Credits"}
                </Typography>
              }
            />
            )}
            {auth.user && (
            <div
              className="icons"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingRight: "10px",
              }}
            >
              {/*
                      <div className="icon">
                        <SocialShare
                          url={shareUrl}
                          title={data.title}
                          externalLinkIcon={externalLinkIconBlack}
                          decription={data.description}
                          hashtags={hashtags}
                          color="#183E4B"
                          opacity={1}
                        />
                      </div>
                      */}
              <Chip
                sx={{
                  padding: "5px",
                }}
                
                color={isFavorited ? "primary" : "default"}
                title={
                  isFavorited ? "Remove from favorites" : "Add to favorites"
                }
                onClick={() => {
                  handleFavoriteImage(data.identifier, !isFavorited);
                }}
                label={
                  <Typography
                    sx={{
                      fontFamily: "Raleway",
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    {isFavorited ? "❤️" : "🤍"}
                  </Typography>
                }
              />
            </div>
             )}
          </div>
          <Typography
            sx={{
              fontFamily: "Raleway",
              fontSize: 18,
              fontWeight: 600,
              paddingTop: "10px",
            }}
            variant="h5"
            title={`Title: ${data.title}`}
            color="text.primary"
          >
            {data.title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            className="my-2"
            fontWeight={500}
            fontSize={14}
          >
            Actual Download Size: {getResolutionText(1)}
          </Typography>
        </div>
        <div
          style={{
            padding: "20px",
            marginTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            className="fw-bold mb-1"
          >
            Description
          </Typography>
          <Typography variant="body2" color="text.secondary" className="my-2">
            {data.description}
          </Typography>
          <br />
          <Typography variant="body2" color="text.secondary">
            <span className="fw-bold mb-1">generated by:</span>
            <span className="mb-1">
              {" "}
              {GetFriendlyUsername(data.generated_by)}
            </span>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <span className="fw-bold mb-1">prompted by:</span>
            <span className="mb-1">
              {" "}
              {GetFriendlyUsername(data.prompted_by)}
            </span>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Uploaded by {GetFriendlyUsername(data.user_id)} &nbsp;
            <TimeAgo date={data.generated_timestamp} />
          </Typography>
        </div>
        <div
          style={{
            paddingTop: "20px",
            padding: "20px",
          }}
        >
          
          <Divider />
          { getTopicsSection()}
          {!auth.user && RenderLoginMessage("to view additional image metadata including topics and tags") }
       
        </div>
        <div
          style={{
            padding: "20px",
          }}
        >
          {auth.user && getTagsSection()}
        </div>

        {renderDownloadOptions()}
      </>
    );
  };

  const renderSubscriptionInfo = () => {
   
    return (
      <div
        className="mt-2 fw-bold mb-2"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5px",
          padding: "20px",
          borderTop: "1px solid #8d8d8d99 ",
        }}
      >
        <i className="fa-solid fa-coins"></i>
        <span className="ms-2">
          Monthly Credits Balance: {getSubscriptionCreditsRemaining()}
        </span>

        <span className="ms-2">
          Wallet Credits: {getWalletCreditsRemaining()}
        </span>
      </div>
    );
  };

  const renderImageCard = () => {
    return (
      <div
        className={s.container__box}
        style={{
          borderRadius: "6px",
          border: "1px solid #8d8d8d",
          backgroundColor: "#EAEAEA",    
          maxWidth: `${(data.width / 2)}px`,      
        }}
      >
        <img
          className={s.container__box__image}
          src={data.public_url_protected}
          alt={data.description}
          style={{
            borderRadius: "6px 6px 0 0",
            width: "100%",
            border: "2px solid #8BA0A4",
            boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.1)",
          }}
        />

        <Typography
          variant="body2"
          color="text.secondary"
          className="my-2"
          sx={{
            fontFamily: "Raleway",
            fontSize: 16,
            fontWeight: 500,
            padding: "10px",
            textAlign: "center",           
          }}
        >
          {data.title}
        </Typography>
        <Typography
          color="text.secondary"
          className="my-2"
          sx={{
            fontFamily: "Raleway",
            fontSize: 12,
            fontStyle: "italic",
            fontWeight: 300,
            textAlign: "center",
          }}
        >
          (low-resolution preview with watermark - download image for full
          resolution)
        </Typography>
      </div>
    );
  };
  const RenderCreditsButton = () => {
    return (
      <div className="bg-banner text-center ">
           <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
            <Button
              className="rounded-pill"
              variant="contained"
              onClick={() => {
                navigate("/credits");
              }}
            >
              View Credit Packs
            </Button>
          </div>
          </div>
    );
  };

  var enoughCredits =
  getSubscriptionCreditsRemaining() + getWalletCreditsRemaining() >=
  price;
  
  return (
    <>
      { data ? (
        <>
          <div className={s.layout}
            style={{
              minWidth: "400px",
            }}
          >
            {loading ? (
              <Backdrop open={true}>
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              <div className={s.container}>
                  {renderImageCard()}
                  <div
                    className={s.container__detail}
                    style={{
                      border: "3px solid rgba(211, 211, 211, 0.2)",
                      borderTop: "none",
                      borderRadius: "0 0 10px 10px",
                    }}
                  >
                    {renderImageDetails()}                         
                    {renderSubscriptionInfo()}
                    { !enoughCredits && RenderCreditsButton() }              
                  </div>                  
              </div>
            )}

            {renderRelatedImages}
          </div>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
}
