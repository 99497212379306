import {useEffect, useState} from "react";
import ImageService from "../../services/image.service";
import "./PageNotFound.css";
import {useEffectOnce} from "../../utils/useEffectOnce";

const PageNotFound = () => {
    const [image, setImage] = useState({});
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);

    const randomNumberInRange = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    useEffectOnce(() => {
        let mounted = true;

        if (mounted) {
            ImageService.GetCleanImages(page).then(response => {
                setImage(...response);
                // setLoading(false);

                console.log(response)
                console.log(image)

                mounted = false
            });
        }
    }, [])

    return (
        <div>
            <div id="notfound">
                <div className="notfound">
                    <img
                        src="https://storage.googleapis.com/smartai-stockimage-dev-storage-protected-na/Character%20Design/20230914/1209fa19-a677-4dba-b55c-b929d38478cc.png"
                        alt="Name"/>

                    <div>
                        <div className="notfound-404">
                            <h1>
                                <span>4</span>
                                <span>0</span>
                                <span>4</span>
                            </h1>
                        </div>
                        <h5>Oops! Page not found</h5>
                        <h4>we are sorry,<br></br> the page you requested was not found</h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
