import { apiURI } from "../shared/api.settings";

class ContentPackService {
    static GetContentPackById = async (id) => {
        return fetch(`${apiURI}/packs/${id}`)
          .then((response) => response.json());
    }

    static GetContentPacks = async (page = 0, pageSize = 48) => {
        return fetch(`${apiURI}/packs/?page=${page}&pageSize=${pageSize}`)
          .then((response) => response.json());
    }
    
    /*
    static GetUserContentPacks = async (userId, page = 0, pageSize = 48) => {
      return fetch(`${apiURI}/packs/user?userId=${userId}&page=${page}&pageSize=${pageSize}`)
        .then((response) => response.json());
    }
   */
  
    static GetContentPacksBySearchTerm = async (searchTerm = '',page = 0, pageSize = 48) => {
      return fetch(`${apiURI}/packs/search/${searchTerm}?page=${page}&pageSize=${pageSize}`)
        .then((response) => response.json());
    }

    static GetContentPacksByTag = async (tag = 'tag', tagValue = '',page = 0, pageSize = 48) => {
      return fetch(`${apiURI}/packs/tag/${tag}/${tagValue}?page=${page}&pageSize=${pageSize}`)
        .then((response) => response.json());
    }

    static GetContentPacksByTopic = async (topic = '',page = 0, pageSize = 48) => {
      return fetch(`${apiURI}/packs/$topic/${topic}?page=${page}&pageSize=${pageSize}`)
        .then((response) => response.json());
    }

    static GetContentPacksByCategory = async (category = '',page = 0, pageSize = 48) => {
      return fetch(`${apiURI}/packs/category/${category}?page=${page}&pageSize=${pageSize}`)
        .then((response) => response.json());
    }

    /*
    static GetContentPackPrice = async (width, height) => {
      return fetch(`${apiURI}/price?width=${width}&height=${height}`)
        .then((response) => response.json());
    }
  
    static GetContentPackPricesheet = async (color = '',page = 0, pageSize = 48) => {
      return fetch(`${apiURI}/prices`)
        .then((response) => response.json());
    }
    */
}

export default ContentPackService;