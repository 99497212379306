import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../services/auth/use-auth";
import {
  Avatar,
  Box,
  Paper,
  Grid,
  Input,
  TextField,
  Typography,
  InputAdornment,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Divider,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
  icon,
} from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used
import UserService from "../../../services/user.service";

const SettingsTab = () => {
  const auth = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [twitterUrl, setTwitterUrl] = React.useState("");
  const [instagramUrl, setInstagramUrl] = React.useState("");
  const [facebookUrl, setFacebookUrl] = React.useState("");
  const [tiktokUrl, setTiktokUrl] = React.useState("");
  const [linkedinUrl, setLinkedinUrl] = React.useState("");
  const [youtubeUrl, setYoutubeUrl] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [validPassword, setValidPassword] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const isUrl = (url) => {
    if (url === "" || url === null) return true;
    try {
      // Regex check if url is valid
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  const isDifferent = () => {
    if (!user) return false;
    var res =
      user.name !== firstName + " " + lastName ||
      user.twitterUrl !== twitterUrl ||
      user.instagramUrl !== instagramUrl ||
      user.facebookUrl !== facebookUrl ||
      user.tikTokUrl !== tiktokUrl ||
      user.linkedInUrl !== linkedinUrl ||
      user.youTubeUrl !== youtubeUrl;
    return res;
  };

  const isValid = () => {
    const res =
      isUrl(twitterUrl) &&
      isUrl(instagramUrl) &&
      isUrl(facebookUrl) &&
      isUrl(tiktokUrl) &&
      isUrl(linkedinUrl) &&
      isUrl(youtubeUrl) &&
      firstName !== "" &&
      lastName !== "";
    return res;
  };

  const canUpdateProfile = () => {
    return isDifferent() ? isValid() : false;
  };

  const updateUserProfile = async () => {
    setLoading(true);
    try {
      const displayName = firstName + " " + lastName;
      var userUpdate = { ...user };
      userUpdate.name = displayName;
      userUpdate.twitterUrl = twitterUrl;
      userUpdate.instagramUrl = instagramUrl;
      userUpdate.facebookUrl = facebookUrl;
      userUpdate.tikTokUrl = tiktokUrl;
      userUpdate.linkedInUrl = linkedinUrl;
      userUpdate.youTubeUrl = youtubeUrl;
      // console.log("userUpdate: ", userUpdate);
      const res = await UserService.UpdateUserProfile(
        auth.user.uid,
        auth.user.auth.currentUser.accessToken,
        userUpdate
      );
      await auth.updateUserProfile({
        displayName: displayName,
        photoURL: auth.user.photoURL,
      });
      setUser(userUpdate);
      setSnackbarMessage("Profile updated successfully");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      // console.log(res);
    } catch (error) {
      // console.log(error);
      setSnackbarMessage("Failed to update profile");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
    setLoading(false);
  };

  const validatePassword = (newPassword) => {
    // console.log("new password: ", newPassword);
    // TODO: Validate that the new password conforms to the password policy
    var length = newPassword.length >= 8 && newPassword.length <= 64;
    var upper = /[A-Z]/.test(newPassword);
    var lower = /[a-z]/.test(newPassword);
    var number = /[0-9]/.test(newPassword);
    var special = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(newPassword);
    if (length && upper && lower && number && special) {
      setValidPassword(true);
    } else {
      setValidPassword(false);
    }
  };

  const handleUpdatePassword = () => {
    setLoading(true);
    // TODO check if new password and confirm password match
    if (newPassword === confirmPassword && newPassword !== currentPassword) {
      var success = auth.changePassword(currentPassword, newPassword);
      if (success) {
        // console.log("Password changed successfully");
        // TODO notify user that password was changed successfully
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setSnackbarMessage("Password changed successfully");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
      } else {
        setSnackbarMessage("Password change failed");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        // console.log("Password change failed");
        // TODO notify user that password change failed
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    // console.log("auth: ", auth);
    if (!user) {
      // console.log("get user: ", user);
      UserService.GetUserProfile(
        auth.user.uid,
        auth.user.auth.currentUser.accessToken
      )
        .then((res) => {
          // console.log("user response: ", res.data);
          const name = res.data.name.split(" ");
          const user = { ...res.data };
          user.twitterUrl = user.twitterUrl ? user.twitterUrl : twitterUrl;
          setTwitterUrl(user.twitterUrl);
          user.instagramUrl = user.instagramUrl
            ? user.instagramUrl
            : instagramUrl;
          setInstagramUrl(user.instagramUrl);
          user.facebookUrl = user.facebookUrl ? user.facebookUrl : facebookUrl;
          setFacebookUrl(user.facebookUrl);
          user.tikTokUrl = user.tikTokUrl ? user.tikTokUrl : tiktokUrl;
          setTiktokUrl(user.tikTokUrl);
          user.linkedInUrl = user.linkedInUrl ? user.linkedInUrl : linkedinUrl;
          setLinkedinUrl(user.linkedInUrl);
          user.youTubeUrl = user.youTubeUrl ? user.youTubeUrl : youtubeUrl;
          setYoutubeUrl(user.youTubeUrl);
          setUser(res.data);
          setFirstName(name[0]);
          setLastName(name.length > 1 ? name[1] : "");
        })
        .catch((err) => {
          // console.log("error getting user from stock image backend: ", err);
        });
    }
    setEmail(auth.user.email);
  }, [user, auth]);
  return (
    <Box
      sx={{
        maxWidth: 1200,
        mx: "auto", // margin left & right
        my: 4, // margin top & botom
        py: 3, // padding top & bottom
        px: 2, // padding left & right
        minHeight: "67vh",
        maxHight: "67vh",
        display: "flex",
        alignItems: "center",
        gap: 2,
        borderRadius: "sm",
        boxShadow: "md",
        overflow: "scroll",
      }}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={ () => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Paper elevation={5} sx={{ p: 4, maxWidth: "95%" }}>
        <Grid
          container
          spacing={{ xs: 1, sm: 2, md: 3 }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          columns={2}
          justifyContent="center"
        >
          <Grid item xs={4}>
            <Avatar
              sx={{
                width: 140,
                height: 140,
                margin: "auto",
              }}
              src={auth.user.photoURL}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="body2" sx={{ fontStyle: 'italic' }}>
                { auth.user.providerData.filter(
              (provider) => provider.providerId === "google.com"
            ).length > 0 ? "Please update your profile picture using Google" : "Edit profile picture (Coming Soon BETA)" }
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography align="center" variant="h3">
              {firstName + " " + lastName}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              sx={{ minWidth: 200 }}
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value.trim())}
            />
          </Grid>
          <Grid item>
            <TextField
              sx={{ minWidth: 200 }}
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value.trim())}
            />
          </Grid>
          <Divider sx={{ mt: 5, width: "80%" }} />
          <Grid item xs={12}>
            <Typography align="center" variant="h5">
              Socials
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontStyle: 'italic' }} align="center" color="red" variant="body2">
              { isValid() ? "" : "Please enter a valid URL starting with http(s)://"}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              justifyContent: "center",
            }}
          >
            <TextField
              sx={{ minWidth: 200 }}
              label="Twitter"
              type="url"
              value={twitterUrl}
              onChange={(e) => setTwitterUrl(e.target.value.trim())}
              error={!isUrl(twitterUrl)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={brands("twitter")} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              sx={{ minWidth: 200 }}
              label="Instagram"
              type="url"
              value={instagramUrl}
              onChange={(e) => setInstagramUrl(e.target.value.trim())}
              error={!isUrl(instagramUrl)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={brands("instagram")} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              sx={{ minWidth: 200 }}
              label="TikTok"
              type="url"
              value={tiktokUrl}
              onChange={(e) => setTiktokUrl(e.target.value.trim())}
              error={!isUrl(tiktokUrl)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={brands("tiktok")} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              sx={{ minWidth: 200 }}
              label="Facebook"
              type="url"
              value={facebookUrl}
              onChange={(e) => setFacebookUrl(e.target.value.trim())}
              error={!isUrl(facebookUrl)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={brands("facebook")} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              sx={{ minWidth: 200 }}
              label="LinkedIn"
              type="url"
              value={linkedinUrl}
              onChange={(e) => setLinkedinUrl(e.target.value.trim())}
              error={!isUrl(linkedinUrl)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={brands("linkedin")} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              sx={{ minWidth: 200 }}
              type="url"
              label="YouTube"
              value={youtubeUrl}
              onChange={(e) => setYoutubeUrl(e.target.value.trim())}
              error={!isUrl(youtubeUrl)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={brands("youtube")} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {/* Center Box */}
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                disabled={!canUpdateProfile()}
                sx={{
                  mt: 4,
                  backgroundColor: "#d74a4a",
                  color: "white",
                  margin: "auto",
                }}
                onClick={() => updateUserProfile()}
              >
                Update Profile
              </Button>
            </Box>
          </Grid>
          {auth.user.providerData.filter(
            (provider) => provider.providerId === "password"
          ).length > 0 ? (
            <>
              <Divider sx={{ mt: 5, width: "80%" }} />
              <Grid item xs={12}>
                <Typography align="center" variant="h5">
                  Change Password
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <TextField
                    sx={{ minWidth: 200, maxWidth: 250 }}
                    type="email"
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!(auth.user && auth.user.email === email)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={solid("at")} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <TextField
                    sx={{ minWidth: 200, maxWidth: 250 }}
                    type="password"
                    label="Current Password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    error={validPassword}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={solid("lock")} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <TextField
                    sx={{ minWidth: 200, maxWidth: 250 }}
                    type="password"
                    autoComplete="off"
                    label="New Password"
                    value={newPassword}
                    onChange={(e) => {
                      validatePassword(e.target.value);
                      setNewPassword(e.target.value);
                    }}
                    error={newPassword.length === 0 ? false : !validPassword}
                    helperText={
                      (newPassword.length === 0 ? false : !validPassword)
                        ? "New password must be at least 8 characters long and contain an upercase letter, number, and special character"
                        : ""
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={solid("lock")} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <TextField
                    sx={{ minWidth: 200, maxWidth: 250 }}
                    type="password"
                    label="Confirm New Password"
                    value={confirmPassword}
                    onChange={(e) => {
                      validatePassword(e.target.value);
                      setConfirmPassword(e.target.value);
                    }}
                    error={newPassword !== confirmPassword}
                    helperText={
                      newPassword !== confirmPassword
                        ? "Passwords do not match"
                        : ""
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={solid("lock")} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                {/* Center Box */}
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    disabled={newPassword !== confirmPassword || !validPassword || newPassword.length === 0 || currentPassword.length === 0}
                    sx={{
                      mt: 4,
                      backgroundColor: "#d74a4a",
                      color: "white",
                      margin: "auto",
                    }}
                    onClick={() => handleUpdatePassword()}
                  >
                    Update Password
                  </Button>
                </Box>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Paper>
    </Box>
  );
};

export default SettingsTab;
