import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FiEdit } from 'react-icons/fi';
import SocialShare from '../social-share/SocialShare';

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';

import './GalleryTile.css';
// import Modal from '../storyModal/Modal';
import Modal from '../modal/storyModal';
import ImageStoryModal from '../storyModal/ImageStoryModal';

export default function GalleryTiles({ data, loadMore, type }) {
  const onMobile = useMediaQuery('(max-width:600px)');
  const onXSMMobile = useMediaQuery('(max-width:331px)');
  const navigate = useNavigate();
  const [isSocialShareOpen, setSocialShareOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const download = async (url) => {
    // console.log("downloadImage: " + JSON.stringify(data));
    var urlDownload = url ? url : data.public_url;
    const blob = await fetch(urlDownload).then((r) => r.blob());
    const urlBlob = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = urlBlob;
    link.target = '_blank';
    link.download =
      'SmartAI-StockImages_' +
      urlDownload.substr(urlDownload.lastIndexOf('/') + 1);
    link.click();
  };

  // Create tile for each item in data array
  // Pass data to each tile and assign a key
  return (
    <Container maxWidth="lg">
      <ImageList
        variant="masonry"
        cols={onMobile ? (onXSMMobile ? 1 : 2) : 4}
        gap={20}
        sx={{ padding: 5 }}
      >
        {data.map((item, index) => (
          <ImageListItem key={index}>
            <img
              src={item.public_url_thumbnail}
              srcSet={`${item.public_url_thumbnail} 1x, ${item.public_url_thumbnail} 2x`}
              alt={item.title}
              loading="lazy"
              style={{
                borderRadius: 4,
                boxShadow: '2px 2px 6px 3px rgba(0, 0, 0, 0.3)',
              }}
            />
            <div
              style={{
                display: 'flex',
                // flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ImageListItemBar
                className="show-on-hover-no-click"
                sx={{
                  background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                  height: '100%',
                }}
                position="top"
                actionIcon={
                  <div
                    style={{
                      display: 'flex',
                      gap:
                        type === 'gallery'
                          ? '10px'
                          : type === 'story'
                          ? '20px'
                          : '0',
                      alignItems: 'center',
                      justifyContent: 'center',
                      //   marginRight: '10px',
                      //   marginBottom: '200px',
                    }}
                  >
                    <IconButton
                      sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                      aria-label={`info about ${item.title}`}
                      onClick={() => download(item.public_url)}
                    >
                      <FontAwesomeIcon size="lg" icon={solid('download')} />
                    </IconButton>                   
                    {type === 'story' && (
                      <>
                        <div onClick={handleEditClick}>
                          <FiEdit
                            style={{
                              color: 'rgba(255, 255, 255, 0.54)',
                              width: '29px',
                              height: '29px',
                              cursor: 'pointer',
                            }}
                          />
                        </div>

                        {isModalOpen && (
                          <Modal
                            setOpenModal={closeModal}
                            title="Image Text Generations"
                          >
                            <ImageStoryModal item={item} />
                          </Modal>
                        )}
                      </>
                    )}
                     {(type === 'gallery' || type === 'story') && (
                      <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        aria-label={`info about ${item.title}`}
                        onClick={() => navigate('/studio?id=' + item.image_id)}
                      >
                        <FontAwesomeIcon size="lg" icon={solid('palette')} />
                      </IconButton>
                    )}
                    
                    { /*
                    <SocialShare
                      color="#fff"
                      opacity={0.54}
                      url={window.location.href + 'image?id=' + item.image_id}
                      title={item.title}
                      decription={item.description}
                      hashtags={[item.category]}
                    />
                    */ }
                  </div>
                }
                title={<div style={{ textAlign: 'center' }}>{item.title}</div>}
              />
            </div>
          </ImageListItem>
        ))}
      </ImageList>
    </Container>
  );
}
