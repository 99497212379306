import React, { Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "../dropdown/Dropdown";
import LogoSmartaiStudio from "../../assets/img/smartai-studio-logo-small.png";
import "./Navbar.css";
import { useAuth } from "../../services/auth/use-auth";
import { Typography } from "@mui/material";

const Navbar = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  function signOut() {
    auth.signout();
    navigate("/");
  }

  const StockImageLogo = (
    <div      
      style={{ 
        cursor: "pointer",
        display: "flex",
        paddingRight: "0.25rem",
        marginLeft: "-1.5rem",
        marginTop: "1.6rem",
      }}
    >
      <Link to="/" className="text-decoration-none">
        <img 
          src={LogoSmartaiStudio} 
          className="logo-si img-fluid" 
          alt="Stock Images Logo"
          style={{ 
            width: "66%",
          }}          
        />
      </Link>
    </div>
  );

  return (
    <div className="">
      <div className="bg-navbar">
        <div className="container">
          <header className="d-flex flex-wrap justify-content-center my-auto text-center h-100">
           
            <div className="d-flex align-items-center mb-3 mb-md-0 me-md-auto">
              {StockImageLogo}
              <Link to="/" className="text-decoration-none">
                <span className="navbar-company2">Stock Images</span>
              </Link>
            </div>

            <ul className="nav nav-pills d-flex align-items-center justify-content-center">
              <li className="nav-item">
                <Dropdown
                  list={[
                    { label: "Images", link: "/" },
                    { label: "Content Packs (soon)", link: "/" },
                  ]}
                >
                  Explore
                </Dropdown>
              </li>
              <li className="nav-item">
                <Link to="/search" className="nav-link">
                  <span className="d-flex text-white-thin">Search</span>
                </Link>

                {/*
              <Dropdown
                  list={[
                    { label: "Search", link: "/search" },
                    { label: "Random", link: "/search", search: "?q=random"},
                  ]}
                >
                  Search
                </Dropdown>
                */}
              </li>

              <li className="nav-item">
                <Link to="/credits" className="nav-link">
                  <span className="d-flex text-white-thin">Credits</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link">
                  <span className="d-flex text-white-thin">About</span>
                </Link>
              </li>
              {auth.user ? (
                <li className="nav-item">
                  <Dropdown
                    list={[
                      { label: "My Images", link: "/profile" },
                      /*
                      {
                        label: "Manage Subscriptions",
                        link: "/profile/edit",
                        search: "?tab=membership",
                      },
                      */
                      {
                        label: "My Profile",
                        link: "/profile/edit",
                        search: "?tab=settings",
                      },
                      { label: "Sign Out", link: "/signout" },
                    ]}
                  >
                    <i className="fa-regular fa-user mx-1"></i>
                    {auth.user.displayName}
                  </Dropdown>
                </li>
              ) : (
                <>
                  <li className="nav-item">
                    <Link to="/login" className="nav-link">
                      <i className="fa-regular fa-user text-white">
                        <span className="text-white-thin">
                          &nbsp;Log&nbsp;in
                        </span>
                      </i>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/signup" className="nav-link">
                      <div className="button">Join</div>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </header>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
