import React, { useRef, useEffect } from 'react'

function StackedEffectCanvas(props) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
        const context = canvas.getContext('2d');
        const baseImage = new Image();

        let contrast = props.contrast / 50;
        let saturateAmount = props.saturate / 50;
        let brightnessAmount = props.brightness * 2.5;

        //console.log('StackedEffectCanvas: useEffect: props.filterType = ' + props.filterType);
        //console.log('StackedEffectCanvas: useEffect: props.filterAmount = ' + props.filterAmount);
        //console.log('StackedEffectCanvas: useEffect: contrast = ' + contrast);
        //console.log('StackedEffectCanvas: useEffect: saturateAmount = ' + saturateAmount);
        //console.log('StackedEffectCanvas: useEffect: brightnessAmount = ' + brightnessAmount);

        baseImage.onload = () => {
            context.filter = `blur(${props.blur}px) sepia(${props.sepia}%) grayscale(${props.grayscale}%) opacity(${props.opacity}%) hue-rotate(${props.hueRotate}deg) invert(${props.invert}%) contrast(${contrast}) saturate(${saturateAmount}) brightness(${brightnessAmount}%)`;
            context.drawImage(baseImage, 0, 0, canvas.width, canvas.height);
            props.handleCurrentStage(canvas);
        };
        baseImage.src = props.imageUrl;
    } else {
        console.log("couldn't find canvas");
    }
  }, [props]);

  return (
    <canvas
        ref={canvasRef}
        id="stackedCanvas"
        width={props.data.width ?? 1024}
        height={props.data.height ?? 1024}
        style={{
          width: "100%",
        }}
    />
  )
}

export default StackedEffectCanvas;
