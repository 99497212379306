/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, {
  useState, useEffect, useContext, createContext,
} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import UserService from '../user.service';
import { signInWithRedirect, signInWithCredential, getRedirectResult, signInWithPopup, getAdditionalUserInfo } from "firebase/auth";
const TENANT_ID = 'ai-stock-image-w22vb';
// Add your Firebase credentials
firebase.initializeApp({
  apiKey: 'AIzaSyBEN3XYDlj3LcnuZo8O9U2_5_zL3SHshxM',
  authDomain: 'smartai-dev-poc-image-gen.firebaseapp.com',
  projectId: 'smartai-dev-poc-image-gen',
  appId: '1:500182862859:web:476c1bda4b3f5f69b00b0e',
});
const actionCodeSettings = {
  url: `https://${process.env.WEB_URL}/confirmEmail`,
  handleCodeInApp: false,
  dynamicLinkDomain: `${process.env.WEB_DOMAIN}`,
}
const authContext = createContext();
const googleProvider = new firebase.auth.GoogleAuthProvider();
firebase.auth().useDeviceLanguage();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  auth.tenantId = TENANT_ID;
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => useContext(authContext);
export const useGoogleProvider = () => useContext(googleProvider);
// eslint-disable-next-line react-hooks/rules-of-hooks
// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [userAditionalDetails, setUserAditionalDetails] = useState(null);
  
  // useGoogleOneTapLogin({
  //   onError: error => // console.log('Google OneTap Error: ', error),
  //   onSuccess: response => // console.log('Google OneTap Success: ', response),
    
  //   googleAccountConfigs: {
  //     callback: handleGoogleOneTapResponse,
  //     client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID
  //   },
  // });
  const auth = firebase.auth();
  auth.tenantId = TENANT_ID;
  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const getAdditionalUserInfoFlow = (result) =>
    getAdditionalUserInfo(result)
  const signin = (email, password) => auth
    .signInWithEmailAndPassword(email, password)
    .then((response) => {
      setUser(response.user);
      return response.user;
    });
  const signInWithCredentialFlow = async (credential) => {
    return signInWithCredential(auth, credential)
      .then((result) => {
        // console.log('SUCCESS: Signin with Google Success: ', result);
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        setUser(user);
        // IdP data available using getAdditionalUserInfo(result)
        return result;
      })
    }

  const changePassword = async (oldPassword, newPassword) => {
    try {
      const reauthUser = await auth.currentUser.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(auth.currentUser.email, oldPassword))
      // console.log('SUCCESS: User reauthenticated', reauthUser);
      await auth.currentUser.updatePassword(newPassword)
      // console.log('SUCCESS: Password changed');
      return true;
      } catch (error) {
        // console.log('ERROR: Password change failed', error);
        return false;
      }
  }
  const signInWithPopupFlow = async () =>
    signInWithPopup(auth, googleProvider)
    .then(async (result) => {
      // console.log('SUCCESS: Signin with Google Success: ', result);
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      setUser(user);
      

      // IdP data available using getAdditionalUserInfo(result)
      return result;
    })
  const signInWithRedirectFlow = async () =>
    signInWithRedirect(auth, googleProvider)
    .then((result) => {
      // console.log('SUCCESS: Signin with Google Success: ', result);
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      setUser(user);
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    })
  const getRedirectResultFlow = async () =>
    getRedirectResult(auth)
    .then((result) => {
      // console.log('SUCCESS: Signin with Google Success: ', result);
      // This gives you a Google Access Token. You can use it to access Google APIs.
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;

      // The signed-in user info.
      const user = result.user;
      setUser(user);
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    })
  const signup = async (email, password, displayName) => {
    return await UserService.CreateNewUserAccount({email, password, displayName});
  }
  const updateUserProfile = (userInfo, success, error) => {
    const user = firebase.auth().currentUser;
    user.updateProfile({
      displayName: userInfo.displayName,
      photoURL: userInfo.photoURL,
    })
    const userNew = firebase.auth().currentUser;
    setUser(userNew)
  };
  const signout = () => auth
    .signOut()
    .then(() => {
      setUser(false);
    });
  const sendPasswordResetEmail = (email) => auth
    .sendPasswordResetEmail(email)
    .then(() => true);
  const confirmPasswordReset = (code, password) => auth
    .confirmPasswordReset(code, password)
    .then(() => true);
  const sendEmailConfirmation = () => auth
    .currentUser.sendEmailVerification(actionCodeSettings)
    .then(() => true);
    

  const confirmEmail = (code) => auth
    .applyActionCode(code)
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  // Return the user object and auth methods
  return {
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    changePassword,
    signInWithRedirectFlow,
    signInWithCredentialFlow,
    getRedirectResultFlow,
    signInWithPopupFlow,
    sendEmailConfirmation,
    confirmEmail,
    getAdditionalUserInfoFlow,
    updateUserProfile
  };
}