import React from 'react';
import { 
    Typography,
    Card,
    CardContent,
    CardActions,
    Button,
    Container,
    Grid,
    Box,
    Divider,
    CardMedia,
    CardHeader,
    Avatar,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemAvatar,
    Paper
 } from '@mui/material';

function UnderConstruction() {
    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundColor: '#f8f9fa',
                width: '100%',
                border: '0px solid #6c757d',
                borderRadius: '0px',
            }}
        >

      <Card sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: '#f8f9fa',
        width: '100%',
        border: '0px solid #6c757d',
        borderRadius: '0px',
      }}>
        
        <CardHeader
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundColor: '#ffff00',
                padding: '20px',
                width: '100%',
            }}
            
            title={
                <Typography 
                    variant='h2'
                    sx={{                        
                        color: 'black',
                    }}                    
                >
                    Under Construction
                </Typography>}

        >
            
        </CardHeader>
        <CardContent
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundColor: '#f8f9fa',
                padding: '20px',
                width: '80%',
            }}
        >
        <Typography
            variant='h5'
            sx={{
                color: '#6c757d',
            }}                
        >
          We are currently repurposing <em>smartai.studio</em> by removing all images generated with LAION based image generators. This will be a prolonged maintenance window. 
          You may still access the 'My Images' and 'My Profile' account information.  No other features will be available during this time.
          </Typography>
          <Divider 
            orientation='horizontal' 
            sx={{
                marginBottom: '10px',
                marginTop: '10px',
                borderColor: '#6c757d',
                borderWidth: '1px',
                width: '60%',
            }}
          />
       
        <Typography
            variant='h6'
            sx={{
                color: '#6c757d',
                }}       
            >     
            We are targeting a redeployment of the site with new image generators that respect intellectual property rights during their training phases and observe best practices for AI generated images that support artists, users and the AI community.
        </Typography>
       
       <Typography
            variant='h6'
            sx={{
                color: '#6c757d',
                }}       
        >     
          Thank you for your patience, and we apologize for any inconvenience.  We are excited to bring you a new and improved smartai.studio during early-mid Fall of 2024.
        </Typography>
        </CardContent>
        </Card> 
         <Card sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            backgroundColor: '#f8f9fa',
            width: '100%',
            border: '0px solid #6c757d',
            borderRadius: '0px',
          }}>
            <CardContent
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundColor: '#f8f9fa',
                padding: '20px',
                width: '80%',
            }}
        >

        <Typography
            sx={{
                fontSize: '1.2rem',
                color: '#6c757d',
                marginTop: '20px',
                }}
        >
            SmartAI Systems recognizes generative AI as a revolutionary force in digital creative expression, opening up new frontiers in art, design, and content creation. This technology allows for the rapid generation of novel images, text, and other media, pushing the boundaries of what's possible in computational creativity.
However, we firmly maintain that the capabilities of generative AI do not and should not override the established intellectual property rights of human artists, writers, and creators.
</Typography>
<Typography
            sx={{
                fontSize: '1.2rem',
                color: '#6c757d',
                marginTop: '20px',
                }}
        >
            We acknowledge the complex legal and ethical questions surrounding AI-generated content and its relationship to the source material used in training. SmartAI Systems is committed to developing and deploying generative AI responsibly, with full respect for copyright laws and the moral rights of creators. 
We advocate for transparent practices in AI development, clear attribution of AI-generated works, and ongoing dialogue with the creative community to ensure that technological advancement and artistic rights evolve in harmony.                          
        </Typography>
        <Divider 
            orientation='horizontal' 
            sx={{
                marginBottom: '10px',
                marginTop: '10px',
                borderColor: '#6c757d',
                borderWidth: '1px',
                width: '60%',                
            }}
          />
        <Typography
            sx={{
                fontSize: '1.2rem',
                color: '#6c757d',
                marginTop: '10px',
                }}
        >
           We are tracking the following legal challenges to AI image generators and will pivot our business model accordingly. 
                              
        </Typography>
        
        <Link href="https://fingfx.thomsonreuters.com/gfx/legaldocs/movalgxbbpa/GOOGLE%20AI%20COPYRIGHT%20LAWSUIT%20complaint.pdf">
            <Typography
                sx={{
                    fontSize: '1.0rem',
                    color: '#6c757d',
                    }}
            >
                Case 3:24-cv-02531 - Zhang, Larson, Andersen and Fink v. Google, LLC and Alphabet Inc.
            </Typography>
        </Link>
        <Link href="https://storage.courtlistener.com/recap/gov.uscourts.cand.407208/gov.uscourts.cand.407208.223.0_2.pdf">
            <Typography
                sx={{
                    fontSize: '1.0rem',
                    color: '#6c757d',
                    }}
            >
                Case 3:23-cv-00201-WHO - SARAH ANDERSEN, et al.,Plaintiffs, v.STABILITY AI LTD., et al., Defendants.
            </Typography>
        </Link>
        </CardContent>  
        </Card>
        </Paper>
    );
  }
  
  export default UnderConstruction;