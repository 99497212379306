import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Box,
  Paper,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Typography,
  Backdrop,
  CircularProgress,
  Divider,
  useMediaQuery
} from "@mui/material";
import { FormControl, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ProductService from "../../services/product.service";
import UserService from "../../services/user.service";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Modal from "../../components/modal/modal";
import { useAuth } from "../../services/auth/use-auth";
import AuthorizeNetLogo from "../../assets/img/authorize-net-visa-logo.jpg";
import CreditPackLogo1 from "../../assets/img/smartai-studio-credits-starter-pack2.png";
import CreditPackLogo4 from "../../assets/img/smartai-studio-credits-visionary-vault-2.png";
import CreditPackLogo2 from "../../assets/img/smartai-studio-credits-creator-constellation-2.png";
import CreditPackLogo3 from "../../assets/img/smartai-studio-credits-innovators-inventory2.png";

import { IMask, IMaskInput } from "react-imask";

import "./credits.css";
import { LoadingButton } from "@mui/lab";

export default function Credits() {
  const [selectedPlan, setSelectedPlan] = React.useState("Free");
  const auth = useAuth();
  //const [{ isPending }] = usePayPalScriptReducer();
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(null);
  //const [credits, setCredits] = useState([]);
  //const [categories, setCategories] = useState(null);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [products, setProducts] = useState([]);

  const [selectedPack, setSelectedPack] = useState(0);
  const [cardNumber, setCardNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [billingPostalCode, setBillingPostalCode] = useState("");
  const [billingCountryRegion, setBillingCountryRegion] =
    useState("United States");
  const [billingState, setBillingState] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingAddress2, setBillingAddress2] = useState("");
  const [billingPhoneNumber, setBillingPhoneNumber] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const onMobile = useMediaQuery("(max-width:600px)");
  const onXSMMobile = useMediaQuery("(max-width:331px)");

  useEffect(() => {
    const fetchData = async () => {
      getCreditPacks();
    };

    const getSubscription = async () => {
      if (auth.user) {
        const response = await UserService.GetUserCredits(
          auth.user.uid,
          auth.user.auth.currentUser.accessToken
        );
        setSubscription(response.creditProfile);

        //// // console.log("CreditProfile: ", JSON.stringify(response.creditProfile));
      } else {
        //const response = await UserService.GetUserCredits("User123");
        var credit = {
          creditProfile: {
            numSubscriptionCreditsRemaining: -1,
            numWalletCreditsRemaining: -1,
            numPendingCredits: -1,
          },
        };
        setSubscription(credit);
        //// // console.log("No Subscription - Not Logged in ", JSON.stringify(credit));
      }
    };

    // {data.title.substring(0, 100) + "..."}
    fetchData();
    getSubscription();
    //getPrices();
  }, [auth.user]);

  const getCreditPacks = async () => {
    // get products
    const productResponse = await ProductService.GetCreditPacks();
    //// // console.log("CreditPacks:", productResponse);
    setProducts(productResponse);
  };

  const getCreditPackNumCredits = (index) => {
    //// // console.log("getCreditPackNumCredits(): index: " + index);
    if (!products) getCreditPacks();
    if (products && products.length > 0) {
      var pack = products[index];
      //// // console.log("getCreditPackNumCredits(): " + JSON.stringify(pack));

      if (pack) {
        var credits = pack.creditPackQuantity;
        return credits;
      }
    }

    return "0";
  };

  const getCreditPackPrice = (index) => {
    //// // console.log("getCreditPackPrice(): index: " + index);
    if (!products) getCreditPacks();
    if (products && products.length > 0) {
      var packPrices = [4.99, 9.99, 19.99, 24.99];
      //// // console.log("getCreditPackPrice(): " + JSON.stringify(packPrices[index]));

      return packPrices[index];
    }

    return "0.0";
  };

  const getPriceString = (index) => {
    var price = getCreditPackPrice(index);

    if (price) {
      var priceFormatted = price.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
      return priceFormatted + " USD";
    } else {
      return "0.00 USD";
    }
  };

  const purchasePackPP = async (transactionId) => {
    if (!products) getCreditPacks();

    var packSelected = products[selectedPack];
    //// // console.log(
    //  "purchasePackPP(): Selected Pack" + JSON.stringify(packSelected)
    // );

    if (packSelected) {
      var price = getCreditPackPrice(selectedPack);

      // // // console.log(
      //  "purchasePackPP(): " + price + " transaction Id: " + transactionId
      //);
      //if (auth.user && acceptTerms && auth.user.id && identifier !== "") {
      if (auth.user) {
        /*
                // // console.log(
                  "purchasePackPP() - Passed  check: id #",
                  packSelected.creditPackId,
                  ", acceptTerms " + acceptTerms,
                  " user: ",
                  JSON.stringify(auth.user.id)
                );
                */

        var userId = auth.user.uid;

        const response = await ProductService.PurchaseCreditPackPP(
          userId,
          auth.user.auth.currentUser.accessToken,
          packSelected.creditPackId,
          price,
          transactionId
        );

        if (response.success === false) {
          alert("Purchase failed: " + response.message);
          // // console.log("purchasePack: " + response);
        } else {
          alert(
            "Successful! - moving ",
            `${packSelected.creditPackQuantity}`,
            " credits to your account."
          );
          navigate("/profile");
        } // purchase repsonse check
      } // product purchase check
      else alert("Please login or register to purchase credits");
    } // product check
    else
      alert(
        "Pack not found - contact support to get your credits added to your account"
      );
    setLoading(false);
  };

  const checkLuhn = (cardnumber) => {
    // Build an array with the digits in the card number
    var digits = cardnumber.split("");
    for (var i = 0; i < digits.length; i++) {
      digits[i] = parseInt(digits[i], 10);
    }
    // Run the Luhn algorithm on the array
    var sum = 0;
    var alt = false;
    for (i = digits.length - 1; i >= 0; i--) {
      if (alt) {
        digits[i] *= 2;
        if (digits[i] > 9) {
          digits[i] -= 9;
        }
      }
      sum += digits[i];
      alt = !alt;
    }
    // Check the result
    if (sum % 10 === 0) {
      //// // console.log("Luhn check passed");
      return true;
    } else {
      //// // console.log("Luhn check failed");
      return false;
    }
  };
  const validateCC = () => {
    //// // console.log("validateCC - checking");
    let error = "";

    if (cardNumber && cardNumber.length === 16) {
      if (
        expirationDate &&
        (expirationDate.length === 4 || expirationDate.length === 5)
      ) {
        if (cvv && (cvv.length === 3 || cvv.length === 4)) {
          if (billingCountryRegion) {
            if (billingState) {
              if (billingCity) {
                if (billingAddress) {
                  if (billingPostalCode) {
                    if (firstName) {
                      if (lastName) {
                        var card = {
                          creditCardNumber: cardNumber,
                          expirationDateString: expirationDate,
                          cvv: cvv,
                          countryRegion: billingCountryRegion,
                          state: billingState,
                          city: billingCity,
                          addressLine1: billingAddress,
                          addressLine2: billingAddress2,
                          postalCode: billingPostalCode,
                          phoneNumber: billingPhoneNumber,
                          email: billingEmail,
                          firstName: firstName,
                          lastName: lastName,
                        };

                        if (checkLuhn(card.creditCardNumber)) {
                          // // console.log("validateCC(): card number is valid");
                          return card;
                        } else {
                          error = "card number is invalid";
                          //return null;
                        }
                      } else error = "invalid last name";
                    } else error = "invalid first name";
                  } else error = "invalid postal code)";
                } else error = "invalid address";
              } else error = "invalid city";
            } else error = "invalid state";
          } else error = "invalid country";
        } else error = "invalid cvv";
      } else error = "invalid expiration date";
    } else error = "invalid card number";

    // // console.log("validateCC(): " + error);
    alert("Failed credit card validation: " + error);
    return null;
  };

  const purchasePackViaCC = async () => {
    if (!products) getCreditPacks();

    var packSelected = products[selectedPack];
    var price = getCreditPackPrice(selectedPack);
    //// // console.log(
    //  "purchasePackViaCC(): Selected Pack" + JSON.stringify(packSelected)
    //);

    if (auth.user && acceptTerms && packSelected && price) {
      /*
            // // console.log(
              "purchasePackViaCC() - Passed preconditions: id #",
              packSelected.content_pack_id,
              ", product id:",
              JSON.stringify(packSelected.creditPackId),
              ", product:",
              JSON.stringify(packSelected),
              " acceptTerms " + acceptTerms,
              " user: ",
              JSON.stringify(auth.user.uid)
            );
            */

      var userId = auth.user.uid;
      var email = auth.user.email;

      let creditCardInfo = validateCC();
      if (creditCardInfo) {
        if (auth.user && acceptTerms) {
          /*
                        // // console.log(
                          "purchasePackViaCC() - Passed  check: id #",
                          packSelected.id,
                          ", acceptTerms " + acceptTerms,
                          " user: ",
                          JSON.stringify(auth.user.uid)
                        );
                        */
          var userId = auth.user.uid;
          var email = auth.user.email;
          try {
            const response = await ProductService.PurchaseCreditPackCC(
              userId,
              auth.user.auth.currentUser.accessToken,
              email,
              packSelected.creditPackId,
              price,
              "USD",
              creditCardInfo
            );

            if (response.success === false) {
              alert("Purchase failed: " + response.message);
            } else {
              //// // console.log("downloadPack: " + response.product.pack_bundle_url);
              //// // console.log("downloadPack: " + response.product.assetUrl);
              //downloadBundle(response.product.pack_bundle_url);
              navigate("/profile");
            } // purchase repsonse check
          } catch (error) {
            // // console.log("purchasePack: " + error);
            alert("Purchase failed: " + error);
          }
        } // ccinfo check
        else {
          alert("Please fill out all required credit  cards fields");
        }
      } // credit card check
    } // user check
    else {
      alert("Pack not found - skipping purchase flow");
    }
    setLoading(false);
  };
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    //// // console.log("handleSubmit");
    var response = purchasePackViaCC();
    //// // console.log("handleSubmit: " + JSON.stringify(response));
  };

  const FreePlan = (
    <div className="col">
      <div className="card pricing-item mb-4 rounded-3 shadow-sm">
        <div className="card-header text-bg-primary py-3"></div>
        <div className="card-body d-flex flex-column">
          <h5 className="fw-bold card-title pricing-card-title mb-4">
            Current
          </h5>
        </div>
        <div className="card-body d-flex flex-column">
          <h5 className="fw-bold card-title pricing-card-title mb-4">
            Free (Current Plan)
          </h5>
          <h5 className="text-muted fw-light card-title pricing-card-title mb-4">
            (ALPHA RELEASE)
          </h5>
          <h2 className="card-title pricing-card-title fw-bold">$0</h2>
          <h4>
            <small className="text-muted fw-light">a month</small>
          </h4>
          <p className="fw-bold mt-2">50 Credits</p>
          <ul className="mt-3 mb-4 text-start">
            <li>No watermark on downloaded images</li>
            <li>Maximum download size 1024x1024</li>
          </ul>

          <Link to={!auth.user ? "/free-signup" : "/signup-payment"}>
            {auth.user ? (
              <Button
                variant="contained"
                hidden={modalOpen ? true : false}
                className="mt-auto w-100 button"
              >
                Continue
              </Button>
            ) : (
              <div
                style={{
                  padding: "10px",
                  border: "2px solid gray",
                  color: "#000000",
                  borderRadius: "10px",
                  marginTop: "10px",
                  backgroundColor: "#f2e1e1",
                  textDecoration: "none",
                }}
              >
                FREE PLAN (no credit card req.)
              </div>
            )}
          </Link>
        </div>
      </div>
    </div>
  );

  function CreditPackCore(index, name, logo) {
    return (
      <div className="col">
        <div className="col">
          <div className="card mb-4 rounded-3 shadow-sm">
            <Card className="d-flex flex-column button-centered">
              <CardMedia
                sx={{
                  border: "1px solid #e0e0e0",
                }}
                component="img"
                image={logo}
                alt="Credit Pack 1"
              />
              <CardContent
                className="d-flex flex-column"
                sx={{
                  flexGrow: 1,
                  justifyContent: "center",
                  border: "none",
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  className="fw-bold card-title pricing-card-title mb-2"
                >
                  {name}
                </Typography>
                <Typography
                  className="fw-bold card-title pricing-card-title mb-2"
                  variant="h5"
                  color="text.secondary"
                >
                  {getCreditPackNumCredits(index) + " Credits"}
                </Typography>
                <Typography
                  className="card-title pricing-card-title fw-bold"
                  variant="h5"
                  color="text.secondary"
                >
                  {getPriceString(index)}
                </Typography>
              </CardContent>
              <CardActions>
                <div className="button-centered">
                  <Button
                    sx={{
                      width: "120px",
                    }}
                    variant="contained"
                    className="mt-auto rounded-pill"
                    disabled={auth.user ? false : true}
                    hidden={modalOpen ? true : false}
                    onClick={() => {
                      setSelectedPack(index);
                      if (auth.user) {
                        setModalOpen(true);
                      } else {
                        setModalOpen(true);
                      }
                    }}
                  >
                    BUY
                  </Button>
                </div>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  /*
    const CreditPack1 = { CreditPackCore(0) };
        <div className="col">
            <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header text-bg-secondary py-3"></div>
                <Card
                    className="d-flex flex-column button-centered"
                    sx={{
                        maxWidth: 345,
                        
                    }}
                >
                    <CardMedia
                        sx={{
                            border: "1px solid #e0e0e0",
                        }}
                        component="img"                       
                        image={CreditPackLogo1}
                        alt="Credit Pack 1"
                    /> 
                    <CardContent
                        className="d-flex flex-column"
                        sx={{
                            flexGrow: 1,
                            justifyContent: "center",
                            border: "none"
                        }}
                    >
                        <Typography                            
                            variant="h5"
                            component="div"
                            className="fw-bold card-title pricing-card-title mb-2"
                        >
                            Spark Starter Pack
                        </Typography>
                        <Typography
                            className="fw-bold card-title pricing-card-title mb-2"
                            variant="h5"
                            color="text.secondary"
                        >
                            {getCreditPackNumCredits(0) + " Credits"}
                        </Typography>
                        <Typography
                            className="card-title pricing-card-title fw-bold"
                            variant="h5"
                            color="text.secondary"
                        >
                            {getPriceString(0)}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <div className="button-centered">
                        <Button
                            sx={{
                                width: "120px",
                            }}
                            variant="contained"
                            className="mt-auto rounded-pill"
                            disabled={auth.user ? false : true}
                            hidden={modalOpen ? true : false}
                            onClick={() => {
                                setSelectedPack(0);
                                if (auth.user) {
                                    setModalOpen(true);
                                } else {
                                    setModalOpen(true);
                                }
                            }}
                        >
                            BUY
                        </Button>
                        </div>
                    </CardActions>
                </Card>
            </div>
            </div>
        </div>
    );

    const CreditPack2 = (
        <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header text-bg-secondary py-3"></div>
                <div className="card-body mx-2 d-flex flex-column">
                    <h5 className="fw-bold card-title pricing-card-title mb-2">
                    Visionary's Vessel Pack
                    </h5>
                    <h5 className="fw-bold card-title pricing-card-title mb-2">
                        {getCreditPackNumCredits(1) + " Credits"}
                    </h5>
                    <h2 className="card-title pricing-card-title fw-bold">
                        {getPriceString(1)}
                    </h2>
                    <div className="button-centered">
                        <Button
                            variant="contained"
                            className="mt-auto rounded-pill w-50"
                            disabled={auth.user ? false : true}
                            hidden={modalOpen ? true : false}
                            onClick={() => {
                                setSelectedPack(1);
                                if (auth.user) {
                                    setModalOpen(true);
                                } else {
                                    setModalOpen(true);
                                }
                            }}
                        >
                            Buy
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );

    const CreditPack3 = (
        <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header text-bg-secondary py-3"></div>
                <div className="card-body mx-2 d-flex flex-column">
                    <h5 className="fw-bold card-title pricing-card-title mb-2">
                    Innovator's Inventory Pack
                    </h5>
                    <h5 className="fw-bold card-title pricing-card-title mb-2">
                        {getCreditPackNumCredits(2) + " Credits"}
                    </h5>
                    <h2 className="card-title pricing-card-title fw-bold">
                        {getPriceString(2)}
                    </h2>
                    <div className="button-centered">
                        <Button
                            variant="contained"
                            className="mt-auto rounded-pill w-50"
                            disabled={auth.user ? false : true}
                            hidden={modalOpen ? true : false}
                            onClick={() => {
                                setSelectedPack(2);
                                if (auth.user) {
                                    setModalOpen(true);
                                } else {
                                    setModalOpen(true);
                                }
                            }}
                        >
                            Buy
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );

    const CreditPack4 = (
        <div className="col">
            <div className="card rounded-3 shadow-sm">
                <div className="card-header text-bg-secondary py-3"></div>
                <div className="card-body mx-2 d-flex flex-column">
                    <h5 className="fw-bold card-title pricing-card-title mb-2">
                    Masterpiece Mint Pack
                    </h5>
                    <h5 className="fw-bold card-title pricing-card-title mb-2">
                        {getCreditPackNumCredits(3) + " Credits"}
                    </h5>
                    <h2 className="card-title pricing-card-title fw-bold">
                        {getPriceString(3)}
                    </h2>
                    <div className="button-centered">
                        <Button
                            variant="contained"
                            className="mt-auto rounded-pill w-50"
                            disabled={auth.user ? false : true}
                            hidden={modalOpen ? true : false}
                            onClick={() => {
                                setSelectedPack(3);
                                if (auth.user) {
                                    setModalOpen(true);
                                } else {
                                    setModalOpen(true);
                                }
                            }}
                        >
                            Buy
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
    */

  const getCreditDisplay = (index) => {
    switch (index) {
      case 0:
        return CreditPackCore(0, "Spark Starter Pack", CreditPackLogo1);
      case 1:
        return CreditPackCore(1, "Visionary Vessel Pack", CreditPackLogo2);
      case 2:
        return CreditPackCore(2, "Innovator Inspire Pack", CreditPackLogo3);
      case 3:
        return CreditPackCore(3, "Masterpiece Mint Pack", CreditPackLogo4);
      default:
        return CreditPackCore(0);
    }
  };

  const inputTermsPrivacy = (
    <div className="mb-3 form-check mx-auto d-flex justify-content-center">
      <input
        type="checkbox"
        className="form-check-input me-3"
        checked={acceptTerms}
        onChange={(e) => setAcceptTerms(!acceptTerms)}
        id="terms"
      />
      <label className="form-check-label" htmlFor="terms">
        I agree to the{" "}
        <a
          rel="noopener"
          className="text-decoration-underline title"
          href="https://app.termly.io/document/terms-of-use-for-website/376fff7a-0bd7-4e6b-a466-732926a0cbce"
          target="_new"
        >
          {" "}
          terms &amp; conditions
        </a>        
      </label>      
    </div>
  );

  const authorizeNetSection = (
    <div id="an-toplevel">
      <div className="AuthorizeNetSeal">
        <script type="text/javascript" language="javascript">
          var ANS_customer_id = "1b923780-b62e-42c7-a551-eef1f3faeedf";
        </script>
        <script
          type="text/javascript"
          language="javascript"
          src="//verify.authorize.net:443/anetseal/seal.js"
        ></script>
      </div>
      <div>
        <a href="https://usa.visa.com/legal/privacy-policy.html">
          Authorize.Net Privacy Policy
        </a>
      </div>
    </div>
  );

  const onPaypalCreateOrder = (paypalData, actions) => {
    if ("paymentSource" in paypalData) {
      if (paypalData.paymentSource !== "card") {
        setLoading(true);
      }
    }
    // // console.log("onPaypalCreateOrder: " + JSON.stringify(paypalData));
    // // console.log("onPaypalCreateOrder: " + JSON.stringify(data));

    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: getCreditPackPrice(selectedPack),
          },
        },
      ],
    });
  };

  const onPaypalApproveOrder = (data, actions) => {
    setLoading(true);
    // // console.log("onPaypalApproveOrder: " + JSON.stringify(data));
    return actions.order.capture().then((details) => {
      //const name = details.payer.name.given_name;
      const transactionId = details.purchase_units[0].payments.captures[0].id;
      //// // console.log("Paypal Transaction Details: " + JSON.stringify(details));
      //// // console.log("Calling post paypal purchase workflow");
      purchasePackPP(transactionId);
    });
  };

  /* PAYPAL - PAYMENT */
  const PayPalOrderSection = (
    <PayPalScriptProvider
      options={{
        "client-id":
          "AYCBsFGtcEQDi1Ya3-Y_-VGVuBDeR8w6hwOGJgY0uh7_Td0V9SErINEmldT19ERsj88ayON_ChmkBSXx", // LIVE
        // "client-id": "AdeF5xY0p5wqpz3dirQW6GYTxAUo674falyk-oZXjTlSXEPzVs3DHC2IhtLA2vEqLgMay4wwNAasWwyZ", // sandbox
        components: "buttons",
      }}
    >
      <PayPalButtons
        style={{ layout: "vertical" }}
        disabled={!acceptTerms}
        createOrder={(data, actions) => onPaypalCreateOrder(data, actions)}
        onApprove={(data, actions) => onPaypalApproveOrder(data, actions)}
        onCancel={(data) => setLoading(false)}
        onError={(err) => {
          alert("There was an error talking with PayPal");
          setLoading(false);
        }}
      />
    </PayPalScriptProvider>
  );

  function handleCancelPayment() {
    setLoading(false);
    setModalOpen(false);
    }

  const CreditCardExpiration = React.forwardRef(function TextMaskCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="MM/YY"
        lazy={true}
        autofix={true}
        overwrite={true}
        onComplete={(value) =>
          onChange({
            target: { name: props.name, value: value.replace("/", "") },
          })
        }
        blocks={{
          MM: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
          },
          YY: {
            mask: IMask.MaskedRange,
            from: 23,
            to: 99,
          },
        }}
        inputRef={ref}
      />
    );
  });

  let _width = onMobile ? 200 : 300;

  const creditCardForm = (
    <div>
        <FormControl className="" onSubmit={handleSubmit}
         style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
        }}
        >
        <TextField
            sx={{ minWidth: _width, width: _width, mt: 1, mb: 1 }}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]* " }}
            disabled={!acceptTerms}
            label="Card Number"
            required={true}
            value={cardNumber}
            //helperText="Enter credit card number"
            onChange={(event) => setCardNumber(event.target.value)}
        />
        <TextField
            sx={{ width: _width, mt: 1, mb: 1 }}
            disabled={!acceptTerms}
            label="Expiration Date (MM/YY)"
            InputProps={{ inputComponent: CreditCardExpiration }}
            required={true}
            name="expirationDate"
            defaultValue="MM/YY"
            value={expirationDate}
            onChange={(event) => setExpirationDate(event.target.value)}
        ></TextField>
        <TextField
            sx={{ width: _width, mt: 1, mb: 1 }}
            disabled={!acceptTerms}
            label="CVV (3 or 4 digit code on card)"
            required={true}
            inputProps={{ inputMode: "numeric", pattern: "/^[0-9]{3,4}$/" }}
            value={cvv}
            //helperText="CVV"
            onChange={(event) => setCvv(event.target.value)}
        />
        <TextField
            sx={{ width: _width, mt: 1, mb: 1 }}
            disabled={!acceptTerms}
            label="Email"
            required={false}
            type="email"
            value={billingEmail}
            //helperText="Email"
            onChange={(event) => setBillingEmail(event.target.value)}
        />
        <TextField
            sx={{ width: _width, mt: 1, mb: 1 }}
            disabled={!acceptTerms}
            label="First Name"
            required={true}
            type="text"
            value={firstName}
            //helperText="First Name"
            onChange={(event) => setFirstName(event.target.value)}
        />
        <TextField
            sx={{ width: _width, mt: 1, mb: 1 }}
            disabled={!acceptTerms}
            label="Last Name"
            required={true}
            type="text"
            value={lastName}
            //helperText="Last Name"
            onChange={(event) => setLastName(event.target.value)}
        />

        <TextField
            sx={{ width: _width, mt: 1, mb: 1 }}
            disabled={!acceptTerms}
            label="Address Line 1"
            required={true}
            type="text"
            value={billingAddress}
            //helperText="Address Line 1"
            onChange={(event) => setBillingAddress(event.target.value)}
        />
        <TextField
            sx={{ width: _width, mt: 1, mb: 1 }}
            disabled={!acceptTerms}
            label="Address Line 2"
            required={false}
            type="text"
            value={billingAddress2}
            //helperText="Address Line 2"
            onChange={(event) => setBillingAddress2(event.target.value)}
        />
        <TextField
            sx={{ width: _width, mt: 1, mb: 1 }}
            disabled={!acceptTerms}
            label="City"
            required={true}
            type="text"
            value={billingCity}
            //helperText="City"
            onChange={(event) => setBillingCity(event.target.value)}
        />
        <TextField
            sx={{ width: _width, mt: 1, mb: 1 }}
            disabled={!acceptTerms}
            label="State"
            required={true}
            type="text"
            value={billingState}
            //helperText="State"
            onChange={(event) => setBillingState(event.target.value)}
        />

        <TextField
            sx={{ width: _width, mt: 1, mb: 1 }}
            disabled={!acceptTerms}
            label="Billing Zip"
            required={true}
            type="number"
            value={billingPostalCode}
            //helperText="Zip Code"
            onChange={(event) => setBillingPostalCode(event.target.value)}
        />
        <TextField
            sx={{ width: _width, mt: 1, mb: 1 }}
            disabled={true}
            label="Country"
            required={true}
            type="text"
            value={"United States"}
            //helperText="Country"
            onChange={(event) => setBillingCountryRegion("United States")}
        />

        <TextField
            sx={{ width: _width, mt: 1, mb: 1 }}
            disabled={!acceptTerms}
            label="Phone"
            required={false}
            type="phone"
            value={billingPhoneNumber}
            //helperText="Phone"
            onChange={(event) => setBillingPhoneNumber(event.target.value)}
        />

        <div className="mt-3 button-centered">
            <LoadingButton
             sx={{ width: _width, mt: 1, mb: 1 }}
            disabled={!acceptTerms}
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleSubmit}
            className="button mt-1 red-button red-pill-button"
            >
            Submit Payment {" "}
            {(acceptTerms) 
                ? (selectedPack >= 0 ? getPriceString(selectedPack) : "not selected")
                : "Please Accept Terms"            
            }
            </LoadingButton>
        </div>    
        <div className="mt-3 button-centered">
            <Button
            sx={{ width: _width, mt: 1, mb: 1 }}
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleCancelPayment}
            className="button mt-1 red-button red-pill-button"
            >
            Cancel
            </Button>
        </div>        
        </FormControl>
    </div>
  );

  
  const renderSubscriptionInfo = () => {
    return (
      subscription &&
      subscription.numSubscriptionCreditsRemaining !== undefined && (
        <Paper
          sx={{
            padding: "10px",
            backgroundColor: "#f2f2f2",
            border: "1px solid #e0e0e0",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          <div className="row">
            <div className="col d-flex justify-content-center">
              <div className="fw-bold">
                Current Monthly Credits:{" "}
                {subscription.numSubscriptionCreditsRemaining}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-center">
              <div className="fw-bold">
                Current Wallet Credits: {subscription.numWalletCreditsRemaining}
              </div>
            </div>
          </div>
        </Paper>
      )
    );
  };

  const paymentModalContent = () => {
    return (
      <div
        className="align-items-center"
        style={{
          backgroundColor: "#f2f2f2",
          borderRadius: "10px",
          padding: "5px",          
          minWidth: "300px",
        }}
      >
        <div className="text-center align-items-end">
          {
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 2,
              }}
            >
              {getCreditDisplay(selectedPack)}
            </Box>
          }
          <br />

          <div className="mt-1">
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {inputTermsPrivacy}              
            </Box>
            <Typography
                variant="caption"
                sx={{
                    color: "text.secondary",
                    fontSize: "0.75rem",
                    textTransform: "uppercase",
                    padding: 0,
                    margin: 0,
                }}
            >
                All sales are final
            </Typography>
          </div>
          <br />
          <Divider />
          <div id="pm_section">
          <Typography 
                id="or"
                variant="h6"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                    marginTop: "20px",
                    marginBottom: "50px",
                    fontSize: "1.5em",
                    fontWeight: "bold",
                    color: "#f2f2f2",
                    backgroundColor: "#183e4b",
                    height: "100px", 
                }}
            >
                Payment Option #1
            </Typography>
            <div className="">{PayPalOrderSection}</div>

            <Typography 
                id="or"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  height: "100px", 
                    marginTop: "20px",
                    marginBottom: "20px",
                    fontSize: "1.5em",
                    fontWeight: "bold",
                    color: "#f2f2f2",
                    backgroundColor: "#183e4b",
                }}
            > Payment Option #2
            </Typography>
            <div className="cc-form mb-3 align-items-center justify-content-center">
              <div className="d-flex bg-white flex-column align-items-center justify-content-center">
                <img src={AuthorizeNetLogo} className="mt-3" />
                <br />
                {authorizeNetSection}
              </div>
              {creditCardForm}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div sx={{ minWidth: 400, maxWidth: 1200, margin: "auto" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {modalOpen && (
        <Modal
          setOpenModal={setModalOpen}
          styles={{ overlay: { background: "#00FF00" } }}
          title="Confirm Purchase"
          children={paymentModalContent()}
          backdropOpacity={0.5}
          portalClassName="modal-container"
          headerClassName="modal-header"
          bodyClassName="modal-body"
        />
      )}

      <div className="container py-3">
        <header>
          <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
            <h1 className="display-5 fw-normal">Explore Our Credit Packs</h1>
          </div>
        </header>

        {renderSubscriptionInfo()}

        <main>
          <h4 className="text-center mb-4">
            Credits Added to Wallet - Never Expire
          </h4>

          <div className="row row text-center row-cols-2 row-cols-md-4 gy-2">
            {getCreditDisplay(0)}
            {getCreditDisplay(1)}
            {getCreditDisplay(2)}
            {getCreditDisplay(3)}
          </div>
        </main>       
      </div>
    </div>
  );
}
