import React, { useRef, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

function Searchbar() {
  const [val, setVal] = useState("");

  const navigate = useNavigate();

  const search = () => {
    if (val.length !== 0) {
      navigate({
        pathname: "/search",
        search: createSearchParams({
          q: val,
        }).toString(),
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      search();

    }
  };

  return (
    <>
      <div className="searchBar col-lg-6 mx-auto">
        <input
          id="searchQueryInput"
          type="text"
          name="searchQueryInput"
          placeholder="search for AI-generated stock images"
          value={val}
          onChange={(e) => {
            setVal(e.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
        <button
          id="searchQuerySubmit"
          type="submit"
          name="searchQuerySubmit"
          onClick={search}
        >
          <i className="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </>
  );
}

export default Searchbar;
