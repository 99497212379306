import React, { useRef, useEffect, useState } from "react";

export function CanvasFilters(props) {
  const canvasRef = useRef(null);
  // ... other state and functions

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const baseImage = new Image();
    baseImage.src = props.baseImageUrl;
    // baseImage.crossOrigin = "anonymous";
    baseImage.onload = () =>
      context.drawImage(baseImage, 0, 0, canvas.width, canvas.height);
    //console.log('CanvasFilters: useEffect: props.filterType = ' + props.filterType);
    switch (props.filterType) {
      case "border-thin":
        addFrame("thin border");
        break;
      case "border-thick":
        addFrame("thick border");
        break;
      case "blur":
        applyEffect("blur");
        break;
      case "sharpen":
        applyEffect("sharpen");
        break;
      case "emboss":
        applyEffect("emboss");
        break;
      case "brightness":
        applyEffect("brightness");
        break;
      case "invert":
        applyEffect("invert");
        break;
      case "contrast":
        applyEffect("contrast");
        break;
      case "gamma":
        applyEffect("gamma");
        break;
      case "hue-rotate":
        applyEffect("hue-rotate");
        break;
      case "saturate":
        applyEffect("saturate");
        break;
      case "sepia":
        applyEffect("sepia");
        break;
      case "grayscale":
        applyEffect("grayscale");
        break;
      case "opacity":
        applyEffect("opacity");
        break;

      default:
        // ... logic to apply no filter
        break;
    }
  }, [props /* other dependencies */]);

  // Function to add another image
  const addImage = (imageUrl, x, y) => {
    // ... logic to load and blend image at (x, y)
  };

  // Function to apply filters and effects
  const applyEffect = (effectType) => {
    // ... logic to apply specified effect

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    //console.log("applyEffect: effectType = " + effectType);
    //console.log("applyEffect: props.filterAmount = " + props.filterAmount);

    switch (effectType) {
      case "blur":
        context.filter = `blur(${props.filterAmount}px)`;
        context.drawImage(canvas, 0, 0);
        break;
      case "sharpen":
        context.filter = "blur(0px)";
        context.drawImage(canvas, 0, 0);
        break;
      case "emboss":
        context.filter = `emboss(${props.filterAmount}px)`;
        context.drawImage(canvas, 0, 0);
        break;
      case "brightness":
        context.filter = `brightness(${props.filterAmount + 50}%)`;
        context.drawImage(canvas, 0, 0);
        break;
      case "invert":
        context.filter = `invert(${props.filterAmount}%)`;
        context.drawImage(canvas, 0, 0);
        break;
      case "contrast":
        let contrastAmount = (props.filterAmount / 50);
        //console.log("applyEffect: contrastAmount = " + contrastAmount);
        context.filter = `contrast(${contrastAmount})`; //`contrast(${props.filterAmount})`;
        context.drawImage(canvas, 0, 0);
        break;
      case "gamma":
        context.filter = `gamma(${props.filterAmount})`;
        context.drawImage(canvas, 0, 0);
        break;
      case "hue-rotate":
        context.filter = `hue-rotate(${props.filterAmount * 3.6}deg)`;
        context.drawImage(canvas, 0, 0);
        break;
      case "saturate":
        let saturateAmount = (props.filterAmount/50);
        //console.log("applyEffect: saturateAmount = " + saturateAmount);
        context.filter = `saturate(${saturateAmount})`;
        context.drawImage(canvas, 0, 0);
        break;

      case "sepia":
        context.filter = `sepia(${props.filterAmount}%)`;
        context.drawImage(canvas, 0, 0);
        break;
      case "opacity":
        context.filter = `opacity(${props.filterAmount})`;
        context.drawImage(canvas, 0, 0);
        break;
      case "grayscale":
        context.filter = `grayscale(${props.filterAmount}%)`;
        context.drawImage(canvas, 0, 0);
        break;

      default:
        console.log("Default", props);

        // ... logic to draw no frame
        break;
    }
  };

  // Function to add frame
  const addFrame = (frameType) => {
    // ... logic to draw frame around the image

    console.log("addFrame: frameType = " + frameType);

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    switch (frameType) {
      case "thin border":
        context.strokeStyle = `${props.color1}`;
        context.lineWidth = 1;
        context.strokeRect(0, 0, canvas.width, canvas.height);
        break;
      case "thick border":
        // ... logic to draw thick border
        context.strokeStyle = `${props.color1}`;
        context.lineWidth = 15;
        context.strokeRect(0, 0, canvas.width, canvas.height);

        break;
      default:
        // ... logic to draw no frame
        break;
    }
  };

  // ... other functions and return statement

  return (
    <canvas
      ref={canvasRef}
      baseimageurl={props.baseImageUrl}
      color1={props.color1}
      color2={props.color2}
      filtertype={props.filterType}
      filteramount={props.filterAmount}
      width={props.width}
      height={props.height}
      onClick={props.handleCanvasClick}
      style={{
        width: "100%",
      }}
    />
  );
}

export default CanvasFilters;

// ... CanvasComponent and example usage
