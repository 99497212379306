import React, { useState } from "react";
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";

import "./Dropdown.css";

class Dropdown extends React.Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
  };

  static defaultProps = {
    list: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      labelItem: null,
      typeDropdown: null,
    };
  }

  componentWillMount() {
    const { label } = this.props.list[0];
    let firstItem = null;
    if (typeof label != "undefined") {
      this.checkType(false);
      firstItem = label;
    } else {
      this.checkType(true);
      firstItem = this.props.list[0];
    }
    this.setState({
      labelItem: firstItem,
    });
  }

  checkType = (value) => {
    this.setState({
      typeDropdown: value,
    });
  };

  showDropdown = (e) => {
    this.setState({ isOpen: true });
    e.stopPropagation();
    document.addEventListener("click", this.hideDropdown);
};

  hideDropdown = () => {
    this.setState({ isOpen: false });
    document.removeEventListener("click", this.hideDropdown);
  };

  chooseItem = (value) => {
    if (this.state.labelItem !== value) {
      this.setState({
        labelItem: value,
      });
    }
  };

  renderDataDropDown = (item, index) => {
    const { value, label, link } = this.state.typeDropdown
      ? { value: index, label: item.label, route: item.link }
      : item;
    return (
      <li key={index} value={value} onClick={() => this.chooseItem(label)}>
        <Link to={{pathname: link, search: item.search}}>{label}</Link>
      </li>
    );
  };

  render() {
    const { list } = this.props;

    return (
        <div className={`dropdown${this.state.isOpen ? ' open' : ''}`}>
            <button className="dropdown-arrow" type="button" onClick={this.showDropdown}>
                {this.props.children}&nbsp;
                {/* {this.state.labelItem}&nbsp; */}
                <i className="fa-solid fa-chevron-down"></i>
            </button>
            <ul className="dropdown-menu">
                {list.map(this.renderDataDropDown)}
            </ul>
        </div>
    );
  }
}

export default Dropdown;
