/* eslint-disable react/function-component-definition */

import * as React from "react";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../services/auth/use-auth";
import UserService from "../../services/user.service";
import useGoogleOneTap from "../../hooks/useGoogleOneTap";
import { GoogleAuthProvider } from "firebase/auth";

import "./LoginPage.scss";
import useScript from "../../hooks/useScript";
import { Backdrop, Box, Button, CircularProgress, Divider, Paper } from "@mui/material";

const LoginPage = () => {
  const auth = useAuth();
  // const script = useScript("https://accounts.google.com/gsi/client");
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loadingProfile, setLoadingProfile] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showEmailPassword, setShowEmailPassword] = React.useState(false);

  /*
  const handleLoginWithGoogle = async () => {
    try {
      setLoadingProfile(true);
      var result = await auth.signInWithPopupFlow();
      // console.log("result: ", result);
      var userDetails = auth.getAdditionalUserInfoFlow(result);
      // console.log("userDetails: ", userDetails);
      var currentUser = result.user;
      // console.log("Successfully created account using Google");
      const isNewUser = userDetails.isNewUser;
      if (isNewUser) {
        // console.log("Creating New user");
        const newUser = {
          userId: currentUser.uid,
          email: currentUser.email,
          password: null,
          displayName: currentUser.displayName,
          photoURL: currentUser.photoURL,
        };
        var createNewUserRes = await UserService.CreateNewUserAccount(newUser);
        // console.log(createNewUserRes);
        if (createNewUserRes?.userId) {
          setLoadingProfile(false);
          navigate("/");
        }
      } else {
        setLoadingProfile(false);
        navigate("/");
      }
    } catch (error) {
      // console.log("Error creating account using Google");
      // console.log(error);
    }
  };
  */

  const handleLogin = async () => {
    setLoading(true);
    try {
      await auth.signin(email, password);
      navigate("/profile");
    } catch (error) {
      setError(true);
      if (error.code === "auth/wrong-password") {
        setErrorMessage("Invalid password. Please try again.");
      } else if (error.code === "auth/too-many-requests") {
        setErrorMessage(
          "Too many failed login attempts. Please try again later, or reset your password."
        );
      } else if (error.code === "auth/user-not-found") {
        setErrorMessage(
          "We could not find an account with that email address. Please try again."
        );
      } else {
        setErrorMessage("An error occurred. Please try again later.");
      }
      // console.log(error.message);
    }
    setLoading(false);
  };
  const validateInput = () => {
    // check email regex
    // check password length
    var emailValidated = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/.test(email);
    // var passwordValidated = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm.test(password);
    return emailValidated;
  };
  React.useEffect(() => {
    if (auth.user && !loadingProfile) {
      navigate("/profile");
    }
  });

  const handleGoogleOneTapResponse = async (response) => {
    setLoading(true);
    try {
      // console.log(
      //   "SUCCESS: Signin with Google One Tap Success: ",
      //   response.credential
      // );
      const credential = response.credential;
      const googleAuthCredential = GoogleAuthProvider.credential(credential);
      // console.log("googleAuthCredential: ", googleAuthCredential);
      var result = await auth.signInWithCredentialFlow(googleAuthCredential);
      // console.log("result: ", result);
      var userDetails = auth.getAdditionalUserInfoFlow(result);
      // console.log("userDetails: ", userDetails);
      var currentUser = result.user;
      // console.log("Successfully created account using Google");
      const isNewUser = userDetails.isNewUser;
      if (isNewUser) {
        // console.log("Creating New user");
        const newUser = {
          userId: currentUser.uid,
          email: currentUser.email,
          password: null,
          displayName: currentUser.displayName,
          photoURL: currentUser.photoURL,
        };
        var createNewUserRes = await UserService.CreateNewUserAccount(newUser);
        // console.log(createNewUserRes);
        if (createNewUserRes?.userId) {
          navigate("/");
        } else {
          alert("Failed to create new user - please contact support team or try another method.");
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      // console.log("ERROR: Signin with Google One Tap Error: ", error);
    }
    setLoading(false);
  };

  useGoogleOneTap({
    // onError: (error) => // console.log("Google OneTap Error: ", error),
    // onComplete: (response) => // console.log("Google OneTap Success: ", response),
    disabled: auth.user ? true : false,
    showPrompt: false,
    disableCancelOnUnmount: false,
    googleConfig: {
      auto_select: true,
      cancel_on_tap_outside: false,
      callback: handleGoogleOneTapResponse,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    },
  });
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <main className="fill d-flex align-items-center">
        <Paper
          className="login-sheet expand"
          sx={{
            width: 400,
            mx: "auto", // margin left & right
            my: 4, // margin top & botom
            py: 3, // padding top & bottom
            px: 2, // padding left & right
            display: "flex",
            flexDirection: "column",
            gap: 2,
            borderRadius: "sm",
            boxShadow: "md",
          }}
        >
          <div>
            <Typography 
              variant="h5"
              sx={{ 
                alignSelf: "center", 
                fontWeight: "bold",
                color: "#183E4B",
                textAlign: "center",
              }}
            >
              SmartAI Identity Login
            </Typography>
            <br />
            <Typography variant="body1" sx={{ alignSelf: "center" }}>
            ● Sign in with Google <br />              
            </Typography>
            <Typography variant="body2" sx={{ alignSelf: "center" }}>
            (refresh page if Google sign-in box not visible)
            </Typography>


          </div>
          {/* <div>
            <Typography level="h4" component="h1">
              <b>SmartAI Account</b>
            </Typography>
          </div> */}
          <div
            id="google-signin-button"
            className="g_id_signin"
            data-type="standard"
            data-size="large"
            data-theme="outline"
            data-text="sign_in_with"
            data-shape="rectangular"
            data-logo_alignment="left"
          ></div>
          {/* <Button
            sx={{ mt: 1, backgroundColor: "#d74a4a" }}
            onClick={handleLoginWithGoogle}
          >
            Log in with Google
          </Button> */}
          <Divider />
          <Typography fontSize="sm" sx={{ alignSelf: "center" }}>
            <Link
              sx={{ color: "#d74a4a", pt: 0.5 }}
              onClick={() => setShowEmailPassword(!showEmailPassword)}
            >
              Sign in with an Email and Password
            </Link>
          </Typography>
          {showEmailPassword ? (
            <>
              
              <TextField
                variant="outlined"
                type="email"
                label="Email"
                error={
                  validateInput() || email.length < 4 ? "neutral" : "danger"
                }
                onChange={(e) => setEmail(e.target.value)}
                // pass down to FormLabel as children
              />
              <TextField
                variant="outlined"
                type="password"
                autoComplete="password"
                error={error}
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyUp={(e) => { if (e.key === "Enter" && validateInput) handleLogin(); }}
              />
              {error ? (
                <FormHelperText
                  sx={(theme) => ({
                    mx: "auto",
                    color: "red"
                  })}
                >
                  {errorMessage}
                </FormHelperText>
              ) : (
                <></>
              )}
              <Button
                variant="contained"
                onClick={handleLogin}
                disabled={!validateInput()}
              >Sign in</Button>
              
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Link to="/signup" sx={{ alignSelf: "center", pt: 0.5 }}>Don&apos;t have an account?</Link>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Link sx={{ alignSelf: "center", color: "#d74a4a", pt: 0.5 }} to="/reset">Forgot your password?</Link>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Paper>
      </main>
    </>
  );
};

export default LoginPage;
